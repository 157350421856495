import { view } from '@risingstack/react-easy-state'
import { message, List } from 'antd'
import StyledBox from 'components/common/styled-components/StyledBox'
import StyledCountryDetails from 'components/common/styled-components/StyledCountryDetails'
import StyledPageContent from 'components/common/styled-components/StyledPageContent'
import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import NotificationsService from 'services/users/notifications.service'
import globalStore from 'store/index'
import { staleWhileRevalidate } from 'utils/render-strategies'

const NotificationShowPage = () => {
		const pageState = globalStore.ui.configuration.approvalChains.show

		const { notificationId } = useParams()

		useEffect(() => {
			const fetchApprovalChain = async () => {
				const pageState = globalStore.ui.configuration.approvalChains.show
				await NotificationsService.show({ pageState, notificationId })
				const { serverStatus, notificationErrors } = pageState

				if (serverStatus.status !== 200) {
					message.error('Oops!! something went wrong')
					return
				}

				if (notificationErrors) {
					message.error(notificationErrors[0] || "Couldn't fetch Country Details")
					return
				}
			}

			fetchApprovalChain()
		}, [notificationId])

		const { notification, API_USERS_NOTIFICATION_SHOW_STATUS } = pageState
		const { messages } = notification || {}
		const renderContent = () => {
      if (!notification) return null
			return (
				<StyledCountryDetails>
					<List
						bordered
						dataSource={messages}
						renderItem={(item) => <List.Item>{item[0]}</List.Item>}
					/>
				</StyledCountryDetails>
			)
		}

		const renderPageContent = () => {
			return (
				<StyledPageContent>
					<div className='content'>{renderContent()}</div>
				</StyledPageContent>
			)
		}

	const accessibleFeatures = globalStore.currentOrganization?.accessible_features
  if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('budget'))
    return <Redirect to={`/notifications`} />

		return (
			<div className='page-content approval-chains-page'>
				<StyledBox>
					{staleWhileRevalidate({
						status: API_USERS_NOTIFICATION_SHOW_STATUS,
						data: notification,
						render: renderPageContent,
					})}
				</StyledBox>
			</div>
		)
}

export default view(NotificationShowPage)

import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
// import styled from "styled-components"
// import { PlusOutlined } from "@ant-design/icons"
import { message } from "antd"

// import StyledBox from "components/common/styled-components/StyledBox"
// import ConditionRow from "components/ConditionRow"
// import TemplateEditor from "components/TemplateEditor"
import globalStore from "store/index"

import OrganizationsService from "services/users/organizations.service"
// import EmailTemplatesService from "services/users/email-templates.service"

// const StyledConfigurationFormBox = styled.div`
//   & .input-box {
//     margin: 10px 0px;
//   }
//   & .action-buttons.submit {
//     margin-top: 20px;
//   }
// `

// const blankCondition = {
//   field: null,
//   operator: null,
//   values: null,
// }

const EmailTemplateEditPage = () => {
  // const [criteria, setCriteria] = useState([[blankCondition]])
  // const [createButtonLoading, setCreateButtonLoading] = useState(false)
  // const [inputValues, setInputValues] = useState({})

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  // const handleInputChange = (e, inputType) => {
  //   const newInputValues = { ...inputValues, [inputType]: e.target.value }

  //   setInputValues(newInputValues)
  // }

  // const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
  //   setCriteria((prevCriteria) => {
  //     const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
  //       if (conSetIndex !== conditionSetIndex) return conditionSet
  //       const newConditionSet = [...conditionSet]
  //       newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
  //       return newConditionSet
  //     })

  //     return newCriteria
  //   })
  // }

  // const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
  //   setCriteria((prevCriteria) => {
  //     const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
  //       if (conSetIndex !== conditionSetIndex) return conditionSet
  //       const newConditionSet = [...conditionSet]
  //       newConditionSet.splice(deletedConditionIndex, 1)
  //       return newConditionSet
  //     })

  //     return newCriteria
  //   })
  // }

  // const handleUpdateConditionValues = ({
  //   conditionSetIndex,
  //   conditionIndex,
  //   condition,
  // }) => {
  //   if (!condition) return
  //   if (!condition.values) return

  //   setCriteria((prevCriteria) => {
  //     const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
  //       if (conSetIndex !== conditionSetIndex) return conditionSet
  //       const newConditionSet = [...conditionSet]
  //       return newConditionSet.map((c, index) => {
  //         if (index !== conditionIndex) return c

  //         return condition
  //       })
  //     })

  //     return newCriteria
  //   })
  // }

  // const handleUpdate = async () => {
  //   if (!inputValues["name"]) {
  //     message.error("Name can't be blank")
  //     return
  //   }

  //   const pageState = {}
  //   const postData = {
  //     salary_range: {
  //       criteria,
  //       ...inputValues,
  //     },
  //   }

  //   setCreateButtonLoading(true)
  //   await EmailTemplatesService.create({
  //     pageState,
  //     values: postData,
  //   })
  //   setCreateButtonLoading(false)

  //   const { serverStatus, emailTemplateErrors } = pageState

  //   if (emailTemplateErrors) {
  //     message.error(
  //       emailTemplateErrors[0] ||
  //         "Email Template couldn't be created."
  //     )
  //     return
  //   }

  //   if (serverStatus.status === 200) {
  //     message.success("Email template created.")
  //     setCriteria([[blankCondition]])
  //     setInputValues({})
  //     return
  //   }

  //   message.error("Oops!! something went wrong")
  // }

  // const renderPageContent = () => {
  //   return (
  //     <StyledConfigurationFormBox>
  //       <h1>Email Template</h1>
  //       <div className="input-box">
  //         <strong>Name</strong>
  //         <Input
  //           value={inputValues["name"]}
  //           onChange={(e) => handleInputChange(e, "name")}
  //         />
  //       </div>
  //       <div className="input-box">
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //           }}
  //         >
  //           <strong>Criteria</strong>
  //           {criteria.filter((c) => c.length > 0).length === 0 && (
  //             <Button
  //               shape="circle"
  //               onClick={() => setCriteria([[blankCondition]])}
  //             >
  //               <PlusOutlined />
  //             </Button>
  //           )}
  //         </div>
  //         {criteria.map((conditionSet, conditionSetIndex) => {
  //           return (
  //             <div key={conditionSetIndex}>
  //               {conditionSet.map((condition, conditionIndex) => {
  //                 return (
  //                   <ConditionRow
  //                     condition={condition}
  //                     key={conditionIndex}
  //                     lastInputGroup={
  //                       conditionSet.length === conditionIndex + 1
  //                     }
  //                     firstInputGroup={conditionIndex === 0}
  //                     inputGroupIndex={conditionIndex}
  //                     updateConditionValues={(condition) =>
  //                       handleUpdateConditionValues({
  //                         conditionSetIndex,
  //                         conditionIndex,
  //                         condition,
  //                       })
  //                     }
  //                     addNewCondition={() =>
  //                       handleAddNewCondition(conditionSetIndex, conditionIndex)
  //                     }
  //                     removeCondition={() =>
  //                       handleRemoveCondition(conditionSetIndex, conditionIndex)
  //                     }
  //                     organizationFields={
  //                       globalStore.currentOrganization.all_fields_with_datatype
  //                     }
  //                   />
  //                 )
  //               })}
  //             </div>
  //           )
  //         })}
  //       </div>
  //       <div className="input-box">
  //         <strong>Template</strong>
  //         <TemplateEditor />
  //       </div>
  //       <div className="action-buttons submit">
  //         <Button
  //           type="primary"
  //           loading={createButtonLoading}
  //           onClick={handleUpdate}
  //         >
  //           Create Email Template
  //         </Button>
  //       </div>
  //     </StyledConfigurationFormBox>
  //   )
  // }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-edit")
  )
    return <Redirect to="/configuration/salary-ranges" />
  
  return <Redirect to="/configuration/email-templates" />
  

  // return (
  //   <div className="page-content dashboard-page">
  //     <StyledBox>{renderPageContent()}</StyledBox>
  //   </div>
  // )
}

export default view(EmailTemplateEditPage)

import { API, generic } from 'api'

export default class VisitorBudgetApprovalsService {
	static approve = async ({ pageState, token }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_VISITORS_BUDGET_APPROVAL_APPROVE_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalErrors'],
			apiEndpoint: API.visitors.budgetApprovals.approve,
			apiUrlReplacements: { token },
			apiData: {},
			errorMessage: 'Budget Tentative Assign Approval Chain could not be submitted.',
		})
	}
	static reject = async ({ pageState, token, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_VISITORS_BUDGET_APPROVAL_REJECT_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalValuesErrors'],
			apiEndpoint: API.visitors.budgetApprovals.reject,
			apiUrlReplacements: { token },
			apiData: { ...values },
			errorMessage: 'Budget Tentative Assign Approval Chain could not be submitted.',
		})
	}

	static show = async ({ pageState, token }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_VISITORS_BUDGET_PENDING_APPROVAL_SHOW_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalValuesErrors'],
			apiEndpoint: API.visitors.budgetApprovals.show,
			apiUrlReplacements: { token },
			apiData: {},
			errorMessage: 'Pending Approval Chain could not be fetched.',
		})
	}
	static comment = async ({ pageState, token, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_VISITORS_BUDGET_PENDING_APPROVAL_COMMENT_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalValuesErrors'],
			apiEndpoint: API.visitors.budgetApprovals.comment,
			apiUrlReplacements: { token },
			apiData: { ...values },
			errorMessage: 'Pending Approval Chain could not be fetched.',
		})
	}
}

import { API, generic } from "api"

export default class TokensService {
  static login = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_USERS_TOKENS_LOGIN_STATUS",
      stateDataKey: ["admin_user"],
      stateErrorKey: ["adminUserErrors"],
      apiEndpoint: API.adminUsers.tokens.login,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Admin user data could not be loggedin.",
      serializedResponse: false,
    })
  }
}

import React from "react"
import styled from "styled-components"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Input, Button } from "antd"

const StyledApproversForm = styled.div``

const StyledApproverGroupList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  & > div {
    display: flex;
    gap: 20px;

    & > div {
      display: flex;
      gap: 10px;

      & h4 {
        margin: 0px 10px 0px 0px;
      }
      & input {
        width: 450px;
        margin-right: 10px;
      }
      & .action-buttons {
        display: flex;
        & > button {
          margin-right: 10px;
        }
      }
    }
  }
`

const ApproversForm = ({ approvers, setApprovers, initialApprover }) => {
  const addApproverInput = (groupIndex) => {
    const clonedApprovers = JSON.parse(JSON.stringify(approvers))

    const updatedApprovers = clonedApprovers.map((a, i) =>
      i === groupIndex ? [...a, ...initialApprover] : a
    )

    setApprovers(updatedApprovers)
  }

  const removeApproverInput = (groupIndex, index) => {
    const clonedApprovers = JSON.parse(JSON.stringify(approvers))

    clonedApprovers[groupIndex].splice(index)

    setApprovers(clonedApprovers.filter((a) => a.length > 0))
  }

  const addApproverValue = ({ value, groupIndex, index }) => {
    const clonedApprovers = JSON.parse(JSON.stringify(approvers))

    clonedApprovers[groupIndex][index] = value

    setApprovers(clonedApprovers)
  }

  const renderApproverInput = ({
    approver,
    groupIndex,
    hideAddButton,
    index,
  }) => {
    return (
      <div
        style={{ display: "flex", gap: 10 }}
        key={`wrapper-${groupIndex}-${index}`}
      >
        <Input
          key={`input-${groupIndex}-${index}`}
          value={approver}
          placeholder='Enter email'
          onChange={(e) =>
            addApproverValue({ value: e.target.value, groupIndex, index })
          }
        />

        <div className="action-buttons">
          <Button
            danger
            shape="circle"
            onClick={() => removeApproverInput(groupIndex, index)}
            disabled={
              approvers[groupIndex].length === 1 && approvers.length === 1
            }
          >
            <DeleteOutlined />
          </Button>
          {!hideAddButton && (
            <Button
              type="primary"
              ghost
              shape="circle"
              onClick={() => addApproverInput(groupIndex)}
            >
              <PlusOutlined />
            </Button>
          )}
        </div>
      </div>
    )
  }

  const renderApproverList = ({ approvers, groupIndex }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {approvers.map((approver, index) => (
          <div>
            {renderApproverInput({
              key: `approver-${index + 1}`,
              approver,
              groupIndex,
              hideAddButton: index !== approvers.length - 1,
              index,
            })}
          </div>
        ))}
      </div>
    )
  }

  const renderApproverGroupList = () => {
    return (
      <StyledApproverGroupList>
        {approvers.map((a, i) => {
          return (
            <div key={`approver-group-${i + 1}`}>
              <h4>Level {i + 1}</h4>
              {renderApproverList({ groupIndex: i, approvers: a })}
            </div>
          )
        })}
      </StyledApproverGroupList>
    )
  }

  return <StyledApproversForm>{renderApproverGroupList()}</StyledApproversForm>
}

export default ApproversForm

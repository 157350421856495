import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Button, message, Tooltip } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import { staleWhileRevalidate } from "utils/render-strategies"
import { hideExportButtons } from "utils/export"
import globalStore from "store/index"
import FitmentConfigurationsService from "services/users/fitmentConfigurations.service"
import FitmentInputGroup from "components/FitmentInputGroup"

const StyledPageContent = styled.div`
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & input:nth-child(2) {
      margin-left: 15px;
    }
    & .action-buttons {
      margin: 0px;
      & > button {
        margin: 0px 0px 0px 15px;
      }
    }
  }
`

const initialFitmentField = [
  {
    data_type: null,
    name: null,
  },
]

const FitmentConfigurationPage = (props) => {
  const pageState = globalStore.ui.configuration.fitments
  const [fitmentFields, setFitmentFields] = useState(initialFitmentField)
  const [fitmentConfigurationId, setFitmentConfigurationId] = useState(null)
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false)
  const [exportingAuditReports, setExportingAuditReports] = useState(false)
  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.fitments

      await FitmentConfigurationsService.index({ pageState })

      const { fitment_configuration } = pageState

      if (!fitment_configuration || !fitment_configuration.fitment_fields) {
        message.error("Oops!! something went wrong")
        return
      }

      const fitmentFields = fitment_configuration.fitment_fields

      setFitmentConfigurationId(fitment_configuration.id)

      if (fitmentFields.length > 0) {
        setFitmentFields(fitmentFields)
      }
    }

    fetchData()
  }, [])

  const handleUpdate = async () => {
    const pageState = {}
    const postData = {
      fitment_configuration: {
        fitment_fields: fitmentFields,
      },
    }

    setUpdateButtonLoading(true)
    await FitmentConfigurationsService.update({
      pageState,
      values: postData,
      fitmentConfigurationId,
    })
    setUpdateButtonLoading(false)

    const { serverStatus } = pageState

    if (serverStatus.status !== 200) {
      message.error("Failed to update.")
      return
    }

    message.success("Updated")
  }

  const handleRemoveInputGroup = (index) => {
    if (fitmentFields.length === 1) return

    const filteredFitmentFields = fitmentFields.filter((a, i) => i !== index)
    setFitmentFields(filteredFitmentFields)
  }

  const handleAddInputGroup = () => {
    setFitmentFields([...fitmentFields, initialFitmentField])
  }

  const handleUpdateFitmentFields = (fitmentField, index) => {
    const newFitmentFields = fitmentFields.map((ff, i) =>
      i === index ? fitmentField : ff
    )

    setFitmentFields(newFitmentFields)
  }

  const handleExportAuditReports = async () => {
    const tempState = {}

    setExportingAuditReports(true)
    await FitmentConfigurationsService.auditReports({ pageState: tempState })
    setExportingAuditReports(false)

    message.success(
      "Export started. Kindly check export page in Report Center to see exported audit reports"
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <h1>Fitment Fields</h1>
          <div>
            {hideExportButtons(accessibleFeatures) && (
              <Tooltip title="Export audit reports">
                <Button
                  type="primary"
                  ghost
                  loading={exportingAuditReports}
                  onClick={handleExportAuditReports}
                  style={{ marginRight: 10 }}
                >
                  {exportingAuditReports
                    ? "Exporting Audit Reports..."
                    : "Export Audit Reports"}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>

        {fitmentFields.map((ff, i) => (
          <FitmentInputGroup
            index={i}
            fitmentField={ff}
            removeInputGroup={handleRemoveInputGroup}
            addInputGroup={handleAddInputGroup}
            updateFitmentFields={(fitmentField) => {
              handleUpdateFitmentFields(fitmentField, i)
            }}
          />
        ))}
        <div>
          <div className="action-buttons">
            <Button
              type="primary"
              loading={updateButtonLoading}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
        </div>
      </StyledPageContent>
    )
  }

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-edit")
  )
    return <Redirect to="/" />

  const { API_USERS_FITMENT_CONFIGURATIONS_INDEX_STATUS } = pageState

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_FITMENT_CONFIGURATIONS_INDEX_STATUS,
          data: fitmentFields,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(FitmentConfigurationPage)

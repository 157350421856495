import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import dayjs from "dayjs"
import { Table, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"

import ExportsService from "services/users/exports.service"

const localizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(localizedFormat)

const StyledPageContent = styled.div`
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & input:nth-child(2) {
      margin-left: 15px;
    }
    & .action-buttons {
      margin: 0px;
      & > button {
        margin: 0px 0px 0px 15px;
      }
    }
  }
  & .status {
    display: flex;
    align-items: baseline;
    & .status-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: gray;
      margin-right: 10px;
    }
    & .status-circle.orange {
      background: orange;
    }
    & .status-circle.green {
      background: green;
    }
  }
`

const ExportPage = (props) => {
  const pageState = globalStore.ui.export
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const tempState = {}

      await ExportsService.index({ pageState: tempState })

      const { exports } = tempState

      if (Array.isArray(exports)) {
        setData(formateData(exports))
        return
      }
    }

    fetchData()
  }, [])

  const formateData = (exports) => {
    return exports.map(
      ({ id, status_display, export_type_display, created_at, user, name }, i) => {
        return {
          key: i,
          id,
          user,
          status: status_display,
          exportedTable: name ?? export_type_display,
          exportedTime: dayjs(created_at).format("DD-MMM-YYYY hh:mm A"),
        }
      }
    )
  }

  const handleDownload = (exportId) => {
    window.open(
      `${process.env.REACT_APP_FRONTEND_HOST}/exports/${exportId}/download`
    )

    return
  }

  const getStatusColor = (status) => {
    if (status === "Completed") {
      return "green"
    }
    if (status === "Pending") {
      return "orange"
    }
    return ""
  }

  const columns = [
    {
      title: "Exported Data",
      dataIndex: "exportedTable",
      key: "exportedTable",
    },
    {
      title: "Exported By",
      dataIndex: "user",
      key: "user",
      render: (user) => {
        return user && user.email
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, row) => {
        return (
          <div className="status">
            <div className={`status-circle ${getStatusColor(value)}`}></div>{" "}
            {value}
          </div>
        )
      },
    },
    {
      title: "Exported Time",
      dataIndex: "exportedTime",
      key: "exportedTime",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, row) =>
        row.status === "Completed" ? (
          <Button type="link" onClick={() => handleDownload(row.id)}>
            Download
          </Button>
        ) : null,
    },
  ]

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <Table dataSource={data} columns={columns} />
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("report-view")
  )
    return <Redirect to="/" />

  const { API_USERS_EXPORTS_INDEX_STATUS } = pageState

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_EXPORTS_INDEX_STATUS,
          data: data,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(ExportPage)

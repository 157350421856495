import { API, generic } from "api"

export default class BudgetCriteriaValuesService {
  static index = async ({ budgetCriteriaHeadId, pageState, page = 1, pageSize = 25, bcsla }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_INDEX_STATUS",
      stateDataKey: ["budget_criteria_values", "total", "page", "page_size"],
      stateErrorKey: [
        "budgetCriteriaValuesErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
      ],
      apiEndpoint: API.users.budget_criteria_heads.budget_criteria_values.index,
      apiUrlReplacements: { budgetCriteriaHeadId },
      apiData: { params: { page, page_size: pageSize, bcsla } },
      errorMessage: "Budget Values could not be fetched.",
    })
  }

  static create = async ({ pageState, budgetCriteriaHeadId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_CREATE_STATUS",
      stateDataKey: ["budget_criteria_value"],
      stateErrorKey: ["budgetCriteriaValueErrors"],
      apiEndpoint: API.users.budget_criteria_heads.budget_criteria_values.create,
      apiUrlReplacements: { budgetCriteriaHeadId },
      apiData: { ...values },
      errorMessage: "Budget Value could not be created.",
  })
  }

  static show = async ({ pageState, budgetCriteriaValueId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_SHOW_STATUS",
      stateDataKey: ["budget_criteria_value"],
      stateErrorKey: ["budgetCriteriaValuesErrors"],
      apiEndpoint: API.users.budget_criteria_heads.budget_criteria_values.show,
      apiUrlReplacements: { budgetCriteriaValueId },
      apiData: {},
      errorMessage: "Budget Value could not be fetched.",
    })
  }

  static update = async ({ pageState, values, budgetCriteriaValueId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_BUDGET_CRITERIA_VALUES_UPDATE_STATUS",
      stateDataKey: ["budget_criteria_value"],
      stateErrorKey: ["budgetCriteriaValueErrors"],
      apiEndpoint: API.users.budget_criteria_heads.budget_criteria_values.update,
      apiUrlReplacements: { budgetCriteriaValueId },
      apiData: { ...values },
      errorMessage: "Budget Value could not be updated.",
    })
  }

  static delete = async ({ pageState, budgetCriteriaValueId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_BUDGET_CRITERIA_VALUES_DELETE_STATUS",
      stateDataKey: ["budget_criteria_value"],
      stateErrorKey: ["budgetCriteriaValueErrors"],
      apiEndpoint: API.users.budget_criteria_heads.budget_criteria_values.delete,
      apiUrlReplacements: { budgetCriteriaValueId },
      apiData: {},
      errorMessage: "Budget value could not be deleted.",
    })
  }

  static parent_budget_criteria_value = async ({ pageState, budgetCriteriaHeadId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_BUDGET_CRITERIA_VALUES_PARENT_BUDGET_CRITERIA_VALUE_STATUS",
      stateDataKey: ["parent_budget_criteria_values"],
      stateErrorKey: ["parentBudgetCriteriaValueErrors"],
      apiEndpoint: API.users.budget_criteria_heads.budget_criteria_values.parent_budget_criteria_value,
      apiUrlReplacements: { budgetCriteriaHeadId },
      apiData: { },
      errorMessage: "Budget Value could not be updated.",
    })
  }
}

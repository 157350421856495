import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { EyeOutlined } from "@ant-design/icons"
import { message, Button, Empty, Pagination, Tooltip } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import AccessGroupsService from "services/users/access-groups.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const AccessGroupsIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.accessGroups.index

  useEffect(() => {
    const fetchBudgets = async () => {
      const pageState = globalStore.ui.accessGroups.index

      await AccessGroupsService.index({ pageState })

      const { serverStatus, accessGroupsErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (accessGroupsErrors) {
        message.error(
          accessGroupsErrors[0] || "Access groups couldn't be fetched."
        )
        return
      }
    }

    fetchBudgets()
  }, [])

  const repaginate = async (page, pageSize, bcsla = null) => {
    if (bcsla) {
      await AccessGroupsService.index({ pageState, page, pageSize, bcsla })
      return
    }
    await AccessGroupsService.index({ pageState, page, pageSize })
  }

  const handleCreate = () => {
    history.push("/access-groups/new")
  }

  const handleCardItemClick = (accessGroupId) => {
    history.push(`/access-groups/${accessGroupId}`)
  }

  const {
    access_groups,
    access_groupsMeta,
    API_USERS_ACCESS_GROUPS_INDEX_STATUS,
    // displayType = "table",
  } = pageState

  const { total, page: currentPage, page_size: pageSize } =
    access_groupsMeta || {}

  const renderList = () => {
    return (
      <StyledList>
        {access_groups.map((ic) => (
          <div
            className="item-card"
            key={`item-card-${ic.id}`}
            onClick={() => handleCardItemClick(ic.id)}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{ic.name}</div>
                <div>{}</div>
              </div>
            </div>
            <div className="icon-box">
              <Button type="link">
                <EyeOutlined />
              </Button>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderContents = () => {
    if (access_groups.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            Create Access Group
          </Button>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    )

    // const tableColumns = [
    //   {
    //     title: "Name",
    //     dataIndex: "name",
    //     key: "name",
    //   },
    //   {
    //     title: "Tags",
    //     dataIndex: "tags",
    //     key: "tags",
    //   },
    //   {
    //     title: "Action",
    //     dataIndex: "action",
    //     key: "action",
    //     render: (value, ic) => {
    //       return (
    //         <Button type="link" onClick={() => handleCardItemClick(ic.id)}>
    //           <EyeOutlined />
    //         </Button>
    //       )
    //     },
    //   },
    // ]

    // const renderTable = () => {
    //   return (
    //     <Table
    //       columns={tableColumns}
    //       dataSource={access_groups}
    //       pagination={false}
    //       scroll={{
    //         x: 1000,
    //       }}
    //     ></Table>
    //   )
    // }

    const renderInfo = () => {
      // if (displayType === "table") return renderTable()
      return renderList()
    }

    return (
      <div>
        {pagination}
        {networkOnly({
          status: API_USERS_ACCESS_GROUPS_INDEX_STATUS,
          data: access_groups,
          render: renderInfo,
        })}

        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {
    // const bcslaSearch = (
    //   <Input.Search
    //     placeholder="BCSLA"
    //     onSearch={(bcsla) => repaginate(1, pageSize, bcsla)}
    //     style={{ width: 200, marginBottom: "10px" }}
    //   />
    // )

    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{/* bcslaSearch */}</div>
          <div className="action-buttons top">
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                Create Access Group
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  // const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  // if (
  //   accessibleFeatures?.length > 0 &&
  //   !accessibleFeatures.includes("fitment-data-view")
  // )
  //   return <Redirect to="/" />

  return (
    <div className="page-content">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_ACCESS_GROUPS_INDEX_STATUS,
          data: access_groups,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(AccessGroupsIndexPage)

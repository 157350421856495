import React, { useEffect, useState } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { EyeOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons"
import {
  Button,
  message,
  Empty,
  Tooltip,
  Table,
  Pagination,
  Popconfirm,
} from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import ControlFeaturesService from "services/users/control-features.service"

import {
  CONTROL_FEATURE_STATUES,
  CONTROL_FEATURE_TYPES,
} from "constants/control-features"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const ControlFeaturesIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.configuration.controlFeatures.index
  const [deletingControlFeatureId, setDeletingControlFeatureId] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.controlFeatures.index

      await ControlFeaturesService.index({ pageState })

      const { serverStatus, controlFeaturesErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (controlFeaturesErrors) {
        message.error(
          controlFeaturesErrors[0] || "Coundn't fetch control features"
        )
        return
      }
    }

    fetchData()
  }, [])

  const handleCreate = () => {
    history.push("/configuration/control-features/new")
  }

  const handleCardItemClick = (controlFeatureId) => {
    history.push(`/configuration/control-features/${controlFeatureId}`)
  }

  const repaginate = async (page, pageSize) => {
    await ControlFeaturesService.index({ pageState, page, pageSize })
  }

  const handleDelete = async (controlFeatureId) => {
    const tempState = {}

    setDeletingControlFeatureId(controlFeatureId)
    await ControlFeaturesService.delete({
      pageState: tempState,
      controlFeatureId,
    })
    setDeletingControlFeatureId(null)

    const { serverStatus } = tempState

    if (serverStatus.status !== 200) return

    globalStore.ui.configuration.controlFeatures.index = {
      ...globalStore.ui.configuration.controlFeatures.index,
      control_features: control_features.filter(
        (controlFeature) => controlFeature.id !== controlFeatureId
      ),
    }

    message.success("Deleted")
  }

  const {
    control_features,
    control_featuresMeta,
    API_USERS_CONTROL_FEATURES_INDEX_STATUS,
  } = pageState

  const {
    total,
    page: currentPage,
    page_size: pageSize,
  } = control_featuresMeta || {}

  const renderContents = () => {
    if (control_features.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            Create Control Feature
          </Button>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    )

    const tableColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Feature Type",
        dataIndex: "feature_type",
        key: "feature_type",
        render: (value) => (
          <span>
            {CONTROL_FEATURE_TYPES.find((t) => t.value === value)?.label}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value) => (
          <span>
            {CONTROL_FEATURE_STATUES.find((t) => t.value === value)?.label}
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (value, ic) => {
          return (
            <div style={{ display: "flex", gap: 5 }}>
              <Tooltip title="View">
                <Button type="link" onClick={() => handleCardItemClick(ic.id)}>
                  <EyeOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Delete">
                {deletingControlFeatureId === ic.id ? (
                  <Button type="link">
                    <LoadingOutlined />
                  </Button>
                ) : (
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => handleDelete(ic.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                )}
              </Tooltip>
            </div>
          )
        },
      },
    ]

    const renderTable = () => {
      return (
        <Table
          columns={tableColumns}
          dataSource={control_features}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        ></Table>
      )
    }

    return (
      <div>
        {pagination}
        <div style={{ margin: "20px 0px" }}>
          {networkOnly({
            status: API_USERS_CONTROL_FEATURES_INDEX_STATUS,
            data: control_features,
            render: renderTable,
          })}
        </div>
        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div></div>
          <div className="action-buttons top">
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                New Control Feature
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("control_feature")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content ss-configurations-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_CONTROL_FEATURES_INDEX_STATUS,
          data: control_features,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(ControlFeaturesIndexPage)

import { API, generic } from "api"

export default class DashboardService {
  static index = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_DASHBOARD_INDEX_STATUS",
      stateDataKey: ["unserialized"],
      stateErrorKey: ["unserializedErrors"],
      apiEndpoint: API.users.dashboard.index,
      apiUrlReplacements: {},
      apiData: {params: {...values}},
      errorMessage: "Dashboard data could not be fetched.",
      serializedResponse: false,
    })
  }
}

import { view } from '@risingstack/react-easy-state'
import { Button, message } from 'antd'
import StyledBox from 'components/common/styled-components/StyledBox'
import StyledCountryDetails from 'components/common/styled-components/StyledCountryDetails'
import StyledPageContent from 'components/common/styled-components/StyledPageContent'
import TemplateEditor from 'components/TemplateEditor'
import React, { useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import CountryDetailsService from 'services/users/country-details.service'
import globalStore from 'store/index'
import { staleWhileRevalidate } from 'utils/render-strategies'

const frequencies = {
	hourly: 'Hourly',
	daily: 'Daily',
	bi_weekly: 'Bi Weekly',
	semi_monthly: 'Semi Monthly',
	monthly: 'Monthly',
	quarterly: 'Quarterly',
	half_yearly: 'Half-Yearly',
	annually: 'Annually',
}

const CountryDetailsShowPage = () => {
	const pageState = globalStore.ui.configuration.approvalChains.show
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	const history = useHistory()
	const { countryId } = useParams()

	useEffect(() => {
		const fetchApprovalChain = async () => {
			const pageState = globalStore.ui.configuration.approvalChains.show
			await CountryDetailsService.show({ pageState, countryId })
			const { serverStatus, countriesDetailErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (countriesDetailErrors) {
				message.error(countriesDetailErrors[0] || "Couldn't fetch Country Details")
				return
			}
		}

		fetchApprovalChain()
	}, [countryId])

	const handleEdit = () => {
		history.push(`/configuration/country-details/${countryId}/edit`)
	}

	const { country_detail, API_USERS_APPROVAL_CHAINS_SHOW_STATUS } = pageState
	const {
		details,
		name,
		envelope_body_content: envelopeBodyContent,
		envelope_body_content_for_letters: envelopeBodyContentForLetters,
	} = country_detail || {}

	const getValue = (data) => {
		if (data.name === 'Default Frequency') {
			return frequencies[data.value]
		}
		if (data.name === 'Disable Frequency') {
			return data.value ? 'Yes' : 'No'
		}
		return data?.value.toString()
	}

	const renderContent = () => {
		if (!country_detail) return null
		return (
			<StyledCountryDetails>
				<div className='card fitment-fields'>
					<div className='item-list'>
						<div className='item-row fitment-field'>
							<div className='label'>Country Name</div>
							<div>{name}</div>
						</div>
						{details.map((data, i) => (
							<div key={i} className='item-row fitment-field'>
								<div className='label'>{data?.name}</div>
								<div>
									<span>{getValue(data)}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				<div>
					<strong>DocuSign Mail Content for Offer Letters :</strong>
					<TemplateEditor customId='editor1' content={envelopeBodyContent} editable={false} />
				</div>
				<div>
					<strong>DocuSign Mail Content for Additional Letters :</strong>
					<TemplateEditor
						customId='editor2'
						content={envelopeBodyContentForLetters}
						editable={false}
					/>
				</div>
			</StyledCountryDetails>
		)
	}

	const renderActionButtons = () => {
		if (!accessibleFeatures.includes('organization-edit')) return null

		const actionButtons = [
			<Button type='primary' ghost onClick={handleEdit} key='edit-button'>
				Edit
			</Button>,
		]

		return actionButtons
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='page-header'>
					<div></div>
					<div className='action-buttons top'>
						{renderActionButtons()}

						{/* {<Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button>} */}
					</div>
				</div>

				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('organization-edit'))
		return <Redirect to={`/configuration/country-details/`} />

	return (
		<div className='page-content approval-chains-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_APPROVAL_CHAINS_SHOW_STATUS,
					data: country_detail,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(CountryDetailsShowPage)

import styled from "styled-components";

const StyledLoginPageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > .form__box {
    width: 400px;
    max-width: 440px;
    padding: 30px;
    margin: 50px auto 0px auto;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px #d9d9d912;
    // border-bottom: 4px solid #d8d8d8;
  }
`;

export default StyledLoginPageContent;

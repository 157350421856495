import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { view } from '@risingstack/react-easy-state'
import StyledBox from 'components/common/styled-components/StyledBox'
import globalStore from 'store/index'
import dayjs from 'dayjs'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { Select, message } from 'antd'
import { useParams, Redirect } from 'react-router-dom'
import BudgetApprovalWorkFlowForm from 'components/BudgetApprovalWorkFlowForm'
import BudgetTentativeValuesService from 'services/users/budget-tentative-values.service'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`
const AdminBudgetApprovalStatusEditPage = () => {
	const { budgetTentativeValueId } = useParams()
	const [budgetHead, setBudgetHead] = useState()
	const [budgetHeads, setBudgetHeads] = useState([])
	const [budgetHeadId, setBudgetHeadId] = useState()
	const recordTypes = [
		{ name: 'Planned Value', id: 1 },
		{ name: 'Actual Value', id: 2 },
	]
	const [recordType, setRecordType] = useState(recordTypes[0])
	const [budgetTentativeData, setBudgetTentativeData] = useState()

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list

		const fetchAllFieldsData = async () => {
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
		}

		fetchAllFieldsData()
	}, [])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetHeads.index

		const fetchBudgetHeads = async () => {
			await BudgetHeadsService.index({ pageState })
			const { budget_heads } = pageState

			if (!budget_heads) {
				message.error('Budget Heads not found')
				return
			}
			setBudgetHeads(budget_heads)
		}
		fetchBudgetHeads()
	}, [])

	useEffect(() => {
		const fetchBudgetValue = async () => {
			const pageState = globalStore.ui.configuration.budgetTentativeValues.show

			await BudgetTentativeValuesService.show({ pageState, budgetTentativeValueId })
			const { serverStatus, budgetValueErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetValueErrors) {
				message.error(budgetValueErrors[0] || "Coundn't fetch Budget Value")
				return
			}
			const { budget_tentative_values } = pageState
			setBudgetHead(budget_tentative_values?.budget_head?.name)
			setBudgetHeadId(budget_tentative_values?.budget_head?.id)
			setRecordType(
				budget_tentative_values?.budget_type === 'planned' ? recordTypes[0] : recordTypes[1]
			)
			setBudgetTentativeData(budget_tentative_values)
		}

		if (budgetTentativeValueId) fetchBudgetValue()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [budgetTentativeValueId])

	const handleDropDownChange = (value, inputType) => {
		if (inputType === 'record_type') {
			setRecordType(recordTypes.find((rt) => rt.name === value))
			return
		}
		if (inputType === 'budget_heads') {
			setBudgetHead(value)
			setBudgetHeadId(budgetHeads.find((bh) => bh.name === value)?.id)
		}
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'baseline',
						marginTop: '25px',
					}}>
					<span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Budget Approval</span>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'baseline',
						marginTop: '25px',
					}}>
					<div
						style={{
							marginTop: '5px',
							display: 'flex',
							justifyContent: 'start',
							alignItems: 'center',
						}}>
						<div style={{ marginRight: '5px' }}> Approval For </div>
						<div>
							<Select
								style={{ minWidth: '260px' }}
								defaultValue={recordTypes[0]?.name}
								disabled
								onChange={(e) => handleDropDownChange(e, 'record_type')}>
								{recordTypes.map((rt) => (
									<Select.Option key={rt.id} value={rt.name} title={rt.name}>
										{rt.name}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}>
						<div style={{ marginRight: '10px' }}>Budget Head</div>
						<div>
							<Select
								showSearch
								style={{ minWidth: '260px' }}
								value={budgetHead}
								disabled
								optionLabelProp='label'
								onChange={(e) => handleDropDownChange(e, 'budget_heads')}>
								{budgetHeads.map((bh) => (
									<Select.Option key={bh.id} value={bh.name} title={bh.name}>
										{bh.display_name}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>
				</div>
				<div>
					<BudgetApprovalWorkFlowForm
						budgetTentativeData={budgetTentativeData?.tentative_data}
						budgetHeadId={budgetHeadId}
						budgetHead={budgetHead}
						recordType={recordType}
						edit={true}
						budgetTentativeValueId={budgetTentativeValueId}
					/>
				</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['smart-budget', 'criteria-edit', 'budget-data-edit'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/' />

	return (
		<div className='page-content budget_values_approval-page'>
			<StyledBox>
				<div>{renderPageContent()}</div>
			</StyledBox>
		</div>
	)
}

export default view(AdminBudgetApprovalStatusEditPage)

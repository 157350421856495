import { API, generic } from "api"

export default class TokensService {
  static login = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_TOKENS_LOGIN_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.tokens.login,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "User could not be logged in.",
      serializedResponse: false,
    })
  }

  static srOauthAccessCallback = async ({ pageState, code }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_TOKENS_SR_OAUTH_ACCESS_CALLBACK_STATUS",
      stateDataKey: ["response"],
      stateErrorKey: ["responseErrors"],
      apiEndpoint: API.users.tokens.srOauthAccessCallback,
      apiUrlReplacements: { code },
      apiData: {},
      errorMessage: "OAuth couldn't be completed.",
      serializedResponse: false,
    })
  }
}

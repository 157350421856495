import React, { useEffect, useState } from 'react'
import StyledBox from 'components/common/styled-components/StyledBox'
import styled from 'styled-components'
import { Button, message, Modal, Input, Tooltip } from 'antd'
import { Color } from 'constants/theme'
import { useParams } from 'react-router-dom'
import globalStore from 'store'
import BudgetApprovalsService from 'services/users/budget-approvals.service'
import VisitorBudgetApprovalsService from 'services/visitors/budget-approvals.service'
import CriteriaSet from 'components/CriteriaSet'
import dayjs from 'dayjs'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import Comments from 'components/Comments'
import { statusColor } from 'helpers/status-color.helper'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`

const StyledItemList = styled.div`
	& > div {
		margin-bottom: 50px;
	}

	& .item-row {
		display: flex;
		margin-bottom: 5px;
		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			max-width: 250px;
			justify-content: flex-end;
			text-transform: uppercase;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
		}
	}
	& .colored.item-row {
		& div:last-child {
			background: #f9f3ff;
		}
	}
`

const PendingBudgetApprovalShowPage = () => {
	const { pendingApprovalId, token } = useParams()
	const pageState = globalStore.ui.budgetApproval.pendingBudgetApprovals.show
	const [budgetApproval, setBudgetApproval] = useState()
	const [budgetTentativeValue, setBudgetTentativeValue] = useState()
	const [approveLoading, setApproveLoading] = useState(false)
	const [rejectButtonLoading, setRejectButtonLoading] = useState(false)
	const [commentButtonLoading, setCommentButtonLoading] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const [comment, setComment] = useState(null)

	useEffect(() => {
		const fetchPendingApprovalShow = async () => {
			const pageState = globalStore.ui.budgetApproval.pendingBudgetApprovals.show
			if (pendingApprovalId) await BudgetApprovalsService.show({ pageState, pendingApprovalId })
			else if (token) await VisitorBudgetApprovalsService.show({ pageState, token })
			const { budget_approval, budgetApprovalValuesErrors } = pageState
			if (budgetApprovalValuesErrors) {
				message.error({
					content: budgetApprovalValuesErrors[0],
				})
				return
			}

			setBudgetApproval(budget_approval)
			setBudgetTentativeValue(budget_approval?.budget_tentative_value)
		}
		fetchPendingApprovalShow()
	}, [pendingApprovalId, token])

	const handleApprove = async () => {
		const tempState = {}
		setApproveLoading(true)
		if (pendingApprovalId)
			await BudgetApprovalsService.approve({
				pageState: tempState,
				budgetTentativeValueId: pendingApprovalId,
			})
		else if (token)
			await VisitorBudgetApprovalsService.approve({
				pageState: tempState,
				token,
			})
		setApproveLoading(false)
		const { budget_approval, budgetApprovalValuesErrors } = tempState

		if (budgetApprovalValuesErrors) {
			message.error({
				content: budgetApprovalValuesErrors[0],
				key: 'updatable',
			})
			return
		}

		setBudgetApproval(budget_approval)
		setBudgetTentativeValue(budget_approval?.budget_tentative_value)

		message.success({
			content: 'Budget approval approved.',
			key: 'updatable',
		})
	}

	const handleReject = async () => {
		if (!comment) {
			message.error("Reason couldn't be blank")
			return
		}

		const tempState = {}
		const postData = {
			comment,
		}
		setRejectButtonLoading(true)
		message.loading({
			content: 'Rejecting fitment approval...',
			key: 'updatable',
		})
		if (pendingApprovalId)
			await BudgetApprovalsService.reject({
				pageState: tempState,
				budgetTentativeValueId: pendingApprovalId,
				values: postData,
			})
		else if (token)
			await VisitorBudgetApprovalsService.reject({
				pageState: tempState,
				token,
				values: postData,
			})

		const { budget_approval, budgetApprovalValuesErrors } = tempState

		if (budgetApprovalValuesErrors) {
			message.error({
				content: budgetApprovalValuesErrors[0],
				key: 'updatable',
			})
			return
		}

		setRejectButtonLoading(false)
		setModalVisible(false)
		setBudgetApproval(budget_approval)
		setBudgetTentativeValue(budget_approval?.budget_tentative_value)

		message.success({
			content: 'Budget approval rejected.',
			key: 'updatable',
		})
	}

	const handleComment = async (comment) => {
		if (!comment) {
			message.error("Comment couldn't be blank")
			return
		}

		const tempState = {}

		const postData = {
			comment,
		}

		setCommentButtonLoading(true)
		if (pendingApprovalId)
			await BudgetApprovalsService.comment({
				pageState: tempState,
				pendingApprovalId,
				values: postData,
			})
		else if (token)
			await VisitorBudgetApprovalsService.comment({
				pageState: tempState,
				token,
				values: postData,
			})
		setCommentButtonLoading(false)

		const { budget_approval, budgetApprovalValuesErrors } = tempState

		if (budgetApprovalValuesErrors) {
			message.error(budgetApprovalValuesErrors[0] || "Comment couldn't be submitted.")
			return
		}
		message.success('Comment submitted.')
		setBudgetApproval(budget_approval)
		setBudgetTentativeValue(budget_approval?.budget_tentative_value)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Button
						type='primary'
						ghost
						onClick={handleApprove}
						loading={approveLoading}
						disabled={['approved', 'rejected'].includes(budgetApproval?.status)}>
						Approve
					</Button>
					<Button
						onClick={() => setModalVisible(true)}
						danger
						disabled={['approved', 'rejected'].includes(budgetApproval?.status)}>
						Reject
					</Button>
				</div>
				<div className='content'>
					<StyledItemList>
						<div>
							<div className='item-row colored'>
								<div className='label'>Budget Name</div>
								<div>{budgetTentativeValue?.budget_head?.name}</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Budget Type</div>
								<div className='text-capitalize'>{budgetTentativeValue?.budget_type}</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Status</div>
								<div className='text-capitalize'>
									<Tooltip
										title={budgetApproval?.status && budgetApproval?.status.split('_').join(' ')}>
										<div
											style={{
												background: statusColor(budgetApproval?.status),
												width: 10,
												height: 10,
												borderRadius: '50%',
												margin: '0 5px',
											}}></div>
									</Tooltip>
									{budgetApproval?.status && budgetApproval?.status.split('_').join(' ')}
								</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Currency</div>
								<div>
									{budgetTentativeValue?.budget_type === 'planned'
										? budgetTentativeValue?.tentative_data?.budget_value?.currency
										: budgetTentativeValue?.tentative_data?.budget_utilization?.currency}
								</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Count</div>
								<div>
									{budgetTentativeValue?.budget_type === 'planned'
										? budgetTentativeValue?.tentative_data?.budget_value?.unit
										: budgetTentativeValue?.tentative_data?.budget_utilization?.unit}
								</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Rate</div>
								<div>
									{budgetTentativeValue?.budget_type === 'planned'
										? budgetTentativeValue?.tentative_data?.budget_value?.rate
										: budgetTentativeValue?.tentative_data?.budget_utilization?.rate}
								</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Frequency</div>
								<div>
									{budgetTentativeValue?.budget_type === 'planned'
										? budgetTentativeValue?.tentative_data?.budget_value?.frequency
												?.charAt(0)
												.toUpperCase() +
										  budgetTentativeValue?.tentative_data?.budget_value?.frequency?.slice(1)
										: budgetTentativeValue?.tentative_data?.budget_utilization?.frequency
												?.charAt(0)
												.toUpperCase() +
										  budgetTentativeValue?.tentative_data?.budget_utilization?.frequency?.slice(1)}
								</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Total Cost</div>
								<div>
									{budgetTentativeValue?.budget_type === 'planned'
										? budgetTentativeValue?.tentative_data?.budget_value?.total_cost
										: budgetTentativeValue?.tentative_data?.budget_utilization?.total_value}
								</div>
							</div>
							<div className='item-row colored'>
								<div className='label'>Start Date</div>
								<div>
									{budgetTentativeValue?.budget_type === 'planned'
										? dayjs(budgetTentativeValue?.tentative_data?.budget_value?.start_date).format(
												'DD-MMM-YYYY'
										  )
										: dayjs(
												budgetTentativeValue?.tentative_data?.budget_utilization?.start_date
										  ).format('DD-MMM-YYYY')}
								</div>
							</div>
							<div className='item-row colored'>
								<div className='label'> End Date </div>
								<div>
									{budgetTentativeValue?.budget_type === 'planned'
										? dayjs(budgetTentativeValue?.tentative_data?.budget_value?.end_date).format(
												'DD-MMM-YYYY'
										  )
										: dayjs(
												budgetTentativeValue?.tentative_data?.budget_utilization?.end_date
										  ).format('DD-MMM-YYYY')}
								</div>
							</div>
						</div>
					</StyledItemList>
					<CriteriaSet
						criteria={
							budgetTentativeValue?.budget_type === 'planned'
								? budgetTentativeValue?.tentative_data?.budget_value?.criteria || []
								: budgetTentativeValue?.tentative_data?.budget_utilization?.criteria || []
						}
					/>
					<Comments
						comments={budgetTentativeValue?.comments || []}
						commentButtonLoading={commentButtonLoading}
						commentAble
						onComment={handleComment}
					/>
					<Modal
						title='Reason'
						closable={false}
						maskClosable={false}
						visible={modalVisible}
						onOk={handleReject}
						okText='Reject'
						confirmLoading={rejectButtonLoading}
						onCancel={() => setModalVisible(false)}>
						<Input.TextArea
							allowClear
							autoSize={{ minRows: 4 }}
							onChange={(e) => setComment(e.target.value)}
						/>
					</Modal>
				</div>
			</StyledPageContent>
		)
	}

	const {
		budget_approval,
		API_USERS_BUDGET_PENDING_APPROVAL_SHOW_STATUS,
		API_VISITORS_BUDGET_PENDING_APPROVAL_SHOW_STATUS,
	} = pageState
	const strategy =
		budget_approval && budget_approval.id === pendingApprovalId ? staleWhileRevalidate : networkOnly

	return (
		<div className='page-content dashboard-page' style={{ margin: 'auto', width: pendingApprovalId ? 'auto' : '45%' }}>
			<StyledBox>
				{strategy({
					status: pendingApprovalId
						? API_USERS_BUDGET_PENDING_APPROVAL_SHOW_STATUS
						: API_VISITORS_BUDGET_PENDING_APPROVAL_SHOW_STATUS,
					data: budget_approval,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default PendingBudgetApprovalShowPage

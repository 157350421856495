import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { message, Button } from 'antd'
import moment from 'moment'

import StyledBox from 'components/common/styled-components/StyledBox'

import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import { Color } from 'constants/theme'
import WorkForcePeriodService from 'services/users/workforce-period.service'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`

const StyledItemList = styled.div`
	& > div {
		margin-bottom: 50px;
	}

	& .item-row {
		display: flex;
		margin-bottom: 5px;
		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			max-width: 250px;
			justify-content: flex-end;
			text-transform: uppercase;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
		}
	}
	& .colored.item-row {
		& div:last-child {
			background: #f9f3ff;
		}
	}
`
const WorkForcePeriodShow = () => {
	const pageState = globalStore.ui.configuration.workforcePeriod.show
	const history = useHistory()
	const { workforcePeriodID } = useParams()

	useEffect(() => {
		const fetchBudget = async () => {
			const pageState = globalStore.ui.configuration.workforcePeriod.show
			await WorkForcePeriodService.show({ pageState, workforcePeriodID })

			const { serverStatus, workforcePeriodsErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (workforcePeriodsErrors) {
				message.error(workforcePeriodsErrors[0] || "WorkForce Period couldn't be fetched.")
				return
			}
		}

		fetchBudget()
	}, [workforcePeriodID])

	const handleEdit = () => {
		history.push(`/configuration/workforce-period/${workforcePeriodID}/edit`)
	}

	const { workforce_period, API_USERS_WORKFORCE_PERIOD_SHOW_STATUS } = pageState

	const renderContent = () => {
		if (!workforce_period) return null

		const { start_date, end_date, current } = workforce_period

		return (
			<div>
				<h1>{workforce_period.name}</h1>
				<StyledItemList>
					<div>
						<div className='item-row colored'>
							<div className='label'>Start date</div>
							<div>{moment.utc(start_date).format('DD-MMM-YYYY')}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>End Date</div>
							<div>{moment.utc(end_date).format('DD-MMM-YYYY')}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Current</div>
							<div>{current ? 'true' : 'false'}</div>
						</div>
					</div>
				</StyledItemList>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Button type='primary' ghost onClick={handleEdit}>
						Edit WorkForce Period
					</Button>
				</div>
				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	const strategy =
		workforce_period && workforce_period.id === workforcePeriodID
			? staleWhileRevalidate
			: networkOnly

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-period-view'))
		return <Redirect to='/' />

	return (
		<div className='page-content budgets-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_WORKFORCE_PERIOD_SHOW_STATUS,
					data: workforce_period,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(WorkForcePeriodShow)

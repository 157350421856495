import React, { useState } from "react"
import styled from "styled-components"
import { RedoOutlined } from "@ant-design/icons"
import { Popover, Button, message } from "antd"

import globalStore from "store/index"
import { Color } from "constants/theme"
import MinimumWagesService from "services/users/minimum-wages.service"
import SalaryRangesService from "services/users/salary-ranges.service"
import BudgetsService from "services/users/budgets.service"
import OrganizationMinimumSalariesService from "services/users/organization-minimum-salaries.service"
import FitmentsService from "services/users/fitments.service"

import StyledFitmentItemCard from "components/common/styled-components/StyledFitmentItemCard"

const StyledSystemValidationBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  & .chip {
    position: relative;
    padding: 13px 30px;
    background: ${Color.secondaryColor};
    border-radius: 40px;
    font-size: 1.2em;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-width: 400px;
  }
  & .chip.invalid {
    border: 2px solid red;
    & .status {
      background: red;
    }
  }
  & .chip.valid {
    border: 2px solid green;
    & .status {
      background: green;
    }
  }
  & .chip.pending {
    border: 2px solid gray;
    & .status {
      background: gray;
    }
  }
  & button {
    position: absolute;
    right: 10px;
  }
`

const SystemValidationBox = ({ validationStatus, fitmentId, onSetFitment }) => {
  const [minimumWageRefreshing, setMinimumWageRefreshing] = useState(false)
  const [salaryRangeRefreshing, setSalaryRangeRefreshing] = useState(false)
  const [budgetRefreshing, setBudgetRefreshing] = useState(false)
  const [revalidateRefreshing, setRevalidateRefreshing] = useState(false)
  const [
    organizationMinimumSalaryRefresh,
    setOrganizationMinimumSalaryRefresh,
  ] = useState(false)

  if (!validationStatus) return null

  const {
    budget_validation,
    salary_range_validation,
    minimum_wage_validation,
    organization_minimum_salary_validation,
  } = validationStatus

  const handleRefreshMinimumWage = async () => {
    const tempState = {}

    setMinimumWageRefreshing(true)
    await MinimumWagesService.refresh({ pageState: tempState, fitmentId })
    setMinimumWageRefreshing(false)

    const { fitment, fitmentErrors } = tempState

    if (fitmentErrors) {
      message.error(fitmentErrors[0] || "Minimum wage couldn't be refreshed.")
    }

    globalStore.ui.fitments.show.fitment = fitment
    onSetFitment(fitment)
  }

  const handleRefreshSalaryRange = async () => {
    const tempState = {}

    setSalaryRangeRefreshing(true)
    await SalaryRangesService.refresh({ pageState: tempState, fitmentId })
    setSalaryRangeRefreshing(false)

    const { fitment, fitmentErrors } = tempState

    if (fitmentErrors) {
      message.error(fitmentErrors[0] || "Salary range couldn't be refreshed.")
    }

    globalStore.ui.fitments.show.fitment = fitment
    onSetFitment(fitment)
  }

  const handleRefreshBudget = async () => {
    const tempState = {}

    setBudgetRefreshing(true)
    await BudgetsService.refresh({ pageState: tempState, fitmentId })
    setBudgetRefreshing(false)

    const { fitment, fitmentErrors } = tempState

    if (fitmentErrors) {
      message.error(fitmentErrors[0] || "Bduget couldn't be refreshed.")
    }

    globalStore.ui.fitments.show.fitment = fitment
    onSetFitment(fitment)
  }

  const handleRefreshOrganizationMinimumSalary = async () => {
    const tempState = {}

    setOrganizationMinimumSalaryRefresh(true)
    await OrganizationMinimumSalariesService.refresh({
      pageState: tempState,
      fitmentId,
    })
    setOrganizationMinimumSalaryRefresh(false)

    const { fitment, fitmentErrors } = tempState

    if (fitmentErrors) {
      message.error(
        fitmentErrors[0] || "Organization minimum salary couldn't be refreshed."
      )
    }

    globalStore.ui.fitments.show.fitment = fitment
    onSetFitment(fitment)
  }

  const handleRevalidateClick = async () => {
    const tempState = {}

    setRevalidateRefreshing(true)
    await FitmentsService.validateSalary({
      pageState: tempState,
      fitmentId,
    })
    setRevalidateRefreshing(false)

    const { fitment, fitmentErrors } = tempState

    if (fitmentErrors) {
      message.error(
        fitmentErrors[0] || "Organization minimum salary couldn't be refreshed."
      )
    }

    globalStore.ui.fitments.show.fitment = fitment
    onSetFitment(fitment)
  }

  const renderMessages = (messages) => {
    if (!messages || !Array.isArray(messages)) return null
    return messages.map((m, i) => (
      <div key={i} className="message">
        {m}
      </div>
    ))
  }

  return (
    <StyledFitmentItemCard>
      <div className="header">
        <h1>System Validation</h1>
        <Button onClick={handleRevalidateClick} disabled={revalidateRefreshing}>
          <RedoOutlined spin={revalidateRefreshing} rotate={270} />
          Revalidate
        </Button>
      </div>

      <StyledSystemValidationBox>
        {minimum_wage_validation && (
          <Popover
            content={renderMessages(minimum_wage_validation.messages)}
            title={minimum_wage_validation.status}
          >
            <div className={`chip ${minimum_wage_validation.status}`}>
              <span>Minimum Wage Validation</span>
              <Button type="link" onClick={handleRefreshMinimumWage}>
                <RedoOutlined spin={minimumWageRefreshing} rotate={270} />
              </Button>
            </div>
          </Popover>
        )}
        {salary_range_validation && (
          <Popover
            content={renderMessages(salary_range_validation.messages)}
            title={salary_range_validation.status}
          >
            <div className={`chip ${salary_range_validation.status}`}>
              <span>Salary Range Validation</span>
              <Button type="link" onClick={handleRefreshSalaryRange}>
                <RedoOutlined spin={salaryRangeRefreshing} rotate={270} />
              </Button>
            </div>
          </Popover>
        )}
        {budget_validation && (
          <Popover
            content={renderMessages(budget_validation.messages)}
            title={budget_validation.status}
          >
            <div className={`chip ${budget_validation.status}`}>
              <span>Budget Validation</span>
              <Button type="link" onClick={handleRefreshBudget}>
                <RedoOutlined spin={budgetRefreshing} rotate={270} />
              </Button>
            </div>
          </Popover>
        )}
        {organization_minimum_salary_validation && (
          <Popover
            content={renderMessages(
              organization_minimum_salary_validation.messages
            )}
            title={organization_minimum_salary_validation.status}
          >
            <div
              className={`chip ${organization_minimum_salary_validation.status}`}
            >
              <span>Organization Minimum Salary Validation</span>
              <Button
                type="link"
                onClick={handleRefreshOrganizationMinimumSalary}
              >
                <RedoOutlined
                  spin={organizationMinimumSalaryRefresh}
                  rotate={270}
                />
              </Button>
            </div>
          </Popover>
        )}
      </StyledSystemValidationBox>
    </StyledFitmentItemCard>
  )
}

export default SystemValidationBox

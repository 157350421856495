import React from 'react'
import styled from 'styled-components'

import { Color } from 'constants/theme'

const StyledCard = styled.div`
	margin-bottom: 30px;
	padding: 16px 30px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 5px;
	color: ${Color.textColor};
	box-shadow: 2px 2px 20px 2px #dadddf82;
	background: ${Color.secondaryColor};
	border: 1px solid rgba(0, 0, 0, 0.1);
	align-items: flex-start;
	& > .name {
		font-size: 2.5em;
		margin: 0px;
		font-weight: bold;
	}
	& > .details {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		width: 100%;
		& > .email {
			font-size: 1.5em;
			margin: 0px 0px 10px 0px;
			font-weight: 300;
		}
		& > .external-id {
			font-weight: 300;
		}
	}
`

const CandidateCard = ({ name, email, external_id, employee_external_id, fitment }) => {
	return (
		<StyledCard>
			<div className='name'>{name}</div>
			<div className='details'>
				<div className='email'>{email}</div>
				<div className='external-id'>
					{fitment?.application_id && <div>Application ID: {fitment?.application_id}</div>}
					{fitment?.employee_external_id && <div>Employee ID: {fitment?.employee_external_id}</div>}
					External ID: {external_id}
				</div>
			</div>
		</StyledCard>
	)
}

export default CandidateCard

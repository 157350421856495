import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { Button, Table, message, Pagination } from 'antd'

import globalStore from 'store'
import SalaryApprovalsService from 'services/users/salary-approvals.service'
import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'

const PendingSalaryApprovalIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.salaryApprovals.pendingApprovals
	const [tableDataLoading, setTableDataLoading] = useState(false)
	const [salaryApprovals, setSalaryApprovals] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.salaryApprovals.pendingApprovals
			setTableDataLoading(true)
			await SalaryApprovalsService.pendingApprovals({ pageState })
			setTableDataLoading(false)

			const { salary_approvals } = pageState

			if (salary_approvals) {
				setSalaryApprovals(salary_approvals)
				return
			}

			message.error('Failed to load salary approvals')
		}

		fetchData()
	}, [])

	const { salaryApprovalsMeta } = pageState

	const formatSalary = () => {
		if (!salaryApprovals) return []
    if (!Array.isArray(salaryApprovals)) return []

		return salaryApprovals.map((salaryApproval) => {
			return {
				salaryApprovalId: salaryApproval.id,
				key: salaryApproval.id,
				salary_approval_id: salaryApproval.id,
				token: salaryApproval.token,
				...salaryApproval['fitment']['candidate'],
			}
		})
	}

	const handleSalaryApprovalClick = (token) => {
		history.push(`/pending-salary-approvals/${token}`)
	}

	const repaginate = async (page, pageSize) => {
		const tempState = {}

		setTableDataLoading(true)

		await SalaryApprovalsService.pendingApprovals({
			pageState: tempState,
			page,
			pageSize,
		})

		setTableDataLoading(false)

		const { salary_approvals, salaryApprovalsMeta } = tempState

		if (salary_approvals) {
			setSalaryApprovals(salary_approvals)
			pageState.salaryApprovalsMeta = salaryApprovalsMeta
			return
		}
	}
	const renderSalaryApprovals = () => {
		const formattedSalary = formatSalary()

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				align: 'right',
				render: (action, row) => {
					return (
						<div className='icon-box'>
							<Button type='link' onClick={() => handleSalaryApprovalClick(row.salaryApprovalId)}>
								<EyeOutlined />
							</Button>
						</div>
					)
				},
			},
		]

		return (
			<div style={{ margin: '10px 0px' }}>
				<Table
					columns={columns}
					dataSource={formattedSalary}
					loading={tableDataLoading}
					pagination={false}
				/>
			</div>
		)
	}

	const { total, page: currentPage, page_size: pageSize } = salaryApprovalsMeta || {}

	const pagination = (
		<Pagination
			current={currentPage}
			total={total}
			onChange={repaginate}
			onShowSizeChange={repaginate}
			pageSize={pageSize || 25}
			pageSizeOptions={[10, 25, 50, 100]}
		/>
	)

	const renderPageContent = () => {
		return (
			<div>
				{pagination}
				{renderSalaryApprovals()}
				{pagination}
			</div>
		)
	}

	const { API_SALARY_APPROVALS_PENDING_APPROVALS_STATUS } = pageState

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_SALARY_APPROVALS_PENDING_APPROVALS_STATUS,
					data: salaryApprovals,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default PendingSalaryApprovalIndexPage

import { API, generic } from 'api'

export default class CountryDetailsService {
	static index = async ({ pageState, page = 1, pageSize = 25 }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_COUNTRIES_DETAILS_INDEX_STATUS',
			stateDataKey: ['country_details'],
			stateErrorKey: ['countriesDetailErrors'],
			apiEndpoint: API.users.countryDetails.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize } },
			errorMessage: 'Country Details data could not be fetched.',
		})
	}
	static create = async ({ pageState, data }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_COUNTRIES_DETAILS_CREATE_STATUS',
			stateDataKey: ['country_details'],
			stateErrorKey: ['countriesDetailErrors'],
			apiEndpoint: API.users.countryDetails.create,
			apiUrlReplacements: {},
			apiData: { ...data },
			errorMessage: 'Country Details data could not be created.',
		})
	}
	static show = async ({ pageState, countryId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_COUNTRIES_DETAILS_INDEX_STATUS',
			stateDataKey: ['country_detail'],
			stateErrorKey: ['countriesDetailErrors'],
			apiEndpoint: API.users.countryDetails.show,
			apiUrlReplacements: { countryId },
			apiData: {},
			errorMessage: 'Country Details show data could not be fetched.',
		})
	}
	static update = async ({ pageState, data, countryId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_COUNTRIES_DETAILS_INDEX_STATUS',
			stateDataKey: ['country_details'],
			stateErrorKey: ['countriesDetailErrors'],
			apiEndpoint: API.users.countryDetails.update,
			apiUrlReplacements: { countryId },
			apiData: { ...data },
			errorMessage: 'Country Details data could not be updated.',
		})
	}
}

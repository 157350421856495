export const isContainsBlankCriteria = (criterias) => {
  if (criterias === null) return true
  if (criterias.length === 0) return true

  let blankCriterias = criterias.map((cri) => {
    return cri.filter((c) => c.field === null || (c.operator !== "not" && !c.values))
  })
  
  let bc = blankCriterias.filter((bc) => bc.length > 0)
  if (bc.length > 0) return true

  return false
}
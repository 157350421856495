import { API, generic } from 'api'

export default class BudgetTentativeValuesService {
	static index = async ({ pageState, module,page = 1, pageSize = 25 }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_TENTATIVE_VALUES_INDEX_STATUS',
			stateDataKey: ['budget_tentative_values', 'total', 'page', 'page_size'],
			stateErrorKey: ['budgetTentativeValuesErrors', 'totalErrors', 'pageErrors', 'pageSizeErrors'],
			apiEndpoint: API.users.budgetTentativeValues.index,
			apiUrlReplacements: {},
			apiData: {
				params: {
					module,
					page,
					page_size: pageSize,
				},
			},
			errorMessage: 'Budget Tentative Values could not be fetched.',
		})
	}

	static show = async ({ pageState, budgetTentativeValueId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_TENTATIVE_VALUES_SHOW_STATUS',
			stateDataKey: ['budget_tentative_values'],
			stateErrorKey: ['budgetTentativeValuesErrors'],
			apiEndpoint: API.users.budgetTentativeValues.show,
			apiUrlReplacements: { budgetTentativeValueId },
			apiData: {},
			errorMessage: 'Budget Tentative Values could not be fetched.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_TENTATIVE_VALUES_CREATE_STATUS',
			stateDataKey: ['budget_tentative_values'],
			stateErrorKey: ['budgetTentativeValuesErrors'],
			apiEndpoint: API.users.budgetTentativeValues.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Budget Tentative could not be created.',
		})
	}
	static update = async ({ pageState, values, budgetTentativeValueId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_TENTATIVE_VALUES_UPDATE_STATUS',
			stateDataKey: ['budget_tentative_values'],
			stateErrorKey: ['budgetTentativeValuesErrors'],
			apiEndpoint: API.users.budgetTentativeValues.update,
			apiUrlReplacements: { budgetTentativeValueId },
			apiData: { ...values },
			errorMessage: 'Budget Tentative could not be updated.',
		})
	}
	static delete = async ({ pageState, budgetTentativeValueId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_TENTATIVE_VALUES_DELETE_STATUS',
			stateDataKey: ['budget_tentative_values'],
			stateErrorKey: ['budgetTentativeValuesErrors'],
			apiEndpoint: API.users.budgetTentativeValues.delete,
			apiUrlReplacements: { budgetTentativeValueId },
			apiData: {},
			errorMessage: 'Budget Tentative could not be deleted.',
		})
	}

	static assignApprovalChain = async ({ pageState, budgetTentativeValueId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_TENTATIVE_VALUES_ASSIGN_APPROVAL_CHAIN_STATUS',
			stateDataKey: ['budget_tentative_values'],
			stateErrorKey: ['budgetTentativeValuesErrors'],
			apiEndpoint: API.users.budgetTentativeValues.assignApprovalChain,
			apiUrlReplacements: { budgetTentativeValueId },
			apiData: {},
			errorMessage: 'Budget Tentative Assign Approval Chain could not be submitted.',
		})
	}
	static comment = async ({ pageState, budgetTentativeValueId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_TENTATIVE_VALUES_COMMENT_STATUS',
			stateDataKey: ['budget_tentative_values'],
			stateErrorKey: ['budgetTentativeValuesErrors'],
			apiEndpoint: API.users.budgetTentativeValues.comment,
			apiUrlReplacements: { budgetTentativeValueId },
			apiData: { ...values },
			errorMessage: 'Budget Tentative Assign Approval Chain could not be submitted.',
		})
	}
}

import { API, generic } from 'api'

export default class PositionService {
	static workforcePositions = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_POSITIONS_STATUS',
			stateDataKey: ['access_groups'],
			stateErrorKey: ['accessGroupsErrors'],
			apiEndpoint: API.users.workforcePositions,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Position data could Fetched',
		})
	}
}

import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { view } from "@risingstack/react-easy-state"

import { message, Button, Modal, Input } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import FitmentView from "components/FitmentView"
import Comments from "components/Comments"
import { staleWhileRevalidate } from "utils/render-strategies"

import globalStore from "store/index"
import SalaryApprovalsService from "services/visitors/salary-approvals.service"

const SalaryApprovalsApprovePage = () => {
  const { token } = useParams()
  const pageState = globalStore.ui.salaryApprovals.show
  const [salary, setSalary] = useState(null)
  const [comment, setComment] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [approveButtonLoading, setApproveButtonLoading] = useState(false)
  const [rejectButtonLoading, setRejectButtonLoading] = useState(false)
  const [commentButtonLoading, setCommentButtonLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.salaryApprovals.show
      await SalaryApprovalsService.show({ pageState, token })

      const { salary_approval, organization } = pageState

      if (organization) {
        globalStore.currentOrganization = organization
      }
      if (salary_approval && salary_approval.fitment) {
        setSalary(salary_approval.fitment)
        return
      }

      message.error("Failed to load salary")
    }

    fetchData()
  }, [token])

  const approveSalary = async () => {
    const { salary_approval } = globalStore.ui.salaryApprovals.show

    if (!salary_approval) return
    if (salary_approval.status !== "pending") return

    const pageState = {}

    setApproveButtonLoading(true)
    await SalaryApprovalsService.approve({ pageState, token })
    setApproveButtonLoading(false)

    if (!pageState.salary_approval) {
      message.error("Salary couldn't be approved.")
      return
    }
    if (pageState.salary_approval.status !== "approved") {
      message.error("Salary couldn't be approved.")
      return
    }

    message.success("Salary approved.")
    globalStore.ui.salaryApprovals.show.salary_approval =
      pageState.salary_approval
  }

  const rejectSalaryApproval = async () => {
    if (!comment) {
      message.error("Reason couldn't be blank")
      return
    }
    const { salary_approval } = globalStore.ui.salaryApprovals.show

    if (!salary_approval) return
    if (salary_approval.status === "rejected") return

    const pageState = {}

    const postData = {
      comment,
    }

    setRejectButtonLoading(true)
    await SalaryApprovalsService.reject({ pageState, token, values: postData })
    setRejectButtonLoading(false)

    if (!pageState.salary_approval) {
      message.error("Salary couldn't be rejected.")
      return
    }
    if (pageState.salary_approval.status !== "rejected") {
      message.error("Salary couldn't be rejected.")
      return
    }

    setModalVisible(false)
    message.success("Salary rejected.")
    globalStore.ui.salaryApprovals.show.salary_approval =
      pageState.salary_approval
  }

  const openCommentModal = () => {
    setModalVisible(true)
  }

  const scrollToCommentBox = () => {
    document.getElementById("comments").scrollIntoView({ behavior: "smooth" })
  }

  const handleComment = async (comment) => {
    if (!comment) {
      message.error("Comment couldn't be blank")
      return
    }
    const { salary_approval } = globalStore.ui.salaryApprovals.show

    if (!salary_approval) return

    const pageState = {}

    const postData = {
      comment,
    }

    setCommentButtonLoading(true)
    await SalaryApprovalsService.comment({
      pageState,
      token,
      values: postData,
    })
    setCommentButtonLoading(false)

    if (!pageState.salary_approval) {
      message.error("Failed to comment")
      return
    }

    message.success("Comment posted.")
    globalStore.ui.salaryApprovals.show.salary_approval =
      pageState.salary_approval
  }

  const renderTopActionButtons = () => {
    const { salary_approval } = globalStore.ui.salaryApprovals.show

    const approved = salary_approval && salary_approval.status === "approved"
    const rejected = salary_approval && salary_approval.status === "rejected"
    const actionTaken = salary_approval.status !== "pending"

    return (
      <div>
        <Button
          type="primary"
          ghost
          disabled={actionTaken}
          loading={approveButtonLoading}
          onClick={approveSalary}
        >
          {approved ? "Already Approved" : "Approve"}
        </Button>
        <Button
          type="danger"
          ghost
          style={{ margin: "0px 10px" }}
          loading={rejectButtonLoading}
          disabled={actionTaken}
          onClick={openCommentModal}
        >
          {rejected ? `Already Rejected` : "Reject"}
        </Button>
        <Button type="primary" ghost onClick={scrollToCommentBox}>
          Comment
        </Button>
      </div>
    )
  }

  const renderPageContent = () => {
    const { salary_approval } = globalStore.ui.salaryApprovals.show
    const { applicable_salary_fields_configuration } = salary

    return (
      <div>
				<FitmentView
					fitment={salary}
					actionButtons={renderTopActionButtons()}
					fitmentfieldsConfigurations={applicable_salary_fields_configuration}
					externalView
				/>
				<Comments
					comments={salary_approval.fitment.comments}
					commentButtonLoading={commentButtonLoading}
					commentAble
					onComment={handleComment}
				/>
			</div>
		)
  }

  const { API_VISITORS_SALARY_APPROVALS_SHOW_STATUS } = pageState

  return (
    <div className="page-content dashboard-page" style={{ margin: "auto" }}>
      <StyledBox>
        {staleWhileRevalidate({
          status: API_VISITORS_SALARY_APPROVALS_SHOW_STATUS,
          data: salary,
          render: renderPageContent,
        })}
      </StyledBox>
      <Modal
        title="Reason"
        closable={false}
        maskClosable={false}
        visible={modalVisible}
        onOk={rejectSalaryApproval}
        okText="Reject"
        confirmLoading={rejectButtonLoading}
        onCancel={() => setModalVisible(false)}
      >
        <Input.TextArea
          allowClear
          autoSize={{ minRows: 4 }}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>
    </div>
  )
}

export default view(SalaryApprovalsApprovePage)

import React from "react"
import StackTrace from "stacktrace-js"
import { view } from "@risingstack/react-easy-state"
import { Result } from "antd"

import siteConfig from "siteConfig"
import * as authHelper from "helpers/auth.helper"

const slack = require("slack-notify")(process.env.REACT_APP_SLACK_WEB_HOOK)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    //Send error on slack
    if (process.env.NODE_ENV !== "development") {
      StackTrace.fromError(error).then((err) => {
        const userToken = authHelper.getToken()

        const errorMsg = {
          type: "React boundary",
          url: window.location.href,
          user_token: userToken,
          user_agent: window.navigator.userAgent,
          date: new Date(),
          msg: error.toString(),
        }

        const slackMessage = `*Error Caught*:
*User*:
\`\`\`${userToken}\`\`\`
*Metadata:*
\`\`\`
${JSON.stringify(errorMsg, null, 2)}
\`\`\`
*Stacktrace:*
\`\`\`
${err
  .map((line) => `${line.fileName}:${line.lineNumber} in ${line.functionName}`)
  .join("\n")}
\`\`\`
`
        slack.bug({
          channel: process.env.REACT_APP_SLACK_CHANNEL_NAME,
          username: process.env.REACT_APP_SLACK_CHANNEL_USER_NAME,
          attachments: [
            {
              text: slackMessage,
            },
          ],
        })
      })
    }
  }

  render() {
    if (this.state.hasError) {
      const { site } = siteConfig
      const { clientSupportEmail, dashboardPath } = site
      return (
        <div className="page-error not-found-page">
          <Result
            status="500"
            title="Oops!!!"
            subTitle={`Some error occurred please try refreshing page, if this continues please mail us ${clientSupportEmail}`}
            extra={
              <p>
                Go back to <a href={dashboardPath}> Dashboard </a>
              </p>
            }
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default view(ErrorBoundary)

import { API, generic } from "api"

export default class UsersService {
  static index = async ({ pageState, organizationId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_USERS_ORGANIZATIONS_USERS_INDEX_STATUS",
      stateDataKey: ["users"],
      stateErrorKey: ["usersErrors"],
      apiEndpoint: API.adminUsers.organizations.users.index,
      apiUrlReplacements: { organizationId },
      apiData: {},
      errorMessage: "Users data could not be fetched.",
    })
  }

  static show = async ({ pageState, organizationId, userId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_USERS_ORGANIZATIONS_USERS_SHOW_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.adminUsers.organizations.users.show,
      apiUrlReplacements: { organizationId, userId },
      apiData: {},
      errorMessage: "User data could not be fetched.",
    })
  }

  static update = async ({ pageState, organizationId, values, userId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_USERS_ORGANIZATIONS_USERS_UPDATE_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.adminUsers.organizations.users.update,
      apiUrlReplacements: { organizationId, userId },
      apiData: { ...values },
      errorMessage: "User data could not be updated.",
    })
  }

  static create = async ({ pageState, values, organizationId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_USERS_ORGANIZATIONS_USERS_CREATE_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.adminUsers.organizations.users.create,
      apiUrlReplacements: { organizationId },
      apiData: { ...values },
      errorMessage: "User could not be created.",
    })
  }
}

import { API, generic } from 'api'

export default class SsoService {
	static payreviewLogin = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_PAYREVIEW_LOGIN_STATUS',
			stateDataKey: ['redirect_url'],
			stateErrorKey: ['payreviewLoginError'],
			apiEndpoint: API.payreviewLogin,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Unable to Login to payreview',
			serializedResponse: false,
		})
	}
	static hirereviewLogin = async ({ pageState,token }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_HIREREVIEW_LOGIN_STATUS',
			stateDataKey: ['user'],
			stateErrorKey: ['hirereviewLoginErrors'],
			apiEndpoint: API.hirereviewLogin,
			apiUrlReplacements: {},
			apiData: { params: { token } },
			errorMessage: 'Unable to Login to hirereview',
			serializedResponse: false,
		})
	}
}

import React, { useEffect } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import { Button, message, Empty, Popconfirm } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import LetterTemplatesService from "services/users/letter-templates.service"
// import FitmentInputGroup from "components/FitmentInputGroup"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const AdditionalLetterTemplatesIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.configuration.letterTemplates.index

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.letterTemplates.index

      await LetterTemplatesService.index({ pageState })

      const { serverStatus, LetterTemplatesErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (LetterTemplatesErrors) {
        message.error(
          LetterTemplatesErrors[0] ||
            "letter templates couldn't be fetched."
        )
        return
      }
    }

    fetchData()
  }, [])

  const handleCreate = () => {
    history.push('/configuration/additional-letter-templates/new')
  }

  const handleCardItemClick = (letterId) => {
    history.push(`/configuration/additional-letter-templates/${letterId}`)
  }

  const handleDelete = async (e, letterTemplateId) => {
    e.stopPropagation()
    const tempState = {}

    await LetterTemplatesService.delete({ pageState: tempState, letterTemplateId })

    const { message: messages, letterTemplateErrors } = tempState

    if (letterTemplateErrors) {
      message.error(letterTemplateErrors[0] || 'Failed to delete this Offer Letter Template')
      return
    }

    pageState.letter_templates = pageState.letter_templates.filter(
      (data) => data.id !== letterTemplateId
    )
    message.success(messages)
  }

  const {
    letter_templates,
    API_USERS_LETTER_TEMPLATES_INDEX_STATUS,
  } = pageState

  const renderContents = () => {
    if (letter_templates.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            New Letter Template
          </Button>
        </Empty>
      )
    }

    return (
      <StyledList>
        {letter_templates.map((letterTemplate) => (
          <div
            className="item-card"
            key={`item-card-${letterTemplate.id}`}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{letterTemplate.name}</div>
                <div>{}</div>
              </div>
            </div>
            <div className="icon-box" style={{ display: 'flex', marginRight: '50px' }}>
              <Button type="link" onClick={() => handleCardItemClick(letterTemplate.id)}>
                <EyeOutlined />
              </Button>
              
              <Popconfirm
                  title='Are you sure?'
                  onConfirm={(e) => handleDelete(e, letterTemplate.id)}
                  onCancel={() => {}}
                  okText='Yes'
                  cancelText='No'>
                  <Button type='link'>
                    <DeleteOutlined />
                  </Button>
              </Popconfirm>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleCreate}>
            New Letter Template
          </Button>
        </div>
        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('additional-template-edit'))
		return <Redirect to='/' />

  return (
    <div className="page-content letter-templates-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_LETTER_TEMPLATES_INDEX_STATUS,
          data: letter_templates,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(AdditionalLetterTemplatesIndexPage)

import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { EyeOutlined, InboxOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {
	message,
	Button,
	Empty,
	Pagination,
	Table,
	Modal,
	Upload,
	Tooltip,
	List,
	Input,
	Select,
} from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetUtilizationsService from 'services/users/budget-utilizations.service'
import { Popconfirm } from '../../../../node_modules/antd/lib/index'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const BudgetUtilizationsIndexPage = (props) => {
	const history = useHistory()
	const { budgetHeadId } = useParams()
	const [importModalVisible, setImportModalVisible] = useState(false)
	const [fileList, setFileList] = useState([])
	const [importing, setImporting] = useState(false)
	const [selectedFilterKey, setSelectedFilterKey] = useState(null)
	const [searchValue, setSearchValue] = useState(null)

	const pageState = globalStore.ui.configuration.budgetUtilizations.index
	const searchPageState = globalStore.ui.configuration.budgetUtilizations.search

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetUtilizations = async () => {
			const pageState = globalStore.ui.configuration.budgetUtilizations.index

			await BudgetUtilizationsService.index({ pageState, budgetHeadId })

			const { serverStatus, budgetUtilizationsErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetUtilizationsErrors) {
				message.error(budgetUtilizationsErrors[0] || "Couldn't fetch Budget Utilizations")
				return
			}
		}

		fetchBudgetUtilizations()
	}, [budgetHeadId])

	const repaginate = async (page, pageSize) => {
		if (Object.keys(globalStore.ui.configuration.budgetUtilizations.search).length !== 0) {
			await BudgetUtilizationsService.search({ pageState, page, pageSize, budgetHeadId })
			return
		}
		await BudgetUtilizationsService.index({ pageState, page, pageSize, budgetHeadId })
	}

	const handleCreate = () => {
		history.push(`/configuration/budget-heads/${budgetHeadId}/budget-utilizations/new`)
	}

	const openImportModal = () => {
		setImportModalVisible(true)
	}

	const handleImport = async () => {
		if (fileList.length === 0) return

		const tempState = {}

		const formData = new FormData()

		formData.append('file', fileList[0])

		setImporting(true)
		await BudgetUtilizationsService.import({ pageState: tempState, formData, budgetHeadId })
		setImporting(false)

		setImportModalVisible(false)

		message.success('Import started. Kindly check import page in Report Center to see imported')
	}

	const exportActualValue = async () => {
		const tempState = {}

		await BudgetUtilizationsService.export({
			pageState: tempState,
			budgetHeadId,
		})

		message.success(
			'Export started. Kindly check export page in Report Center to see exported audit reports'
		)
	}

	const handleRowClick = (budgetUtilizationId) => {
		history.push(
			`/configuration/budget-heads/${budgetHeadId}/budget-utilizations/${budgetUtilizationId}`
		)
	}

	const handleEdit = (budgetUtilizationId) => {
		history.push(
			`/configuration/budget-heads/${budgetHeadId}/budget-utilizations/${budgetUtilizationId}/edit`
		)
	}

	const handleDelete = async (budgetUtilizationId) => {
		const tempState = {}

		await BudgetUtilizationsService.delete({
			pageState: tempState,
			budgetUtilizationId,
		})

		const { serverStatus } = tempState

		if (serverStatus.status !== 200) return

		if (Object.keys(searchPageState).length !== 0) {
			globalStore.ui.configuration.budgetUtilizations.search = {
				...globalStore.ui.configuration.budgetUtilizations.search,
				budget_utilizations: budget_utilizations.filter(
					(budget_utilization) => budget_utilization.id !== budgetUtilizationId
				),
			}
			return
		}
		globalStore.ui.configuration.budgetUtilizations.index = {
			...globalStore.ui.configuration.budgetUtilizations.index,
			budget_utilizations: budget_utilizations.filter(
				(budget_utilization) => budget_utilization.id !== budgetUtilizationId
			),
		}
	}

	const handleClearFilter = async () => {
		const pageState = globalStore.ui.configuration.budgetUtilizations.index
		setSelectedFilterKey(null)
		setSearchValue(null)
		globalStore.ui.configuration.budgetUtilizations.search = {}
		await BudgetUtilizationsService.index({ pageState, budgetHeadId })
	}

	const handleFilterSelectInputChange = (value) => {
		if (!value) return
		setSelectedFilterKey(value)
	}

	const handleSearch = async (value) => {
		if (!value) return

		setSearchValue(value)

		const pageState = globalStore.ui.configuration.budgetUtilizations.search

		const searchData = {
			field: selectedFilterKey,
			value,
		}

		await BudgetUtilizationsService.search({
			pageState,
			values: searchData,
			budgetHeadId,
		})
	}

	const {
		budget_utilizations,
		budget_utilizationsMeta,
		API_USERS_BUDGET_UTILIZATIONS_INDEX_STATUS,
		displayType = 'table',
	} = pageState

	const { API_USERS_BUDGET_UTILIZATIONS_SEARCH_STATUS } = searchPageState

	const {
		total,
		page: currentPage,
		page_size: pageSize,
		criteria_keys,
		budget_head_name,
	} = budget_utilizationsMeta || {}

	const renderList = () => {
		return (
			<StyledList>
				{budget_utilizations.map((sr) => (
					<div
						className='item-card'
						key={`item-card-${sr.id}`}
						onClick={() => handleRowClick(sr.id)}>
						<div className='details-box'>
							<div className='name-box'>
								<div className='name'>{sr.name}</div>
								<div>{}</div>
							</div>
						</div>
						<div className='icon-box'>
							<Button type='link'>
								<EyeOutlined />
							</Button>
						</div>
					</div>
				))}
			</StyledList>
		)
	}

	const getFilteredOption = () => {
		const displayCriteriaForBudget = globalStore.currentOrganization.display_criteria_for_budget
		if (displayCriteriaForBudget && displayCriteriaForBudget.length)
			return displayCriteriaForBudget.map((filteredCriteria, index) => {
				if (!filteredCriteria) return null
				if (!filteredCriteria[0]) return null
				if (filteredCriteria.length === 0) return null

				return (
					<Select.Option key={`option-${index}`} value={filteredCriteria}>
						{filteredCriteria}
					</Select.Option>
				)
			})

		return criteria_keys.map((filteredCriteria, index) => {
			if (!filteredCriteria) return null
			if (!filteredCriteria[0]) return null
			if (filteredCriteria.length === 0) return null

			return (
				<Select.Option key={`option-${index}`} value={filteredCriteria}>
					{filteredCriteria}
				</Select.Option>
			)
		})
	}

	const renderContents = () => {
		if (budget_utilizations.length === 0) {
			return (
				<div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: 10,
							margin: '15px 0px',
						}}>
						<div></div>
						<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
							{selectedFilterKey ? (
								<Button type='link' onClick={handleClearFilter}>
									Clear filter
								</Button>
							) : null}
							<Select
								placeholder='Filter on'
								style={{ width: 200 }}
								value={selectedFilterKey}
								onChange={handleFilterSelectInputChange}>
								<Select.Option disabled>Filter on</Select.Option>
								{getFilteredOption()}
							</Select>
							<Input.Search
								placeholder='Enter value to search'
								value={searchValue}
								disabled={!selectedFilterKey}
								onSearch={handleSearch}
								onChange={(e) => setSearchValue(e.target.value)}
							/>
						</div>
					</div>
					<Empty>
						<Button type='primary' ghost onClick={handleCreate}>
							Create Actual Value
						</Button>
					</Empty>
				</div>
			)
		}

		const pagination = () => {
			if (Object.keys(searchPageState).length !== 0) {
				const { budget_utilizationsMeta } = searchPageState

				const { total, page: currentPage, page_size: pageSize } = budget_utilizationsMeta || {}
				return (
					<Pagination
						current={currentPage}
						total={total}
						onChange={repaginate}
						onShowSizeChange={repaginate}
						pageSize={pageSize || 25}
						pageSizeOptions={[10, 25, 50, 100]}
					/>
				)
			}
			return (
				<Pagination
					current={currentPage}
					total={total}
					onChange={repaginate}
					onShowSizeChange={repaginate}
					pageSize={pageSize || 25}
					pageSizeOptions={[10, 25, 50, 100]}
				/>
			)
		}

		const getCriteriaKeys = () => {
			const displayCriteriaForBudget = globalStore.currentOrganization.display_criteria_for_budget
			if (displayCriteriaForBudget && displayCriteriaForBudget.length)
				return displayCriteriaForBudget.map((ck) => ({
					title: ck,
					dataIndex: ck,
					key: ck,
					render: (filteredCriteria, row) => {
						if (!filteredCriteria) return '--' // if null
						if (!filteredCriteria[0]) return '--' // If [null]
						if (filteredCriteria.length === 0) return '---' // if []

						const dataSource = filteredCriteria.filter((fc) => fc) // FIXME: need to fix

						return (
							<List
								dataSource={dataSource}
								renderItem={(values) => {
									if (Array.isArray(values)) {
										return values?.join(', ')
									}
									return values
								}}
							/>
						)
					},
				}))

			return criteria_keys.map((ck) => ({
				title: ck,
				dataIndex: ck,
				key: ck,
				render: (filteredCriteria, row) => {
					if (!filteredCriteria) return '--' // if null
					if (!filteredCriteria[0]) return '--' // If [null]
					if (filteredCriteria.length === 0) return '---' // if []

					const dataSource = filteredCriteria.filter((fc) => fc) // FIXME: need to fix

					return (
						<List
							dataSource={dataSource}
							renderItem={(values) => {
								if (Array.isArray(values)) {
									return values?.join(', ')
								}
								return values
							}}
						/>
					)
				},
			}))
		}

		const tableColumns = [
			...getCriteriaKeys(),
			...[
				{
					title: 'Currency',
					dataIndex: 'currency',
					key: 'currency',
				},
				{
					title: 'Count',
					dataIndex: 'unit',
					key: 'count',
				},
				{
					title: 'Rate',
					dataIndex: 'rate',
					key: 'rate',
				},
				{
					title: 'Total Cost',
					dataIndex: 'total_value',
					key: 'total_value',
				},
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					align: 'center',
					render: (value, sr) => {
						return (
							<>
								<Button type='link' onClick={() => handleRowClick(sr.id)}>
									<EyeOutlined />
								</Button>
								<Button type='link' onClick={() => handleEdit(sr.id)}>
									<EditOutlined />
								</Button>
								<Tooltip title='Delete'>
									<Popconfirm
										title='Are you sure to delete?'
										onConfirm={() => handleDelete(sr.id)}
										onCancel={() => {}}
										okText='Yes'
										cancelText='No'>
										<Button type='link'>
											<DeleteOutlined />
										</Button>
									</Popconfirm>
								</Tooltip>
							</>
						)
					},
				},
			],
		]

		const dataSource = () => {
			if (Object.keys(searchPageState).length !== 0) {
				const { budget_utilizations, budget_utilizationsMeta } = searchPageState

				const { criteria_keys } = budget_utilizationsMeta || {}

				return budget_utilizations.map((sr) => {
					const retval = { ...sr, key: sr.id }
					criteria_keys.forEach((ck) => {
						retval[ck] = sr.criteria.map((conditions) => {
							const relevantCondition = conditions.find((c) => c.field === ck)
							if (!relevantCondition) return null
							return relevantCondition.values
						})
					})
					return retval
				})
			}

			return budget_utilizations.map((sr) => {
				const retval = { ...sr, key: sr.id }
				criteria_keys.forEach((ck) => {
					retval[ck] = sr.criteria.map((conditions) => {
						const relevantCondition = conditions.find((c) => c.field === ck)
						if (!relevantCondition) return null
						return relevantCondition.values
					})
				})
				return retval
			})
		}
		const renderTable = () => {
			return (
				<div style={{ margin: '10px 0px' }}>
					<Table
						columns={tableColumns}
						dataSource={dataSource()}
						pagination={false}
						scroll={{
							x: 'max-content',
						}}
					/>
				</div>
			)
		}

		const renderInfo = () => {
			if (displayType === 'table') return renderTable()
			return renderList()
		}

		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
						margin: '10px 0px',
					}}>
					{pagination()}
					<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
						{selectedFilterKey ? (
							<Button type='link' onClick={handleClearFilter}>
								Clear filter
							</Button>
						) : null}
						<Select
							placeholder='Filter on'
							style={{ width: 200 }}
							value={selectedFilterKey}
							onChange={handleFilterSelectInputChange}>
							<Select.Option disabled>Filter on</Select.Option>
							{getFilteredOption()}
						</Select>
						<Input.Search
							placeholder='Enter value to search'
							value={searchValue}
							disabled={!selectedFilterKey}
							onSearch={handleSearch}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</div>
				</div>

				{networkOnly({
					status: API_USERS_BUDGET_UTILIZATIONS_SEARCH_STATUS
						? API_USERS_BUDGET_UTILIZATIONS_SEARCH_STATUS
						: API_USERS_BUDGET_UTILIZATIONS_INDEX_STATUS,
					data: budget_utilizations,
					render: renderInfo,
				})}

				{pagination()}
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div>
						<h1>{budget_head_name}</h1>
					</div>
					<div className='action-buttons top'>
						<Tooltip title='Create or update multiple records by upload'>
							<Button onClick={openImportModal} style={{ marginRight: 10 }}>
								Import
							</Button>
						</Tooltip>
						<Tooltip title='Download Actual Value Data Sheet'>
							<Button onClick={exportActualValue} style={{ marginRight: 10 }}>
								Download
							</Button>
						</Tooltip>
						<Tooltip title='Create a new record'>
							<Button type='primary' ghost onClick={handleCreate}>
								Create Actual Value
							</Button>
						</Tooltip>
					</div>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}
	const handleSampleFileDownload = () => {
		window.open(
			'https://storage.googleapis.com/ps-in-images-compensation/images/pdf/1097/budget_utilization_importer_sample.xlsx?1630665379'
		)
	}
	const uploadProps = {
		accept:
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
		onRemove: (file) => {
			const index = fileList.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)

			setFileList(newFileList)
		},
		beforeUpload: (file) => {
			setFileList([file])
			return false
		},
		fileList,
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures.length &&
		!['budget-data-edit', 'smart-budget'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/' />

	return (
		<div className='page-content budget_utilizations-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_UTILIZATIONS_SEARCH_STATUS
						? API_USERS_BUDGET_UTILIZATIONS_SEARCH_STATUS
						: API_USERS_BUDGET_UTILIZATIONS_INDEX_STATUS,
					data: budget_utilizations,
					render: renderPageContent,
				})}
			</StyledBox>
			<Modal
				title='Import'
				visible={importModalVisible}
				confirmLoading={importing}
				closable={false}
				maskClosable={false}
				destroyOnClose
				onOk={handleImport}
				okText='Import'
				onCancel={() => setImportModalVisible(false)}>
				<Upload.Dragger {...uploadProps}>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>Click or drag file to this area to upload</p>
					<p className='ant-upload-hint'>
						Please ensure data accuracy in your file for upload. After upload, each associated
						records will be updated directly.
					</p>
				</Upload.Dragger>
				<Button type='link' onClick={handleSampleFileDownload}>
					Sample Import File
				</Button>
			</Modal>
		</div>
	)
}

export default view(BudgetUtilizationsIndexPage)

import { API, generic } from "api"

export default class VendorsService {
  static index = async ({ pageState, page = 1, pageSize = 25 }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_VENDORS_INDEX_STATUS",
      stateDataKey: [
        "vendors",
        "total",
        "page",
        "page_size",
      ],
      stateErrorKey: [
        "vendorErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
      ],
      apiEndpoint: API.users.vendors.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize }},
      errorMessage: "Vendors could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_VENDORS_CREATE_STATUS",
      stateDataKey: ["vendors"],
      stateErrorKey: ["vendorErrors"],
      apiEndpoint: API.users.vendors.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Vendor could not be created.",
    })
  }

  static show = async ({ pageState, vendorId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_VENDORS_SHOW_STATUS",
      stateDataKey: ["vendor"],
      stateErrorKey: ["vendorErrors"],
      apiEndpoint: API.users.vendors.show,
      apiUrlReplacements: { vendorId },
      apiData: {},
      errorMessage: "Vendor could not be fetched.",
    })
  }

  static update = async ({ pageState, vendorId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_VENDORS_UPDATE_STATUS",
      stateDataKey: ["vendor"],
      stateErrorKey: ["vendorErrors"],
      apiEndpoint: API.users.vendors.update,
      apiUrlReplacements: { vendorId },
      apiData: { ...values },
      errorMessage: "Vendor could not be updated.",
    })
  }

  static delete = async ({ pageState, vendorId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_VENDOR_DELETE_STATUS",
      stateDataKey: ["vendor"],
      stateErrorKey: ["vendorErrors"],
      apiEndpoint: API.users.documents.delete,
      apiUrlReplacements: { vendorId },
      apiData: {},
      errorMessage: "Vendor could not be deleted.",
    })
  }
}

import styled from "styled-components"

import { Color } from "constants/theme"

const StyledApprovalChainBox = styled.div`
  margin-top: 30px;
  & .card {
    & > h1 {
      font-size: 1.5em;
      font-weight: bold;
    }
    & .approvers-box {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      gap: 20px;

      &:before {
        content: "\\21E7";
        position: absolute;
        right: -40px;
        font-size: 2em;
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        color: #e6e6e6;
      }
    }
    & .approvers-box:last-child {
      &:before {
        content: none;
      }
    }

    & .chip {
      position: relative;
      padding: 13px 30px;
      background: ${Color.secondaryColor};
      border-radius: 40px;
      font-size: 1.2em;
      display: flex;
      align-items: center;
      //box-shadow: 0px 5px 20px transparent, 0px 8px 20px #00000008;
      & .status {
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      & .status {
        margin-left: 20px;
      }
      & .anticon {
        margin-left: 5px;
        margin-right: -15px;
        font-size: 1.2rem;
      }
    }
    & .chip.rejected {
      & .status {
        background: red;
      }
    }
    & .chip.approved {
      & .status {
        background: green;
      }
    }
    & .chip.pending {
      & .status {
        background: orange;
      }
    }
    & .chip.not-initiated {
      & .status {
        background: gray;
      }
    }
    & .chip:last-child {
      margin-right: 0px;
      &:before {
        content: none;
      }
    }
  }
`

export default StyledApprovalChainBox

import { API, generic } from "api"

export default class OrganizationMinimumSalariesService {
  static index = async ({ pageState, page = 1, pageSize = 25 }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_MINIMUM_SALARIES_INDEX_STATUS",
      stateDataKey: [
        "organization_minimum_salaries",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "organizationMinimumSalariesErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.organizationMinimumSalaries.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize } },
      errorMessage: "Organization minimum salaries could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_CREATE_STATUS",
      stateDataKey: ["organization_minimum_salary"],
      stateErrorKey: ["organizationMinimumSalaryErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Organization minimum salary could not be created.",
    })
  }

  static show = async ({ pageState, organizationMinimumSalaryId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_MINIMUM_SALARIES_SHOW_STATUS",
      stateDataKey: ["organization_minimum_salary"],
      stateErrorKey: ["organizationMinimumSalaryErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.show,
      apiUrlReplacements: { organizationMinimumSalaryId },
      apiData: {},
      errorMessage: "Organization minimum salary could not be fetched.",
    })
  }

  static update = async ({
    pageState,
    values,
    organizationMinimumSalaryId,
  }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_UPDATE_STATUS",
      stateDataKey: ["organization_minimum_salary"],
      stateErrorKey: ["organizationMinimumSalaryErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.update,
      apiUrlReplacements: { organizationMinimumSalaryId },
      apiData: { ...values },
      errorMessage: "Organization minimum salary could not be updated.",
    })
  }

  static import = async ({ pageState, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_IMPORT_STATUS",
      stateDataKey: ["organization_minimum_salary"],
      stateErrorKey: ["organizationMinimumSalaryErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.import,
      apiUrlReplacements: {},
      apiData: formData,
      errorMessage: "Organization minimum salaries could not be imported.",
    })
  }

  static export = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_EXPORT_STATUS",
      stateDataKey: ["organization_minimum_salaries"],
      stateErrorKey: ["organizationMinimumSalariesErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.export,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Organization minimum salaries could not be exported.",
    })
  }

  static refresh = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_REFRESH_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.refresh,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Organization minimum salary could not be refreshed.",
    })
  }

  static revalidate = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_REVALIDATE_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.revalidate,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Organization Minimum Salary could not be revalidated.",
    })
  }

  static delete = async ({ pageState, organizationMinimumSalaryId }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_DELETE_STATUS",
      stateDataKey: ["minimum_wage"],
      stateErrorKey: ["minimumWageErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.delete,
      apiUrlReplacements: { organizationMinimumSalaryId },
      apiData: {},
      errorMessage: "Minimum salaries could not be deleted.",
    })
  }

  static auditReport = async ({ pageState, organizationMinimumSalaryId }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_AUDIT_REPORT_STATUS",
      stateDataKey: ["organization_minimum_salary"],
      stateErrorKey: ["organizationMinimumSalaryErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.auditReport,
      apiUrlReplacements: { organizationMinimumSalaryId },
      apiData: {},
      errorMessage: "Audit report could not be exported.",
    })
  }

  static auditReports = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_ORGANIZATION_MINIMUM_SALARIES_AUDIT_REPORTS_STATUS",
      stateDataKey: ["organization_minimum_salaries"],
      stateErrorKey: ["organizationMinimumSalariesErrors"],
      apiEndpoint: API.users.organizationMinimumSalaries.auditReports,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Audit reports could not be exported.",
    })
  }
}

import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { message, Button } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import CriteriaSet from 'components/CriteriaSet'
import dayjs from 'dayjs'

import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import { Color } from 'constants/theme'
import BudgetValuesService from 'services/users/budget-values.service'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`

const StyledItemList = styled.div`
	& > div {
		margin-bottom: 50px;
	}

	& .item-row {
		display: flex;
		margin-bottom: 5px;
		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			max-width: 250px;
			justify-content: flex-end;
			text-transform: uppercase;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
		}
	}
	& .colored.item-row {
		& div:last-child {
			background: #f9f3ff;
		}
	}
`

const BudgetValueShowPage = (props) => {
	const pageState = globalStore.ui.configuration.budgetValues.show
	const history = useHistory()
	const { budgetValueId, budgetHeadId } = useParams()
	// const [deleteLoading, setDeleteLoading] = useState(false)

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetValue = async () => {
			const pageState = globalStore.ui.configuration.budgetValues.show

			await BudgetValuesService.show({ pageState, budgetValueId })

			const { serverStatus, budgetValueErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetValueErrors) {
				message.error(budgetValueErrors[0] || "Coundn't fetch Budget Value")
				return
			}
		}

		fetchBudgetValue()
	}, [budgetValueId, budgetHeadId])

	const handleEdit = () => {
		history.push(`/configuration/budget-heads/${budgetHeadId}/budget-values/${budgetValueId}/edit`)
	}

	// const handleDelete = async () => {
	//   const pageState = {}

	//   setDeleteLoading(true)
	//   await MinimumWagesService.delete({ pageState, budgetValueId })
	//   setDeleteLoading(false)

	//   const { serverStatus } = pageState

	//   if (serverStatus.status !== 0) {
	//     message.error("Failed to delete this minimum wage")
	//     return
	//   }

	//   message.success("Minimum Wage deleted.")

	//   history.push("/configuration/minimum-wages")
	// }

	const { budget_value, API_USERS_BUDGET_VALUE_SHOW_STATUS } = pageState

	const renderContent = () => {
		if (!budget_value) return null
		const {
			criteria,
			currency,
			total_cost,
			rate,
			frequency,
			unit,
			start_date,
			end_date,
		} = budget_value

		return (
			<div>
				<h1>{budget_value.name}</h1>
				<StyledItemList>
					<div>
						<div className='item-row colored'>
							<div className='label'>Currency</div>
							<div>{currency}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Count</div>
							<div>{unit}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Rate</div>
							<div>{rate}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Frequency</div>
							<div>{frequency?.charAt(0).toUpperCase() + frequency?.slice(1)}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Total Cost</div>
							<div>{total_cost}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Start Date</div>
							<div>{dayjs(start_date).format('DD-MMM-YYYY')}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'> End Date </div>
							<div>{dayjs(end_date).format('DD-MMM-YYYY')}</div>
						</div>
					</div>
				</StyledItemList>
				<CriteriaSet criteria={criteria} />
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Button type='primary' ghost onClick={handleEdit}>
						Edit
					</Button>
					{/* <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button> */}
				</div>
				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	const strategy =
		budget_value && budget_value.id === budgetValueId ? staleWhileRevalidate : networkOnly

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures.length &&
		!['smart-budget'].every((featureKey) => accessibleFeatures.includes(featureKey))
	)
		return <Redirect to='/' />

	return (
		<div className='page-content budget-values-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_BUDGET_VALUE_SHOW_STATUS,
					data: budget_value,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetValueShowPage)

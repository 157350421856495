import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { EyeOutlined, InboxOutlined } from "@ant-design/icons"
import {
  message,
  Button,
  Empty,
  Pagination,
  Table,
  Modal,
  Upload,
  Tooltip,
} from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import DesignationsService from "services/users/designations.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const DesignationsIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.designations.index
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [fileList, setFileList] = useState([])
  const [importing, setImporting] = useState(false)

  useEffect(() => {
    const fetchDesignations = async () => {
      const pageState = globalStore.ui.designations.index

      await DesignationsService.index({ pageState })

      const { serverStatus, designationsErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (designationsErrors) {
        message.error(designationsErrors[0] || "Coundn't fetch designations")
        return
      }
    }

    fetchDesignations()
  }, [])

  const repaginate = async (page, pageSize, bcsla = null) => {
    if (bcsla) {
      await DesignationsService.index({ pageState, page, pageSize, bcsla })
      return
    }
    await DesignationsService.index({ pageState, page, pageSize })
  }

  const openImportModal = () => {
    setImportModalVisible(true)
  }

  const handleImport = async () => {
    if (fileList.length === 0) return

    const tempState = {}

    const formData = new FormData()

    formData.append("file", fileList[0])

    setImporting(true)
    await DesignationsService.import({ pageState: tempState, formData })
    setImporting(false)

    setImportModalVisible(false)

    message.success(
      "Import started. Kindly check import page in Report Center to see imported designations"
    )
  }

  const handleCardItemClick = (incentiveId) => {
    history.push(`/configuration/incentives/${incentiveId}`)
  }

  const {
    designations,
    designationsMeta,
    API_USERS_DESIGNATIONS_INDEX_STATUS,
    displayType = "table",
  } = pageState

  const {
    total,
    page: currentPage,
    page_size: pageSize,
  } = designationsMeta || {}

  const renderList = () => {
    return (
      <StyledList>
        {designations.map((ic) => (
          <div
            className="item-card"
            key={`item-card-${ic.id}`}
            onClick={() => handleCardItemClick(ic.id)}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{ic.name}</div>
                <div>{}</div>
              </div>
            </div>
            <div className="icon-box">
              <Button type="link">
                <EyeOutlined />
              </Button>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderContents = () => {
    if (designations.length === 0) {
      return (
        <Empty>
          <Tooltip title="Create or update multiple records by upload">
            <Button onClick={openImportModal} style={{ marginRight: 10 }}>
              Import
            </Button>
          </Tooltip>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    )

    const tableColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Job Family",
        dataIndex: "job_family",
        key: "job_family",
      },
      {
        title: "Role Band",
        dataIndex: "role_band",
        key: "role_band",
      },
      {
        title: "Pseudo Role Band",
        dataIndex: "pseudo_role_band",
        key: "pseudo_role_band",
      },
    ]

    const dataSource = designations.map((ic) => {
      const retval = { ...ic, key: ic.id }

      return retval
    })

    const renderTable = () => {
      return (
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        ></Table>
      )
    }

    const renderInfo = () => {
      if (displayType === "table") return renderTable()
      return renderList()
    }

    return (
      <div>
        {pagination}
        <br />
        {networkOnly({
          status: API_USERS_DESIGNATIONS_INDEX_STATUS,
          data: designations,
          render: renderInfo,
        })}

        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <div className="action-buttons top">
            <Tooltip title="Create or update multiple records by upload">
              <Button onClick={openImportModal} style={{ marginRight: 10 }}>
                Import
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const uploadProps = {
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([file])
      return false
    },
    fileList,
  }

  // const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  // if (
  //   accessibleFeatures?.length > 0 &&
  //   !accessibleFeatures.includes("fitment-data-view")
  // )
  //   return <Redirect to="/" />

  return (
    <div className="page-content incentives-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_DESIGNATIONS_INDEX_STATUS,
          data: designations,
          render: renderPageContent,
        })}
      </StyledBox>
      <Modal
        title="Import"
        visible={importModalVisible}
        confirmLoading={importing}
        closable={false}
        maskClosable={false}
        destroyOnClose
        onOk={handleImport}
        okText="Import"
        onCancel={() => setImportModalVisible(false)}
      >
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Please ensure data accuracy in your file for upload. After upload,
            each associated records will be updated directly.
          </p>
        </Upload.Dragger>
      </Modal>
    </div>
  )
}

export default view(DesignationsIndexPage)

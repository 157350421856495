import React from "react"
import { view } from "@risingstack/react-easy-state"
import { Layout, Button, message } from "antd"

import siteConfig from "siteConfig"
import { isLoggedIn } from "helpers/auth.helper"
import { Redirect, useHistory } from "react-router-dom"

import queryString from "query-string"

const { Footer, Content } = Layout

const AuthLayout = (props) => {
  const history = useHistory()
  const params = queryString.parse(history.location.search)
  const { integration } = params

  if(history.location.pathname !=="/hirereview-sso-login")
    if (integration !== "SmartRecruiter" && isLoggedIn()) {
      message.info("You are already lodged in")
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }

  return (
    <div className="auth-page">
      <Layout>
        <Content>{props.children}</Content>
        <Footer>
          <div>
            <div>Copyright &copy; {siteConfig.site.companyName}</div>
            <div className="links">
              {siteConfig.site.footerLinks.map(({ href, label }, i) => {
                return (
                  <Button type="link" href={href} key={i}>
                    {label}
                  </Button>
                )
              })}
            </div>
          </div>
        </Footer>
      </Layout>
    </div>
  )
}

export default view(AuthLayout)

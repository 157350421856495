import React, { useState, useEffect } from "react"

import ConditionRow from "components/budget-analysis/ConditionRow"
import globalStore from "store/index"
import {message } from "antd"
import BudgetCriteriaHeadsService from "services/users/budget-criteria-heads.service"


const blankCondition = {
  field: null,
  operator: null,
  values: null,
}
const Criteria = ({onCriteriaUpdate}) => {
  const [criteria, setCriteria] = useState([[blankCondition]])
  const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
  const [criteriaChildren, setCriteriaChildren] = useState([])

  useEffect(() => {
    const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list

    const fetchAllFieldsData = async () => {
      await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
      const {budget_criteria_heads} = pageState


      if (!budget_criteria_heads) {
        message.error("Budget Criteria not found")
        return
      }
      setBudgetCriteriaHeads(budget_criteria_heads)
    }

    fetchAllFieldsData()
  }, [])

  useEffect(() => {
    if (criteriaChildren.length) {
      const updateCriteriaData = criteria[0].map((data) => {
        if (criteriaChildren.some((items) => items.name === data.field)) {
          return { ...data, values: null, operator: null }
        }
        return data
      })
      setCriteria([updateCriteriaData])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteriaChildren])

  const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
        return newConditionSet
      })
      onCriteriaUpdate(newCriteria)
      return newCriteria
    })
  }

  const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(deletedConditionIndex, 1)
        return newConditionSet
      })
      onCriteriaUpdate(newCriteria)
      return newCriteria
    })
  }

  const handleUpdateConditionValues = ({
    conditionSetIndex,
    conditionIndex,
    condition,
  }) => {
    if (!condition) return
    if (!condition.values) return

    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        return newConditionSet.map((c, index) => {
          if (index !== conditionIndex) return c

          return condition
        })
      })
      onCriteriaUpdate(newCriteria)
      return newCriteria
    })
  }

  return (
    <div>
      {criteria.map((conditionSet, conditionSetIndex) => {
        return (
          <div key={conditionSetIndex}>
            {conditionSet.map((condition, conditionIndex) => {
              return (
                <ConditionRow
                  criteria={criteria}
                  clearChildren={setCriteriaChildren}
                  condition={condition}
                  key={conditionIndex}
                  conditionSetLength = {conditionSet.length}
                  lastInputGroup={conditionSet.length === conditionIndex + 1}
                  firstInputGroup={conditionIndex === 0}
                  inputGroupIndex={conditionIndex}
                  updateConditionValues={(condition) =>
                    handleUpdateConditionValues({
                      conditionSetIndex,
                      conditionIndex,
                      condition,
                    })
                  }
                  addNewCondition={() =>
                    handleAddNewCondition(conditionSetIndex, conditionIndex)
                  }
                  removeCondition={() =>
                    handleRemoveCondition(conditionSetIndex, conditionIndex)
                  }
                  budgetCriteriaHeads={
                    budgetCriteriaHeads
                  }
                />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Criteria

import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, Redirect, useParams, Link } from 'react-router-dom'
import styled from 'styled-components'
import { EyeOutlined } from '@ant-design/icons'
import { message, Button, Pagination, Table, Tooltip, Empty, Popconfirm } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetCriteriaValuesService from 'services/users/budget-criteria-values.service'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const BudgetCriteriaValuesIndexPage = (props) => {
	const history = useHistory()
	const pageState = globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.index
	const { budgetCriteriaHeadId } = useParams()

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetCriteriaValues = async () => {
			const pageState = globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.index
			await BudgetCriteriaValuesService.index({ pageState, budgetCriteriaHeadId })

			const { serverStatus, budgetCriteriaValuesErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetCriteriaValuesErrors) {
				message.error(budgetCriteriaValuesErrors[0] || "Budget Value couldn't be fetched.")
				return
			}
		}

		fetchBudgetCriteriaValues()
	}, [budgetCriteriaHeadId])

	const repaginate = async (page, pageSize, bcsla = null) => {
		if (bcsla) {
			await BudgetCriteriaValuesService.index({ pageState, page, pageSize, bcsla })
			return
		}
		await BudgetCriteriaValuesService.index({ pageState, page, pageSize })
	}

	const handleCreate = () => {
		history.push(
			`/configuration/budget-criteria-heads/${budgetCriteriaHeadId}/budget-criteria-values/new`
		)
	}

	const handleEditItemClick = (budgetCriteriaValueId) => {
		history.push(
			`/configuration/budget-criteria-heads/${budgetCriteriaHeadId}/budget-criteria-values/${budgetCriteriaValueId}/edit`
		)
	}

	const handleDelete = async (budgetCriteriaValueId) => {
		const tempState = {}

		await BudgetCriteriaValuesService.delete({
			pageState: tempState,
			budgetCriteriaValueId,
		})

		const { serverStatus } = tempState

		if (serverStatus.status !== 200) return

		globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.index = {
			...globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.index,
			budget_criteria_values: budget_criteria_values.filter(
				(budget_criteria_value) => budget_criteria_value.id !== budgetCriteriaValueId
			),
		}
	}

	const {
		budget_criteria_values,
		budget_criteria_valuesMeta,
		API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_INDEX_STATUS,
		displayType = 'table',
	} = pageState
	const { total, page: currentPage, page_size: pageSize } = budget_criteria_valuesMeta || {}

	const renderList = () => {
		return (
			<StyledList>
				{budget_criteria_values.map((ic) => (
					<div className='item-card' key={`item-card-${ic.id}`}>
						<div className='details-box'>
							<div className='name-box'>
								<div className='name'>{ic.name}</div>
								<div>{}</div>
							</div>
						</div>
						<div className='icon-box'>
							<Button type='link'>
								<EyeOutlined />
							</Button>
						</div>
					</div>
				))}
			</StyledList>
		)
	}

	const renderContents = () => {
		if (budget_criteria_values.length === 0) {
			return (
				<Empty>
					<Button type='primary' ghost onClick={handleCreate}>
						Create Hierarchy Values
					</Button>
				</Empty>
			)
		}

		const pagination = (
			<Pagination
				current={currentPage}
				total={total}
				onChange={repaginate}
				onShowSizeChange={repaginate}
				pageSize={pageSize || 25}
				pageSizeOptions={[10, 25, 50, 100]}
			/>
		)

		const parentColumns = budget_criteria_values[0]?.parent_heirarchy.map((step, i) => {
			return Object.keys(step)
		})

		const tableColumns = [
			...[
				...parentColumns.map((pc) => ({
					title: pc,
					dataIndex: 'parent_heirarchy',
					key: 'parent_heirarchy',
					render: (value, ic, index) => {
						return (
							<>
								{value.map((step) => {
									if (step[pc] !== undefined) {
										return <>{Object.values(step[pc])}</>
									}
									return ''
								})}
							</>
						)
					},
				})),
				{
					title: budget_criteria_values[0].budget_criteria_head_name,
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					render: (value, ic) => {
						return (
							<>
								<Link onClick={() => handleEditItemClick(ic.id)}>Edit</Link>
								<span> | </span>
								<Popconfirm
									title='Are you sure to delete?'
									onConfirm={() => handleDelete(ic.id)}
									onCancel={() => {}}
									okText='Yes'
									cancelText='No'>
									<Button style={{ padding: 0 }} type='link'>
										Delete
									</Button>
								</Popconfirm>
							</>
						)
					},
				},
			],
		]

		const dataSource = budget_criteria_values.map((ic) => {
			const retval = { ...ic, key: ic.id }
			return retval
		})

		const renderTable = () => {
			return (
				<Table
					columns={tableColumns}
					dataSource={dataSource}
					pagination={false}
					scroll={{
						x: 'max-content',
					}}></Table>
			)
		}

		const renderInfo = () => {
			if (displayType === 'table') return renderTable()
			return renderList()
		}

		return (
			<div>
				{pagination}
				{networkOnly({
					status: API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_INDEX_STATUS,
					data: budget_criteria_values,
					render: renderInfo,
				})}

				{pagination}
			</div>
		)
	}

	const renderPageContent = () => {
		// const bcslaSearch = (
		//   <Input.Search
		//     placeholder="BCSLA"
		//     onSearch={(bcsla) => repaginate(1, pageSize, bcsla)}
		//     style={{ width: 200, marginBottom: "10px" }}
		//   />
		// )

		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div>{/* bcslaSearch */}</div>
					{budget_criteria_values.length > 0 && (
						<div className='action-buttons top'>
							<Tooltip title='Create a new record'>
								<Button type='primary' ghost onClick={handleCreate}>
									Create Hierarchy Values
								</Button>
							</Tooltip>
						</div>
					)}
				</div>

				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('criteria-edit'))
		return <Redirect to='/' />

	return (
		<div className='page-content budgets-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_INDEX_STATUS,
					data: budget_criteria_values,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetCriteriaValuesIndexPage)

import globalStore from "store"

export const getWNSCountry = (fitment) => {
  return fitment?.job?.tags?.find((t) => t.label === "WNS Country")?.value
    ?.label
}

export const hideStandardFields = ({ fitment }) => {
  let hideStandardFieldsObject = {}

  const wnsCountry = fitment?.country

  const hiddenFieldsFor =
    globalStore?.currentOrganization?.hidden_fields_for || {}

  Object.keys(hiddenFieldsFor).forEach((f) => {
    hideStandardFieldsObject[f] = hiddenFieldsFor[f].includes(wnsCountry)
    return
  })

  return hideStandardFieldsObject
}

export const isConfigurationValueZero = (resource) => {
  const possibleZeros = ["0.0", "0", 0]

  if (!resource) return false

  if (
    ["budget", "minimum_wage", "organization_minimum_salary"].includes(
      resource.type
    ) &&
    possibleZeros.includes(resource.amount)
  )
    return true

  if (
    resource.type === "salary_range" &&
    possibleZeros.includes(resource.maximum) &&
    possibleZeros.includes(resource.minimum) &&
    possibleZeros.includes(resource.median)
  )
    return true

  if (
    resource.type === "incentive_configuration" &&
    possibleZeros.includes(resource.payout_value)
  )
    return true

  return false
}

import React, { useEffect,useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useParams, Redirect, useHistory } from "react-router-dom"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledPageContent from "components/common/styled-components/StyledPageContent"
import CriteriaSet from "components/CriteriaSet"
import TemplateEditor from "components/TemplateEditor"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import OfferLetterTemplatesService from "services/users/offer-letter-templates.service"

const OfferLetterTemplatesShowPage = () => {
  const pageState = globalStore.ui.configuration.offerLetterTemplates.show
  const history = useHistory()
  const { offerLetterTemplateId } = useParams()
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.offerLetterTemplates.show

      await OfferLetterTemplatesService.show({
        pageState,
        offerLetterTemplateId,
      })

      const { serverStatus, offerLetterTemplateErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (offerLetterTemplateErrors) {
        message.error(
          offerLetterTemplateErrors[0] ||
            "Offer Letter Template couldn't be fetched."
        )
        return
      }
    }

    fetchData()
  }, [offerLetterTemplateId])

  const handleEdit = () => {
    history.push(
      `/configuration/offer-letter-templates/${offerLetterTemplateId}/edit`
    )
  }

  const handleDelete = async () => {
    const pageState = {}

    setDeleteLoading(true)
    await OfferLetterTemplatesService.delete({ pageState, offerLetterTemplateId })
    setDeleteLoading(false)

    const { message:messages, offerLetterTemplateErrors } = pageState
    
    if (offerLetterTemplateErrors) {
			message.error(offerLetterTemplateErrors[0] || 'Failed to delete this Offer Letter Template')
			return
		}

    message.success(messages)

    history.push("/configuration/offer-letter-templates")
  }

  const {
    offer_letter_template,
    API_USERS_OFFER_LETTER_TEMPLATES_SHOW_STATUS,
  } = pageState

  const renderContent = () => {
    if (!offer_letter_template) return null

    const { criteria, content } = offer_letter_template
    return (
      <div>
        <CriteriaSet criteria={criteria} />
        <div className="input-box">
          <strong>Template</strong>
          <TemplateEditor content={content} editable={false} />
        </div>
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="page-header">
          <h1>{offer_letter_template.name}</h1>
          <div className="action-buttons top">
            <Button type="primary" ghost onClick={handleEdit}>
              Edit
            </Button>
            <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </div>

        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("letter-template-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content offer-letter-template-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_OFFER_LETTER_TEMPLATES_SHOW_STATUS,
          data: offer_letter_template,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(OfferLetterTemplatesShowPage)

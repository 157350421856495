import React, { useEffect, useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'

import { message, List, Button, Dropdown, Menu } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledFitmentDetails from 'components/common/styled-components/StyledFitmentDetails'

import FitmentService from 'services/users/fitments.service'
import { staleWhileRevalidate } from 'utils/render-strategies'

import globalStore from 'store/index'
import CandidateCard from 'components/CandidateCard'

import RefreshButton from 'components/RefreshButton'
import { DownOutlined } from '@ant-design/icons'

const AdditionalFitmentsDocumentShowPage = () => {
	const pageState = globalStore.ui.fitments.show
	// const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	const { fitmentId } = useParams()
	const [fitment, setFitment] = useState(null)
	const [letterList, setLetterList] = useState([])
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.fitments.show

			await FitmentService.fitmentLetterDetails({ pageState, fitmentId })

			const { fitment } = pageState

			if (!fitment) {
				message.error('Failed to load fitments')
				return
			}
			const { applicable_letters_details: applicableLettersDetails, letters } = fitment
			const list = applicableLettersDetails.map((data) => {
				const getLetterWithSameId = letters.find(
					(letterData) => letterData.letter_template_id === data.id
				)
				const returnList = { ...data, downloadUrl: getLetterWithSameId?.frontend_download_url }
				return returnList
			})
			setLetterList(list)

			setFitment(fitment)
		}

		fetchData()
	}, [fitmentId])

	const handleRefreshBulkLetters = async () => {
		const tempState = {}
		const values = { fitment_ids: [fitmentId] }
		await FitmentService.refreshBulkDocusignLetterStatus({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
	}

	const handleGenerateBulkLetters = async () => {
		const tempState = {}
		const values = { fitment_ids: [fitmentId] }
		await FitmentService.generateBulkLetters({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
	}
	const handleSendBulkLetters = async () => {
		const tempState = {}
		const values = { fitment_ids: [fitmentId] }
		await FitmentService.sendBulkLetters({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
	}
	const handleSendBulkLettersViaDocusign = async () => {
		const tempState = {}
		const values = { fitment_ids: [fitmentId] }
		await FitmentService.sendBulkLettersViaDocusign({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		message.destroy()
		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
	}

	const sendDocumentButtonMenu = (
		<Menu>
			<Menu.Item key='0'>
				<Button type='link' ghost onClick={handleSendBulkLetters}>
					Send Via Email
				</Button>
			</Menu.Item>
			<Menu.Item key='1'>
				<Button type='link' ghost onClick={handleSendBulkLettersViaDocusign}>
					Send Via Docusign
				</Button>
			</Menu.Item>
		</Menu>
	)

	const renderPageContent = () => {
		return (
			<StyledFitmentDetails>
				<CandidateCard {...fitment['candidate']} />
				{['approved', 'docusign_completed', 'docusign_inprogress'].includes(fitment?.status) && (
					<div
						className='action-buttons top'
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: '20px',
						}}>
						<RefreshButton
							key='refresh-button'
							type='primary'
							style={{ marginRight: 10 }}
							ghost
							refresh={handleRefreshBulkLetters}
						/>
						<Button
							type='primary'
							ghost
							style={{ marginRight: 10 }}
							onClick={handleGenerateBulkLetters}>
							Generate Additional Document
						</Button>
						<Dropdown overlay={sendDocumentButtonMenu}>
							<Button>
								Send Document <DownOutlined />
							</Button>
						</Dropdown>
					</div>
				)}
				<div className='header'>
					<h1>Document List</h1>
				</div>
				<List
					itemLayout='horizontal'
					style={{ margin: '0px 20px' }}
					dataSource={letterList}
					renderItem={(item) => {
						return (
							<List.Item
								actions={[
									item?.downloadUrl ? (
										<Button
											type='link'
											target='_blank'
											href={item?.downloadUrl}
											downloadable='true'>
											Download
										</Button>
									) : (
										''
									),
								]}>
								<List.Item.Meta title={item.name} />
							</List.Item>
						)
					}}
				/>
				<div className='header' style={{ marginBottom: 20 }}>
					<h1>Docusign Letter</h1>
				</div>
				<a href={fitment?.docusign_letter_frontend_url || '#'} target='_blank' rel='noopener noreferrer'>
					<Button type='primary' ghost disabled={!fitment?.docusign_letter_frontend_url}>
						Download Docusign Letter
					</Button>
				</a>
			</StyledFitmentDetails>
		)
	}

	const { API_USERS_FITMENTS_LETTER_DETAILS_STATUS } = pageState
	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('additional-document-send'))
		return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_FITMENTS_LETTER_DETAILS_STATUS,
					data: fitment,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(AdditionalFitmentsDocumentShowPage)

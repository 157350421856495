import React from "react"
import { view } from "@risingstack/react-easy-state"

const DashboardPage = (props) => {
  return (
    <div className="page-content dashboard-page">Super Admin Dashboard</div>
  )
}

export default view(DashboardPage)

import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import BudgetsService from "services/users/budgets.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;box-shadow
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const BudgetsShowPage = (props) => {
  const pageState = globalStore.ui.configuration.budgets.show
  const history = useHistory()
  const { budgetId } = useParams()
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    const fetchBudget = async () => {
      const pageState = globalStore.ui.configuration.budgets.show

      await BudgetsService.show({ pageState, budgetId })

      const { serverStatus, budgetErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (budgetErrors) {
        message.error(budgetErrors[0] || "Budget couldn't be fetched.")
        return
      }
    }

    fetchBudget()
  }, [budgetId])

  const handleEdit = () => {
    history.push(`/configuration/budgets/${budgetId}/edit`)
  }

  const handleDelete = async () => {
    const pageState = {}

    setDeleteLoading(true)
    await BudgetsService.delete({ pageState, budgetId })
    setDeleteLoading(false)

    const { serverStatus } = pageState

    if (serverStatus.status !== 0) {
      message.error("Budget couldn't be deleted.")
      return
    }

    message.success("Budget deleted.")

    history.push("/configuration/budgets")
  }

  const { budget, API_USERS_BUDGETS_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!budget) return null

    const { criteria, currency, amount } = budget

    return (
      <div>
        <h1>{budget.name}</h1>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Currency</div>
              <div>{currency}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Amount</div>
              <div>{amount}</div>
            </div>
          </div>
        </StyledItemList>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Budget
          </Button>
          <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const strategy =
    budget && budget.id === budgetId ? staleWhileRevalidate : networkOnly

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/configuration/budgets" />

  return (
    <div className="page-content budgets-page">
      <StyledBox>
        {strategy({
          status: API_USERS_BUDGETS_SHOW_STATUS,
          data: budget,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(BudgetsShowPage)

import { API, generic } from 'api'

export default class ApprovalChainsService {
	static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_INDEX_STATUS',
			stateDataKey: ['approval_chains'],
			stateErrorKey: ['approvalChainsErrors'],
			apiEndpoint: API.users.approvalChains.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, bcsla } },
			errorMessage: 'Approval chains could not be fetched.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_CREATE_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Approval chain could not be created.',
		})
	}

	static show = async ({ pageState, approvalChainId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_SHOW_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.show,
			apiUrlReplacements: { approvalChainId },
			apiData: {},
			errorMessage: 'Approval chain could not be fetched.',
		})
	}

	static refresh = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_REFRESH_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.approvalChains.refresh,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Approval chain could not be refreshed.',
		})
	}
	static refreshSalaryApprovalChain = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SALARY_APPROVAL_CHAINS_REFRESH_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.approvalChains.refreshSalaryApprovalChain,
			apiUrlReplacements: {},
			apiData: { fitment_id: fitmentId },
			errorMessage: 'Salary Approval chain could not be refreshed.',
		})
	}

	static refreshBudgetTentativeValue = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_REFRESH_BUDGET__TENTATIVE_VALUE_STATUS',
			stateDataKey: ['budget_head'],
			stateErrorKey: ['budgetHeadErrors'],
			apiEndpoint: API.users.approvalChains.refreshBudgetTentativeValue,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Approval chain could not be refreshed.',
		})
	}

	static update = async ({ pageState, approvalChainId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_UPDATE_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.update,
			apiUrlReplacements: { approvalChainId },
			apiData: { ...values },
			errorMessage: 'Approval chain could not be updated.',
		})
	}

	static import = async ({ pageState, formData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_IMPORT_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.import,
			apiUrlReplacements: {},
			apiData: formData,
			errorMessage: 'Approval chains could not be imported.',
		})
	}

	static export = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_EXPORT_STATUS',
			stateDataKey: ['approval_chains'],
			stateErrorKey: ['approvalChainsErrors'],
			apiEndpoint: API.users.approvalChains.export,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Approval chains could not be exported.',
		})
	}

	static delete = async ({ pageState, approvalChainId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_DELETE_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.delete,
			apiUrlReplacements: { approvalChainId },
			apiData: {},
			errorMessage: 'Approval chain could not be deleted.',
		})
	}

	static publish = async ({ pageState, approvalChainId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_PUBLISH_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.publish,
			apiUrlReplacements: { approvalChainId },
			apiData: {},
			errorMessage: 'Approval chain could not be published.',
		})
	}
	static unpublish = async ({ pageState, approvalChainId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_UNPUBLISH_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.unpublish,
			apiUrlReplacements: { approvalChainId },
			apiData: {},
			errorMessage: 'Approval chain could not be unpublished.',
		})
	}

	static auditReport = async ({ pageState, approvalChainId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_AUDIT_REPORT_STATUS',
			stateDataKey: ['approval_chain'],
			stateErrorKey: ['approvalChainErrors'],
			apiEndpoint: API.users.approvalChains.auditReport,
			apiUrlReplacements: { approvalChainId },
			apiData: {},
			errorMessage: 'Audit report could not be exported.',
		})
	}

	static auditReports = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_APPROVAL_CHAINS_AUDIT_REPORTS_STATUS',
			stateDataKey: ['approval_chains'],
			stateErrorKey: ['approvalChainsErrors'],
			apiEndpoint: API.users.approvalChains.auditReports,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Audit reports could not be exported.',
		})
	}
	static syncBCSLAOwnerEmail = async ({ pageState, bcsla }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SYNC_BCSLA_OWNER_EMAIL_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['messageErrors'],
			apiEndpoint: API.users.approvalChains.syncBCSLAOwnerEmail,
			apiUrlReplacements: {},
			apiData: { params: { bcsla } },
			errorMessage: 'Audit reports could not be exported.',
			serializedResponse: false,
		})
	}
}

import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import queryString from "query-string"
import { Form, Input, Button, message } from "antd"

import StyledLoginPageContent from "components/common/styled-components/StyledLoginPageContent"
import globalStore from "store/index"
import * as AuthHelper from "helpers/auth.helper"
import TokensService from "services/admin-users/tokens.service"

import { storeInLocalStorage } from "store/localStorage"

const LoginPage = (props) => {
  const history = useHistory()
  const params = queryString.parse(history.location.search)

  const { redirectTo } = params

  const [submitting, setSubmitting] = useState(false)

  const onFinish = async (values) => {
    const pageState = {}

    const postData = {
      auth: {
        ...values,
      },
    }

    setSubmitting(true)
    await TokensService.login({ pageState, values: postData })
    setSubmitting(false)

    const { admin_user, adminUserErrors } = pageState

    if (admin_user) {
      globalStore.currentUser = null
      AuthHelper.setToken(admin_user.jwt)
      storeInLocalStorage({ key: "userType", value: "admin-users" })
      message.success("LoggedIn successfully")
      if (redirectTo) {
        history.push(redirectTo)
        return
      }
      history.push("/admin-users")
      return
    }

    message.error(
      (adminUserErrors && adminUserErrors[0]) || "Oops!! something went wrong"
    )
  }

  return (
    <div className="page-content login-page">
      <StyledLoginPageContent>
        <div className="form__box">
          <h1>Super Admin Login</h1>
          <p>Enter your details below to continue</p>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Enter your email or username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Enter your password" />
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Submit
                </Button>
                {/* <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    window.location = `${process.env.REACT_APP_API_BASE_URL}/auth/saml`
                  }}
                >
                  ADFS Login
                </Button> */}
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledLoginPageContent>
    </div>
  )
}

export default view(LoginPage)

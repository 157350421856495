import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Row, Col, Input, Button, message, Select } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"

import UsersService from "services/admin-users/organizations/users.service"

import OrganizationRolesService from "services/admin-users/organizations/organization-roles.service"

const StyledPageContent = styled.div`
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & input:nth-child(2) {
      margin-left: 15px;
    }
    & .action-buttons {
      margin: 0px;
      & > button {
        margin: 0px 0px 0px 15px;
      }
    }
  }
`

const UsersEditPage = (props) => {
  const history = useHistory()
  const { organizationId, userId } = useParams()
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [selectedRoleIds, setSelectedRoleIds] = useState([])
  const [roles, setRoles] = useState([])
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false)

  useEffect(() => {
    const fetchUser = async () => {
      const tempState = {}

      await UsersService.show({
        pageState: tempState,
        organizationId,
        userId,
      })

      const { user } = tempState

      if (!user) {
        message.destroy()
        message.error("User couldn't be fetched")
        return
      }

      setName(user.name)
      setEmail(user.email)
      setSelectedRoleIds(user.organization_roles?.map((r) => r.id))
    }

    fetchUser()
  }, [organizationId, userId])

  useEffect(() => {
    const fetchRoles = async () => {
      const tempState = {}

      await OrganizationRolesService.index({
        pageState: tempState,
        organizationId,
      })

      const { organization_roles } = tempState

      setRoles(organization_roles || [])
    }

    fetchRoles()
  }, [organizationId])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSelectedRolesChange = (roleIds) => {
    setSelectedRoleIds(roleIds)
  }

  const handleUpdate = async () => {
    const tempState = {}
    const postData = {
      user: {
        name,
        email,
        organization_role_ids: selectedRoleIds,
      },
    }

    setUpdateButtonLoading(true)
    await UsersService.update({
      pageState: tempState,
      values: postData,
      organizationId,
      userId,
    })
    setUpdateButtonLoading(false)

    const { serverStatus } = tempState

    if (serverStatus.status !== 200) {
      message.error("User couldn't be updated.")
      return
    }

    message.destroy()
    message.success("User updated.")
    history.push(`/admin-users/organizations/${organizationId}/users/${userId}`)
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="input-box">
              <h1>Name</h1>
              <Input
                value={name}
                onChange={handleNameChange}
                placeholder="Enter organization name"
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="input-box">
              <h1>Email</h1>
              <Input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter email"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="input-box">
              <h1>Roles</h1>
              <Select
                mode="multiple"
                placeholder="Select roles"
                value={selectedRoleIds}
                onChange={handleSelectedRolesChange}
                style={{ width: "100%" }}
              >
                {roles.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <div>
          <div className="action-buttons">
            <Button
              type="primary"
              loading={updateButtonLoading}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
        </div>
      </StyledPageContent>
    )
  }

  return (
    <div className="page-content">
      <StyledBox maxWidth="800px">{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(UsersEditPage)

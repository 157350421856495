import React from 'react'
import { Link } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'

const StyledDashboardBox = styled.div`
	display: flex;
	flex-warp: warp;
	gap: 10px;
	& .cards {
		flex: 1 0 21%;
		padding: 15px 20px 20px 20px;
		display: flex;
		flex-direction: column;
	}
	& .card-bg {
		background-color: white;
		box-shadow: 0px 5px 10px 0px #d9d9d912;
	}
`
const WorkForceDashboard = () => {
	return (
		<div className='page-content'>
			<StyledDashboardBox>
				<div className='cards card-bg'>
					<h3>Rules Setup</h3>
					<div style={{ marginBottom: 5 }}>Functional</div>
					<Link to={{ pathname: '/configuration/budget-heads', state: { goBackLink: true } }}>
						Budget Creation
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-criteria-heads',
							state: { goBackLink: true },
						}}>
						Org Hierarchy
					</Link>
					<Link
						to={{ pathname: '/configuration/workforce-period', state: { goBackLink: true } }}>
						WorkForce Period
					</Link>
					<Link to={{ pathname: '/configuration/approval-chains', state: { goBackLink: true } }}>
						Approval Workflow
					</Link>
					<Link to={{ pathname: '/configuration/salary-ranges', state: { goBackLink: true } }}>
						Control Tables
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/resource-plan',
							state: { goBackLink: true },
						}}>
						Resource Planning
					</Link>
					<div style={{ margin: '15px 0 5px 0' }}>Org Administration</div>
					<Link to={{ pathname: '/users/organizations/edit', state: { goBackLink: true } }}>
						Org Info
					</Link>
					<Link to={{ pathname: '/users', state: { goBackLink: true } }}>Permissions</Link>
					<Link to={{ pathname: '/configuration/email-templates', state: { goBackLink: true } }}>
						Templates
					</Link>
				</div>
				<div className='cards card-bg'>
					<h3>Data Operation</h3>
					<Link
						to={{
							pathname: '/configuration/budget-heads/add-position',
							state: { goBackLink: true },
						}}>
						Add Position
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/add-employee',
							state: { goBackLink: true },
						}}>
						Update Employee
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/budget-approval-workflow',
							state: { goBackLink: true },
						}}>
						Budget Approval
					</Link>
				</div>
				<div className='cards card-bg'>
					<h3>Reports</h3>
					<Link
						to={{
							pathname: '/configuration/budget-heads/position-occupancy-report',
							state: { goBackLink: true },
						}}>
						Position Listing
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/recruitment-staffing-report',
							state: { goBackLink: true },
						}}>
						Vacancy Staffing Reports
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/recruitment-vacancy-report',
							state: { goBackLink: true },
						}}>
						Recruitment Vacancy Report
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/position-transition-analysis',
							state: { goBackLink: true },
						}}>
						Position Transition Analysis
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/yoy-summary',
							state: { goBackLink: true },
						}}>
						Y-O-Y Summary
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/incremental-change-timeline',
							state: { goBackLink: true },
						}}>
						Incremental Change Timeline
					</Link>
				</div>
				<div className='cards card-bg'>
					<h3>Dashboard & Analytics</h3>
					<Link
						to={{
							pathname: '/configuration/budget-heads/budget-analysis',
							state: { goBackLink: true },
						}}>
						Planned vs Actual
					</Link>
					<Link
						to={{
							pathname: '/configuration/budget-heads/budget-monitoring-utilization-report',
							state: { goBackLink: true },
						}}>
						Budget Timeline analysis
					</Link>
					<Link
						to={{
							pathname: '/position-reportings/organization-chart',
							state: { goBackLink: true },
						}}>
						Org Structure
					</Link>
				</div>
			</StyledDashboardBox>
		</div>
	)
}

export default view(WorkForceDashboard)

import styled from "styled-components"

import { Color } from "constants/theme"

const StyledFitmentDetails = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-left: 15px;
		}
	}
	& .card {
		margin-bottom: 50px;
		& .header > h1 {
			font-size: 1.5rem !important;
			font-weight: bold;
		}
	}
	& .card:last-child {
		margin: 0px;
	}
	& .candidate-card,
	& .job-card {
		margin-bottom: 30px;
		padding: 16px 30px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		border-radius: 5px;
		color: ${Color.textColor};
		box-shadow: 2px 2px 20px 2px #dadddf82;
		background: ${Color.secondaryColor};
		border: 1px solid rgba(0, 0, 0, 0.1);
		align-items: flex-start;

		& > .name {
			font-size: 2.5em;
			margin: 0px;
			font-weight: bold;
		}
		& > .email {
			font-size: 1.5em;
			margin: 0px 0px 10px 0px;
			font-weight: 300;
		}
		& > .external-id {
			font-weight: 300;
		}
	}
	& .fitment-fields.card {
		& .header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 10px 5px;
			& h1 {
				margin: 0px;
			}
		}
		& .action-buttons {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin: ${(props) => (props.editable ? '20px' : 'none')};
		}
	}
	& .item-list {
		width: 660px;
		margin: auto;
	}
	& .item-row {
		display: flex;
		margin-bottom: 5px;

		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			width: 300px;
			justify-content: flex-end;
			text-transform: capitalize;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
			text-align: right;
		}
	}
	& .fitment-field.item-row {
		& div.select-after {
			& > div.ant-select-selector {
				border-color: #d9d9d9 !important;
				border-left: 0px !important;
				border-right: 0px !important;
				background: white !important;
			}
		}
		& div.ant-picker {
			background: white !important;
			& div.ant-picker-input {
				background: white !important;
			}
		}
		& .tooltip {
			width: 100% !important;
			background: none !important;
		}
		& div:last-child {
			background: #f9f3ff;
			width: 300px;
		}
	}
	& .properties-row {
		display: flex;
		& > .label {
			margin-right: 20px;
			font-weight: bold;
		}
	}
	& .upload-btn {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}
`

export default StyledFitmentDetails

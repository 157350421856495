import { API, generic } from 'api'

export default class PositionReportingService {
	static orgCharts = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_POSITION_REPORTS_ORG_CHART_STATUS',
			stateDataKey: ['org_chart_data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.positionReportings.orgCharts,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'Org Chart data could not be fetched.',
			serializedResponse: false,
		})
	}

	static import = async ({ pageState, formData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_POSITION_REPORTS_IMPORT_STATUS',
			stateDataKey: ['org_chart_data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.positionReportings.import,
			apiUrlReplacements: {},
			apiData: formData,
			errorMessage: 'Org Chart could not be imported.',
		})
	}
	static importTransition = async ({ pageState, formData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_POSITION_REPORTS_IMPORT_TRANSITION_STATUS',
			stateDataKey: ['org_chart_data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.positionReportings.importTransition,
			apiUrlReplacements: {},
			apiData: formData,
			errorMessage: 'Transition could not be imported.',
		})
	}
}

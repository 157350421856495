import React, { useState, useEffect } from "react"
import { useParams, useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Input, message } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import ConditionRow from "components/ConditionRow"
import globalStore from "store/index"

import TemplateEditor from "components/TemplateEditor"

import OrganizationsService from "services/users/organizations.service"
import LetterTemplatesService from "services/users/letter-templates.service"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const blankCondition = {
  field: null,
  operator: null,
  values: null,
}

const AdditionalLetterTemplatesEditPage = () => {
  const history = useHistory()
  const { letterTemplateId } = useParams()
  const [criteria, setCriteria] = useState([[blankCondition]])
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const [inputValues, setInputValues] = useState({})
  const [templateContent, setTemplateContent] = useState(null)
  const [content, setContent] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.letterTemplates.edit

      await LetterTemplatesService.show({
        pageState,
        letterTemplateId,
      })

      const {
        serverStatus,
        letter_template,
        letterTemplateErrors,
      } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (letterTemplateErrors) {
        message.error(
          letterTemplateErrors[0] || "Configuration couldn't be fetched."
        )
        return
      }

      const { name, criteria, content } = letter_template

      const newCriteria = criteria?.length > 0 ? criteria : [[blankCondition]]

      setCriteria(newCriteria)
      setInputValues({
        name,
      })
      setTemplateContent(content)
      setContent(content)
    }

    fetchData()
  }, [letterTemplateId])

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  const handleInputChange = (e, inputType) => {
    let newInputValues = null

    newInputValues = { ...inputValues, [inputType]: e.target.value }

    setInputValues(newInputValues)
  }

  const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(deletedConditionIndex, 1)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleUpdateConditionValues = ({
    conditionSetIndex,
    conditionIndex,
    condition,
  }) => {
    if (!condition) return
    if (condition.operator !== "not" && !condition.values) return

    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        return newConditionSet.map((c, index) => {
          if (index !== conditionIndex) return c

          return condition
        })
      })

      return newCriteria
    })
  }

  const handleEditorContentChange = (content) => {
    setContent(content)
  }

  const handleUpdate = async () => {
    if (!inputValues["name"]) {
      message.error("Name can't be blank")
      return
    }

    const pageState = {}
    const postData = {
      letter_template: {
        criteria,
        content,
        ...inputValues,
      },
    }

    setCreateButtonLoading(true)
    await LetterTemplatesService.update({
      pageState,
      values: postData,
      letterTemplateId,
    })
    setCreateButtonLoading(false)

    const { serverStatus, letterTemplateErrors } = pageState

    if (letterTemplateErrors) {
      message.error(
        letterTemplateErrors[0] || "Configuration couldn't be updated."
      )
      return
    }

    if (serverStatus.status === 200) {
      message.success("Configuration updated.")
      history.push(`/configuration/additional-letter-templates/${letterTemplateId}`)
      setCriteria([[blankCondition]])
      setInputValues({})

      return
    }

    message.error("Oops!! something went wrong")
  }

  const renderPageContent = () => {
    return (
      <StyledConfigurationFormBox>
        <h1>Letter Template</h1>
        <div className="input-box">
          <strong>Name</strong>
          <Input
            value={inputValues["name"]}
            onChange={(e) => handleInputChange(e, "name")}
          />
        </div>
        <div className="input-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>
              <strong>Criteria</strong>
            </h3>
            {criteria.filter((c) => c.length > 0).length === 0 && (
              <Button
                shape="circle"
                onClick={() => setCriteria([[blankCondition]])}
              >
                <PlusOutlined />
              </Button>
            )}
          </div>
          {criteria.map((conditionSet, conditionSetIndex) => {
            return (
              <div key={conditionSetIndex}>
                <strong>{`Condition Set ${conditionSetIndex + 1}`}</strong>
                {conditionSet.map((condition, conditionIndex) => {
                  return (
                    <ConditionRow
                      condition={condition}
                      key={conditionIndex}
                      lastInputGroup={
                        conditionSet.length === conditionIndex + 1
                      }
                      firstInputGroup={conditionIndex === 0}
                      inputGroupIndex={conditionIndex}
                      updateConditionValues={(condition) =>
                        handleUpdateConditionValues({
                          conditionSetIndex,
                          conditionIndex,
                          condition,
                        })
                      }
                      addNewCondition={() =>
                        handleAddNewCondition(conditionSetIndex, conditionIndex)
                      }
                      removeCondition={() =>
                        handleRemoveCondition(conditionSetIndex, conditionIndex)
                      }
                      organizationFields={
                        globalStore.currentOrganization.all_fields_with_datatype
                      }
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="input-box">
          <strong>Template</strong>
          <TemplateEditor
            content={templateContent}
            onChange={handleEditorContentChange}
          />
        </div>
        <div className="action-buttons submit">
          <Button
            type="primary"
            loading={createButtonLoading}
            onClick={handleUpdate}
          >
            Save
          </Button>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('additional-template-edit'))
		return <Redirect to={`/configuration/additional-letter-templates/${letterTemplateId}`} />

  return (
    <div className="page-content dashboard-page">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(AdditionalLetterTemplatesEditPage)

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import moment from "moment"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import MinimumWagesService from "services/users/minimum-wages.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const MinimumWagesShowPage = (props) => {
  const pageState = globalStore.ui.configuration.minimumWages.show
  const history = useHistory()
  const { minimumWageId } = useParams()
  // const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    const fetchMinimumWage = async () => {
      const pageState = globalStore.ui.configuration.minimumWages.show

      await MinimumWagesService.show({ pageState, minimumWageId })

      const { serverStatus, minimumWageErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (minimumWageErrors) {
        message.error(minimumWageErrors[0] || "Coundn't fetch minimum wage")
        return
      }
    }

    fetchMinimumWage()
  }, [minimumWageId])

  const handleEdit = () => {
    history.push(`/configuration/minimum-wages/${minimumWageId}/edit`)
  }

  // const handleDelete = async () => {
  //   const pageState = {}

  //   setDeleteLoading(true)
  //   await MinimumWagesService.delete({ pageState, minimumWageId })
  //   setDeleteLoading(false)

  //   const { serverStatus } = pageState

  //   if (serverStatus.status !== 0) {
  //     message.error("Failed to delete this minimum wage")
  //     return
  //   }

  //   message.success("Minimum Wage deleted.")

  //   history.push("/configuration/minimum-wages")
  // }

  const { minimum_wage, API_USERS_MINIMUM_WAGES_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!minimum_wage) return null

    const {
      criteria,
      currency,
      amount,
      frequency,
      live_start_at,
      live_end_at,
    } = minimum_wage

    return (
      <div>
        <h1>{minimum_wage.name}</h1>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Currency</div>
              <div>{currency}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Amount</div>
              <div>{amount}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Frequency</div>
              <div style={{textTransform: "capitalize"}}>{frequency}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Live Start At</div>
              <div>
                {live_start_at ? (
                  moment(live_start_at).format("DD-MMM-YYYY hh:mm A")
                ) : (
                  <em>Not set</em>
                )}
              </div>
            </div>
            <div className="item-row colored">
              <div className="label">Live End At</div>
              <div>
                {live_end_at ? (
                  moment(live_end_at).format("DD-MMM-YYYY hh:mm A")
                ) : (
                  <em>Not set</em>
                )}
              </div>
            </div>
          </div>
        </StyledItemList>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit
          </Button>
          {/* <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button> */}
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const strategy =
    minimum_wage && minimum_wage.id === minimumWageId
      ? staleWhileRevalidate
      : networkOnly

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content minimum-wages-page">
      <StyledBox>
        {strategy({
          status: API_USERS_MINIMUM_WAGES_SHOW_STATUS,
          data: minimum_wage,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(MinimumWagesShowPage)

import React from 'react'
import ContentLoading from 'components/common/ContentLoading'
import ContentLoadingError from 'components/common/ContentLoadingError'

export const staleWhileRevalidate = ({ status, render, data }) => {
	if (data) return render()
	if (status === 'rejected') return <ContentLoadingError />
	return <ContentLoading />
}

export const networkOnly = ({ status, render, data }) => {
	if (!status) return <ContentLoading />
	if (status === 'pending') return <ContentLoading />
	if (status === 'rejected') return <ContentLoadingError />

	if (!data) return <ContentLoading />
	return render()
}

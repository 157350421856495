import React, { useState, useEffect } from "react"
import { Redirect, useParams } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Select, Button, Form, Input, message } from "antd"

import { staleWhileRevalidate } from "utils/render-strategies"
import UsersService from "services/users/users.service"

import OrganizationRolesService from "services/users/organization-roles.service"
import AccessGroupsService from "services/users/access-groups.service"

import globalStore from "store"

import StyledBox from "components/common/styled-components/StyledBox"

const StyledUserEditForm = styled.div``

const UsersEditPage = () => {
  const pageState = globalStore.ui.users.edit
  const { userId } = useParams()
  const [user, setUser] = useState(null)
  const [organizationRoles, setOrganizationRoles] = useState([])
  const [accessGroups, setAccessGroups] = useState([])
  const [updating, setUpdating] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      const tempState = {}

      await OrganizationRolesService.index({ pageState: tempState })

      const { organization_roles, serverStatus } = tempState

      if (serverStatus && serverStatus.status === 200) {
        setOrganizationRoles(organization_roles || [])
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const tempState = {}

      await AccessGroupsService.index({ pageState: tempState })

      const { access_groups, serverStatus } = tempState

      if (serverStatus && serverStatus.status === 200) {
        setAccessGroups(access_groups || [])
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const tempState = {}

      await UsersService.show({ pageState: tempState, userId })

      const { user, serverStatus } = tempState

      if (serverStatus && serverStatus.status === 200) {
        const { name, email, organization_roles, access_groups } = user
        const filteredUser = {
					name,
					email,
					organization_roles,
					access_groups,
				}
        setUser(filteredUser || [])
      }
    }

    fetchData()
  }, [userId])

  const handleUpdate = async () => {
    const tempState = {}
    const postData = {
      user: {
        ...user,
        organization_role_ids: user.organization_roles.map((or) => or.id),
        access_group_ids: user.access_groups.map((or) => or.id),
      },
    }

    setUpdating(true)
    await UsersService.update({
      pageState: tempState,
      values: postData,
      userId,
    })
    setUpdating(false)

    const { serverStatus, userError } = tempState

    if (serverStatus.status !== 200) {
      message.error("User details couldn't be updated")
      return
    }

    if (userError?.length > 0) {
      message.error(userError[0])
      return
    }

    message.success("User details updated.")
  }

  const handleNameChange = (e) => {
    setUser({
      ...user,
      name: e.target.value,
    })
  }

  const handleEmailChange = (e) => {
    setUser({
      ...user,
      email: e.target.value,
    })
  }

  const handleOrganizationRoleChange = (selectedRoleIds) => {
    const seletedRoles = organizationRoles.filter((or) =>
      selectedRoleIds.includes(or.id)
    )

    setUser({
      ...user,
      organization_roles: seletedRoles,
    })
  }

  const handleAccessGroupChange = (selectedIds) => {
    const seletedAccessGroups = accessGroups.filter((or) =>
      selectedIds.includes(or.id)
    )

    setUser({
      ...user,
      access_groups: seletedAccessGroups,
    })
  }


  const renderPageContent = () => {
    const { name, email, organization_roles, access_groups } = user

    const selectedOrganizationRoleIds = organization_roles.map((sor) => sor.id)
    const selectedAccessGroupIds = access_groups.map((ag) => ag.id)

    return (
      <StyledUserEditForm>
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input
              placeholder="Enter name"
              value={name}
              onChange={handleNameChange}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              placeholder="Enter email"
              value={email}
              onChange={handleEmailChange}
            />
          </Form.Item>
          <Form.Item label="Organization Roles">
            <Select
              mode="multiple"
              placeholder="Choose organization roles"
              value={selectedOrganizationRoleIds}
              onChange={handleOrganizationRoleChange}
              style={{ width: "100%" }}
            >
              {organizationRoles.map((item, i) => (
                <Select.Option key={item.key} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Access Groups">
            <Select
              mode="multiple"
              placeholder="Choose access groups"
              value={selectedAccessGroupIds}
              onChange={handleAccessGroupChange}
              style={{ width: "100%" }}
            >
              {accessGroups.map((item, i) => (
                <Select.Option key={item.key} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ cssFloat: "right" }}
              loading={updating}
              onClick={handleUpdate}
            >
              {updating ? "Updating..." : "Update"}
            </Button>
          </Form.Item>
        </Form>
      </StyledUserEditForm>
    )
  }


  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("user-edit")
  )
    return <Redirect to="/" />

  const { API_USERS_SHOW_STATUS } = pageState

  return (
    <div className="page-content user-page">
      <StyledBox maxWidth={"500px"}>
        {staleWhileRevalidate({
          status: API_USERS_SHOW_STATUS,
          data: user,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(UsersEditPage)

import { Button, Input, Select, Divider, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import styled from 'styled-components'

const StyledCriteria = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0px;
	& > .input {
		flex: 1;
	}
	& > .input:nth-child(2) {
		margin: 0px 10px;
		flex: 1;
	}
	& > .action-buttons {
		display: flex;
		justify-content: space-between;
		width: 85px;
		& button {
			margin-left: 10px;
		}
	}
`
let index = 0

const DropDownConditionalRow = ({ fieldData, setFieldData, placeholder }) => {
	const [items, setItems] = useState([])
	const [name, setName] = useState('')

	useEffect(() => {
		setItems(fieldData.map((data) => data.name).filter((data) => data))
	}, [fieldData])

	const handleAddNew = () => {
		setFieldData((data) => [...data, { name: null, value: null }])
	}

	const handleRemove = (i) => {
		setFieldData((data) => data.filter((_item, index) => i !== index))
	}

	const handleValueChange = (i, value) => {
		const values = [...fieldData]
		values[i].name = value
		setFieldData(values)
	}

	const handleFieldChange = (i, value) => {
		const values = [...fieldData]
		values[i].value = value
		setFieldData(values)
	}

	const onNameChange = (event) => {
		setName(event.target.value)
	}

	const addItem = (e) => {
		e.preventDefault()
		setItems([...items, name || `New item ${index++}`])
		setName('')
	}

	return (
		<>
			{fieldData.map((data, i) => (
				<StyledCriteria key={`fieldData-${i}`}>
					<div className='input'>
						<Select
							style={{ width: '100%' }}
							showSearch
							placeholder={placeholder?.name || 'Choose field'}
							value={data?.name || null}
							filterOption={(inputValue, option) => {
								if (!option.children) return false
								return option.children.toLowerCase().includes(inputValue.toLowerCase())
									? true
									: false
							}}
							onChange={(value) => handleValueChange(i, value)}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider
										style={{
											margin: '8px 0',
										}}
									/>
									<Space
										style={{
											padding: '0 8px 4px',
										}}>
										<Input placeholder='Please enter item' value={name} onChange={onNameChange} />
										<Button type='text' icon={<PlusOutlined />} onClick={addItem}>
											Add item
										</Button>
									</Space>
								</>
							)}
							options={items.map((item) => ({
								label: item,
								value: item,
							}))}
						/>
					</div>
					<div className='input'>
						<Input
							placeholder={placeholder?.value || "Enter field's value"}
							disabled={!data?.name}
							value={data?.value || ''}
							onChange={(e) => handleFieldChange(i, e.target.value)}
						/>
					</div>
					<div className='action-buttons'>
						<div>
							<Button
								danger
								shape='circle'
								disabled={fieldData?.length < 2}
								onClick={() => handleRemove(i)}>
								<DeleteOutlined />
							</Button>
						</div>
						<div>
							<Button
								type='primary'
								disabled={!data?.name || !data?.value}
								ghost
								shape='circle'
								onClick={handleAddNew}>
								<PlusOutlined />
							</Button>
						</div>
					</div>
				</StyledCriteria>
			))}
		</>
	)
}

export default DropDownConditionalRow

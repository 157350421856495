import React from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { InfoCircleOutlined } from "@ant-design/icons"
import { Popover } from "antd"

const StyledConfiguration = styled.div`
  & .section-configuration-row {
    padding: 10px;
    display: flex;
    background-color: #f2f2f2;
    min-width: 100px;
    & > .name,
    & > .monthly,
    & > .annual {
      font-weight: bold;
      width: 33%;
    }

    & > .monthly,
    & > .annual {
      text-align: right;
    }
  }
  & .head-configuration-row {
    padding: 10px;
    // padding-left: 20px;
    display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid #ddd;
    & > .name,
    & > .monthly,
    & > .annual {
      width: ${(props) => (props.hideMonthly ? "50%" : "33%")};
      display: flex;
      & > span {
        margin-left: 10px;
      }
    }
    & > .monthly,
    & > .annual {
      justify-content: flex-end;
    }

    // & .value {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   & .info-icon {
    //     margin-left: 10px;
    //   }
    // }
  }
`

const StyledFormula = styled.div`
  & .formula {
    word-break: break-all;
    & > .tag-variable {
      font-size: 12px;
      margin: 0px 5px;
      padding: 0px 5px;
      color: #722ed1;
      background: #f9f0ff;
      border: 1px solid #d3adf7;
    }
  }
`

const SSConfiguration = ({
  ss_configuration,
  computedSalary = [],
  mode = "formula",
  hideMonthly = false,
}) => {
  const { section_configurations } = ss_configuration

  const renderValue = (hc) => {
    const formula = (
      <StyledFormula>
        <div
          dangerouslySetInnerHTML={{ __html: hc.formula }}
          className="formula"
        ></div>
      </StyledFormula>
    )

    if (mode === "values") {
      return (
        <div className="value">
          {/* <div>{hc.computed_value}</div>{" "} */}
          <Popover content={formula} title={"Formula"}>
            <InfoCircleOutlined className="info-icon" />
          </Popover>
        </div>
      )
    }

    return formula
  }

  return (
    <StyledConfiguration hideMonthly={hideMonthly}>
      {section_configurations
        .sort((a, b) => a.position - b.position)
        .map((sc, index) => {
          return (
            <div key={index}>
              <div className="section-configuration-row">
                <div className="name">Salary Head</div>
                {!hideMonthly && <div className="monthly">Monthly</div>}
                <div className="annual">Annual</div>
              </div>
              {sc.head_configurations.map((hc, h_index) => {
                const salary =
                  computedSalary.find((cs) => cs.name === hc.name) || {}

                return (
                  <div className="head-configuration-row" key={h_index}>
                    <div className="name">{hc.name}</div>
                    {!hideMonthly && (
                      <div className="monthly">
                        {salary.computed_monthly_value}
                      </div>
                    )}
                    <div className="annual">
                      {salary.computed_annual_value}
                      <span>{renderValue(hc)}</span>
                    </div>
                    {/* {renderValue(hc)} */}
                  </div>
                )
              })}
            </div>
          )
        })}
    </StyledConfiguration>
  )
}

export default view(SSConfiguration)

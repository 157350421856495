import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useParams } from "react-router-dom"
import ContentLoading from "components/common/ContentLoading"

import ExportsService from "services/users/exports.service"

const ExportDownloadRedirector = () => {
  const { exportId } = useParams()

  useEffect(() => {
    const fetchDataAndRedirect = async () => {
      const tempState = {}

      await ExportsService.download({ pageState: tempState, exportId })

      const { redirect_url } = tempState

      // TODO: Handle errors. Show error messages on the same page
      if (redirect_url) window.location = redirect_url
    }
    fetchDataAndRedirect()
  }, [exportId])

  return <ContentLoading />
}

export default view(ExportDownloadRedirector)

import StyledBox from 'components/common/styled-components/StyledBox'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Layout, message, Collapse } from 'antd'
import UsersService from 'services/users/users.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSuitcase, faPaperclip, faCompass } from '@fortawesome/free-solid-svg-icons'
const ConsentForm = () => {
	const [loading, setLoading] = useState(false)
	const { state } = useLocation()
	const { Panel } = Collapse

	const handleApprove = async () => {
		if (!state) return
		const { companyId, redirect } = state
		const tempState = {}
		const values = { companyId }
		setLoading(true)
		await UsersService.smartRecruitersConsent({ pageState: tempState, values })
		const { message: successMessage, error } = tempState
		if (error) message.error(error, 10)
		else message.success(successMessage, 10)
		setLoading(false)

		setTimeout(function () {
			window.open(redirect, '_self')
		}, 3000)
	}
	const contentSmartRec = () => (
		<div style={{ margin: '20px 10px' }}>
			<div style={{ margin: '10px 0', fontStyle: 'semi-bold' }}>
				Smart Recruiter will use below information:
			</div>
			<Collapse expandIconPosition='end' bordered={false}>
				<Panel
					header={
						<div>
							<FontAwesomeIcon icon={faUser} style={{ marginRight: 20 }} />
							Generate Assessment Package Selection
						</div>
					}
					key='1'>
					<div>Manage results of assessment packages</div>
				</Panel>
				<Panel
					header={
						<div>
							<FontAwesomeIcon icon={faSuitcase} style={{ marginRight: 20 }} />
							Process Assessments
						</div>
					}
					key='2'>
					<div>Process Assessments</div>
				</Panel>
				<Panel
					header={
						<div>
							<FontAwesomeIcon icon={faCompass} style={{ marginRight: 20 }} />
							Accept Assessment Result
						</div>
					}
					key='3'>
					<div>Accept Assessment Result</div>
				</Panel>
				<Panel
					header={
						<div>
							<FontAwesomeIcon icon={faPaperclip} style={{ marginRight: 20 }} />
							Accept Attachment for the Assessment
						</div>
					}
					key='4'>
					<div>Accept Attachment for the Assessment</div>
				</Panel>
			</Collapse>
		</div>
	)
	return (
		<Layout>
			<div className='private-page'>
				<div className='page-content dashboard-page' style={{ marginInline: 200 }}>
					<StyledBox>
						<h2>Smart Recruiter Integration</h2>
						{contentSmartRec()}
						<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
							<Button type='primary' loading={loading} onClick={handleApprove}>
								Allow and continue
							</Button>
							<Button
								type='primary'
								ghost
								style={{ margin: '0px 10px' }}
								disabled={loading}
								onClick={() => window.open(state?.redirect, '_self')}>
								Cancel
							</Button>
						</div>
					</StyledBox>
				</div>
			</div>
		</Layout>
	)
}

export default ConsentForm

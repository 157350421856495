import React, { useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { EyeOutlined } from "@ant-design/icons"
import { Table, Tag, Button, Switch, Input } from 'antd'

import { staleWhileRevalidate } from "utils/render-strategies"
import UsersService from "services/users/users.service"
import globalStore from "store"

import StyledBox from "components/common/styled-components/StyledBox"

const StyledPageContent = styled.div`
  & > .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
`

const UsersIndexPage = () => {
  const pageState = globalStore.ui.users.index
  const history = useHistory()
  const [tableLoading, setTableLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.users.index
      setTableLoading(true)
      await UsersService.index({ pageState })
      setTableLoading(false)
    }

    fetchData()
	}, [])
	
	const onStatusChange = async (userId) => {
		const tempState = {}
		await UsersService.updateStatus({ pageState: tempState, userId })
	}
	const onSearch = async (email) => {
			setTableLoading(true)
			await UsersService.index({ pageState,email })
			setTableLoading(false)
	}

  const getColumns = (roles) => {
    let columns = [
			{
				title: '#',
				dataIndex: 'sn',
				key: 'sn',
				width: 50,
				fixed: 'left',
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				width: 220,
				fixed: 'left',
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
				width: 220,
				fixed: 'left',
			},
			{
				title: 'Roles',
				dataIndex: 'roles',
				key: 'roles',
				fixed: 'left',
				render: (roles) => {
					return roles.map((r, i) => <Tag key={i}>{r}</Tag>)
				},
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				fixed: 'left',
				render: (status, row) => (
					<Switch
						size='small'
						defaultChecked={status === 'active' ? true : false}
						onChange={() => onStatusChange(row.key)}
					/>
				),
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				width: 50,
				render: (value, row) => (
					<Button type='link' onClick={() => history.push(`/users/${row.key}`)}>
						<EyeOutlined />
					</Button>
				),
			},
		]

    return columns
  }

  const users = pageState.users || []

  const columns = getColumns()

  const tableData = users.map((u, i) => {
    const retval = {
			key: u.id,
			sn: i + 1,
			name: u.name,
			email: u.email,
			roles: u.organization_roles.map((r) => r.name),
			status:u.status
		}

    return retval
  })

  const renderPageContent = () => {
    return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<Input.Search
						placeholder='Email'
						allowClear
						onSearch={onSearch}
						style={{ width: 200, marginBottom: '20px' }}
					/>
					<Button type='primary' ghost onClick={() => history.push('/users/new')}>
						Create User
					</Button>
				</div>
				<Table loading={tableLoading} dataSource={tableData} columns={columns} />
			</StyledPageContent>
		)
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("user-view")
  )
    return <Redirect to="/" />

  const { API_USERS_INDEX_STATUS } = pageState

  return (
    <div className="page-content users-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_INDEX_STATUS,
          data: users,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(UsersIndexPage)

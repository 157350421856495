import React, { useState, useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Row, Col, Select, Input, Button, message } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store/index'

import OrganizationsService from 'services/users/organizations.service'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'

import CountriesService from 'services/users/countries.service'
import IndustriesService from 'services/users/industries.service'
import OrganizationRolesService from 'services/users/organization-roles.service'

const StyledPageContent = styled.div`
	& .action-buttons {
		display: flex;
		justify-content: flex-end;
		margin-top: 10px;
	}
	& .input-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		& input:nth-child(2) {
			margin-left: 15px;
		}
		& .action-buttons {
			margin: 0px;
			& > button {
				margin: 0px 0px 0px 15px;
			}
		}
	}
`

const currencyData = [
	'AFN',
	'EUR',
	'ALL',
	'DZD',
	'USD',
	'AOA',
	'XCD',
	'ARS',
	'AMD',
	'AWG',
	'SHP',
	'AUD',
	'AZN',
	'BSD',
	'BHD',
	'BDT',
	'BBD',
	'BYN',
	'BZD',
	'XOF',
	'BMD',
	'BTN',
	'BOB',
	'BAM',
	'BWP',
	'BRL',
	'BND',
	'BGN',
	'BIF',
	'CVE',
	'KHR',
	'XAF',
	'CAD',
	'KYD',
	'NZD',
	'CLP',
	'CNY',
	'COP',
	'KMF',
	'CDF',
	'none',
	'CRC',
	'HRK',
	'CUP',
	'ANG',
	'CZK',
	'DKK',
	'DJF',
	'DOP',
	'EGP',
	'ERN',
	'SZL',
	'ETB',
	'FKP',
	'FJD',
	'XPF',
	'GMD',
	'GEL',
	'GHS',
	'GIP',
	'GTQ',
	'GGP',
	'GNF',
	'GYD',
	'HTG',
	'HNL',
	'HKD',
	'HUF',
	'ISK',
	'INR',
	'IDR',
	'XDR',
	'IRR',
	'IQD',
	'IMP',
	'ILS',
	'JMD',
	'JPY',
	'JEP',
	'JOD',
	'KZT',
	'KES',
	'KWD',
	'KGS',
	'LAK',
	'LBP',
	'LSL',
	'LRD',
	'LYD',
	'CHF',
	'MOP',
	'MKD',
	'MGA',
	'MWK',
	'MYR',
	'MVR',
	'MRU',
	'MUR',
	'MXN',
	'MDL',
	'MNT',
	'MAD',
	'MZN',
	'MMK',
	'NAD',
	'NPR',
	'NIO',
	'NGN',
	'KPW',
	'NOK',
	'OMR',
	'PKR',
	'PGK',
	'PYG',
	'PEN',
	'PHP',
	'PLN',
	'QAR',
	'RON',
	'RUB',
	'RWF',
	'WST',
	'STN',
	'SAR',
	'RSD',
	'SCR',
	'SLL',
	'SGD',
	'SBD',
	'SOS',
	'ZAR',
	'GBP',
	'KRW',
	'SSP',
	'LKR',
	'SDG',
	'SRD',
	'SEK',
	'SYP',
	'TWD',
	'TJS',
	'TZS',
	'THB',
	'TOP',
	'TTD',
	'TND',
	'TRY',
	'TMT',
	'UGX',
	'UAH',
	'AED',
	'UYU',
	'UZS',
	'VUV',
	'VES',
	'VND',
	'YER',
	'ZMW',
]

const OrganizationEditPage = (props) => {
	// const { organizationId } = useParams()
	const history = useHistory()
	const pageState = globalStore.ui.users.organizations.show

	const [name, setName] = useState(null)
	const [industryId, setIndustryId] = useState(null)
	const [address, setAddress] = useState(null)
	const [payreviewOrgId, setPayreviewOrgId] = useState(null)
	const [city, setCity] = useState(null)
	const [countryId, setCountryId] = useState(null)
	const [countries, setCountries] = useState([])
	const [industries, setIndustries] = useState([])
	const [organization, setOrganization] = useState(null)
	const [updateButtonLoading, setUpdateButtonLoading] = useState(false)
	const [standardFieldCountries, setStandardFieldCountries] = useState({})
	const [organizationId, setOrganizationId] = useState(null)
	const [resourcePlaningOrgId, setResourcePlaningOrgId] = useState(null)
	const [dataProvider, setDataProvider] = useState(null)
	const [dataProviderList, setDataProviderList] = useState([
		{ name: 'Smart Recruiters', value: 'smart_recruiters' },
	])
	const [dataProviderKey, setDataProviderKey] = useState(null)
	const [srMarketplaceApiKey, setSrMarketplaceApiKey] = useState(null)
	const [externalId, setExternalId] = useState(null)
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [displayCriteriaForBudgetValue, setDisplayCriteriaForBudgetValue] = useState(null)
	const [workforceCriteriaForBudgetValue, setWorkforceCriteriaForBudgetValue] = useState(null)
	const [displayForceUpdatePassword, setDisplayForceUpdatePassword] = useState()
	const [currency, setCurrency] = useState(null)
	const [enableAllocationFeature, setEnableAllocationFeature] = useState(null)
	useEffect(() => {
		const pageState = globalStore.currentOrganization

		const fetchData = async () => {
			await OrganizationsService.me({ pageState: pageState })

			const { organization } = pageState
			if (organization) {
				setOrganization(organization)
				const {
					name,
					industry,
					address,
					city,
					country,
					hidden_fields_for,
					payreview_org_id,
					id,
					resource_planing_org_id,
					data_provider,
					data_provider_key,
					sr_marketplace_api_key,
					external_id,
					display_criteria_for_budget,
					force_update_password,
					currency,
					workforce_criteria,
					enable_allocation_feature,
				} = organization
				setOrganizationId(id)
				setName(name)
				setIndustryId(industry?.id)
				setAddress(address)
				setCity(city)
				setCountryId(country?.id)
				setStandardFieldCountries(hidden_fields_for)
				setPayreviewOrgId(payreview_org_id)
				setResourcePlaningOrgId(resource_planing_org_id)
				setDataProvider(data_provider || 'smart_recruiters')
				setDataProviderList([
					{
						name: 'Smart Recruiters',
						value: 'smart_recruiters',
					},
				])
				setDataProviderKey(data_provider_key)
				setSrMarketplaceApiKey(sr_marketplace_api_key)
				setExternalId(external_id)
				setDisplayCriteriaForBudgetValue(display_criteria_for_budget)
				setWorkforceCriteriaForBudgetValue(workforce_criteria)
				setDisplayForceUpdatePassword(force_update_password)
				setCurrency(currency)
				setEnableAllocationFeature(enable_allocation_feature)

				const tempState = {}
				await BudgetCriteriaHeadsService.criteriaHeadList({ pageState: tempState })
				const { budget_criteria_heads } = tempState

				if (!budget_criteria_heads) {
					message.error('Budget criteria not found')
					return
				}
				setBudgetCriteriaHeads(budget_criteria_heads)
				return
			}

			message.error("Organization data couldn't be fetched")

			history.push(`/`)
		}

		fetchData()
	}, [organizationId, history])

	useEffect(() => {
		const tempState = {}

		const fetchCountries = async () => {
			await CountriesService.index({ pageState: tempState })

			const { countries } = tempState

			if (countries) {
				setCountries(countries)
			}
		}

		fetchCountries()
	}, [])

	useEffect(() => {
		const tempState = {}

		const fetchIndustries = async () => {
			await IndustriesService.index({ pageState: tempState })

			const { industries } = tempState

			if (industries) {
				setIndustries(industries)
			}
		}

		fetchIndustries()
	}, [])

	const handleNameChange = (e) => {
		setName(e.target.value)
	}

	const handleIndustryChange = (industryId) => {
		setIndustryId(industryId)
	}

	const handleAddressChange = (e) => {
		setAddress(e.target.value)
	}
	const handlePayreviewOrgIdChange = (e) => {
		setPayreviewOrgId(e.target.value)
	}

	const handleCityChange = (e) => {
		setCity(e.target.value)
	}

	const handleCountryChange = (countryId) => {
		setCountryId(countryId)
	}

	const handleResourcePlaningOrgId = (e) => {
		setResourcePlaningOrgId(e.target.value)
	}

	const handleDataProvider = (value) => {
		setDataProvider(value)
	}

	const handleDataProviderKey = (e) => {
		setDataProviderKey(e.target.value)
	}

	const handleSrMarketplaceApiKey = (e) => {
		setSrMarketplaceApiKey(e.target.value)
	}

	const handleExternalId = (e) => {
		setExternalId(e.target.value)
	}

	const handleUpdate = async () => {
		const tempState = {}
		const postData = {
			organization: {
				name,
				address,
				city,
				currency,
				country_id: countryId,
				industry_id: industryId,
				hidden_fields_for: standardFieldCountries,
				payreview_org_id: payreviewOrgId,
				resource_planing_org_id: resourcePlaningOrgId,
				data_provider: dataProvider,
				data_provider_key: dataProviderKey,
				sr_marketplace_api_key: srMarketplaceApiKey,
				external_id: externalId,
				display_criteria_for_budget: displayCriteriaForBudgetValue,
				force_update_password: displayForceUpdatePassword,
				workforce_criteria: workforceCriteriaForBudgetValue,
				enable_allocation_feature: enableAllocationFeature,
			},
		}

		setUpdateButtonLoading(true)
		await OrganizationsService.update({
			pageState: tempState,
			values: postData,
			id: organizationId,
		})
		setUpdateButtonLoading(false)

		const { serverStatus, organization } = tempState

		if (serverStatus.status !== 200) {
			message.error('Failed to update.')
			return
		}

		if (organization) {
			setOrganization(organization)
			message.destroy()
			message.success('Organization details updated.')
			return
		}

		message.success('Updated')
	}

	const handleStandardFieldCountryChange = (key, value) => {
		const standardFieldCountriesCopy = JSON.parse(JSON.stringify(standardFieldCountries))

		setStandardFieldCountries({ ...standardFieldCountriesCopy, [key]: value })
	}

	const handleDisplayCriteriaForBudget = (value) => {
		setDisplayCriteriaForBudgetValue(value)
	}
	const handleWorkforceCriteriaForBudget = (value) => {
		setWorkforceCriteriaForBudgetValue(value)
	}
	const handleCurrency = (value) => {
		setCurrency(value)
	}
	const handleEnableAllocationFeature = (value) => {
		setEnableAllocationFeature(value)
	}

	const handleForceUpdatePassword = (value) => {
		setDisplayForceUpdatePassword(value)
	}

	const handleCreateDefaultRole = async () => {
		const tempState = {}

		await OrganizationRolesService.createDefaultRole({ pageState: tempState })
		const { message: messages, organizationRolesErrors } = tempState

		if (organizationRolesErrors) {
			message.error(organizationRolesErrors)
		}
		message.success(messages)
	}

	const renderCountriesOptions = () => {
		return countries.map((c) => (
			<Select.Option key={c.id} value={c.name}>
				{c.name}
			</Select.Option>
		))
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<Row gutter={[20, 5]}>
					<Col span={12} offset={19}>
						<Button type='link' onClick={handleCreateDefaultRole}>
							Create Default Role
						</Button>
					</Col>
				</Row>
				<Row gutter={[20, 20]}>
					<Col span={12}>
						<div className='input-box'>
							<h1>Name</h1>
							<Input
								value={name}
								onChange={handleNameChange}
								placeholder='Enter organization name'
							/>
						</div>
					</Col>
					<Col span={12}>
						<div className='input-box'>
							<h1>Industry</h1>
							<Select
								showSearch
								style={{
									width: '100%',
								}}
								placeholder='Select industry'
								optionFilterProp='children'
								onChange={handleIndustryChange}
								value={industryId}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{industries.map((c) => (
									<Select.Option key={c.id} value={c.id}>
										{c.name}
									</Select.Option>
								))}
							</Select>
						</div>
					</Col>
				</Row>

				<div className='input-box'>
					<h1>Address</h1>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<Input value={address} placeholder='Enter address' onChange={handleAddressChange} />
						</Col>
						<Col span={6}>
							<Input value={city} placeholder='Enter city' onChange={handleCityChange} />
						</Col>
						<Col span={6}>
							<Select
								showSearch
								style={{
									width: '100%',
								}}
								placeholder='Select country'
								optionFilterProp='children'
								value={countryId}
								onChange={handleCountryChange}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{countries.map((c) => (
									<Select.Option key={c.id} value={c.id}>
										{c.name}
									</Select.Option>
								))}
							</Select>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Display Criteria For Budget</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Enter Display Criteria For Budget'
									tokenSeparators={[',']}
									value={displayCriteriaForBudgetValue || undefined}
									onChange={handleDisplayCriteriaForBudget}>
									{budgetCriteriaHeads.map((data, index) => {
										return (
											<Select.Option key={index} value={data.name}>
												{data.name}
											</Select.Option>
										)
									})}
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Force Update Password</h1>
								<Select
									style={{ width: '100%' }}
									placeholder='Force Update Password'
									value={displayForceUpdatePassword}
									onChange={handleForceUpdatePassword}>
									<Select.Option key='yes' value={true}>
										Yes
									</Select.Option>
									<Select.Option key='no' value={false}>
										No
									</Select.Option>
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Currency</h1>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Select Currency'
									value={currency || undefined}
									onChange={handleCurrency}>
									{currencyData.map((data, index) => {
										return (
											<Select.Option key={index} value={data}>
												{data}
											</Select.Option>
										)
									})}
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Criteria For Workforce</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Select Essential Criteria For Workforce'
									tokenSeparators={[',']}
									value={workforceCriteriaForBudgetValue || undefined}
									onChange={handleWorkforceCriteriaForBudget}>
									{budgetCriteriaHeads.map((data, index) => {
										return (
											<Select.Option key={index} value={data.name}>
												{data.name}
											</Select.Option>
										)
									})}
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Enable Allocation Feature</h1>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Select Allocation Feature'
									value={enableAllocationFeature}
									onChange={handleEnableAllocationFeature}>
									<Select.Option key='yes' value={true}>
										Yes
									</Select.Option>
									<Select.Option key='no' value={false}>
										No
									</Select.Option>
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<br />
				<div className='input-box'>
					<h1 style={{ marginBottom: 10 }}>
						<strong>Api Configuration</strong>
					</h1>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<h1>Payreview Organization Id</h1>
							<Input
								value={payreviewOrgId}
								placeholder='Enter Payreview Organization Id'
								onChange={handlePayreviewOrgIdChange}
							/>
						</Col>
						<Col span={12}>
							<h1>Resource Planning Id</h1>

							<Input
								value={resourcePlaningOrgId}
								placeholder='Enter Resource Planning Id'
								onChange={handleResourcePlaningOrgId}
							/>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<h1>Data Provider</h1>
							<Select
								showSearch
								style={{
									width: '100%',
								}}
								placeholder='Select Data provider'
								optionFilterProp='children'
								value={dataProvider}
								onChange={handleDataProvider}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{dataProviderList.map((c, i) => (
									<Select.Option key={i} value={c.value}>
										{c.name}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col span={12}>
							<h1>Data Provider Key</h1>

							<Input
								value={dataProviderKey}
								placeholder='Enter Data provider key'
								onChange={handleDataProviderKey}
							/>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<h1>Smart Recruiter Market Place Api Key</h1>
							<Input
								value={srMarketplaceApiKey}
								placeholder='Enter Smart recruiter market place api key'
								onChange={handleSrMarketplaceApiKey}
							/>
						</Col>
						<Col span={12}>
							<h1>Organization External Id</h1>
							<Input
								value={externalId}
								placeholder='Enter Organization
								External Id'
								onChange={handleExternalId}
							/>
						</Col>
					</Row>
				</div>
				<br />
				<div className='input-box'>
					<h1 style={{ marginBottom: 10 }}>
						<strong>Hide following standard fields for selected countries</strong>
					</h1>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Offered fixed compensation</h1>
								<Select
									mode='tags'
									style={{
										width: '100%',
									}}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.offered_fixed_compensation || []}
									onChange={(value) =>
										handleStandardFieldCountryChange('offered_fixed_compensation', value)
									}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Previous fixed compensation</h1>
								<Select
									mode='tags'
									style={{
										width: '100%',
									}}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.previous_fixed_compensation || []}
									onChange={(value) =>
										handleStandardFieldCountryChange('previous_fixed_compensation', value)
									}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Rate</h1>
								<Select
									mode='tags'
									style={{
										width: '100%',
									}}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.rate || []}
									onChange={(value) => handleStandardFieldCountryChange('rate', value)}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Previous variable compensation</h1>
								<Select
									mode='tags'
									style={{
										width: '100%',
									}}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.previous_variable_compensation || []}
									onChange={(value) =>
										handleStandardFieldCountryChange('previous_variable_compensation', value)
									}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div>
					<div className='action-buttons'>
						<Button type='primary' loading={updateButtonLoading} onClick={handleUpdate}>
							Update
						</Button>
					</div>
				</div>
			</StyledPageContent>
		)
	}

	const { API_USERS_ORGANIZATIONS_ME_STATUS } = pageState

	const accessibleFeatures = globalStore.currentUser?.accessible_features

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('organization-edit'))
		return <Redirect to={`/`} />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox maxWidth='800px'>
				{staleWhileRevalidate({
					status: API_USERS_ORGANIZATIONS_ME_STATUS,
					data: organization,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(OrganizationEditPage)

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { view } from '@risingstack/react-easy-state'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Select, Button, message, Input } from 'antd'

import ContentLoading from 'components/common/ContentLoading'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'

const StyledCriteria = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0px;
	& > .input {
		flex: 2;
	}
	& > .input:nth-child(2) {
		margin: 0px 10px;
		flex: 1;
	}
	& > .action-buttons {
		display: flex;
		justify-content: space-between;
		width: 85px;
		& button {
			margin-left: 10px;
		}
	}
`

const ConditionRow = ({
	lastInputGroup = false,
	criteria,
	condition,
	conditionSetLength,
	addNewCondition,
	removeCondition,
	updateConditionValues,
	budgetCriteriaHeads = [],
	canDelete,
	hideNotOperator = false,
	clearChildren,
}) => {
	const [fieldName, setFieldName] = useState(null)
	const [fieldId, setFieldId] = useState(null)
	const [fieldDataType, setFieldDataType] = useState(null)
	const [operatorValue, setOperatorValue] = useState(null)
	const [value, setValue] = useState([])
	const [dropdownLoading, setDropdownLoading] = useState(false)
	const [dropdownValues, setDropdownValues] = useState([])

	useEffect(() => {
		if (budgetCriteriaHeads.length === 0) return
		let budgetCriteriaHead = budgetCriteriaHeads.find((bh) => bh.name === condition.field)
		setFieldId(budgetCriteriaHead?.id || budgetCriteriaHead?.key)
		// setFieldName(defaultSelectedField.name)

		// setFieldDataType(defaultSelectedField.data_type)
		// setFieldType(defaultSelectedField.type)
		setOperatorValue(condition.operator)

		setValue(condition.values)
	}, [condition, budgetCriteriaHeads])

	useEffect(() => {
		if (!fieldId) return
		if (!budgetCriteriaHeads) return
		const field = budgetCriteriaHeads.find(
			(oField) => oField.id === fieldId || oField.key === fieldId
		)
		if (!field) return

		setFieldName(field.name)
		setFieldDataType(field.data_type)
		// if (!isNumeric(fieldId)) return

		// const fetchData = async () => {
		// 	const tempState = {}

		// 	setDropdownLoading(true)
		// 	await budgetCriteriaValuesService.index({
		// 		pageState: tempState,
		// 		budgetCriteriaHeadId: fieldId,
		// 	})

		// 	const { budget_criteria_values } = tempState

		// 	if (!budget_criteria_values) {
		// 		message.error('Oops!! something went wrong')
		// 		setDropdownLoading(false)
		// 		return
		// 	}
		// 	const ddv = budget_criteria_values
		// 	if (!ddv || ddv.length === 0) {
		// 		message.error(`No Hierarchy value found in ${field.name}`)
		// 		setDropdownLoading(false)
		// 		return
		// 	}

		// 	setDropdownValues(ddv)
		// 	setDropdownLoading(false)
		// }

		// fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldId, budgetCriteriaHeads])

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldId, budgetCriteriaHeads])

	const fetchData = async (ifClearChildren) => {
		// if (!operatorValue && !operator) return
		if (!fieldId) return
		if (!budgetCriteriaHeads) return
		const field = budgetCriteriaHeads.find(
			(oField) => oField.id === fieldId || oField.key === fieldId
		)
		if (!field) return

		setFieldName(field.name)
		setFieldDataType(field.data_type)
		const tempState = {}
		setDropdownLoading(true)
		await BudgetCriteriaHeadsService.criteriaValuesList({
			pageState: tempState,
			values: { name: field.name, criteria: criteria },
		})

		const { budget_criteria_values, children_info, serverStatus } = tempState
		if (serverStatus?.status !== 200) {
			message.error('Oops!! something went wrong')
			setDropdownLoading(false)
			return
		}
		if (ifClearChildren) clearChildren(children_info || [])
		const ddv = budget_criteria_values
		// if (!ddv || ddv.length === 0) {
		// 	message.error(`No Hierarchy value found in ${fieldName}`)
		// 	setDropdownValues(ddv)
		// 	setDropdownLoading(false)
		// 	return
		// }

		setDropdownValues(ddv)
		setDropdownLoading(false)
	}
	const handleFieldChange = (fieldId, option) => {
		setFieldId(fieldId)
		setValue([])
	}

	const handleOperatorChange = (operator) => {
		setOperatorValue(operator)
		fetchData(true)

		if (value?.length > 0) {
			updateConditionValues({
				field: fieldName,
				operator,
				values: value,
			})
		}
	}

	const handleValueChange = (values, dataType) => {
		if (!values) return
		fetchData(true)

		let tempValues = values

		if (dataType === 'boolean') {
			tempValues = JSON.parse(values)
		}
		setValue(tempValues)
		updateConditionValues({
			field: fieldName,
			operator: operatorValue,
			values: tempValues,
		})
	}

	const renderFieldOptions = () => {
		if (!budgetCriteriaHeads) return

		return budgetCriteriaHeads.map((f, index) => {
			const key = f.id || f.key
			return (
				<Select.Option key={index} value={key}>
					{f.name}
				</Select.Option>
			)
		})
	}

	const renderOperatorOptions = () => {
		if (fieldDataType === 'decimal') {
			return (
				<React.Fragment>
					<Select.Option value='='>{`=`}</Select.Option>
					<Select.Option value='<'>{`<`}</Select.Option>
					<Select.Option value='>'>{`>`}</Select.Option>
				</React.Fragment>
			)
		}

		if (fieldDataType === 'boolean') {
			return (
				<React.Fragment>
					<Select.Option value='='>{`=`}</Select.Option>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<Select.Option value='any'>Any</Select.Option>
				{!hideNotOperator && <Select.Option value='not'>Not</Select.Option>}
			</React.Fragment>
		)
	}

	const renderFieldValueOptions = () => {
		if (!dropdownValues) return
		return dropdownValues.map((f, i) => (
			<Select.Option key={i} value={f.name}>
				{f.name}
			</Select.Option>
		))
	}

	const renderFieldValueInput = () => {
		if (Number.isInteger(fieldId)) {
			return (
				<Select
					mode={['any', 'not'].includes(operatorValue?.toLowerCase()) ? 'multiple' : null}
					allowClear
					placeholder='Choose a value'
					showSearch
					style={{ width: '100%' }}
					disabled={
						!operatorValue ||
						(!!fieldName &&
							!condition.field &&
							criteria.flat().some((obj) => obj.field === fieldName))
					}
					value={value || []}
					optionFilterProp='children'
					loading={dropdownLoading}
					onChange={handleValueChange}>
					{renderFieldValueOptions()}
				</Select>
			)
		}

		if (fieldDataType === 'boolean') {
			return (
				<Select
					placeholder='Choose a value'
					style={{ width: '100%' }}
					disabled={
						!operatorValue ||
						(!!fieldName &&
							!condition.field &&
							criteria.flat().some((obj) => obj.field === fieldName))
					}
					value={JSON.stringify(value) || []}
					loading={dropdownLoading}
					onChange={(values) => handleValueChange(values, 'boolean')}>
					<Select.Option key={`option-true`} value={JSON.stringify(['true'])}>
						True
					</Select.Option>
					<Select.Option key={`option-false`} value={JSON.stringify(['false'])}>
						False
					</Select.Option>
				</Select>
			)
		}

		if (fieldDataType === 'list') {
			const fieldValues = budgetCriteriaHeads.find((of) => of.name === fieldName)?.values
			return (
				<Select
					mode={['any', 'not'].includes(operatorValue?.toLowerCase()) ? 'multiple' : null}
					placeholder='Choose a value'
					style={{ width: '100%' }}
					disabled={
						!operatorValue ||
						(!!fieldName &&
							!condition.field &&
							criteria.flat().some((obj) => obj.field === fieldName))
					}
					value={value || []}
					loading={dropdownLoading}
					onChange={handleValueChange}>
					{fieldValues.map((v, i) => (
						<Select.Option key={i} value={v}>
							{v}
						</Select.Option>
					))}
				</Select>
			)
		}

		return (
			<Input
				placeholder='Enter a value'
				disabled={
					!operatorValue ||
					(!!fieldName &&
						!condition.field &&
						criteria.flat().some((obj) => obj.field === fieldName))
				}
				value={value}
				onChange={(e) => handleValueChange([e.target.value])}
			/>
		)
	}

	if (budgetCriteriaHeads.length === 0) {
		return <ContentLoading />
	}

	return (
		<>
			<StyledCriteria>
				<div className='input'>
					<Select
						style={{ width: '100%' }}
						showSearch
						placeholder='Choose Criteria'
						value={fieldId}
						filterOption={(inputValue, option) => {
							if (!option.children) return false
							return option.children.toLowerCase().includes(inputValue.toLowerCase()) ? true : false
						}}
						onChange={handleFieldChange}>
						{renderFieldOptions()}
					</Select>
				</div>
				<div className='input'>
					<Select
						placeholder='Choose an operator'
						style={{ width: '100%' }}
						value={operatorValue}
						disabled={
							!!fieldName &&
							!condition.field &&
							criteria.flat().some((obj) => obj.field === fieldName)
						}
						onChange={handleOperatorChange}>
						{renderOperatorOptions()}
					</Select>
				</div>
				<div className='input'>{renderFieldValueInput()}</div>
				<div className='action-buttons'>
					{conditionSetLength > 1 && (
						<div>
							<Button danger shape='circle' onClick={removeCondition} disabled={canDelete}>
								<DeleteOutlined />
							</Button>
						</div>
					)}
					{lastInputGroup && (
						<div>
							<Button
								type='primary'
								disabled={
									dropdownLoading ||
									(operatorValue === 'any' && !value) ||
									!operatorValue ||
									(!!fieldName &&
										!condition.field &&
										criteria.flat().some((obj) => obj.field === fieldName))
								}
								ghost
								shape='circle'
								onClick={addNewCondition}>
								<PlusOutlined />
							</Button>
						</div>
					)}
				</div>
			</StyledCriteria>
			{!!fieldName &&
				!condition.field &&
				criteria.flat().some((obj) => obj.field === fieldName) && (
					<div
						style={{
							fontSize: 12,
							color: 'red',
						}}>
						Please provide different criteria as they cannot be identical.
					</div>
				)}
		</>
	)
}

export default view(ConditionRow)

import React from "react"
import StyledBox from "components/common/styled-components/StyledBox"
import { Link } from "react-router-dom"
import globalStore from "store/index"
import { view } from "@risingstack/react-easy-state"

const Settings = () => {
	const { currentOrganization } = globalStore
	const currentOrganizationId = currentOrganization?.id

	return (
		<div className='page-content'>
			<StyledBox>
				<div className='setting-outer-layer'>
					<div className='setting-list'>
						<ul className='list-style'>
							<li>
								<h2 className='list-head'>
									Shortlisted
									Candidates
								</h2>
							</li>
							<li>
								<div className='list-sub'>
									Take next
									actions
									for hiring
								</div>
							</li>
							<li>
								<Link to='/'>
									Analytics
									Dashboard
								</Link>
							</li>
							<li>
								<Link to='/fitments'>
									Shortlisted
									Candidates
								</Link>
							</li>
							<li>
								<Link to='/exports'>
									Report
									Centre -
									Export
								</Link>
							</li>
							<li>
								<Link to='/imports'>
									Report
									Centre -
									Import
								</Link>
							</li>
						</ul>
						<ul className='list-style'>
							<li>
								<h2 className='list-head'>
									Configurations
								</h2>
							</li>
							<li>
								<div className='list-sub'>
									Customize
									application
									settings
								</div>
							</li>
							<li>
								<Link to='/configuration/budget-criteria-heads'>
									Organization
									Hierarchy
								</Link>
							</li>
							<li>
								<Link to='/configuration/control-features'>
									Control
									Features
								</Link>
							</li>
							<li>
								<Link to='/configuration/fitment-fields'>
									Fitment
									Field
								</Link>
							</li>
							<li>
								<Link to='/configuration/documents'>
									Document
									List
								</Link>
							</li>
							<li>
								<Link to='/configuration/vendors'>
									Vendor
									List
								</Link>
							</li>
							<li>
								<Link to='/configuration/integration'>
									Apps &
									Integrations
								</Link>
							</li>
						</ul>
					</div>
					<div className='setting-list'>
						<ul className='list-style'>
							<li>
								<h2 className='list-head'>
									Control
									Tables
								</h2>
							</li>
							<li>
								<div className='list-sub'>
									Manage
									data
									points to
									control
									outcomes
								</div>
							</li>
							<li>
								<Link to='/configuration/minimum-wages'>
									Minimum
									Wages
								</Link>
							</li>
							<li>
								<Link to='/configuration/organization-minimum-salaries'>
									Minimum
									Salaries
								</Link>
							</li>
							<li>
								<Link to='/configuration/salary-ranges'>
									Salary
									Ranges
								</Link>
							</li>
							<li>
								<Link to='/configuration/budgets'>
									Candidate
									Budgets
								</Link>
							</li>
							<li>
								<Link to='/configuration/incentives'>
									Incentives
									Structure
								</Link>
							</li>
							<li>
								<Link to='/configuration/approval-chains'>
									Approvals
									Workflow
								</Link>
							</li>
						</ul>
						<ul className='list-style padding-down'>
							<li>
								<h2 className='list-head'>
									Permissions
								</h2>
							</li>
							<li>
								<div className='list-sub'>
									Manage the
									level of
									access of
									your users
								</div>
							</li>
							<li>
								<Link to='/roles'>
									Roles &
									Rights
								</Link>
							</li>
							<li>
								<Link to='/access-groups'>
									Access
									Group
								</Link>
							</li>
							<li>
								<Link to='/users'>
									User
									Mapping
								</Link>
							</li>
						</ul>
					</div>
					<div className='setting-list'>
						<ul className='list-style'>
							<li>
								<h2 className='list-head'>
									Templates
								</h2>
							</li>
							<li>
								<div className='list-sub'>
									Set up and
									manage
									templates
								</div>
							</li>
							<li>
								<Link to='/configuration/ss-configurations'>
									Salary
									Structures
								</Link>
							</li>
							<li>
								<Link to='/configuration/offer-letter-templates'>
									Offer
									Letter
									Templates
								</Link>
							</li>
							<li>
								<Link to='/configuration/email-templates'>
									Email
									Templates
								</Link>
							</li>
						</ul>
						<ul className='list-style padding-down'>
							<li>
								<h2 className='list-head'>
									Administration
								</h2>
							</li>
							<li>
								<div className='list-sub'>
									Access
									company
									information
									&
									subscription
								</div>
							</li>
							<li>
								<Link
									to={`/admin-users/organizations/${currentOrganizationId}/edit`}>
									Company
									Information
								</Link>
							</li>
							<li>
								<Link
									to={`/admin-users/organizations/${currentOrganizationId}/users`}>
									Organization
									Admin
								</Link>
							</li>
							<li>
								<Link to='/admin-users/organization/parameters'>
									Control
									Subscription
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</StyledBox>
		</div>
	)
}

export default view(Settings)

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import BudgetCriteriaValuesService from "services/users/budget-criteria-values.service"
import { changeTitle } from "utils/header-meta"
import LABELS from "constants/labels"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;box-shadow
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const BudgetCriteriaValuesShowPage = (props) => {
  const pageState = globalStore.ui.configuration.budgetCriteriaValues.show
  const history = useHistory()
  const { budgetCriteriaValueId } = useParams()
  // const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    changeTitle(LABELS.smartBudgetPageTitle)

    return () => { changeTitle(LABELS.defaultPageTitle) }

  }, [])

  useEffect(() => {
    const fetchBudgetCriteriaValue = async () => {
      const pageState = globalStore.ui.configuration.budgetCriteriaValues.show

      await BudgetCriteriaValuesService.show({ pageState, budgetCriteriaValueId })

      const { serverStatus, budgetCriteriaValues } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (budgetCriteriaValues) {
        message.error(budgetCriteriaValues[0] || "Couldn't fetch Budget Values")
        return
      }
    }

    fetchBudgetCriteriaValue()
  }, [budgetCriteriaValueId])

  const handleEdit = () => {
    history.push(`/configuration/budget-criteria-values/${budgetCriteriaValueId}/edit`)
  }

  // const handleDelete = async () => {
  //   const pageState = {}

  //   setDeleteLoading(true)
  //   await MinimumWagesService.delete({ pageState, budgetCriteriaHeadId })
  //   setDeleteLoading(false)

  //   const { serverStatus } = pageState

  //   if (serverStatus.status !== 0) {
  //     message.error("Failed to delete this minimum wage")
  //     return
  //   }

  //   message.success("Minimum Wage deleted.")

  //   history.push("/configuration/minimum-wages")
  // }

  const { budget_criteria_value, API_USERS_BUDGET_CRITERIA_VALUES_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!budget_criteria_value) return null

    const { name, budget_criteria_head } = budget_criteria_value

    return (
      <div>
        <h1>{budget_criteria_value.name}</h1>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Name</div>
              <div>{name}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Budget Criteria</div>
              <div>{budget_criteria_head}</div>
            </div>
          </div>
        </StyledItemList>
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit
          </Button>
          {/* <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button> */}
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const strategy =
    budget_criteria_value && budget_criteria_value.id === budgetCriteriaValueId
      ? staleWhileRevalidate
      : networkOnly

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('criteria-edit'))
		return <Redirect to='/' />

  return (
    <div className="page-content budget-criteria-values-page">
      <StyledBox>
        {strategy({
          status: API_USERS_BUDGET_CRITERIA_VALUES_SHOW_STATUS,
          data: budget_criteria_value,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(BudgetCriteriaValuesShowPage)
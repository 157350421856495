import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import {
	DashboardOutlined,
	UserOutlined,
	SettingOutlined,
	BankOutlined,
	// UngroupOutlined,
	// LogoutOutlined,
	// LoginOutlined,
	ApartmentOutlined,
	TeamOutlined,
	// BarChartOutlined,
	AuditOutlined,
} from '@ant-design/icons'

import globalStore from 'store'
import { fetchFromLocalStorage } from 'store/localStorage'

const menu = () => {
	const userType = fetchFromLocalStorage({ key: 'userType' })

	if (userType === 'admin-users') {
		return [
			{
				key: 'organizations',
				icon: <ApartmentOutlined />,
				label: 'Organizations',
				path: '/admin-users/organizations',
			},
			{
				key: 'organization-parameters',
				icon: <ApartmentOutlined />,
				label: 'Organization Parameters',
				path: '/admin-users/organization/parameters',
			},
		]
	}

	return [
		{
			key: 'home',
			icon: <DashboardOutlined />,
			label: 'HireReview',
			children: [
				{
					key: 'analytics',
					// icon: <BarChartOutlined />,
					label: 'Analytics',
					featureKey: 'fitment-data-edit',
					path: '/',
				},
				{
					key: 'fitments',
					// icon: <FileOutlined />,
					label: 'Fitments',
					featureKey: 'offer-view',
					path: '/fitments',
				},
				{
					key: 'additional-fitment-document',
					// icon: <FileOutlined />,
					label: 'Additional Document',
					featureKey: 'additional-document-send',
					path: '/additional-fitment-document',
				},
			],
		},

		{
			key: 'smart-budget',
			icon: (
				<span className='anticon'>
					<FontAwesomeIcon icon={faBalanceScale} className='fa-sm' />
				</span>
			),
			// icon: <UngroupOutlined />,
			label: 'SmartBudget',
			featureKey: 'smart-budget',
			children: [
				{
					key: 'budget-head',
					// icon: <FileOutlined />,
					label: 'Budget Creation',
					featureKey: 'smart-budget',
					path: '/configuration/budget-heads',
				},
				{
					key: 'budget-approval',
					// icon: <FileOutlined />,
					label: 'Budget Approval',
					featureKey: 'smart-budget',
					path: '/configuration/budget-heads/budget-approval-workflow',
				},
				{
					key: 'admin-budget-approval-status',
					// icon: <FileOutlined />,
					label: 'Admin Budget Approval Status',
					featureKey: 'smart-budget',
					path: '/configuration/admin-budget-approval-status',
				},
				// {
				// 	key: 'budget-planning',
				// 	icon: <FileOutlined />,
				// 	label: 'Expense Booking',
				// 	featureKey: 'smart-budget',
				// 	path: '/configuration/budget-heads/budget-planning',
				// },

				{
					key: 'budget-analysis',
					// icon: <FileOutlined />,
					label: 'Budget Analysis',
					featureKey: 'smart-budget',
					children: [
						{
							key: 'plan-actual',
							// icon: <FileOutlined />,
							label: 'Plan vs Actual',
							featureKey: 'smart-budget',
							path: '/configuration/budget-heads/budget-analysis',
						},
						{
							key: 'timeline-basis',
							// icon: <FileOutlined />,
							label: 'Timeline Basis',
							featureKey: 'smart-budget',
							path: '/configuration/budget-heads/budget-utilization-data',
						},
					],
				},
			],
		},
		{
			key: 'workforce-plan',
			icon: <TeamOutlined />,
			label: 'Workforce Plan',
			featureKey: 'workforce',
			children: [
				{
					key: 'workforce-dashboard',
					// icon: <FileOutlined />,
					label: 'WorkForce DashBoard',
					featureKey: 'workforce',
					path: '/configuration/workforce-dashboard',
				},
				{
					key: 'workforce-period',
					// icon: <FileOutlined />,
					label: 'WorkForce Period',
					featureKey: 'workforce-period-view',
					path: '/configuration/workforce-period',
				},
				{
					key: 'workforce-plan-add-position',
					// icon: <FileOutlined />,
					label: 'Add Position',
					featureKey: 'wfm-position-edit',
					path: '/configuration/budget-heads/add-position',
				},
				{
					key: 'workforce-plan-add-employee',
					// icon: <FileOutlined />,
					label: 'Update Employee',
					featureKey: 'wfm-employee-view',
					path: '/configuration/budget-heads/add-employee',
				},
				{
					key: 'admin-workforce-approval-status',
					// icon: <FileOutlined />,
					label: 'Admin Workforce Approval Status',
					featureKey: 'workforce',
					path: '/configuration/admin-workforce-approval-status',
				},
				{
					key: 'workforce-plan-resource-planning',
					// icon: <FileOutlined />,
					label: 'Resource Planning',
					featureKey: 'workforce',
					path: '/configuration/budget-heads/resource-plan',
				},
				{
					key: 'wfm-analysis',
					// icon: <FileOutlined />
					label: 'Analytics  & Insights',
					featureKey: 'workforce',
					children: [
						{
							key: 'org-chart',
							// icon: <FileOutlined />,
							label: 'Organization Chart',
							featureKey: 'organization-chart-view',
							path: '/position-reportings/organization-chart',
						},
						{
							key: 'position-report',
							// icon: <FileOutlined />
							label: 'Position Occupancy Report',
							featureKey: 'workforce-report',
							path: '/configuration/budget-heads/position-occupancy-report',
						},
						{
							key: 'workforce-plan-staff-reporting',
							// icon: <FileOutlined />
							label: 'Recruitment Staffing Report',
							featureKey: 'workforce-report',
							path: '/configuration/budget-heads/recruitment-staffing-report',
						},
						{
							key: 'incremental-timeline',
							// icon: <FileOutlined />
							label: 'Incremental Change Timeline',
							featureKey: 'workforce-report',
							path: '/configuration/budget-heads/incremental-change-timeline',
						},
						{
							key: 'transition-analysis',
							// icon: <FileOutlined />
							label: 'Position Transition Analysis',
							featureKey: 'workforce-report',
							path: '/configuration/budget-heads/position-transition-analysis',
						},
						{
							key: 'positions-recruitment-report',
							// icon: <FileOutlined />
							label: 'Recruitment Vacancy Report',
							featureKey: 'workforce-report',
							path: '/configuration/budget-heads/recruitment-vacancy-report',
						},
						{
							key: 'wf-monitoring',
							// icon: <FileOutlined />
							label: 'Budget Monitoring & Utilization Report',
							featureKey: 'workforce-report',
							path: '/configuration/budget-heads/budget-monitoring-utilization-report',
						},
						{
							key: 'yoy-summary',
							// icon: <FileOutlined />
							label: 'YOY Summary',
							featureKey: 'workforce-report',
							path: '/configuration/budget-heads/yoy-summary',
						},
					],
				},
			],
		},
		{
			key: 'content-management',
			icon: <ApartmentOutlined />,
			label: 'Content Management',
			children: [
				{
					key: 'ss-configuration',
					// icon: <FileOutlined />,
					label: 'Salary Structures',
					featureKey: 'fitment-data-view',
					path: '/configuration/ss-configurations',
				},
				{
					key: 'offer-letter-template',
					// icon: <FileOutlined />,
					label: 'Offer Letter Templates',
					featureKey: 'letter-template-view',
					path: '/configuration/offer-letter-templates',
				},
				{
					key: 'email-template',
					// icon: <FileOutlined />,
					label: 'Email Templates',
					featureKey: 'fitment-data-view',
					path: '/configuration/email-templates',
				},
				{
					key: 'additional-letter-template',
					// icon: <FileOutlined />,
					label: 'Addition Templates',
					featureKey: 'additional-template-edit',
					path: '/configuration/additional-letter-templates',
				},
			],
		},
		{
			key: 'reports-center',
			icon: <AuditOutlined />,
			label: 'Reports Center',
			featureKey: 'report-view',
			children: [
				{
					key: 'export',
					// icon: <LogoutOutlined />,
					label: 'Exports',
					featureKey: 'report-view',
					path: '/exports',
				},
				{
					key: 'import',
					// icon: <LoginOutlined />,
					label: 'Imports',
					featureKey: 'report-view',
					path: '/imports',
				},
			],
		},
		{
			key: 'user-group',
			icon: <UserOutlined />,
			label: 'Permissions',
			children: [
				{
					key: 'roles',
					// icon: <ApartmentOutlined />,
					label: 'Roles & Rights',
					featureKey: 'role-edit',
					path: '/roles',
				},
				{
					key: 'users',
					// icon: <UserOutlined />,
					label: 'User Mapping',
					featureKey: 'user-view',
					path: '/users',
				},
				{
					key: 'access-group',
					// icon: <FileOutlined />,
					label: 'Access Group',
					featureKey: 'fitment-data-view',
					path: '/access-groups',
				},
			],
		},
		{
			key: 'configuration',
			icon: <SettingOutlined />,
			label: 'Configuration',
			children: [
				{
					key: 'control-tables',
					// icon: <ApartmentOutlined />,
					label: 'Control Tables',
					featureKey: 'fitment-data-view',
					children: [
						{
							key: 'minimum-wages-configuration',
							// icon: <FileOutlined />,
							label: 'Minimum Wages',
							featureKey: 'fitment-data-view',
							path: '/configuration/minimum-wages',
						},
						{
							key: 'minimum-salaries-configuration',
							// icon: <FileOutlined />,
							label: 'Minimum Salaries',
							featureKey: 'fitment-data-view',
							path: '/configuration/organization-minimum-salaries',
						},
						{
							key: 'salary-range-configuration',
							// icon: <FileOutlined />,
							label: 'Salary Ranges',
							featureKey: 'fitment-data-view',
							path: '/configuration/salary-ranges',
						},
						{
							key: 'budget-configuration',
							// icon: <FileOutlined />,
							label: 'Candidate Budgets',
							featureKey: 'fitment-data-view',
							path: '/configuration/budgets',
						},
						{
							key: 'incentive-configuration',
							// icon: <FileOutlined />,
							label: 'Incentives',
							featureKey: 'fitment-data-view',
							path: '/configuration/incentives',
						},
					],
				},

				{
					key: 'approval-chain-configuration',
					// icon: <FileOutlined />,
					label: 'Approvals Workflow',
					featureKey: 'approval-chain-view',
					path: '/configuration/approval-chains',
				},
				{
					key: 'budget-criteria-head',
					// icon: <FileOutlined />,
					label: 'Organization Hierarchy',
					featureKey: 'criteria-edit',
					path: '/configuration/budget-criteria-heads',
				},
				{
					key: 'vendors',
					// icon: <FileOutlined />,
					label: 'Vendors',
					featureKey: 'fitment-data-view',
					path: '/configuration/vendors',
				},
				{
					key: 'country-details',
					// icon: <FileOutlined />,
					label: 'Country Details',
					featureKey: 'organization-edit',
					path: '/configuration/country-details',
				},
				{
					key: 'other-setting',
					// icon: <FileOutlined />,
					label: 'Settings',
					featureKey: 'fitment-data-view',
					path: '/setting',
					children: [
						{
							key: 'fitment-fields-configurations',
							// icon: <FileOutlined />,
							label: 'Fitment Fields',
							featureKey: 'fitment-data-view',
							path: '/configuration/fitment-fields',
						},
						{
							key: 'documents',
							// icon: <FileOutlined />,
							label: 'Documents',
							path: '/configuration/documents',
						},
						{
							key: 'integration',
							// icon: <SettingOutlined />,
							label: 'Integration',
							featureKey: 'organization-edit',
							path: '/configuration/integration',
						},
						{
							key: 'control_feature',
							// icon: <SettingOutlined />,
							label: 'Control Features',
							featureKey: 'control_feature',
							path: '/configuration/control-features',
						},
						{
							key: 'organization-configuration',
							// icon: <SettingOutlined />,
							label: 'Organization Configuration',
							featureKey: 'organization-edit',
							path: '/users/organization-configuration',
						},
						{
							key: 'organization-edit',
							// icon: <SettingOutlined />,
							label: 'Organization',
							featureKey: 'organization-edit',
							path: '/users/organizations/edit',
						},
					],
				},
			],
		},
		{
			key: 'organization',
			icon: <BankOutlined />,
			label: 'Organization',
			featureKey: 'fitment-data-edit',
			children: [
				{
					key: 'setup',
					// icon: <BarChartOutlined />,
					label: 'Setup',
					featureKey: 'organization-setup',
					path: '/edit-organization',
				},
				{
					key: 'control-parameters',
					// icon: <BarChartOutlined />,
					label: 'Control Parameters',
					featureKey: 'control-parameters',
					path: '/organization/parameters',
				},
			],
		},
		{
			key: 'pending-hirereview-approvals',
			icon: <BankOutlined />,
			label: 'Pending HireReview Approvals',
			path: '/fitment-approvals/pending-hirereview-approvals',
		},
		{
			key: 'pending-salary-approvals',
			icon: <BankOutlined />,
			label: 'Pending Salary Approvals',
			path: '/pending-salary-approvals',
		},
		{
			key: 'pending-budget-approvals',
			icon: <BankOutlined />,
			featureKey: 'smart-budget',
			label: 'Pending Budget Approvals',
			path: '/budget-approvals/pending-budget-approvals',
		},
	]
}

const SideMenu = () => {
	// const history = useHistory()
	const { currentUser, currentOrganization } = globalStore

	// const handleMenuItemClick = (path) => {
	// 	if (!path) return

	// 	history.push(path)
	// }

	const allowedMenu = () => {
		const userType = fetchFromLocalStorage({ key: 'userType' })

		if (userType === 'admin-users') {
			return menu()
				.map((m) => {
					// if (
					//   !currentOrganization.accessible_features.includes("budget") &&
					//   m.key === "budget-analysis"
					// ) {
					//   return null
					// }
					// if (accessible_features.length > 0 && m.key === "pending-approvals")
					//  return null

					if (!m.children) {
						return m
					}
					return { ...m, children: m.children }
				})
				.filter((m) => !!m)
		}

		if (!currentUser) return []
		if (!currentUser.accessible_features) return []
		if (!currentOrganization.accessible_features) return []

		const isMenuFeatureAccessible = (featureKey) => {
			if (!featureKey) return true
			const { accessible_features: orgAccessibleFeatures } = currentOrganization
			const { accessible_features } = currentUser

			if (!accessible_features.includes(featureKey)) return false
			if (featureKey === 'smart-budget') return orgAccessibleFeatures.includes('budget')
			if (featureKey === 'workforce') return orgAccessibleFeatures.includes('workforce')

			return true
		}

		const newMenu = menu()
			.map((m) => {
				if (!currentOrganization.accessible_features.includes('budget')) {
					if (m.key === 'configuration') {
						if (m.children) {
							let filteredChildren = m.children.filter(
								(child) => child.key !== 'budget-criteria-head' && child.key !== 'vendors'
							)
							m.children = filteredChildren
							return m
						}
					}
				}

				// if (accessible_features.length > 0 && m.key === "pending-approvals")
				// return null
				if (
					(m.key === 'pending-budget-approvals' && currentUser?.roles.includes('super-admin')) ||
					(m.key === 'pending-hirereview-approvals' &&
						!currentOrganization.enable_fitment_approval) ||
					(m.key === 'pending-salary-approvals' && !currentOrganization.enable_salary_approval)
				) {
					return null
				}

				if (!m.children) {
					if (!isMenuFeatureAccessible(m.featureKey)) return null
					return m
				}
				const filteredChildren = m.children
					.filter((child) => {
						return isMenuFeatureAccessible(child.featureKey)
					})
					.map((child) => {
						return {
							...child,
							children: child?.children?.filter((child) => {
								return isMenuFeatureAccessible(child.featureKey)
							}),
						}
					})
				if (filteredChildren.length === 0) return null
				return { ...m, children: filteredChildren }
			})
			.filter((m) => {
				if (m?.featureKey) return isMenuFeatureAccessible(m?.featureKey)
				return !!m
			})
		return newMenu
	}

	const renderMenuItem = (m, thirdLevel = false) => {
		const title = (
			<span>
				{m.icon}
				<span>{m.label}</span>
			</span>
		)

		if (!thirdLevel && m.children) {
			return (
				<Menu.SubMenu key={m.key} title={title}>
					{m.children.map((cm) => renderMenuItem(cm, true))}
				</Menu.SubMenu>
			)
		}
		// onClick={() => handleMenuItemClick(m.path)}
		return (
			<Menu.Item key={m.key}>
				<Link to={m.path} style={{ textDecoration: 'none' }}>
					{title}
				</Link>
			</Menu.Item>
		)
	}

	const menuItems = allowedMenu().map((m) => {
		const title = (
			<span>
				{m.icon}
				<span>{m.label}</span>
			</span>
		)
		if (m.children) {
			return (
				<Menu.SubMenu key={m.key} title={title}>
					{m.children.map((cm) => renderMenuItem(cm))}
				</Menu.SubMenu>
			)
		}

		return renderMenuItem(m, title)
	})

	return (
		<Menu theme='dark' mode='inline'>
			{menuItems}
		</Menu>
	)
}

export default view(SideMenu)

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import Odometer from "react-odometerjs"
import moment from "moment"
import { InfoCircleOutlined } from "@ant-design/icons"
import { DatePicker, Tooltip } from "antd"

import "odometer/themes/odometer-theme-default.css"

import StyledBox from "components/common/styled-components/StyledBox"
// import ContentLoading from "components/common/ContentLoading"
import { Color } from "constants/theme"

import globalStore from "store"

const StyledSuperAdminDashboard = styled(StyledBox)`
  padding: 10px 30px;
  & > .period-selector {
    display: flex;
    gap: 20px;
    align-items: center;
    & > .period__label {
      font-weight: bold;
    }
    & > .period__range-picker {
      display: flex;
      gap: 20px;
    }
  }
  & > .card__section {
    margin: 0px;
    & > h1 {
      font-size: 1.5em;
      margin: 0px;
    }
    & > .card__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > h1 {
        font-size: 1.5em;
        margin: 0px;
      }
    }
    & > .card__list {
      display: flex;
      justify-content: space-evenly;
      padding: 10px 0px;
      gap: 20px;
      flex-wrap: wrap;
      & > .card {
        max-width: 300px;
        min-width: 200px;
        flex: 1;
        background: ${Color.secondaryColor};
        text-align: center;
        border-radius: 5px;
        overflow: hidden;
        & > .card__label {
          position: relative;
          padding: 5px 0px;
          color: white;
          background: ${Color.brandColor};
          & > .anticon {
            position: absolute;
            right: 5px;
            top: 8px;
          }
        }
        & > .card__count {
          font-size: 3em;
          padding: 10px;
          font-weight: bold;
        }
      }
    }
  }
`

const cardData = [
  {
    heading: "Volume of work",
    cards: [
      {
        key: "shortlisted_candidates",
        title: "Shortlisted Candidates",
        description: "Number of Assessment received",
      },
      {
        key: "issued_offer_letters",
        title: "Issued Offer Letters",
        description:
          "Number of Letter issued excluding regenerated offer letter",
      },
      {
        key: "approval_in_transit",
        title: "Approval In Transit",
        description: "Number of Pending approval at any stage",
        link: "/fitments",
      },
    ],
  },
  // {
  //   heading: "Efficiency of work",
  //   cards: [
  //     {
  //       title: "Time To Fill",
  //       description: "Time from position published date to offer",
  //     },
  //     {
  //       title: "Time To Hire",
  //       description: "Time from candidate applied date to offer",
  //     },
  //     {
  //       title: "Time Per Offer",
  //       description: "Time from shortlisting to offer",
  //     },
  //   ],
  // },
  // {
  //   heading: "Quality of work",
  //   cards: [
  //     {
  //       title: "Candidate NPS",
  //       description:
  //         "% of 'promoters' (ranked 9-10) - % of 'detractors' ( ranked 1-6)",
  //     },
  //     {
  //       title: "Offer Acceptance Rate",
  //       description: "Number of offers accepted per Number of offers",
  //     },
  //     {
  //       title: "Internal Source Mix",
  //       description: "% of candidate sourced internally vs. others ",
  //     },
  //   ],
  // },
]

const SuperAdminDashboard = ({ data, fecthDashboardData}) => {
  const history = useHistory()
  const [counts, setCounts] = useState(null)
  const currentYear = new Date().getFullYear();
  const [dateRange, setDateRange] = useState([
    moment.utc(currentYear + '-04-01'),
    moment.utc(currentYear + 1 + '-03-31'),
  ])

  const dateFormat = "DD-MMM-YY";

  useEffect(() => {
    setCounts(data)
  }, [data])

  useEffect(() => {
    fecthDashboardData(dateRange[0], dateRange[1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCardClick = (link) => {
    if (!link) return

    history.push(link)
  }

  const userRoles = globalStore.currentUser?.roles || []

  if (!userRoles?.includes("super-admin")) return null

  const handleDateRangeChange = (range) => {
    if (range && range.length === 2) {
      const [start, end] = range;

      setDateRange([start, end]);
      fecthDashboardData(start, end);
    }
  };

  return (
    <StyledSuperAdminDashboard>
      {cardData.map((cd, i) => {
        return (
          <div className="card__section" key={`card__section-${i}`}>
            {i === 0 ? (
              <div className="card__header">
                <h1>{cd.heading}</h1>
                  <DatePicker.RangePicker
                    format={dateFormat}
                    defaultValue={dateRange}
                    onChange={handleDateRangeChange}
                  />
              </div>
            ) : (
              <h1>{cd.heading}</h1>
            )}
            <div className="card__list">
              {cd.cards.map((c, i) => {
                // if (!data[c.key]) return null

                return (
                  <div
                    key={`card-${i}`}
                    className="card"
                    style={{ cursor: c.link ? "pointer" : "default" }}
                    onClick={() => handleCardClick(c.link)}
                  >
                    <div className="card__label">
                      <span>{c.title}</span>
                      <Tooltip title={c.description}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                    <div className="card__count">
                      <Odometer
                        format="d"
                        duration={500}
                        value={counts ? counts[c.key] : 0}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </StyledSuperAdminDashboard>
  )
}

export default SuperAdminDashboard

import { API, generic } from 'api'

export default class WorkForcePeriodService {
	static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WORKFORCE_PERIOD_INDEX_STATUS',
			stateDataKey: ['workforce_periods', 'total', 'page', 'page_size', 'criteria_keys'],
			stateErrorKey: [
				'workforcePeriodsErrors',
				'totalErrors',
				'pageErrors',
				'pageSizeErrors',
				'criteriaKeysErrors',
			],
			apiEndpoint: API.users.workForcePeriod.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, bcsla } },
			errorMessage: 'WorkForce Period could not be fetched.',
		})
	}
	static currentWorkforcePeriod = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_CURRENT_WORKFORCE_PERIOD_STATUS',
			stateDataKey: ['workforce_period'],
			stateErrorKey: [
				'workforcePeriodErrors'
			],
			apiEndpoint: API.users.workForcePeriod.currentWorkforcePeriod,
			apiUrlReplacements: {},
			apiData:{},
			errorMessage: 'Current WorkForce Period could not be fetched.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WORKFORCE_PERIOD_CREATE_STATUS',
			stateDataKey: ['workforce_periods'],
			stateErrorKey: ['workforcePeriodsErrors'],
			apiEndpoint: API.users.workForcePeriod.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'WorkForce Period could not be created.',
		})
	}

	static show = async ({ pageState, workforcePeriodID }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WORKFORCE_PERIOD_SHOW_STATUS',
			stateDataKey: ['workforce_period'],
			stateErrorKey: ['workforcePeriodsErrors'],
			apiEndpoint: API.users.workForcePeriod.show,
			apiUrlReplacements: { workforcePeriodID },
			apiData: {},
			errorMessage: 'WorkForce Period could not be fetched.',
		})
	}

	static update = async ({ pageState, values, workforcePeriodID }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WORKFORCE_PERIOD_UPDATE_STATUS',
			stateDataKey: ['workforce_periods'],
			stateErrorKey: ['workforcePeriodsErrors'],
			apiEndpoint: API.users.workForcePeriod.update,
			apiUrlReplacements: { workforcePeriodID },
			apiData: { ...values },
			errorMessage: 'WorkForce Period could not be updated.',
		})
	}


}

import React, { useState, useEffect } from "react"
import { useParams, useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Button, Input, message } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import globalStore from "store/index"

import TemplateEditor from "components/TemplateEditor"

import OrganizationsService from "services/users/organizations.service"
import EmailTemplatesService from "services/users/email-templates.service"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const EmailTemplatesEditPage = () => {
  const history = useHistory()
  const { emailTemplateId } = useParams()
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const [inputValues, setInputValues] = useState({})
  const [templateContent, setTemplateContent] = useState(null)
  const [content, setContent] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.emailTemplates.edit

      await EmailTemplatesService.show({
        pageState,
        emailTemplateId,
      })

      const { serverStatus, email_template, emailTemplateErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (emailTemplateErrors) {
        message.error(
          emailTemplateErrors[0] || "Configuration couldn't be fetched."
        )
        return
      }

      const { template_type, content } = email_template

      setInputValues({
        template_type,
      })
      setTemplateContent(content)
      setContent(content)
    }

    fetchData()
  }, [emailTemplateId])

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  const handleInputChange = (e, inputType) => {
    let newInputValues = null

    newInputValues = { ...inputValues, [inputType]: e.target.value }

    setInputValues(newInputValues)
  }

  const handleEditorContentChange = (content) => {
    setContent(content)
  }

  const handleUpdate = async () => {
    if (!inputValues["template_type"]) {
      message.error("Name can't be blank")
      return
    }

    const pageState = {}
    const postData = {
      email_template: {
        content,
        ...inputValues,
      },
    }

    setCreateButtonLoading(true)
    await EmailTemplatesService.update({
      pageState,
      values: postData,
      emailTemplateId,
    })
    setCreateButtonLoading(false)

    const { serverStatus, emailTemplateErrors } = pageState

    if (emailTemplateErrors) {
      message.error(
        emailTemplateErrors[0] || "Configuration couldn't be updated."
      )
      return
    }

    if (serverStatus.status === 200) {
      message.success("Configuration updated.")
      history.push(`/configuration/email-templates/${emailTemplateId}`)
      setInputValues({})

      return
    }

    message.error("Oops!! something went wrong")
  }

  const renderPageContent = () => {
    return (
      <StyledConfigurationFormBox>
        <h1>Email Template</h1>
        <div className="input-box">
          <strong>Template Type</strong>
          <Input
            disabled
            value={inputValues["template_type"]}
            onChange={(e) => handleInputChange(e, "template_type")}
          />
        </div>
        <div className="input-box">
          <strong>Template</strong>
          <TemplateEditor
            content={templateContent}
            onChange={handleEditorContentChange}
          />
        </div>
        <div className="action-buttons submit">
          <Button
            type="primary"
            loading={createButtonLoading}
            onClick={handleUpdate}
          >
            Save
          </Button>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-edit")
  )
    return <Redirect to={`/configuration/email-templates/${emailTemplateId}`} />

  return (
    <div className="page-content dashboard-page">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(EmailTemplatesEditPage)

import { API, generic } from "api"

export default class SalaryApprovalsService {
	static approve = async ({ pageState, salaryApprovalId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SALARY_APPROVALS_APPROVE_STATUS',
			stateDataKey: ['salary_approval', 'salary_configuration'],
			stateErrorKey: ['salaryApprovalErrors', 'salaryConfigurationErrors'],
			apiEndpoint: API.users.salaryApprovals.approve,
			apiUrlReplacements: { salaryApprovalId },
			apiData: {},
			errorMessage: 'Salary approval could not be approved.',
		})
	}

	static reject = async ({ pageState, salaryApprovalId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SALARY_APPROVALS_REJECT_STATUS',
			stateDataKey: ['salary_approval', 'salary_configuration'],
			stateErrorKey: ['salaryApprovalErrors', 'salaryConfigurationErrors'],
			apiEndpoint: API.users.salaryApprovals.reject,
			apiUrlReplacements: { salaryApprovalId },
			apiData: { ...values },
			errorMessage: 'Salary approval could not be rejected.',
		})
	}

	static show = async ({ pageState, salaryApprovalId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SALARY_APPROVALS_SHOW_STATUS',
			stateDataKey: ['salary_approval'],
			stateErrorKey: ['salaryApprovalsError'],
			apiEndpoint: API.users.salaryApprovals.show,
			apiUrlReplacements: { salaryApprovalId },
			apiData: {},
			errorMessage: "Salary approvals couldn't be fetched.",
		})
	}

	static comment = async ({ pageState, salaryApprovalId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_SALARY_APPROVAL_COMMENT_STATUS',
			stateDataKey: ['salary_approval'],
			stateErrorKey: ['salaryApprovalErrors'],
			apiEndpoint: API.users.salaryApprovals.comment,
			apiUrlReplacements: { salaryApprovalId },
			apiData: { ...values },
			errorMessage: 'Comment could not be submitted.',
		})
	}
	static pendingApprovals = async ({ pageState, page, pageSize }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SALARY_APPROVALS_PENDING_APPROVALS_STATUS',
			stateDataKey: ['salary_approvals', 'salary_configuration'],
			stateErrorKey: ['salaryApprovalsErrors', 'salaryConfigurationErrors'],
			apiEndpoint: API.users.salaryApprovals.pendingApprovals,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize } },
			errorMessage: "Pending approvals couldn't be fetched.",
		})
	}
}
import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, Redirect } from "react-router-dom"
import styled from "styled-components"
import { EyeOutlined } from "@ant-design/icons"
import { message, Button, Empty, Pagination, Table, Tooltip } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import OrganizationsService from "services/admin-users/organizations.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const OrganizationsIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.adminUsers.organizations.index

  useEffect(() => {
    const fetchOrganizations = async () => {
      const pageState = globalStore.ui.adminUsers.organizations.index

      await OrganizationsService.index({ pageState })

      const { serverStatus, organizationsErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (organizationsErrors) {
        message.error(
          organizationsErrors[0] || "Organizations couldn't be fetched."
        )
        return
      }
    }

    fetchOrganizations()
  }, [])

  const repaginate = async (page, pageSize, bcsla = null) => {
    if (bcsla) {
      await OrganizationsService.index({ pageState, page, pageSize, bcsla })
      return
    }
    await OrganizationsService.index({ pageState, page, pageSize })
  }

  const handleCreate = () => {
    history.push("/admin-users/organizations/new")
  }

  const handleCardItemClick = (organizationsId) => {
    history.push(`/admin-users/organizations/${organizationsId}`)
  }

  const {
    organizations,
    organizationsMeta,
    API_ADMIN_USERS_ORGANIZATIONS_INDEX_STATUS,
    displayType = "table",
  } = pageState

  const { total, page: currentPage, page_size: pageSize } =
    organizationsMeta || {}

  const renderList = () => {
    return (
      <StyledList>
        {organizations.map((ic) => (
          <div
            className="item-card"
            key={`item-card-${ic.id}`}
            onClick={() => handleCardItemClick(ic.id)}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{ic.name}</div>
                <div>{}</div>
              </div>
            </div>
            <div className="icon-box">
              <Button type="link">
                <EyeOutlined />
              </Button>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderContents = () => {
    if (organizations.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            Create Organization
          </Button>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
        style={{ margin: "10px 0px" }}
      />
    )

    const tableColumns = [
      // {
      //   title: "Logo",
      //   dataIndex: "logo_url",
      //   key: "logo_url",
      //   render: (url, row) => (
      //     <div style={{ height: 50, overflow: "hidden" }}>
      //       <img
      //         src={url}
      //         alt={`${row.name}'s logo`}
      //         style={{ height: "100%" }}
      //       />
      //     </div>
      //   ),
      // },
      {
        title: "Organization Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        render: (country) => country?.name,
      },
      {
        title: "Industry",
        dataIndex: "industry",
        key: "industry",
        render: (industry) => industry?.name,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 200,
        render: (value, ic) => {
          return (
            <div>
              <Button type="link" onClick={() => handleCardItemClick(ic.id)}>
                <EyeOutlined />
              </Button>
              <Button
                type="link"
                onClick={() =>
                  history.push(`/admin-users/organizations/${ic.id}/users`)
                }
              >
                View Users
              </Button>
            </div>
          )
        },
      },
    ]

    const dataSource = organizations.map((ic) => {
      const retval = { ...ic, key: ic.id }
      return retval
    })

    const renderTable = () => {
      return (
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        ></Table>
      )
    }

    const renderInfo = () => {
      if (displayType === "table") return renderTable()
      return renderList()
    }

    return (
      <div>
        {pagination}
        {networkOnly({
          status: API_ADMIN_USERS_ORGANIZATIONS_INDEX_STATUS,
          data: organizations,
          render: renderInfo,
        })}

        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div></div>
          <div className="action-buttons top">
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                Create Organization
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content organizations-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_ADMIN_USERS_ORGANIZATIONS_INDEX_STATUS,
          data: organizations,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(OrganizationsIndexPage)

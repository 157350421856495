import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { view } from '@risingstack/react-easy-state'
import { Button, Empty, message, Pagination, Table, Tooltip } from 'antd'
import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import CountryDetailServices from 'services/users/country-details.service'
import globalStore from 'store/index'
import styled from 'styled-components'
import { networkOnly, staleWhileRevalidate } from 'utils/render-strategies'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const CountryDetailsIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.configuration.countryDetails.index
	
	useEffect(() => {
		const fetchApprovalChains = async () => {
			const pageState = globalStore.ui.configuration.countryDetails.index
			await CountryDetailServices.index({ pageState })
			const { serverStatus, countriesDetailErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (countriesDetailErrors) {
				message.error(countriesDetailErrors[0] || "Couldn't fetch Country Details")
				return
			}
		}

		fetchApprovalChains()
	}, [])

	const repaginate = async (page, pageSize) => {
		await CountryDetailServices.index({ pageState, page, pageSize })
	}

	const handleCreate = () => {
		history.push('/configuration/country-details/new')
	}

	const handleCardItemClick = (id) => {
		history.push(`/configuration/country-details/${id}`)
	}

	const handleEdit = (id) => {
		history.push(`/configuration/country-details/${id}/edit`)
	}

	// const handleDelete = async (approvalChainId) => {
	// 	const tempState = {}
	// 	await CountryDetailServices.delete({
	// 	pageState: tempState,
	// 	approvalChainId,
	// 	})
	// 	const { serverStatus } = tempState
	// 	if (serverStatus.status !== 200) return
	// 	globalStore.ui.configuration.approvalChains.index = {
	// 	...globalStore.ui.configuration.approvalChains.index,
	// 	approval_chains: country_details.filter(
	// 		(approval_chain) => approval_chain.id !== approvalChainId
	// 	),
	// 	}
	// }

	const handleRowClick = handleCardItemClick


	const { country_details, country_detailsMeta, API_USERS_COUNTRIES_DETAILS_INDEX_STATUS } = pageState

	const { total, page: currentPage, page_size: pageSize } = country_detailsMeta || {}


	const renderTable = () => {
		const tableColumns = [
			...[
				{
					title: 'Country Name',
					dataIndex: 'name',
					key: 'name',
				},
			],
			...[
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					align: 'center',
					render: (value, sr) => {
						return (
							<div>
								<Tooltip title='View'>
									<Button type='link' onClick={() => handleRowClick(sr.id)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
								<Tooltip title='Edit'>
									<Button type='link' onClick={() => handleEdit(sr.id)}>
										<EditOutlined />
									</Button>
								</Tooltip>
								{/* <Tooltip title='Delete'>
									<Popconfirm
										title='Are you sure to delete?'
										onConfirm={() =>
											handleDelete(
												sr.id
											)
										}
										onCancel={() => {}}
										okText='Yes'
										cancelText='No'>
										<Button type='link'>
											<DeleteOutlined />
										</Button>
									</Popconfirm>
								</Tooltip> */}
							</div>
						)
					},
				},
			],
		]

		const dataSource = country_details.map((data, i) => {
			const list = { ...data, key: i }
			return list
		})

		return (
			<Table
				columns={tableColumns}
				dataSource={dataSource}
				pagination={false}
				scroll={{
					x: 'max-content',
				}}
			/>
		)
	}


	const renderContents = () => {
		if (country_details.length === 0) {
			return (
				<Empty>
					<Button type='primary' ghost onClick={handleCreate}>
						Create Country
					</Button>
				</Empty>
			)
		}

		const pagination = (
			<Pagination
				current={currentPage}
				total={total}
				onChange={repaginate}
				onShowSizeChange={repaginate}
				pageSize={pageSize || 25}
				pageSizeOptions={[10, 25, 50, 100]}
			/>
		)

		return (
			<div>
				{pagination}
				<StyledList>
					{networkOnly({
						status: API_USERS_COUNTRIES_DETAILS_INDEX_STATUS,
						data: country_details,
						render: renderTable,
					})}
				</StyledList>
				{pagination}
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div></div>
					<div className='action-buttons top'>
						<Tooltip title='Create a new country'>
							<Button type='primary' ghost onClick={handleCreate}>
								Create Country
							</Button>
						</Tooltip>
					</div>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('organization-edit')) return <Redirect to={`/`} />

	return (
		<div className='page-content approval-chains-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_COUNTRIES_DETAILS_INDEX_STATUS,
					data: country_details,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(CountryDetailsIndexPage)

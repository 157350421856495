import { API, generic } from "api"

export default class OrganizationsService {
  static me = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATIONS_ME_STATUS",
      stateDataKey: ["organization"],
      stateErrorKey: ["organizationErrors"],
      apiEndpoint: API.users.organizations.me,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Organization data could not be fetched.",
    })
  }

  static uploadLogo = async ({ pageState, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATIONS_UPLOAD_LOGO_STATUS",
      stateDataKey: ["organization"],
      stateErrorKey: ["organizationErrors"],
      apiEndpoint: API.users.organizations.uploadLogo,
      apiUrlReplacements: {},
      apiData: formData,
      errorMessage: "Organization logo could not be uploaded.",
    })
  }

  static templateVariable = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATIONS_TEMPLATE_VARIABLE_STATUS",
      stateDataKey: ["unserialized"],
      stateErrorKey: ["unserializedErrors"],
      apiEndpoint: API.users.organizations.templateVariable,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Organization template variable could not be fetched.",
      serializedResponse: false,
    })
  }

  static update = async ({ pageState, id, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATIONS_UPDATE_STATUS",
      stateDataKey: ["organization"],
      stateErrorKey: ["organizationErrors"],
      apiEndpoint: API.users.organizations.update,
      apiUrlReplacements: {id},
      apiData: {...values},
      errorMessage: "Organization data could not be fetched.",
    })
  }
}

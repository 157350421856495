import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import OrganizationParameters from "components/OrganizationParameters"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
// import OrganizationsService from "services/users/organizations.service"

const StyledPageContent = styled.div`
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & input:nth-child(2) {
      margin-left: 15px;
    }
    & .action-buttons {
      margin: 0px;
      & > button {
        margin: 0px 0px 0px 15px;
      }
    }
  }
`

const mockParameters = [
  {
    validationParameter: "Minimum Wages",
    explanation: "Pay more than statutory minimum wage",
    comparisonPoint: "Basic + HRA + City Compensatory Allowance",
    returnValue: "Absolute",
    controlFeature: "Complete",
    expectedAction: "Stop",
    controlException: "0%",
    status: "Active",
  },
  {
    validationParameter: "Company Location Guidelines",
    explanation: "Pay more than organisation prescribed state salary",
    comparisonPoint: "Fixed CTC",
    returnValue: "Absolute",
    controlFeature: "Complete",
    expectedAction: "Stop",
    controlException: "0%",
    status: "Active",
  },
  {
    validationParameter: "Budget CTC",
    explanation: "Pay within prescribed budgeted CTC",
    comparisonPoint: "CTC",
    returnValue: "Average",
    controlFeature: "Partial",
    expectedAction: "Warn",
    controlException: "0%",
    status: "Active",
  },
  {
    validationParameter: "Salary Range",
    explanation: "Pay within minimum & maximum of salary range",
    comparisonPoint: "CTC",
    returnValue: "Absolute",
    controlFeature: "Partial",
    expectedAction: "Warn",
    controlException: "0%",
    status: "Active",
  },
  {
    validationParameter: "Compa Ratio",
    explanation: "Pay within prescribed range of compa ratio",
    comparisonPoint: "CTC",
    returnValue: "Absolute",
    controlFeature: "Partial",
    expectedAction: "Warn",
    controlException: "0%",
    status: "Not Active",
  },
  {
    validationParameter: "Market Ratio",
    explanation: "Pay within prescribed range of market ratio",
    comparisonPoint: "CTC",
    returnValue: "Absolute",
    controlFeature: "Partial",
    expectedAction: "Warn",
    controlException: "0%",
    status: "Not Active",
  },
  {
    validationParameter: "Range Penetration",
    explanation: "Pay within prescribed range of range penetration",
    comparisonPoint: "CTC",
    returnValue: "Absolute",
    controlFeature: "Partial",
    expectedAction: "Warn",
    controlException: "0%",
    status: "Not Active",
  },
  {
    validationParameter: "Percent Rank",
    explanation: "Pay within prescribed range of percent rank",
    comparisonPoint: "CTC",
    returnValue: "Absolute",
    controlFeature: "Partial",
    expectedAction: "Warn",
    controlException: "0%",
    status: "Not Active",
  },
  {
    validationParameter: "Budget Payroll Cost",
    explanation: "Pay within total payroll cost",
    comparisonPoint: "CTC",
    returnValue: "Total",
    controlFeature: "Complete",
    expectedAction: "Stop",
    controlException: "0%",
    status: "Not Active",
  },
  {
    validationParameter: "Budget Headcount",
    explanation: "Hire within budgeted headcount",
    comparisonPoint: "NA",
    returnValue: "Total",
    controlFeature: "Complete",
    expectedAction: "Stop",
    controlException: "0%",
    status: "Not Active",
  },
]

const ParametersEditPage = (props) => {
  const pageState = globalStore.currentOrganization
  const [parameters, setParameters] = useState([])
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false)

  useEffect(() => {
    // const pageState = globalStore.currentOrganization

    // if (pageState.id) return

    // const fetchData = async () => {
    //   await OrganizationsService.me({ pageState })
    // }

    // fetchData()

    setParameters(mockParameters)
  }, [])

  const updateParameters = (parameters) => {}

  const handleUpdate = async () => {
    // const pageState = {}
    // const postData = {
    //   organization: {
    //     parameters,
    //   },
    // }

    setUpdateButtonLoading(true)
    // await OrganizationsService.update({
    //   pageState,
    //   values: postData,
    // })
    // setUpdateButtonLoading(false)

    // const { serverStatus } = pageState

    // if (serverStatus.status !== 200) {
    //   message.error("Failed to update.")
    //   return
    // }

    // message.success("Updated")
  }

  const renderPageContent = () => {

    return (
      <StyledPageContent>
        {/* <h1>Update Organization Details</h1> */}
        <OrganizationParameters
          parameters={parameters}
          onChange={updateParameters}
        />
        <div>
          <div className="action-buttons">
            <Button
              type="primary"
              loading={updateButtonLoading}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
        </div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("control-parameters")
  )
    return <Redirect to="/" />

  const { API_USERS_FITMENT_CONFIGURATIONS_INDEX_STATUS } = pageState

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_FITMENT_CONFIGURATIONS_INDEX_STATUS,
          data: {},
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(ParametersEditPage)

import React, { useState, useEffect } from "react"
import { Redirect, useParams, useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons"
import {
  Button,
  Input,
  message,
  Row,
  Col,
  Select,
  Switch,
  Checkbox,
} from "antd"
import { isContainsBlankCriteria } from "utils/criteria"

import StyledBox from "components/common/styled-components/StyledBox"
import ConditionRow from "components/ConditionRow"
import globalStore from "store/index"

import FitmentFieldsConfigurationService from "services/users/fitment-fields-configurations.service"
import OrganizationsService from "services/users/organizations.service"
import DataTypes from "constants/data-types"
import DocumentsService from "services/users/documents.service"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const blankCondition = {
  field: null,
  operator: null,
  values: null,
}

const FitmntFieldsConfigurationsEditPage = () => {
  const [criteria, setCriteria] = useState([[blankCondition]])
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const { fitmentFieldsConfigurationId } = useParams()
  const history = useHistory()

  const [inputValues, setInputValues] = useState({})
  const [documentTypes, setDocumentTypes] = useState([])
  const [FitmentFieldsConfiguration, setFitmentFieldsConfiguration] = useState(
    []
  )

  useEffect(() => {
    const fetchFitmentFieldsConfiguration = async () => {
      const pageState = globalStore.ui.fitmentFieldsConfigurations.edit

      await FitmentFieldsConfigurationService.show({
        pageState,
        fitmentFieldsConfigurationId,
      })

      const {
        serverStatus,
        fitment_fields_configuration,
        fitmentFieldsConfigurationErrors,
      } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (fitmentFieldsConfigurationErrors) {
        message.error(
          fitmentFieldsConfigurationErrors[0] ||
            "Couldn't fetch fitment configuration"
        )
        return
      }

      const {
        name,
        data_type,
        field_type,
        list_options,
        mandatory_document_types,
        document_required,
        criteria,
        field_required,
        parent_variable_id,
        parent_variable_value,
      } = fitment_fields_configuration

      const newCriteria = criteria?.length > 0 ? criteria : [[blankCondition]]

      setCriteria(newCriteria)
      setInputValues({
        name,
        data_type,
        field_type,
        list_options,
        mandatory_document_types,
        document_required,
        field_required,
        parent_variable_id,
        parent_variable_value,
      })
    }

    fetchFitmentFieldsConfiguration()
  }, [fitmentFieldsConfigurationId])

  useEffect(() => {
    const pageState = globalStore.ui.fitmentFieldsConfigurations.new
    const FitmentFieldsConfiguration = pageState.fitment_fields_configuration

    if (FitmentFieldsConfiguration && FitmentFieldsConfiguration.length !== 0) {
      setFitmentFieldsConfiguration(FitmentFieldsConfiguration)
      return
    }

    const fetchFitmentFieldsConfiguration = async () => {
      await FitmentFieldsConfigurationService.index({ pageState })

      const { fitment_fields_configuration } = pageState

      if (fitment_fields_configuration) {
        setFitmentFieldsConfiguration(fitment_fields_configuration)
      }
    }

    fetchFitmentFieldsConfiguration()
  }, [])

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  useEffect(() => {
    const fetchDocuments = async () => {
      const pageState = globalStore.ui.documents.index
      await DocumentsService.index({ pageState })

      const { serverStatus, documentErrors, documents } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (documentErrors) {
        message.error(documentErrors[0] || "Couldn't fetch document")
        return
      }
      setDocumentTypes(documents)
    }

    fetchDocuments()
  }, [])

  const handleInputChange = (e, inputType) => {
    const newInputValues = { ...inputValues, [inputType]: e.target.value }

    setInputValues(newInputValues)
  }

  const handleDropDownChange = (e, inputType) => {
    const newInputValues = { ...inputValues, [inputType]: e }

    setInputValues(newInputValues)
  }

  const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(deletedConditionIndex, 1)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleUpdateConditionValues = ({
    conditionSetIndex,
    conditionIndex,
    condition,
  }) => {
    if (!condition) return
    if (condition.operator !== "not" && !condition.values) return

    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        return newConditionSet.map((c, index) => {
          if (index !== conditionIndex) return c

          return condition
        })
      })

      return newCriteria
    })
  }

  const handleUpdate = async () => {
    if (!inputValues["name"]) {
      message.error("Name can't be blank")
      return
    }

    if (!inputValues["data_type"]) {
      message.error("Data type can't be blank")
      return
    }

    if (isContainsBlankCriteria(criteria)) {
      message.error("Criteria cannot be blank")
      return
    }

    const pageState = {}
    const postData = {
      fitment_fields_configuration: {
        criteria,
        ...inputValues,
      },
    }

    setCreateButtonLoading(true)
    await FitmentFieldsConfigurationService.update({
      pageState,
      values: postData,
      fitmentFieldsConfigurationId,
    })
    setCreateButtonLoading(false)

    const { serverStatus, fitmentFieldsConfigurationErrors } = pageState

    if (fitmentFieldsConfigurationErrors) {
      message.error(
        fitmentFieldsConfigurationErrors[0] ||
          "Fitment Fields Configuration couldn't be created."
      )
      return
    }

    if (serverStatus.status === 200) {
      message.success("Fitment fields configuration created.")
      setCriteria([[blankCondition]])
      setInputValues({})
      history.push(
        `/configuration/fitment-fields/${fitmentFieldsConfigurationId}`
      )
      return
    }

    message.error("Oops!! something went wrong")
  }

  const handleRequiredCheckboxChange = (checked, inputType) => {
    const newInputValues = { ...inputValues, [inputType]: checked }

    setInputValues(newInputValues)
  }

  const renderPageContent = () => {
    const FitmentFieldsConfigurationExceptCurrent =
      FitmentFieldsConfiguration.filter(
        (ffc) => ffc.name !== inputValues["name"]
      )

    return (
      <StyledConfigurationFormBox>
        <h1>Fitment Fields Configuration</h1>
        <Row gutter={20}>
          <Col span={8}>
            <div className="input-box">
              <strong>Name</strong>
              <Input
                value={inputValues["name"]}
                onChange={(e) => handleInputChange(e, "name")}
              />
            </div>
          </Col>
          <Col span={4}>
            <div className="input-box">
              <div>
                <strong>Data Type</strong>
              </div>
              <Select
                placeholder="Choose documents"
                value={inputValues["data_type"]}
                style={{ width: "100%", marginRight: 10 }}
                onChange={(e) => handleDropDownChange(e, "data_type")}
              >
                {DataTypes.map((dt) => (
                  <Select.Option key={dt.value} value={dt.value}>
                    {dt.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          {inputValues["data_type"] === "list" && (
            <Col span={12}>
              <div className="input-box">
                <div>
                  <strong>List Options</strong>
                </div>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  value={inputValues["list_options"]}
                  tokenSeparators={[","]}
                  placeholder="Add option - use comma to separate"
                  onChange={(values) =>
                    handleDropDownChange(values, "list_options")
                  }
                ></Select>
              </div>
            </Col>
          )}
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <div className="input-box">
              <strong>Parent Fitment Field Name</strong>
              <Select
                placeholder="Choose Fitment Field"
                value={inputValues["parent_variable_id"]}
                style={{ width: "100%", marginRight: 10 }}
                onChange={(e) => handleDropDownChange(e, "parent_variable_id")}
                allowClear
              >
                {FitmentFieldsConfigurationExceptCurrent.map((ff) => (
                  <Select.Option key={ff.id} value={ff.id}>
                    {ff.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={8}>
            <div className="input-box">
              <div>
                <strong>Parent Fitment Field Value</strong>
              </div>
              <Input
                value={inputValues["parent_variable_value"]}
                onChange={(e) => handleInputChange(e, "parent_variable_value")}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20}>
            <div className="input-box">
              <strong>Document Required</strong>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={inputValues["document_required"]}
                onChange={(e) => handleDropDownChange(e, "document_required")}
                style={{ marginLeft: "10px" }}
              />
            </div>
          </Col>
          <Col span={20}>
            <div
              className="input-box"
              style={{
                display: inputValues["document_required"] ? "block" : "none",
              }}
            >
              <div>
                <strong>Mandatory Documents</strong>
              </div>
              <Select
                mode="multiple"
                style={{ width: 260, marginRight: 10 }}
                placeholder="Choose documents"
                value={inputValues["mandatory_document_types"]}
                onChange={(e) =>
                  handleDropDownChange(e, "mandatory_document_types")
                }
              >
                {documentTypes.map((dt) => (
                  <Select.Option key={dt.id} value={dt.name}>
                    {dt.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <div className="input-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong>Criteria</strong>
            {criteria.filter((c) => c.length > 0).length === 0 && (
              <Button
                shape="circle"
                onClick={() => setCriteria([[blankCondition]])}
              >
                <PlusOutlined />
              </Button>
            )}
          </div>
          {criteria.map((conditionSet, conditionSetIndex) => {
            return (
              <div key={conditionSetIndex}>
                {conditionSet.map((condition, conditionIndex) => {
                  return (
                    <ConditionRow
                      condition={condition}
                      key={conditionIndex}
                      lastInputGroup={
                        conditionSet.length === conditionIndex + 1
                      }
                      firstInputGroup={conditionIndex === 0}
                      conditionSetLength={conditionSet.length}
                      inputGroupIndex={conditionIndex}
                      updateConditionValues={(condition) =>
                        handleUpdateConditionValues({
                          conditionSetIndex,
                          conditionIndex,
                          condition,
                        })
                      }
                      addNewCondition={() =>
                        handleAddNewCondition(conditionSetIndex, conditionIndex)
                      }
                      removeCondition={() =>
                        handleRemoveCondition(conditionSetIndex, conditionIndex)
                      }
                      organizationFields={
                        globalStore.currentOrganization.all_fields_with_datatype
                      }
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div
          className="action-buttons submit"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 25,
            marginTop: 45,
          }}
        >
          <div>
            <Checkbox
              checked={inputValues["field_required"]}
              onChange={(e) => {
                handleRequiredCheckboxChange(e.target.checked, "field_required")
              }}
            >
              Make this fitment field mandatory
            </Checkbox>
          </div>
          <div>
            <Button
              type="primary"
              loading={createButtonLoading}
              onClick={handleUpdate}
            >
              Update Fitment Fields Configuration
            </Button>
          </div>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-edit")
  )
    return <Redirect to="/configuration/fitment-fields" />

  return (
    <div className="page-content dashboard-page">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(FitmntFieldsConfigurationsEditPage)

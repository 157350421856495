import { API, generic } from "api"

export default class FitmentApprovalsService {
  static show = async ({ pageState, fitmentApprovalId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_APPROVAL_SHOW_STATUS",
      stateDataKey: ["fitment_approval", "fitment_configuration"],
      stateErrorKey: ["fitmentApprovalErrors", "fitmentConfigurationErrors"],
      apiEndpoint: API.users.fitmentApprovals.show,
      apiUrlReplacements: { fitmentApprovalId },
      apiData: {},
      errorMessage: "Fitment approval could not be fetched.",
    })
  }
  static approve = async ({ pageState, fitmentApprovalId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_APPROVALS_APPROVE_STATUS",
      stateDataKey: ["fitment_approval", "fitment_configuration"],
      stateErrorKey: ["fitmentApprovalErrors", "fitmentConfigurationErrors"],
      apiEndpoint: API.users.fitmentApprovals.approve,
      apiUrlReplacements: { fitmentApprovalId },
      apiData: {},
      errorMessage: "Fitment approval could not be approved.",
    })
  }

  static reject = async ({ pageState, fitmentApprovalId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_APPROVALS_REJECT_STATUS",
      stateDataKey: ["fitment_approval", "fitment_configuration"],
      stateErrorKey: ["fitmentApprovalErrors", "fitmentConfigurationErrors"],
      apiEndpoint: API.users.fitmentApprovals.reject,
      apiUrlReplacements: { fitmentApprovalId },
      apiData: { ...values },
      errorMessage: "Fitment approval could not be rejected.",
    })
  }

  static comment = async ({ pageState, fitmentApprovalId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_APPROVALS_COMMENT_STATUS",
      stateDataKey: ["fitment_approval", "fitment_configuration"],
      stateErrorKey: ["fitmentApprovalErrors", "fitmentConfigurationErrors"],
      apiEndpoint: API.users.fitmentApprovals.comment,
      apiUrlReplacements: { fitmentApprovalId },
      apiData: { ...values },
      errorMessage: "Comment could not be submitted.",
    })
  }

  static pendingApprovals = async ({ pageState, page, pageSize }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_APPROVALS_PENDING_APPROVALS_STATUS",
      stateDataKey: ["fitment_approvals", "fitment_configuration"],
      stateErrorKey: ["fitmentApprovalsErrors", "fitmentConfigurationErrors"],
      apiEndpoint: API.users.fitmentApprovals.pendingApprovals,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize } },
      errorMessage: "Pending approvals couldn't be fetched.",
    })
  }
}

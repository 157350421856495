import { API, generic } from "api"

export default class OrganizationRolesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_ROLES_INDEX_STATUS",
      stateDataKey: ["features", "organization_roles"],
      stateErrorKey: ["featureErrors", "organizationRolesErrors"],
      apiEndpoint: API.users.organizationRoles.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Organization roles data could not be fetched.",
    })
  }

  static addFeature = async ({ pageState, roleId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_ROLES_ADD_FEATURE_STATUS",
      stateDataKey: ["features", "organization_roles"],
      stateErrorKey: ["featureErrors", "organizationRolesErrors"],
      apiEndpoint: API.users.organizationRoles.addFeature,
      apiUrlReplacements: { roleId },
      apiData: { ...values },
      errorMessage: "Feature data could not be added.",
    })
  }

  static removeFeature = async ({ pageState, roleId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_ROLES_REMOVE_FEATURE_STATUS",
      stateDataKey: ["features", "organization_roles"],
      stateErrorKey: ["featureErrors", "organizationRolesErrors"],
      apiEndpoint: API.users.organizationRoles.removeFeature,
      apiUrlReplacements: { roleId },
      apiData: { ...values },
      errorMessage: "Feature data could not be removed.",
    })
  }


  static createDefaultRole = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CREATE_DEFAULT_ROLE_STATUS",
      stateDataKey: ["message"],
      stateErrorKey: ["organizationRolesErrors"],
      apiEndpoint: API.users.organizationRoles.createDefaultRole,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Default Organization roles data could not be fetched.",
      serializedResponse:false,
    })
  }
}
import { API, generic } from "api"

export default class FitmentFieldsConfigurationsService {
  static index = async ({ pageState, page = 1, pageSize = 25, jobFamily }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_FIELDS_CONFIGURATIONS_INDEX_STATUS",
      stateDataKey: [
        "fitment_fields_configuration",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "fitmentFieldsConfigurationErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.fitmentFieldsConfigurations.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize, job_family: jobFamily } },
      errorMessage: "Fitment fields configuration could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_FITMENT_FIELDS_CONFIGURATIONS_CREATE_STATUS",
      stateDataKey: ["fitment_fields_configuration"],
      stateErrorKey: ["fitmentFieldsConfigurationErrors"],
      apiEndpoint: API.users.fitmentFieldsConfigurations.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Fitment fields configuration could not be created.",
    })
  }

  static show = async ({ pageState, fitmentFieldsConfigurationId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_FIELDS_CONFIGURATIONS_SHOW_STATUS",
      stateDataKey: ["fitment_fields_configuration"],
      stateErrorKey: ["fitmentFieldsConfigurationErrors"],
      apiEndpoint: API.users.fitmentFieldsConfigurations.show,
      apiUrlReplacements: { fitmentFieldsConfigurationId },
      apiData: {},
      errorMessage: "Fitment Fields Configuration could not be fetched.",
    })
  }

  static update = async ({
    pageState,
    fitmentFieldsConfigurationId,
    values,
  }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_FITMENT_FIELDS_CONFIGURATIONS_UPDATE_STATUS",
      stateDataKey: ["fitment_fields_configuration"],
      stateErrorKey: ["fitmentFieldsConfigurationErrors"],
      apiEndpoint: API.users.fitmentFieldsConfigurations.update,
      apiUrlReplacements: { fitmentFieldsConfigurationId },
      apiData: { ...values },
      errorMessage: "Fitment Fields Configuration could not be updated.",
    })
  }

  static delete = async ({ pageState, fitmentFieldsConfigurationId }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_FITMENT_FIELDS_CONFIGURATIONS_DELETE_STATUS",
      stateDataKey: ["fitment_fields_configuration"],
      stateErrorKey: ["fitmentFieldsConfigurationErrors"],
      apiEndpoint: API.users.fitmentFieldsConfigurations.delete,
      apiUrlReplacements: { fitmentFieldsConfigurationId },
      apiData: {},
      errorMessage: "Fitment Fields Configuration could not be deleted.",
    })
  }

  static sortOrder = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_FITMENT_FIELDS_CONFIGURATIONS_SORT_ORDER_STATUS",
      stateDataKey: ["fitment_fields_configuration"],
      stateErrorKey: ["fitmentFieldsConfigurationErrors"],
      apiEndpoint: API.users.fitmentFieldsConfigurations.sortOrder,
      apiUrlReplacements: {},
      apiData: { order_ids: values },
      errorMessage: "Fitment Fields Configuration could not be sorted.",
    })
  }
}

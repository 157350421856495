import styled from "styled-components"

const StyledFitmentItemCard = styled.div`
  margin-top: 30px;
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > h1 {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
  & > .approval-chains {
    padding: 10px;
    & .card {
      & > h1 {
        margin-bottom: 10px;
      }
    }
  }
  & .hc-cat-title {
    font-size: 13px;
    font-weight: bold;
  }
`

export default StyledFitmentItemCard

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"
import DataTypes from "constants/data-types"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import FitmentFieldsConfigurationService from "services/users/fitment-fields-configurations.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const FitmentFieldsConfigurationShowPage = (props) => {
  const pageState = globalStore.ui.fitmentFieldsConfigurations.show
  const history = useHistory()
  const { fitmentFieldsConfigurationId } = useParams()

  useEffect(() => {
    const fetchSalaryRange = async () => {
      const pageState = globalStore.ui.fitmentFieldsConfigurations.show

      await FitmentFieldsConfigurationService.show({
        pageState,
        fitmentFieldsConfigurationId,
      })

      const { serverStatus, fitmentFieldsConfigurationErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (fitmentFieldsConfigurationErrors) {
        message.error(
          fitmentFieldsConfigurationErrors[0] ||
            "Couldn't fetch fitment fields configuration"
        )
        return
      }
    }

    fetchSalaryRange()
  }, [fitmentFieldsConfigurationId])

  const handleEdit = () => {
    history.push(
      `/configuration/fitment-fields/${fitmentFieldsConfigurationId}/edit`
    )
  }

  const {
    fitment_fields_configuration,
    API_USERS_FITMENT_FIELDS_CONFIGURATIONS_SHOW_STATUS,
  } = pageState

  const renderContent = () => {
    if (!fitment_fields_configuration) return null

    const { criteria, data_type, list_options, mandatory_document_types } =
      fitment_fields_configuration

    return (
      <div>
        <h1>{fitment_fields_configuration.name}</h1>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Data Type</div>
              <div>{DataTypes.find((dt) => dt.value === data_type)?.label}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Field Required</div>
              <div>
                {fitment_fields_configuration.field_required ? "True" : "False"}
              </div>
            </div>
            {data_type === "list" && (
              <div className="item-row colored">
                <div className="label">List Options</div>
                <div>{list_options.join(", ")}</div>
              </div>
            )}
            <div className="item-row colored">
              <div className="label">Mandatory Document Types</div>
              <div>
                {mandatory_document_types
                  ? mandatory_document_types.map((md) => {
                      return md + ", "
                    })
                  : []}
              </div>
            </div>
          </div>
        </StyledItemList>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Fitment Fields Configuration
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/configuration/fitment-fields" />

  const strategy =
    fitment_fields_configuration &&
    fitment_fields_configuration.id === fitmentFieldsConfigurationId
      ? staleWhileRevalidate
      : networkOnly

  return (
    <div className="page-content salary-ranges-page">
      <StyledBox>
        {strategy({
          status: API_USERS_FITMENT_FIELDS_CONFIGURATIONS_SHOW_STATUS,
          data: fitment_fields_configuration,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(FitmentFieldsConfigurationShowPage)

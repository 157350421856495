import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, message, Row, Col, Select } from 'antd'
import dayjs from 'dayjs'

import StyledBox from 'components/common/styled-components/StyledBox'
import ConditionRow from 'components/budget-analysis/ConditionRow'
import globalStore from 'store/index'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import BudgetUtilizationsService from 'services/users/budget-utilizations.service'
import { DatePicker } from '../../../../node_modules/antd/lib/index'
import moment from '../../../../node_modules/moment/moment'
import Insight from 'components/budget-analysis/Insight'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const blankCondition = {
	field: null,
	operator: null,
	values: null,
}

const BudgetUtilizationsEditPage = () => {
	const history = useHistory()
	// const pageState = globalStore.ui.configuration.budgetUtilizations.edit
	const { budgetUtilizationId, budgetHeadId } = useParams()
	const [criteria, setCriteria] = useState([[blankCondition]])
	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [budgetHeadName, setBudgetHeadName] = useState('')
	const [rate, setRate] = useState(null)
	const [count, setCount] = useState(1)
	const [total, setTotal] = useState(null)
	const [frequency, setFrequency] = useState('annually')
	const [annualizedRate, setAnnualizedRate] = useState(0)
	const [proportioned, setProportioned] = useState(1)
	const dateFormat = 'DD-MMM-YYYY'
	const currentYear = new Date().getFullYear()
	const [dateRange, setDateRange] = useState([
		moment(currentYear + '-04-01'),
		moment(currentYear + 1 + '-03-31'),
	])
	const [insight, setInsight] = useState('')
	const [insightStatus, setInsightStatus] = useState('')
	const [criteriaChildren, setCriteriaChildren] = useState([])

	const enableAllocationFeature = globalStore?.currentOrganization?.enable_allocation_feature

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetUtilization = async () => {
			const pageState = globalStore.ui.configuration.budgetUtilizations.edit

			await BudgetUtilizationsService.show({ pageState, budgetUtilizationId })

			const { serverStatus, budget_utilization, budgetUtilizationErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetUtilizationErrors) {
				message.error(budgetUtilizationErrors || "Coundn't fetch minimum wage")
				return
			}

			const {
				currency,
				frequency,
				rate,
				start_date,
				end_date,
				budget_head_name,
				unit,
				criteria,
				total_value_number,
				insight_message,
				insight_status,
				annualized_amount,
			} = budget_utilization

			setTotal(total_value_number)
			setBudgetHeadName(budget_head_name)
			setInsight(insight_message)
			setInsightStatus(insight_status)
			const newCriteria = criteria?.length > 0 ? criteria : [[blankCondition]]
			setCriteria(newCriteria)
			setInputValues({
				currency,
				frequency,
				rate,
				unit,
				start_date,
				end_date,
				total_value: total_value_number,
			})

			const newFrequency = frequency ? frequency : 'annually'
			const newAnnualizedAmount = annualized_amount
				? annualized_amount
				: getAnnualizedRate(rate, newFrequency)

			setCount(unit)
			setRate(rate)
			setTotal(total_value_number)
			setFrequency(newFrequency)
			setAnnualizedRate(newAnnualizedAmount)
			setDateRange([moment(start_date), moment(end_date)])
		}
		fetchBudgetUtilization()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [budgetUtilizationId, budgetHeadId])

	useEffect(() => {
		if (criteriaChildren.length) {
			const updateCriteriaData = criteria[0].map((data) => {
				if (criteriaChildren.some((items) => items.name === data.field)) {
					return { ...data, values: null, operator: null }
				}
				return data
			})
			setCriteria([updateCriteriaData])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteriaChildren])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list

		const fetchAllFieldsData = async () => {
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		fetchAllFieldsData()
	}, [])

	const handleInputChange = (e, inputType) => {
		let newInputValues = null

		if (inputType === 'frequency') {
			newInputValues = { ...inputValues, [inputType]: e }
			setFrequency(e)
			setAnnualizedRate(getAnnualizedRate(rate, e))
			setTotal(getTotal(count, rate * getFrequencyDays(e), proportioned, e))
		} else {
			newInputValues = { ...inputValues, [inputType]: e.target.value }
		}

		if (inputType === 'rate') {
			setRate(e.target.value)
			setTotal(
				getTotal(count, getAnnualizedRate(e.target.value, frequency), proportioned, frequency)
			)
			setAnnualizedRate(getAnnualizedRate(e.target.value, frequency))
		}

		if (inputType === 'unit') {
			setCount(e.target.value)
			setTotal(getTotal(e.target.value, annualizedRate, proportioned, frequency))
		}

		if (inputType === 'total_value') {
			setTotal(e.target.value)
			setRate(e.target.value / count)
			setAnnualizedRate(getAnnualizedRate(e.target.value / count, frequency))
			// getInsights(e.target.value/count, count, e.target.value)
		}

		setInputValues(newInputValues)
	}

	const handleDateChange = (range) => {
		if (range) {
			setDateRange([range[0], range[1]])

			setProportioned(getProportionedFactor(range[0], range[1]))

			setInputValues({
				...inputValues,
				start_date: dateRange[0],
				end_date: dateRange[1],
			})
			setTotal(
				getTotal(count, annualizedRate, getProportionedFactor(range[0], range[1]), frequency)
			)
		}
	}

	const getTotal = (count, annualizedRate, proportioned, frequency) => {
		if (frequency === 'one_time') {
			return count * annualizedRate
		} else {
			return count * annualizedRate * proportioned
		}
	}

	const getProportionedFactor = (startDate, endDate) => {
		return Math.round((getPeriodDays(startDate, endDate) / 365) * 100) / 100
	}

	const getAnnualizedRate = (rate, frequency) => {
		return rate * getFrequencyDays(frequency)
	}

	const getFrequencyDays = (frequency) => {
		if (frequency === 'daily') {
			return 365.0
		} else if (frequency === 'half_yearly') {
			return 2 // 2.027
		} else if (frequency === 'quarterly') {
			return 4 // 4.05
		} else if (frequency === 'monthly') {
			return 12 // 12.16
		} else if (frequency === 'weekly') {
			return 52 // 52.14
		} else if (frequency === 'annually') {
			return 1
		} else if (frequency === 'one_time') {
			return 1
		}
	}

	const getPeriodDays = (startDate, endDate) => {
		const oneDay = 24 * 60 * 60 * 1000
		const days = Math.round(Math.abs((endDate - startDate) / oneDay))
		return days + 1
	}

	const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(deletedConditionIndex, 1)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleUpdateConditionValues = ({ conditionSetIndex, conditionIndex, condition }) => {
		if (!condition) return
		if (!condition.values) return

		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				return newConditionSet.map((c, index) => {
					if (index !== conditionIndex) return c

					return condition
				})
			})

			return newCriteria
		})
	}

	const handleValidate = async () => {
		// if (!inputValues["name"]) {
		//   message.error("Name can't be blank")
		//   return
		// }
		if (!inputValues['currency']) {
			message.error("Currency can't be blank")
			return
		}
		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}

		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues['total_value'] = total
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()
		inputValues['frequency'] = frequency
		inputValues['annualized_amount'] = annualizedRate

		const pageState = {}
		const postData = {
			budget_utilization: {
				criteria: criteria.filter((c) => c.length > 0),
				...inputValues,
				budget_head_id: budgetHeadId,
			},
		}

		await BudgetUtilizationsService.validateActualBudget({
			pageState,
			values: postData,
			budgetUtilizationId,
			budgetHeadId,
		})

		const { serverStatus, insight, insightErrors } = pageState

		if (insightErrors) {
			setInsight(insightErrors)
			setInsightStatus('error')
			// message.error(budgetUtilizationErrors || "Actual Budget couldn't be created.")
			return
		}
		const insightMessage = insight?.message
		setInsight(insightMessage ? insightMessage : 'Go Ahead')
		setInsightStatus(insight?.status)
		// showInsightMessage()
		if (serverStatus.status === 200) {
			return
		}

		message.error('Oops!! something went wrong')
	}
	// const showInsightMessage = ()=>{

	//   if (insightStatus === "warning") {
	//      message.warning(insight, 5)
	//      return
	//    }
	//    if(insightStatus === "error" ){
	//     message.error(insight, 5)
	//     return
	//    }

	//    message.success(insight, 5)
	// }
	const handleUpdate = async () => {
		// if (!inputValues["name"]) {
		//   message.error("Name can't be blank")
		//   return
		// }
		if (!inputValues['currency']) {
			message.error("Currency can't be blank")
			return
		}
		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}

		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues['total_value'] = total
		inputValues['annualized_amount'] = annualizedRate
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()

		const pageState = {}
		const postData = {
			budget_utilization: {
				criteria: criteria.filter((c) => c.length > 0),
				...inputValues,
				budget_head_id: budgetHeadId,
			},
		}

		setCreateButtonLoading(true)
		await BudgetUtilizationsService.update({
			pageState,
			values: postData,
			budgetUtilizationId,
			budgetHeadId,
		})
		setCreateButtonLoading(false)

		const { serverStatus, budgetUtilizationErrors } = pageState

		if (budgetUtilizationErrors) {
			message.error(budgetUtilizationErrors[0] || 'Failed to update Actual Budget')
			return
		}

		if (serverStatus.status === 200) {
			message.success('Actual Budget updated.')
			history.push(
				`/configuration/budget-heads/${budgetHeadId}/budget-utilizations/${budgetUtilizationId}`
			)
			setCriteria([[blankCondition]])
			setInputValues({})
			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		// if (showInsight) {
		//   return (
		//     <Insight insight={insight} insightStatus={insightStatus} />
		//   )
		// }
		return (
			<StyledConfigurationFormBox>
				<h1>Actual Value for {budgetHeadName}</h1>
				<Row gutter={20}>
					<Col span={16}>
						<div className='input-box'>
							<div>
								<strong>Period</strong>
							</div>
							<DatePicker.RangePicker
								format={dateFormat}
								value={dateRange}
								onChange={handleDateChange}
							/>
						</div>
					</Col>
					<Col span={8} pull={2}>
						<div className='input-box'>
							<strong>Proportioned Factor</strong>
							<Input placeholder='Proportioned Factor' value={proportioned} disabled={true} />
						</div>
					</Col>
					<Col span={2}>
						<div className='input-box'>
							<strong>Currency</strong>
							<Input
								placeholder='Currency'
								value={inputValues['currency']}
								onChange={(e) => handleInputChange(e, 'currency')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Count</strong>
							<Input
								placeholder='Enter Count'
								value={count || inputValues['unit']}
								onChange={(e) => handleInputChange(e, 'unit')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Rate</strong>
							<Input
								placeholder='Enter Rate'
								value={rate || inputValues['rate']}
								onChange={(e) => handleInputChange(e, 'rate')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Frequency</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose frequency'
								value={frequency}
								onChange={(value) => handleInputChange(value, 'frequency')}>
								<Select.Option value='daily'>Daily</Select.Option>
								<Select.Option value='weekly'>Weekly</Select.Option>
								<Select.Option value='monthly'>Monthly</Select.Option>
								<Select.Option value='quarterly'>Quarterly</Select.Option>
								<Select.Option value='half_yearly'>Half Yearly</Select.Option>
								<Select.Option value='annually'>Annually</Select.Option>
								<Select.Option value='one_time'>One Time</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Annualised Rate</strong>
							<Input placeholder='Annualised Rate' value={annualizedRate} disabled={true} />
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Total Cost</strong>
							<Input
								placeholder='Total Cost'
								value={total || inputValues['total_value']}
								onChange={(e) => handleInputChange(e, 'total_value')}
							/>
						</div>
					</Col>
				</Row>
				{enableAllocationFeature && (
					<Row gutter={20}>
						<Col span={6}>
							<div className='input-box'>
								<strong>Allocation Percentage</strong>
								<Input
									placeholder='Enter Allocation Percentage'
									value={inputValues['allocation_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'allocation_percentage')}
								/>
							</div>
						</Col>
						<Col span={6}>
							<div className='input-box'>
								<strong>Increment Percentage</strong>
								<Input
									placeholder='Enter Increment Percentage'
									value={inputValues['increment_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'increment_percentage')}
								/>
							</div>
						</Col>
					</Row>
				)}
				<div className='input-box'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<strong>Criteria</strong>
						{/* <Col span={20} style={{marginLeft: "95px"}}><strong>Symbol</strong></Col>
            <Col span={20}><strong>Value</strong></Col> */}
						{criteria.filter((c) => c.length > 0).length === 0 && (
							<Button shape='circle' onClick={() => setCriteria([[blankCondition]])}>
								<PlusOutlined />
							</Button>
						)}
					</div>
					{criteria.map((conditionSet, conditionSetIndex) => {
						return (
							<div key={conditionSetIndex}>
								{conditionSet.map((condition, conditionIndex) => {
									return (
										<ConditionRow
										criteria={criteria}
												clearChildren={setCriteriaChildren}
											condition={condition}
											key={conditionIndex}
											conditionIndex={conditionSetIndex}
											conditionSetLength={conditionSet.length}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleUpdateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											budgetCriteriaHeads={budgetCriteriaHeads}
										/>
									)
								})}
							</div>
						)
					})}
				</div>
				<div className='action-buttons submit'>
					<Button type='primary' loading={createButtonLoading} onClick={handleUpdate}>
						Update
					</Button>
					<Button type='link' onClick={handleValidate}>
						Validate
					</Button>
				</div>
				<Insight insight={insight} insightStatus={insightStatus} />
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures.length &&
		!['criteria-edit', 'smart-budget', 'budget-data-edit'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to={`/configuration/budget-utilizations/${budgetUtilizationId}`} />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(BudgetUtilizationsEditPage)

import { API, generic } from "api"

export default class CountriesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INDUSTRIES_INDEX_STATUS",
      stateDataKey: ["industries"],
      stateErrorKey: ["industriesErrors"],
      apiEndpoint: API.users.industries.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Industries data could not be fetched.",
    })
  }
}

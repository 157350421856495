import { API, generic } from "api"

export default class OrganizationRolesService {
  static index = async ({ pageState, organizationId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_USERS_ORGANIZATIONS_ROLES_INDEX_STATUS",
      stateDataKey: ["organization_roles"],
      stateErrorKey: ["organizationRolesErrors"],
      apiEndpoint: API.adminUsers.organizations.organizationRoles.index,
      apiUrlReplacements: { organizationId },
      apiData: {},
      errorMessage: "Organization roles could not be fetched.",
    })
  }
}

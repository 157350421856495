import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import { view } from "@risingstack/react-easy-state"
import { message } from "antd"

import ContentLoading from "components/common/ContentLoading"
import TokensService from "services/users/tokens.service"

const SmartRecruitersAuth = (props) => {
  const history = useHistory()
  const { code } = queryString.parse(window.location.search)

  useEffect(() => {
    if (!code) return

    const fetchData = async () => {
      const tempState = {}

      await TokensService.srOauthAccessCallback({ pageState: tempState, code })

      const { response } = tempState

      if (!response) {
        message.error("Oops!! something went wrong.")
        history.push("/")
        return
      }

      const { messages, success } = response

      if (success) {
        message.success(messages[0] || "OAuth Completed.")
      } else {
        message.error(messages[0] || "OAuth couldn't be completed.")
      }

      history.push("/")
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  if (!code) {
    history.replace("/")
    return null
  }

  return (
    <div className="page-content auth-page">
      <ContentLoading />
    </div>
  )
}

export default view(SmartRecruitersAuth)

import { API, generic } from 'api'

export default class EmployeePositionsService {
	static transitionAnalysis = async ({
		pageState,
		startDate,
		endDate,
	}) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_EMPLOYEE_POSITION__INDEX_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.employeePositions.transitionAnalysis,
			apiUrlReplacements: {},
			apiData: {
				params: {
					start_date: startDate,
					end_date: endDate,
				},
			},
			errorMessage: 'Transition Analysis could not be fetched.',
			serializedResponse: false,
		})
	}
}

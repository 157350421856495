import React, { useState, useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, message, Input } from 'antd'
import { Redirect } from 'react-router-dom'

import globalStore from 'store/index'
import StyledBox from 'components/common/styled-components/StyledBox'

import OrganizationFieldsService from 'services/users/organization-fields.service'
import ApprovalChainsService from 'services/users/approval-chains.service'

const StyledOAuthButton = styled(StyledBox)`
	text-align: center;
	margin-top: 30px;
`

const IntegrationPage = (props) => {
	const user = globalStore.currentUser
	const [organizationId, setOrganizationId] = useState(null)
	const [oAuthToken, setOAuthToken] = useState(null)
	const [syncingOrganizationFields, setSyncingOrganizationFields] = useState(false)
	const [syncingBCSLAOwnerEmail, setSyncingBCSLAOwnerEmail] = useState(false)
	const [bcsla, setBcsla] = useState()

	useEffect(() => {
		if (user) {
			const { organization_id, organization_access_token_response } = user

			setOrganizationId(organization_id)
			setOAuthToken(organization_access_token_response?.access_token)
		}
	}, [user])

	const connectToOAuth = () => {
		if (!organizationId) return

		const url = `${process.env.REACT_APP_API_BASE_URL}/auth/smart-recruiters/${organizationId}/oauth-login`

		window.location = url
	}

	const syncBCSLAOwnerEmail = async () => {
		const tempState = {}

		setSyncingBCSLAOwnerEmail(true)
		await ApprovalChainsService.syncBCSLAOwnerEmail({
			pageState: tempState,
			bcsla,
		})
		setSyncingBCSLAOwnerEmail(false)

		if (tempState?.serverStatus?.status === 200) {
			message.success(tempState.message)
			return
		}

		message.error(tempState.message || 'Oop!! something went wrong')
	}
	const syncOrganizationFields = async () => {
		const tempState = {}

		setSyncingOrganizationFields(true)
		await OrganizationFieldsService.sync({
			pageState: tempState,
		})
		setSyncingOrganizationFields(false)

		if (tempState?.serverStatus?.status === 200) {
			message.success(tempState.message)
			return
		}

		message.error(tempState.message || 'Oop!! something went wrong')
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('organization-edit'))
		return <Redirect to={`/`} />

	return (
		<div className='page-content dashboard-page'>
			<StyledOAuthButton>
				<Button type='primary' onClick={syncOrganizationFields} loading={syncingOrganizationFields}>
					Sync Organization Fields
				</Button>
			</StyledOAuthButton>
			{organizationId && (
				<StyledOAuthButton>
					<Button disabled={oAuthToken} onClick={connectToOAuth}>
						{oAuthToken ? 'Already connected' : 'Connect'} to OAuth
					</Button>
				</StyledOAuthButton>
			)}
			<StyledOAuthButton>
				<div style={{ display: 'flex', gap: 10 }}>
					<Input placeholder='Please Enter Bcsla' onChange={(e) => setBcsla(e.target.value)} />
					<Button type='primary' onClick={syncBCSLAOwnerEmail} loading={syncingBCSLAOwnerEmail}>
						Sync BCSLA Owner Email
					</Button>
				</div>
			</StyledOAuthButton>
		</div>
	)
}

export default view(IntegrationPage)

import React, { useEffect, useState } from "react"
import { useParams, useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import dayjs from "dayjs"
import { SaveOutlined } from "@ant-design/icons"
import { message, Input, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledFitmentDetails from "components/common/styled-components/StyledFitmentDetails"
import CandidateCard from "components/CandidateCard"
import { staleWhileRevalidate } from "utils/render-strategies"

import StyledFitmentItemCard from "components/common/styled-components/StyledFitmentItemCard"

import globalStore from "store/index"
import CandidatesService from "services/users/candidates.service"

const localizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(localizedFormat)

const CandidateEditPage = () => {
  const history = useHistory()
  const { candidateId } = useParams()
  const pageState = globalStore.ui.candidates.edit
  const [candidate, setCandidate] = useState(null)
  const [applicant_street_one, setApplicantStreetOne] = useState(null)
  const [applicant_street_two, setApplicantStreetTwo] = useState(null)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.candidates.edit
      await CandidatesService.show({ pageState, candidateId })

      const { candidate } = pageState

      if (candidate) {
        setCandidate(candidate)
        setApplicantStreetOne(candidate.applicant_street_one)
        setApplicantStreetTwo(candidate.applicant_street_two)
        return
      }

      message.error("Failed to load candidate")
    }

    fetchData()
  }, [candidateId])

  const handleUpdate = async () => {
    const tempState = {}

    const postData = {
      candidate: {
        applicant_street_one: applicant_street_one,
        applicant_street_two: applicant_street_two,
      },
    }

    setSaving(true)
    await CandidatesService.update({
      pageState: tempState,
      candidateId,
      values: postData,
    })
    setSaving(false)

    const { candidate, candidateErrors } = tempState

    if (candidateErrors) {
      message.error(candidateErrors[0] || "Opps!! something went wrong.")
      return
    }

    message.success("Candidate Address updated.")
    setCandidate(candidate)
    setApplicantStreetOne(candidate.applicant_street_one)
    setApplicantStreetTwo(candidate.applicant_street_two)

    history.push(`/candidates/${candidateId}/edit`)
  }

  const handleApplicantStreetOne = (e) => {
    setApplicantStreetOne(e.target.value)
  }

  const handleApplicantStreetTwo = (e) => {
    setApplicantStreetTwo(e.target.value)
  }

  const renderCandidateAddress = () => {
    return (
      <StyledFitmentItemCard>
        <div className="header">
          <h1>Candidate Address</h1>
        </div>
        <div className="item-list">
          <div className="item-row candidate-field" key="ff-a1">
            <div className="label">Applicant Street One</div>
            <div>
              <Input
                value={applicant_street_one}
                placeholder={`Enter Applicant Street One`}
                disabled={saving}
                type="text"
                onChange={handleApplicantStreetOne}
              />
            </div>
          </div>
        </div>
        <div className="item-list">
          <div className="item-row candidate-field" key="ff-a2">
            <div className="label">Applicant Street Two</div>
            <div>
              <Input
                value={applicant_street_two}
                placeholder={`Enter the Applicant Street Two`}
                disabled={saving}
                type="text"
                onChange={handleApplicantStreetTwo}
              />
            </div>
          </div>
        </div>
      </StyledFitmentItemCard>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledFitmentDetails editable>
        <CandidateCard {...candidate} />

        <div className="card candidate-fields">
          {renderCandidateAddress()}
          <div className="action-buttons">
            <Button type="primary" onClick={handleUpdate} loading={saving}>
              <SaveOutlined /> {saving ? "Saving" : "Save"} Address
            </Button>
          </div>
        </div>
      </StyledFitmentDetails>
    )
  }

  const { API_USERS_CANDIDATES_SHOW_STATUS } = pageState
  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("offer-edit")
  )
    return <Redirect to={`/fitments`} />

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_CANDIDATES_SHOW_STATUS,
          data: candidate,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(CandidateEditPage)

import React from "react"
import { view } from "@risingstack/react-easy-state"
import { Table } from "antd"

import StyledFitmentItemCard from "components/common/styled-components/StyledFitmentItemCard"
import { hideStandardFields } from "utils/fitment"

const columns = ({ fitment }) => [
  {
    title: "Salary Head",
    dataIndex: "salaryHead",
    key: "salary-head",
  },
  {
    title: "Previous",
    dataIndex: "previous",
    key: "previous",
    align: "right",
    hide: hideStandardFields({ fitment })?.["previous_fixed_compensation"],
  },
  {
    title: "Offered",
    dataIndex: "offered",
    key: "offered",
    align: "right",
  },
  {
    title: "% Hike",
    dataIndex: "hike",
    key: "hike",
    align: "right",
    hide: hideStandardFields({ fitment })?.["previous_fixed_compensation"],
    render: (value) => `${value}%`,
  },
]

const CompensationTable = ({ fitment }) => {
  const {
    hike_fixed,
    hike_gross,
    new_fixed,
    new_gross,
    previous_fixed,
    previous_gross,
  } = fitment?.salary_table

  const filtredColumns = columns({ fitment }).filter((c) => !c.hide)

  const dataSource = [
    {
      key: "1",
      salaryHead: "Fixed Compensation",
      previous: previous_fixed,
      offered: new_fixed,
      hike: hike_fixed,
    },
    {
      key: "3",
      salaryHead: "Total Cost to Company",
      previous: previous_gross,
      offered: new_gross,
      hike: hike_gross,
    },
  ]

  if (fitment.disable_features.includes("salary_comparison")) return null

  return (
    <StyledFitmentItemCard>
      <div className="header">
        <h1>Salary Comparison</h1>
      </div>
      <div style={{ textAlign: "center" }}>
        <Table
          columns={filtredColumns}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    </StyledFitmentItemCard>
  )
}

export default view(CompensationTable)

import { API, generic } from "api"

export default class OrganizationFieldsService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_FIELDS_INDEX_STATUS",
      stateDataKey: ["organization_fields"],
      stateErrorKey: ["organizationFieldsErrors"],
      apiEndpoint: API.users.organizationFields.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Organization fields data could not be fetched.",
    })
  }

  static show = async ({ pageState, fieldId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_FIELDS_SHOW_STATUS",
      stateDataKey: ["organization_field"],
      stateErrorKey: ["organizationFieldErrors"],
      apiEndpoint: API.users.organizationFields.show,
      apiUrlReplacements: { fieldId },
      apiData: {},
      errorMessage: "Organization field data could not be fetched.",
    })
  }

  static sync = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ORGANIZATION_FIELDS_SYNC_STATUS",
      stateDataKey: ["message"],
      stateErrorKey: ["messageErrors"],
      apiEndpoint: API.users.organizationFields.sync,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Organization field data could not be synced.",
      serializedResponse: false,
    })
  }
}

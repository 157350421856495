import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, Redirect } from "react-router-dom"
import styled from "styled-components"
import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import {
  message,
  Button,
  Empty,
  Pagination,
  Table,
  Tooltip,
  Popconfirm,
} from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import VendorsService from "services/users/vendors.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const VendorIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.vendors.index

  useEffect(() => {
    const fetchVendors = async () => {
      const pageState = globalStore.ui.vendors.index
      await VendorsService.index({ pageState })

      const { serverStatus, vendorErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (vendorErrors) {
        message.error(
          vendorErrors[0] ||
          "Couldn't fetch document"
        )
        return
      }
    }

    fetchVendors()
  }, [])

  const repaginate = async (page, pageSize) => {
    await VendorsService.index({ pageState, page, pageSize })
  }

  const handleCreate = () => {
    history.push(`/configuration/vendors/new`)
  }

  const handleCardItemClick = (vendorId) => {
    history.push(
      `/configuration/vendors/${vendorId}`
    )
  }

  const handleEdit = (vendorId) => {
    history.push(
      `/configuration/vendors/${vendorId}/edit`
    )
  }

  const handleDelete = async (vendorId) => {
    const tempState = {}

    await VendorsService.delete({
      pageState: tempState,
      vendorId,
    })

    const { serverStatus } = tempState

    if (serverStatus.status !== 200) return

    globalStore.ui.vendors.index = {
      ...globalStore.ui.vendors.index,
      vendors: vendors.filter(
        (vendors) =>
          vendors.id !== vendorId
      ),
    }
  }

  const {
    vendors,
    vendorsMeta,
    API_USERS_VENDORS_INDEX_STATUS,
    displayType = "table",
  } = pageState

  const { total, page: currentPage, page_size: pageSize } =
    vendorsMeta || {}

  const renderList = () => {
    return (
      <StyledList>
        {vendors.map((ffc) => (
          <div
            className="item-card"
            key={`item-card-${ffc.id}`}
            onClick={() => handleCardItemClick(ffc.id)}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{ffc.name}</div>
                <div>{ }</div>
              </div>
            </div>
            <div className="icon-box">
              <Button type="link">
                <EyeOutlined />
              </Button>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderContents = () => {
    if (vendors.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            Create Vendor
          </Button>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    )

    const tableColumns = [
      ...[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          align: "center",
          render: (value, ffc) => {
            return (
              <div>
                {/* <Tooltip title="View">
                  <Button
                    type="link"
                    onClick={() => handleCardItemClick(ffc.id)}
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip> */}
                <Tooltip title="Edit">
                  <Button type="link" onClick={() => handleEdit(ffc.id)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => handleDelete(ffc.id)}
                    onCancel={() => { }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </div>
            )
          },
        },
      ],
    ]

    const dataSource = vendors.map((ffc) => {
      const retval = { ...ffc, key: ffc.id }
      return retval
    })

    const renderTable = () => {
      return (
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: "max-content",
          }}
        ></Table>
      )
    }

    const renderInfo = () => {
      if (displayType === "table") return renderTable()
      return renderList()
    }

    return (
      <div>
        {pagination}

        {networkOnly({
          status: API_USERS_VENDORS_INDEX_STATUS,
          data: vendors,
          render: renderInfo,
        })}

        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {

    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{ }</div>
          <div className="action-buttons top">
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                Create Vendor
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content salary-ranges-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_VENDORS_INDEX_STATUS,
          data: vendors,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(VendorIndexPage)

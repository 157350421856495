import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Form, message, Input } from 'antd'

import { staleWhileRevalidate } from 'utils/render-strategies'
import BudgetHeadsService from 'services/users/budget-heads.service'

import globalStore from 'store'

import StyledBox from 'components/common/styled-components/StyledBox'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledUserEditForm = styled.div``

const BudgetHeadsNewPage = () => {
	const pageState = globalStore.ui.configuration.budgetHeads.new
	const history = useHistory()
	const [budgetHeadName, setBudgetHeadName] = useState('')
	// const [steps, setSteps] = useState([{ id: "", name: "" }])
	const [creating, setCreating] = useState(false)

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}

			await BudgetHeadsService.index({ pageState: tempState })

			const serverStatus = tempState.serverStatus
			// const { serverStatus } = tempState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
			}
		}

		fetchData()
	}, [])

	const handleCreate = async () => {
		// if (!budgetHeadName) {
		//   message.error("Name field can't be blank")
		//   return
		// }
		const tempState = {}
		const postData = {
			budget_head: {
				name: budgetHeadName,
			},
		}

		setCreating(true)
		await BudgetHeadsService.create({
			pageState: tempState,
			values: postData,
		})
		setCreating(false)

		const { serverStatus, budgetHeadErrors } = tempState

		if (serverStatus.status !== 200) {
			message.error("budgetHead couldn't be created")
			return
		}

		if (budgetHeadErrors?.length > 0) {
			message.error(budgetHeadErrors[0])
			return
		}

		message.success('Budget Criteria created.')
		history.push('/configuration/budget-heads')
	}

	const handleNameChange = (e) => {
		setBudgetHeadName(e.target.value)
	}

	const renderPageContent = () => {
		// const selectedOrganizationRoleIds = organization_roles.map((sor) => sor.id)
		return (
			<StyledUserEditForm>
				<Form layout='vertical'>
					<Form.Item label='Budget Name'>
						<Input
							placeholder='Enter Budget Name'
							value={budgetHeadName}
							onChange={handleNameChange}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							type='primary'
							htmlType='submit'
							style={{ cssFloat: 'right' }}
							loading={creating}
							onClick={handleCreate}>
							{creating ? 'Creating...' : 'Create'}
						</Button>
					</Form.Item>
				</Form>
			</StyledUserEditForm>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['budget-data-edit', 'smart-budget'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/' />

	const { API_BUDGET_HEADS_SHOW_STATUS } = pageState

	return (
		<div className='page-content user-page'>
			<StyledBox maxWidth={'500px'}>
				{staleWhileRevalidate({
					status: API_BUDGET_HEADS_SHOW_STATUS,
					data: { budgetHeadName },
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetHeadsNewPage)

import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Row, Col, Select, Input, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import OrganizationsService from "services/users/organizations.service"

const StyledPageContent = styled.div`
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & input:nth-child(2) {
      margin-left: 15px;
    }
    & .action-buttons {
      margin: 0px;
      & > button {
        margin: 0px 0px 0px 15px;
      }
    }
  }
`

const OrganizationEditPage = (props) => {
  const pageState = globalStore.currentOrganization
  // const [name, setName] = useState(null)
  // const [industry, setIndustry] = useState(null)
  const [address, setAddress] = useState({})
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false)

  useEffect(() => {
    const pageState = globalStore.currentOrganization

    if (pageState.id) return

    const fetchData = async () => {
      await OrganizationsService.me({ pageState })
    }

    fetchData()
  }, [])

  // const handleNameChange = (e) => {
  //   setName(e.target.value)
  // }

  // const handleIndustryChange = (industry) => {
  //   setIndustry(industry)
  // }

  const handleAddressChange = ({ value, key }) => {
    setAddress({ ...address, [key]: value })
  }

  const handleUpdate = async () => {
    // const pageState = {}
    // const postData = {
    //   organization: {
    //     name,
    //     industry,
    //     address,
    //   },
    // }


    setUpdateButtonLoading(true)
    // await OrganizationsService.update({
    //   pageState,
    //   values: postData,
    // })
    // setUpdateButtonLoading(false)

    // const { serverStatus } = pageState

    // if (serverStatus.status !== 200) {
    //   message.error("Failed to update.")
    //   return
    // }

    // message.success("Updated")
  }

  const renderPageContent = () => {
    const { name } = pageState

    return (
      <StyledPageContent>
        {/* <h1>Update Organization Details</h1> */}
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="input-box">
              <h1>Name</h1>
              <Input
                value={name}
                onChange={()=>{}}
                placeholder="Enter organization name"
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="input-box">
              <h1>Industry</h1>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select industry"
                onChange={()=>{}}
              >
                <Select.Option value="it">IT</Select.Option>
                <Select.Option value="media">Media</Select.Option>
                <Select.Option value="tom">Tom</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>

        <div className="input-box">
          <h1>Address</h1>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Input
                value={address.locality}
                placeholder="Enter locality"
                onChange={(e) =>
                  handleAddressChange({
                    value: e.target.value,
                    key: "locality",
                  })
                }
              />
            </Col>
            <Col span={6}>
              <Input
                value={address.city}
                placeholder="Enter city"
                onChange={(e) =>
                  handleAddressChange({
                    value: e.target.value,
                    key: "city",
                  })
                }
              />
            </Col>
            <Col span={6}>
              <Input
                value={address.country}
                placeholder="Enter country"
                onChange={(e) =>
                  handleAddressChange({
                    value: e.target.value,
                    key: "country",
                  })
                }
              />
            </Col>
          </Row>
        </div>
        <div>
          <div className="action-buttons">
            <Button
              type="primary"
              loading={updateButtonLoading}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
        </div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("organization-setup")
  )
    return <Redirect to="/" />

  const { API_USERS_FITMENT_CONFIGURATIONS_INDEX_STATUS } = pageState

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_FITMENT_CONFIGURATIONS_INDEX_STATUS,
          data: {},
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(OrganizationEditPage)

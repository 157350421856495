import { API, generic } from "api"

export default class ControlFeaturesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CONTROL_FEATURES_INDEX_STATUS",
      stateDataKey: ["control_features"],
      stateErrorKey: ["controlFeaturesErrors"],
      apiEndpoint: API.users.control_features.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Control features could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CONTROL_FEATURES_CREATE_STATUS",
      stateDataKey: ["control_features"],
      stateErrorKey: ["controlFeaturesErrors"],
      apiEndpoint: API.users.control_features.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Control feature could not be created.",
    })
  }

  static show = async ({ pageState, controlFeatureId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CONTROL_FEATURES_SHOW_STATUS",
      stateDataKey: ["control_feature"],
      stateErrorKey: ["controlFeatureErrors"],
      apiEndpoint: API.users.control_features.show,
      apiUrlReplacements: { controlFeatureId },
      apiData: {},
      errorMessage: "Control feature could not be fetched.",
    })
  }

  static update = async ({ pageState, values, controlFeatureId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CONTROL_FEATURES_UPDATE_STATUS",
      stateDataKey: ["control_feature"],
      stateErrorKey: ["controlFeatureErrors"],
      apiEndpoint: API.users.control_features.update,
      apiUrlReplacements: { controlFeatureId },
      apiData: { ...values },
      errorMessage: "Control feature could not be updated.",
    })
  }

  static delete = async ({ pageState, controlFeatureId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CONTROL_FEATURES_DELETE_STATUS",
      stateDataKey: ["control_feature"],
      stateErrorKey: ["controlFeatureErrors"],
      apiEndpoint: API.users.control_features.delete,
      apiUrlReplacements: { controlFeatureId },
      apiData: {},
      errorMessage: "Control feature could not be deleted.",
    })
  }
}

import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { message, Empty, Table } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import EmployeePositionsService from 'services/users/employee-positions.service'
import moment from 'moment'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import { Redirect } from 'react-router-dom'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const PositionTransitionAnalysisPage = () => {

	const pageState = globalStore.ui.configuration.budgetHeads.transitionAnalysis

	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetValues = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.transitionAnalysis
			await EmployeePositionsService.transitionAnalysis({ pageState })
			const { serverStatus, dataErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (dataErrors) {
				message.error(dataErrors[0] || "Couldn't fetch Budget Values")
				return
			}
		}

		fetchBudgetValues()
	}, [])

	const { data, API_USERS_EMPLOYEE_POSITION__INDEX_STATUS } = pageState

	const renderContents = () => {
		if (data && !data?.length) {
			return (
				<div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: 10,
							margin: '15px 0px',
						}}>
						<div></div>
					</div>
					<Empty />
				</div>
			)
		}

		const tableColumns = [
			{
				title: 'Position Code',
				dataIndex: 'position_code',
				key: 'position_code',
				align: 'left',
				render: (value) => value || '--',
			},
			{
				title: 'Existing EC',
				dataIndex: 'employee_code',
				key: 'employee_code',
				render: (value) => value || '--',
			},
			{
				title: 'Existing EC Salary',
				dataIndex: 'display_current_salary',
				key: 'current_salary',
				render: (value) => (value ? value : '--'),
			},
			{
				title: 'Revised EC',
				dataIndex: 'revised_employee_code',
				key: 'revised_employee_code',
				render: (value) => value || '--',
			},
			{
				title: 'Revised EC Salary',
				dataIndex: 'display_revised_salary',
				key: 'revised_salary',
				render: (value) => (value ? value : '--'),
			},
			{
				title: 'Replacement Cost',
				dataIndex: 'display_replacement_cost',
				key: 'replacement_cost',
				render: (value) => (value ? value : '--'),
			},
			{
				title: 'LWD of Existing EC',
				dataIndex: 'end_date',
				key: 'end_date',
				render: (value) => (value ? moment.utc(value).format('DD-MMM-YYYY') : '--'),
			},
			{
				title: 'Joining date of Existing EC',
				dataIndex: 'joining_date',
				key: 'joining_date',
				render: (value) => (value ? moment.utc(value).format('DD-MMM-YYYY') : '--'),
			},
			{
				title: 'Joining date of Revised EC',
				dataIndex: 'revised_employee_start_date',
				key: 'revised_employee_start_date',
				render: (value) => (value ? moment.utc(value).format('DD-MMM-YYYY') : '--'),
			},
			{
				title: 'Overlapping period (days)',
				dataIndex: 'overlapping_period',
				key: 'overlapping_period',
				render: (value) => value || '--',
			},
			{
				title: 'Overlapping cost',
				dataIndex: 'display_overlapping_cost',
				key: 'overlapping_cost',
				render: (value) => (value ? value : '--'),
			},
		]

		const dataSource = () => {
			const returnDataSource = data.map((d, index) => {
				const data = { ...d, key: index }
				return data
			})
			return returnDataSource
		}

		const renderTable = () => {
			return (
				<div style={{ margin: '10px 0px', textAlign: 'center' }}>
					<Table
						className='transition-analysis'
						columns={tableColumns}
						dataSource={dataSource()}
						pagination={false}
						scroll={{
							x: 'max-content',
						}}
					/>
				</div>
			)
		}


		const renderInfo = () => {
			return renderTable()
		}

		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
						margin: '10px 0px',
					}}>
				</div>

				{networkOnly({
					status: true,
					data: true,
					render: renderInfo,
				})}
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-report'))
		return <Redirect to='/' />

	return (
		<div className='page-content budget_values-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_EMPLOYEE_POSITION__INDEX_STATUS,
					data: data,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(PositionTransitionAnalysisPage)

import { API, generic } from "api"

export default class CandidatesService {
  static update = async ({ pageState, candidateId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CANDIDATES_UPDATE_STATUS",
      stateDataKey: ["candidate"],
      stateErrorKey: ["candidateErrors"],
      apiEndpoint: API.users.candidates.update,
      apiUrlReplacements: { candidateId },
      apiData: { ...values },
      errorMessage: "Fitment could not be updated.",
    })
  }

  static show = async ({ pageState, candidateId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CANDIDATES_SHOW_STATUS",
      stateDataKey: ["fitment", "candidate"],
      stateErrorKey: ["fitmentErrors", "candidateErrors"],
      apiEndpoint: API.users.candidates.show,
      apiUrlReplacements: { candidateId },
      apiData: {},
      errorMessage: "Fitment could not be fetched.",
    })
  }
}

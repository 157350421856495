import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import DocumentsService from "services/users/documents.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const DocumentsShowPage = (props) => {
  const pageState = globalStore.ui.documents.show
  const history = useHistory()
  const { documentId } = useParams()

  useEffect(() => {
    const fetchDocument = async () => {
      const pageState = globalStore.ui.documents.show

      await DocumentsService.show({
        pageState,
        documentId,
      })

      const { serverStatus, documentErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (documentErrors) {
        message.error(
          documentErrors[0] || "Couldn't fetch fitment fields configuration"
        )
        return
      }
    }

    fetchDocument()
  }, [documentId])

  const handleEdit = () => {
    history.push(`/configuration/documents/${documentId}/edit`)
  }

  const { document, API_USERS_DOCUMENTS_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!document) return null

    const { criteria } = document

    return (
      <div>
        <h1>{document.name}</h1>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Document
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/configuration/documents" />

  const strategy =
    document && document.id === documentId ? staleWhileRevalidate : networkOnly

  return (
    <div className="page-content salary-ranges-page">
      <StyledBox>
        {strategy({
          status: API_USERS_DOCUMENTS_SHOW_STATUS,
          data: document,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(DocumentsShowPage)

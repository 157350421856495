import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, Redirect } from "react-router-dom"
import styled from "styled-components"
import {
  EyeOutlined,
  InboxOutlined,
  DeleteOutlined,
  FileSearchOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons"
import {
  message,
  Button,
  Empty,
  Pagination,
  Tag,
  Tooltip,
  List,
  Table,
  Input,
  Modal,
  Upload,
  Popconfirm,
  Dropdown,
  Menu,
} from "antd"

import { hideExportButtons } from "utils/export"
import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import ApprovalChainsService from "services/users/approval-chains.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const ApprovalChainsIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.configuration.approvalChains.index
  const displayType = "table"
  const [exporting, setExporting] = useState(false)
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [fileList, setFileList] = useState([])
  const [importing, setImporting] = useState(false)
  const [exportingAuditReports, setExportingAuditReports] = useState(false)

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  useEffect(() => {
    const fetchApprovalChains = async () => {
      const pageState = globalStore.ui.configuration.approvalChains.index

      await ApprovalChainsService.index({ pageState })

      const { serverStatus, approvalChainsErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (approvalChainsErrors) {
        message.error(
          approvalChainsErrors[0] || "Coundn't fetch approval chains"
        )
        return
      }
    }

    fetchApprovalChains()
  }, [])

  const repaginate = async (page, pageSize, bcsla = null) => {
    if (bcsla) {
      await ApprovalChainsService.index({ pageState, page, pageSize, bcsla })
      return
    }
    await ApprovalChainsService.index({ pageState, page, pageSize })
  }

  const handleCreate = () => {
    history.push("/configuration/approval-chains/new")
  }

  const openImportModal = () => {
    setImportModalVisible(true)
  }

  const handleImport = async () => {
    if (fileList.length === 0) return

    const tempState = {}

    const formData = new FormData()

    formData.append("file", fileList[0])

    setImporting(true)
    await ApprovalChainsService.import({ pageState: tempState, formData })
    setImporting(false)

    setImportModalVisible(false)

    message.success(
      "Import started. Kindly check import page in Report Center to see imported Approvals"
    )
  }

  const handleExport = async () => {
    const tempState = {}

    setExporting(true)
    await ApprovalChainsService.export({ pageState: tempState })
    setExporting(false)

    message.success(
      "Export started. Kindly check export page in Report Center to see exported Approvals"
    )
  }

  const handleExportAuditReports = async () => {
    const tempState = {}

    setExportingAuditReports(true)
    await ApprovalChainsService.auditReports({ pageState: tempState })
    setExportingAuditReports(false)

    message.success(
      "Export started. Kindly check export page in Report Center to see exported audit reports"
    )
  }

  const handleExportAuditReport = async (approvalChainId) => {
    const tempState = {}

    await ApprovalChainsService.auditReport({
      pageState: tempState,
      approvalChainId,
    })

    message.success(
      "Export started. Kindly check export page in Report Center to see exported audit reports"
    )
  }

  const handleCardItemClick = (approvalChainId) => {
    history.push(`/configuration/approval-chains/${approvalChainId}`)
  }

  const handleEdit = (approvalChainId) => {
    history.push(`/configuration/approval-chains/${approvalChainId}/edit`)
  }

  const handleDelete = async (approvalChainId) => {
    const tempState = {}

    await ApprovalChainsService.delete({
      pageState: tempState,
      approvalChainId,
    })

    const { serverStatus } = tempState

    if (serverStatus.status !== 200) return

    globalStore.ui.configuration.approvalChains.index = {
      ...globalStore.ui.configuration.approvalChains.index,
      approval_chains: approval_chains.filter(
        (approval_chain) => approval_chain.id !== approvalChainId
      ),
    }
  }
  const exportButtonMenu = (
    <Menu>
      <Menu.Item key="0">
        <Button type="link" ghost loading={exporting} onClick={handleExport}>
          Export
        </Button>
      </Menu.Item>
      <Menu.Item key="1">
        <Button
          type="link"
          ghost
          loading={exportingAuditReports}
          onClick={handleExportAuditReports}
        >
          {exportingAuditReports
            ? "Exporting Audit Reports..."
            : "Export Audit Reports"}
        </Button>
      </Menu.Item>
    </Menu>
  )

  const handleRowClick = handleCardItemClick

  const statusColor = (status) => {
    if (status === "published") return "green"
    return "gray"
  }

  const {
    approval_chains,
    approval_chainsMeta,
    API_USERS_APPROVAL_CHAINS_INDEX_STATUS,
  } = pageState

  const {
    total,
    page: currentPage,
    page_size: pageSize,
    criteria_keys,
  } = approval_chainsMeta || {}

  const renderList = () => {
    return approval_chains.map((ac) => (
      <div
        className="item-card"
        key={`item-card-${ac.id}`}
        onClick={() => handleCardItemClick(ac.id)}
      >
        <div className="status-box">
          <Tooltip title={ac.status}>
            <div className={`status ${statusColor(ac.status)}`}></div>
          </Tooltip>
        </div>
        <div className="details-box">
          <div className="name-box">
            <div className="name">{ac.name}</div>
            <div>
              <Tag>Approvers: {ac.approver_emails.length}</Tag>
              <Tag>Criteria: {ac.criteria.length}</Tag>
            </div>
          </div>
        </div>
        <div className="icon-box">
          <Button type="link">
            <EyeOutlined />
          </Button>
        </div>
      </div>
    ))
  }

  const renderTable = () => {
    const tableColumns = [
      ...[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
      ],
      ...criteria_keys.map((ck) => ({
        title: ck,
        dataIndex: ck,
        key: ck,
        render: (filteredCriteria, row) => {
          if (!filteredCriteria) return "--" // if null
          if (!filteredCriteria[0]) return "--" // If [null]
          if (filteredCriteria.length === 0) return "---" // if []

          const dataSource = filteredCriteria.filter((fc) => fc) // FIXME: need to fix
          return (
            <List
              dataSource={dataSource}
              renderItem={(values) => {
                return values.join(", ")
              }}
            ></List>
          )
        },
      })),
      ...[
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          align: "center",
          render: (value, sr) => {
            return (
              <div>
                <Tooltip title="View">
                  <Button type="link" onClick={() => handleRowClick(sr.id)}>
                    <EyeOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Edit">
                  <Button type="link" onClick={() => handleEdit(sr.id)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => handleDelete(sr.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip title="Export audit report">
                  <Button
                    type="link"
                    onClick={() => handleExportAuditReport(sr.id)}
                  >
                    <FileSearchOutlined />
                  </Button>
                </Tooltip>
              </div>
            )
          },
        },
      ],
    ]

    const dataSource = approval_chains.map((sr) => {
      const retval = { ...sr, key: sr.id }
      criteria_keys.forEach((ck) => {
        retval[ck] = sr.criteria.map((conditions) => {
          const relevantCondition = conditions.find((c) => c.field === ck)
          if (!relevantCondition) return null
          return relevantCondition.values
        })
      })
      return retval
    })
    return (
      <Table
        columns={tableColumns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          x: "max-content",
        }}
      ></Table>
    )
  }

  const renderInfo = () => {
    if (displayType === "table") return renderTable()
    return renderList()
  }

  const renderContents = () => {
    if (approval_chains.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            Create Approval Chain
          </Button>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    )

    return (
      <div>
        {pagination}
        <StyledList>
          {networkOnly({
            status: API_USERS_APPROVAL_CHAINS_INDEX_STATUS,
            data: approval_chains,
            render: renderInfo,
          })}
        </StyledList>
        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {
    const bcslaSearch = (
      <Input.Search
        placeholder="BCSLA"
        onSearch={(bcsla) => repaginate(1, pageSize, bcsla)}
        style={{ width: 200, marginBottom: "10px" }}
      />
    )

    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{bcslaSearch}</div>
          <div className="action-buttons top">
            <Tooltip title="Create or update multiple records by upload">
              <Button onClick={openImportModal} style={{ marginRight: 10 }}>
                Import
              </Button>
            </Tooltip>
            {hideExportButtons(accessibleFeatures) && (
              <React.Fragment>
                <Dropdown overlay={exportButtonMenu}>
                  <Button style={{ marginRight: 10 }}>
                    Export <DownOutlined />
                  </Button>
                </Dropdown>

                {/* <Tooltip title="Prepares report for download, available under export section">
                  <Button
                    loading={exporting}
                    onClick={handleExport}
                    style={{ marginRight: 10 }}
                  >
                    Export
                  </Button>
                </Tooltip>
                <Tooltip title="Export audit reports of approval chains">
                  <Button
                    loading={exportingAuditReports}
                    onClick={handleExportAuditReports}
                    style={{ marginRight: 10 }}
                  >
                    {exportingAuditReports
                      ? "Exporting Audit Reports..."
                      : "Export Audit Reports"}
                  </Button>
                </Tooltip> */}
              </React.Fragment>
            )}
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                Create Approval Chain
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const handleSampleFileDownload = () => {
    window.open(
      "https://storage.googleapis.com/ps-in-images-compensation/images/pdf/1098/approval_chain_importer_sample.xlsx?1630668972"
    )
  }

  const uploadProps = {
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([file])
      return false
    },
    fileList,
  }

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("approval-chain-view")
  )
    return <Redirect to={`/`} />

  return (
    <div className="page-content approval-chains-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_APPROVAL_CHAINS_INDEX_STATUS,
          data: approval_chains,
          render: renderPageContent,
        })}
      </StyledBox>
      <Modal
        title="Import"
        visible={importModalVisible}
        confirmLoading={importing}
        closable={false}
        maskClosable={false}
        destroyOnClose
        onOk={handleImport}
        okText="Import"
        onCancel={() => setImportModalVisible(false)}
      >
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Please ensure data accuracy in your file for upload. After upload,
            each associated records will be updated directly.
          </p>
        </Upload.Dragger>
        <Button type="link" onClick={handleSampleFileDownload}>
          Sample Import File
        </Button>
      </Modal>
    </div>
  )
}

export default view(ApprovalChainsIndexPage)

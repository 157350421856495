import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { EyeOutlined } from "@ant-design/icons"
import { Button, Table, message, Pagination } from "antd"

import globalStore from "store"
import FitmentApprovalsService from "services/users/fitment-approvals.service"
import StyledBox from "components/common/styled-components/StyledBox"
import { staleWhileRevalidate } from "utils/render-strategies"
const PendingHireReviewApprovalIndexPage = () => {
  const history = useHistory()
  const pageState = globalStore.ui.fitmentApprovals.pendingApprovals
  const [tableDataLoading, setTableDataLoading] = useState(false)
  const [fitmentApprovals, setFitmentApprovals] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.fitmentApprovals.pendingApprovals
      setTableDataLoading(true)
      await FitmentApprovalsService.pendingApprovals({ pageState })
      setTableDataLoading(false)

      const { fitment_approvals } = pageState

      if (fitment_approvals) {
        setFitmentApprovals(fitment_approvals)
        return
      }

      message.error("Failed to load fitment approvals")
    }

    fetchData()
  }, [])

  const { fitmentApprovalsMeta } = pageState

  const formatFitments = () => {
    if (!fitmentApprovals) return []
    if (!Array.isArray(fitmentApprovals)) return []

    return fitmentApprovals.map((fitmentApproval) => {
      return {
        fitmentApprovalId: fitmentApproval.id,
        key: fitmentApproval.id,
        fitment_approval_id: fitmentApproval.id,
        token: fitmentApproval.token,
        ...fitmentApproval["fitment"]["candidate"],
      }
    })
  }

  const handleFitmentApprovalClick = (token) => {
    history.push(`/fitment-approvals/pending-hirereview-approvals/${token}`)
  }

  const repaginate = async (page, pageSize) => {
    const tempState = {}

    setTableDataLoading(true)

    await FitmentApprovalsService.pendingApprovals({
      pageState: tempState,
      page,
      pageSize,
    })

    setTableDataLoading(false)

    const { fitment_approvals, fitmentApprovalsMeta } = tempState

    if (fitment_approvals) {
      setFitmentApprovals(fitment_approvals)
      pageState.fitmentApprovalsMeta = fitmentApprovalsMeta
      return
    }
  }
  const renderFitmentApprovals = () => {
    const formattedFitments = formatFitments()

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        align: "right",
        render: (action, row) => {
          return (
            <div className="icon-box">
              <Button
                type="link"
                onClick={() =>
                  handleFitmentApprovalClick(row.fitmentApprovalId)
                }
              >
                <EyeOutlined />
              </Button>
            </div>
          )
        },
      },
    ]

    return (
      <div style={{ margin: "10px 0px" }}>
        <Table
          columns={columns}
          dataSource={formattedFitments}
          loading={tableDataLoading}
          pagination={false}
        />
      </div>
    )
  }

  const {
    total,
    page: currentPage,
    page_size: pageSize,
  } = fitmentApprovalsMeta || {}

  const pagination = (
    <Pagination
      current={currentPage}
      total={total}
      onChange={repaginate}
      onShowSizeChange={repaginate}
      pageSize={pageSize || 25}
      pageSizeOptions={[10, 25, 50, 100]}
    />
  )

  const renderPageContent = () => {
    return (
      <div>
        {pagination}
        {renderFitmentApprovals()}
        {pagination}
      </div>
    )
  }

  const { API_FITMENT_APPROVALS_PENDING_APPROVALS_STATUS } = pageState

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_FITMENT_APPROVALS_PENDING_APPROVALS_STATUS,
          data: fitmentApprovals,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default PendingHireReviewApprovalIndexPage

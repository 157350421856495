import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { view } from "@risingstack/react-easy-state"

import { message, Button, Modal, Input } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import FitmentView from "components/FitmentView"
import Comments from "components/Comments"
import FitmentApprovalsService from "services/visitors/fitment-approvals.service"
import { staleWhileRevalidate } from "utils/render-strategies"

import globalStore from "store/index"

const FitmentsShowPage = () => {
  const { token } = useParams()
  const pageState = globalStore.ui.fitmentApprovals.show
  const [fitment, setFitment] = useState(null)
  const [comment, setComment] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [approveButtonLoading, setApproveButtonLoading] = useState(false)
  const [rejectButtonLoading, setRejectButtonLoading] = useState(false)
  const [commentButtonLoading, setCommentButtonLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.fitmentApprovals.show
      await FitmentApprovalsService.show({ pageState, token })

      const { fitment_approval, organization } = pageState

      if (organization) {
        globalStore.currentOrganization = organization
      }

      if (fitment_approval && fitment_approval.fitment) {
        setFitment(fitment_approval.fitment)
        return
      }

      message.error("Failed to load fitment")
    }

    fetchData()
  }, [token])

  const approveFitment = async () => {
    const { fitment_approval } = globalStore.ui.fitmentApprovals.show

    if (!fitment_approval) return
    if (fitment_approval.status !== "pending") return

    const pageState = {}

    setApproveButtonLoading(true)
    await FitmentApprovalsService.approve({ pageState, token })
    setApproveButtonLoading(false)

    if (!pageState.fitment_approval) {
      message.error("Fitment couldn't be approved.")
      return
    }
    if (pageState.fitment_approval.status !== "approved") {
      message.error("Fitment couldn't be approved.")
      return
    }

    message.success("Fitment approved.")
    globalStore.ui.fitmentApprovals.show.fitment_approval =
      pageState.fitment_approval
  }

  const rejectFitmentApproval = async () => {
    if (!comment) {
      message.error("Reason couldn't be blank")
      return
    }
    const { fitment_approval } = globalStore.ui.fitmentApprovals.show

    if (!fitment_approval) return
    if (fitment_approval.status === "rejected") return

    const pageState = {}

    const postData = {
      comment,
    }

    setRejectButtonLoading(true)
    await FitmentApprovalsService.reject({ pageState, token, values: postData })
    setRejectButtonLoading(false)

    if (!pageState.fitment_approval) {
      message.error("Fitment couldn't be rejected.")
      return
    }
    if (pageState.fitment_approval.status !== "rejected") {
      message.error("Fitment couldn't be rejected.")
      return
    }

    setModalVisible(false)
    message.success("Fitment rejected.")
    globalStore.ui.fitmentApprovals.show.fitment_approval =
      pageState.fitment_approval
  }

  const openCommentModal = () => {
    setModalVisible(true)
  }

  const scrollToCommentBox = () => {
    document.getElementById("comments").scrollIntoView({ behavior: "smooth" })
  }

  const handleComment = async (comment) => {
    if (!comment) {
      message.error("Comment couldn't be blank")
      return
    }
    const { fitment_approval } = globalStore.ui.fitmentApprovals.show

    if (!fitment_approval) return

    const pageState = {}

    const postData = {
      comment,
    }

    setCommentButtonLoading(true)
    await FitmentApprovalsService.comment({
      pageState,
      token,
      values: postData,
    })
    setCommentButtonLoading(false)

    if (!pageState.fitment_approval) {
      message.error("Failed to comment")
      return
    }

    message.success("Comment posted.")
    globalStore.ui.fitmentApprovals.show.fitment_approval =
      pageState.fitment_approval
  }

  const renderTopActionButtons = () => {
    const { fitment_approval } = globalStore.ui.fitmentApprovals.show

    const approved = fitment_approval && fitment_approval.status === "approved"
    const rejected = fitment_approval && fitment_approval.status === "rejected"
    const actionTaken = fitment_approval.status !== "pending"

    return (
      <div>
        <Button
          type="primary"
          ghost
          disabled={actionTaken}
          loading={approveButtonLoading}
          onClick={approveFitment}
        >
          {approved ? "Already Approved" : "Approve"}
        </Button>
        <Button
          type="danger"
          ghost
          style={{ margin: "0px 10px" }}
          loading={rejectButtonLoading}
          disabled={actionTaken}
          onClick={openCommentModal}
        >
          {rejected ? `Already Rejected` : "Reject"}
        </Button>
        <Button type="primary" ghost onClick={scrollToCommentBox}>
          Comment
        </Button>
      </div>
    )
  }

  const renderPageContent = () => {
    const { fitment_approval } = globalStore.ui.fitmentApprovals.show
    const { applicable_fitment_fields_configuration } = fitment

    return (
			<div>
				<FitmentView
					fitment={fitment}
					actionButtons={renderTopActionButtons()}
					fitmentfieldsConfigurations={applicable_fitment_fields_configuration}
					externalView
				/>
				<Comments
					comments={fitment_approval.fitment.comments}
					commentButtonLoading={commentButtonLoading}
					commentAble
					onComment={handleComment}
				/>
			</div>
		)
  }

  const { API_VISITORS_FITMENT_APPROVALS_SHOW_STATUS } = pageState

  return (
    <div className="page-content dashboard-page" style={{ margin: "auto" }}>
      <StyledBox>
        {staleWhileRevalidate({
          status: API_VISITORS_FITMENT_APPROVALS_SHOW_STATUS,
          data: fitment,
          render: renderPageContent,
        })}
      </StyledBox>
      <Modal
        title="Reason"
        closable={false}
        maskClosable={false}
        visible={modalVisible}
        onOk={rejectFitmentApproval}
        okText="Reject"
        confirmLoading={rejectButtonLoading}
        onCancel={() => setModalVisible(false)}
      >
        <Input.TextArea
          allowClear
          autoSize={{ minRows: 4 }}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>
    </div>
  )
}

export default view(FitmentsShowPage)

import { API, generic } from "api"

export default class OfferLetterTemplatesService {
  static index = async ({ pageState, page = 1, pageSize = 25 }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_OFFER_LETTER_TEMPLATES_INDEX_STATUS",
      stateDataKey: [
        "offer_letter_templates",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "offerLetterTemplatesErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.offerLetterTemplates.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize } },
      errorMessage: "Offer Letter Templates could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_OFFER_LETTER_TEMPLATES_CREATE_STATUS",
      stateDataKey: ["offer_letter_template"],
      stateErrorKey: ["offerLetterTemplateErrors"],
      apiEndpoint: API.users.offerLetterTemplates.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Offer Letter Template could not be created.",
    })
  }

  static show = async ({ pageState, offerLetterTemplateId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_OFFER_LETTER_TEMPLATES_SHOW_STATUS",
      stateDataKey: ["offer_letter_template"],
      stateErrorKey: ["offerLetterTemplateErrors"],
      apiEndpoint: API.users.offerLetterTemplates.show,
      apiUrlReplacements: { offerLetterTemplateId },
      apiData: {},
      errorMessage: "Offer Letter Template could not be fetched.",
    })
  }

  static update = async ({ pageState, values, offerLetterTemplateId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_OFFER_LETTER_TEMPLATES_UPDATE_STATUS",
      stateDataKey: ["offer_letter_template"],
      stateErrorKey: ["offerLetterTemplateErrors"],
      apiEndpoint: API.users.offerLetterTemplates.update,
      apiUrlReplacements: { offerLetterTemplateId },
      apiData: { ...values },
      errorMessage: "Offer Letter Template could not be updated.",
    })
  }
  static delete = async ({ pageState, offerLetterTemplateId }) => {
    await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_OFFER_LETTER_TEMPLATES_DELETE_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['offerLetterTemplateErrors'],
			apiEndpoint: API.users.offerLetterTemplates.delete,
			apiUrlReplacements: { offerLetterTemplateId },
			apiData: {},
      errorMessage: 'Offer Letter Template could not be Deleted.',
      serializedResponse : false,
		})
  }

  static refresh = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_OFFER_LETTER_TEMPLATES_REFRESH_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.offerLetterTemplates.refresh,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Offer Letter Template could not be refreshed.",
    })
  }
}

import React, { useState, useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Form, Select, Input, message } from 'antd'

import { staleWhileRevalidate } from 'utils/render-strategies'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import BudgetCriteriaValuesService from 'services/users/budget-criteria-values.service'
import globalStore from 'store'

import StyledBox from 'components/common/styled-components/StyledBox'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledUserEditForm = styled.div``

const BudgetCriteriaValuesNewPage = () => {
	const pageState = globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.new
	const history = useHistory()
	const { budgetCriteriaHeadId } = useParams()
	const [budgetCriteriaValueName, setBudgetCriteriaValueName] = useState('')
	const [creating, setCreating] = useState(false)
	const [parentCriteriaValues, setParentCriteriaValues] = useState([{}])
	const [parentValue, setParentValue] = useState('')
	const [budgetCriteriaHeadName, setBudgetCriteriaHeadName] = useState('')
	// const [parentHeirarchy, setParentHeirarchy] = useState([])
	const [budgetValueName, setBudgetValueName] = useState('')

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.index
			await BudgetCriteriaHeadsService.show({ pageState, budgetCriteriaHeadId })

			const { serverStatus, budgetCriteriaValuesErrors, budget_criteria_head } = pageState

			const { name } = budget_criteria_head

			setBudgetCriteriaHeadName(name)

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetCriteriaValuesErrors) {
				message.error(budgetCriteriaValuesErrors[0] || "Budget Criteria couldn't be fetched.")
				return
			}
		}

		fetchData()
	}, [budgetCriteriaHeadId])

	useEffect(() => {
		const fetchParentData = async () => {
			const pageState =
				globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues
					.parentBudgetCriteriaValue
			await BudgetCriteriaValuesService.parent_budget_criteria_value({
				pageState,
				budgetCriteriaHeadId,
			})

			const { serverStatus, budgetCriteriaValuesErrors, parent_budget_criteria_values } = pageState

			if (parent_budget_criteria_values && parent_budget_criteria_values.length) {
				setBudgetValueName(parent_budget_criteria_values[0].budget_criteria_head_name)
				// setParentHeirarchy(parent_budget_criteria_values[0].parent_heirarchy)
			}

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetCriteriaValuesErrors) {
				message.error(budgetCriteriaValuesErrors[0] || "Budget Criteria couldn't be fetched.")
				return
			}

			setParentCriteriaValues(parent_budget_criteria_values)
		}

		fetchParentData()
	}, [budgetCriteriaHeadId])

	const handleCreate = async () => {
		if (!budgetCriteriaValueName) {
			message.error("Name field can't be blank")
			return
		}
		const tempState = {}
		const postData = {
			budget_criteria_value: {
				name: budgetCriteriaValueName,
				parent_id: parentValue,
			},
		}

		setCreating(true)

		await BudgetCriteriaValuesService.create({
			pageState: tempState,
			values: postData,
			budgetCriteriaHeadId: budgetCriteriaHeadId,
		})
		setCreating(false)

		const { serverStatus, budgetCriteriaValueError, budget_criteria_value } = tempState

		if (serverStatus.status !== 200) {
			message.error(`${budgetCriteriaValueName} couldn't be created`)
			return
		}

		if (budgetCriteriaValueError?.length > 0) {
			message.error(budgetCriteriaValueError[0])
			return
		}
		const budgetCriteriaHeadName = budget_criteria_value.budget_criteria_head_name
		message.success(`${budgetCriteriaHeadName} Hierarchy Value Created.`)
		history.push('/configuration/budget-criteria-heads')
	}

	const handleNameChange = (e) => {
		setBudgetCriteriaValueName(e.target.value)
	}

	const handleParentNameChange = (e) => {
		setParentValue(e)
	}

	const renderPageContent = () => {
		// if (parentHeirarchy[parentHeirarchy.length - 1]) {
		//   setParentValue(Object.values(parentHeirarchy[parentHeirarchy.length - 1]))
		// }
		return (
			<StyledUserEditForm>
				<Form layout='vertical'>
					<Form.Item label={budgetCriteriaHeadName || 'Name'}>
						<Input placeholder='Enter value' onChange={handleNameChange} />
					</Form.Item>
					{parentCriteriaValues && (
						<Form.Item label={budgetValueName || 'Value'}>
							<Select placeholder='Choose value' onChange={(e) => handleParentNameChange(e)}>
								{parentCriteriaValues.map((parentValue) => {
									const key = parentValue.id
									return (
										<Select.Option key={key} value={key}>
											{parentValue.name}
										</Select.Option>
									)
								})}
							</Select>
						</Form.Item>
					)}
					<Form.Item>
						<Button
							type='primary'
							htmlType='submit'
							style={{ cssFloat: 'right' }}
							loading={creating}
							onClick={handleCreate}>
							{creating ? 'Creating...' : 'Create'}
						</Button>
					</Form.Item>
				</Form>
			</StyledUserEditForm>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('criteria-edit'))
		return <Redirect to='/' />

	const { API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_SHOW_STATUS } = pageState

	return (
		<div className='page-content user-page'>
			<StyledBox maxWidth={'500px'}>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_SHOW_STATUS,
					data: { budgetCriteriaValueName },
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetCriteriaValuesNewPage)

import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"

import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"

import { staleWhileRevalidate } from "utils/render-strategies"

import globalStore from "store/index"

import OrganizationsService from "services/admin-users/organizations.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const FitmentsShowPage = () => {
  const history = useHistory()
  const pageState = globalStore.ui.adminUsers.organizations.show

  const { organizationId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.adminUsers.organizations.show

      await OrganizationsService.show({ pageState, organizationId })

      const { organization } = pageState

      if (!organization) {
        message.error("Failed to load organizations")
        return
      }
    }

    fetchData()
  }, [organizationId])

  const handleEdit = () => {
    const { organization } = pageState

    history.push(`/admin-users/organizations/${organization.id}/edit`)
  }

  const renderPageContent = () => {
    const { organization } = pageState

    const { name, address, city, country, industry } = organization

    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Organization
          </Button>
        </div>
        <div className="content">
          {/* <div className="image__box">
          <img src={logo_url} alt={`${name}'s logo`} />
        </div> */}
          <div>
            <strong>Company Name:</strong> {name}
          </div>
          <div>
            <strong>Industry:</strong> {industry ? industry?.name : ""}
          </div>
          <div>
            <strong>Address:</strong> {address}
          </div>
          <div>
            <strong>City:</strong> {city}
          </div>
          <div>
            <strong>Country:</strong> {country ? country.name : ""}
          </div>
        </div>
      </StyledPageContent>
    )
  }

  const { organization, API_ADMIN_USERS_ORGANIZATIONS_SHOW_STATUS } = pageState

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_ADMIN_USERS_ORGANIZATIONS_SHOW_STATUS,
          data: organization,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(FitmentsShowPage)

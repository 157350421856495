import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, Select } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledCriteria = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0px;
	& > .input {
		flex: 1;
	}
	& > .input:nth-child(2) {
		margin: 0px 10px;
		flex: 1;
	}
	& > .action-buttons {
		display: flex;
		justify-content: space-between;
		width: 85px;
		& button {
			margin-left: 10px;
		}
	}
`

const selectOption = [
	{ label: 'Date Format', value: 'Date Format' },
	{ label: 'Round', value: 'Round' },
	{ label: 'Default Frequency', value: 'Default Frequency' },
	{ label: 'Disable Frequency', value: 'Disable Frequency' },
]

const frequencies = [
	{ value: 'hourly', label: 'Hourly' },
	{ value: 'daily', label: 'Daily' },
	{ value: 'bi_weekly', label: 'Bi Weekly' },
	{ value: 'semi_monthly', label: 'Semi Monthly' },
	{ value: 'monthly', label: 'Monthly' },
	{ value: 'quarterly', label: 'Quarterly' },
	{ value: 'half_yearly', label: 'Half-Yearly' },
	{ value: 'annually', label: 'Annually' },
]

const CountryDetailsConditionalRow = ({ field, setField }) => {
	const handleAddNew = () => {
		setField((data) => [...data, { name: null, value: null }])
	}

	const handleRemove = (i) => {
		setField((data) => data.filter((item, index) => i !== index))
	}

	const handleValueChange = (i, value) => {
		const values = [...field]
		values[i].name = value
		setField(values)
	}

	const handleFieldChange = (i, value) => {
		const values = [...field]
		values[i].value = value
		setField(values)
	}

	const getInputType = (i, data) => {
		if (data?.name === 'Default Frequency') {
			return (
				<Select
					style={{ width: '100%' }}
					showSearch
					placeholder='Choose field'
					value={data?.value || null}
					disabled={!data?.name}
					options={frequencies}
					onChange={(value) => handleFieldChange(i, value)}
				/>
			)
		}
		if (data?.name === 'Disable Frequency') {
			return (
				<Select
					style={{ width: '100%' }}
					showSearch
					placeholder='Choose field'
					value={data?.value ?? null}
					disabled={!data?.name}
					options={[
						{ value: true, label: 'Yes' },
						{ value: false, label: 'No' },
					]}
					onChange={(value) => handleFieldChange(i, value)}
				/>
			)
		}

		return (
			<Input
				placeholder="Enter field's value"
				disabled={!data?.name}
				value={data?.value || ''}
				onChange={(e) => handleFieldChange(i, e.target.value)}
			/>
		)
	}

	return (
		<>
			{field.map((data, i) => (
				<StyledCriteria key={`field-${i}`}>
					<div className='input'>
						<Select
							style={{ width: '100%' }}
							showSearch
							placeholder='Choose field'
							value={data?.name ?? null}
							filterOption={(inputValue, option) => {
								if (!option.children) return false
								return option.children.toLowerCase().includes(inputValue.toLowerCase())
									? true
									: false
							}}
							options={selectOption}
							onChange={(value) => handleValueChange(i, value)}
						/>
					</div>
					<div className='input'>{getInputType(i, data)}</div>
					<div className='action-buttons'>
						<div>
							<Button
								danger
								shape='circle'
								disabled={field?.length < 2}
								onClick={() => handleRemove(i)}>
								<DeleteOutlined />
							</Button>
						</div>
						<div>
							<Button
								type='primary'
								disabled={!data.name || data.value === undefined || data.value === null}
								ghost
								shape='circle'
								onClick={handleAddNew}>
								<PlusOutlined />
							</Button>
						</div>
					</div>
				</StyledCriteria>
			))}
		</>
	)
}

export default CountryDetailsConditionalRow

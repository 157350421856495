import React, { useRef } from 'react'
import OrganizationChart from '@dabeng/react-orgchart'
import { useEffect } from 'react'
import LABELS from 'constants/labels'
import StyledBox from 'components/common/styled-components/StyledBox'
import PositionReportingService from 'services/users/position-reportings.service'
import { message, Empty, Select, Modal, Button, Table, Tooltip } from 'antd'
import { view } from '@risingstack/react-easy-state'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import { useState } from 'react'
import styled from 'styled-components'
import ContentLoading from 'components/common/ContentLoading'
import PositionService from 'services/users/positions.service'
import { EyeOutlined } from '@ant-design/icons'
import globalStore from 'store/index'
import { Redirect } from 'react-router-dom'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const OrgChart = () => {
	const [error, setError] = useState(null)
	const [orgChartData, setOrgChartData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [accessGroups, setAccessGroups] = useState(null)
	const [position, setPosition] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []
	const orgChartRef = useRef()
	const orgParentRef = useRef()

	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchPosition = async () => {
			const tempState = {}
			await PositionService.workforcePositions({ pageState: tempState })
			const { access_groups } = tempState
			setAccessGroups(access_groups)
		}
		fetchPosition()
		fetchOrgChartData()
	}, [])

	useEffect(() => {
		const container = orgParentRef.current
		if (container) container.scrollLeft = (container.scrollWidth - container.clientWidth) / 2
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgParentRef.current])

	const fetchOrgChartData = async (positionId) => {
		const tempState = {}
		const postData = {
			position_id: positionId,
		}
		setLoading(true)
		await PositionReportingService.orgCharts({ pageState: tempState, postData })
		setLoading(false)
		const { serverStatus, dataErrors } = tempState
		if (serverStatus.status !== 200) {
			message.error('Oops!! something went wrong')
			return
		}

		if (dataErrors) {
			message.error(dataErrors[0] || "Org Chart couldn't be fetched.")
			if (dataErrors[0] === 'User not authorized or Position not present!') setError(dataErrors[0])
			setOrgChartData(null)
			return
		}
		const { org_chart_data } = tempState
		setError(null)
		setOrgChartData(org_chart_data)
	}

	const showModal = (position) => {
		setPosition(position)
		setIsModalVisible(true)
	}

	const handleOk = () => {
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	const columns = [
		{
			title: 'Position competencies',
			dataIndex: 'position',
			key: 'position',
		},
		{
			title: 'Required competencies Score',
			dataIndex: 'required',
			key: 'required',
			align: 'center',
		},
		{
			title: 'Available employee competencies Score',
			dataIndex: 'available',
			key: 'available',
			align: 'center',
		},
		{
			title: 'Gap',
			dataIndex: 'gap',
			key: 'gap',
			align: 'center',
		},
	]

	const data = () => {
		const first = [
			{ key: '1', position: 'Negotiation Skills', required: '5', available: '3', gap: '2' },
			{ key: '2', position: 'Problem Solving Skills', required: '4', available: '3', gap: '1' },
			{ key: '3', position: 'Problem Solving Skills', required: '3', available: '3', gap: '0' },
		]
		const second = [
			{ key: '1', position: 'Problem Solving Skills', required: '5', available: '2', gap: '3' },
			{ key: '2', position: 'Negotiation Skills', required: '4', available: '3', gap: '1' },
			{ key: '3', position: 'Effective Communication', required: '4', available: '2', gap: '2' },
		]
		const third = [
			{ key: '1', position: 'Customer Orientation', required: '4', available: '1', gap: '3' },
			{ key: '2', position: 'Decision Making', required: '5', available: '1', gap: '4' },
		]
		const fourth = [
			{ key: '1', position: 'Effective Communication', required: '4', available: '1', gap: '3' },
			{ key: '2', position: 'Problem Solving Skills', required: '5', available: '1', gap: '4' },
			{ key: '3', position: 'Customer Orientation', required: '3', available: '3', gap: '0' },
		]
		if (position === 'P-1013') {
			return first
		}
		if (position === 'P-1007' || position === 'R1212') {
			return second
		}
		if (position === 'P-1012') {
			return third
		}
		if (position === 'P-1000') {
			return fourth
		}
	}

	const card = ({ nodeData }) => {
		return (
			<div
				onClick={() => fetchOrgChartData(nodeData?.position_id)}
				style={{
					borderRadius: 4,
					border: `1px solid ${
						nodeData?.employee_code && nodeData?.employee_name
							? '#1AB394'
							: 'rgba(217, 83, 79, 0.8)'
					}`,
					backgroundColor: 'white',
					width: 250,
					textAlign: 'center',
				}}>
				<div
					style={{
						backgroundColor: nodeData?.employee_code ? '#1AB394' : 'rgba(217, 83, 79, 0.8)',
						color: 'white',
						padding: '5px 0',
						display: 'flex',
					}}>
					<div style={{ flexGrow: 1 }}>
						<div>
							<strong>Position Code :&nbsp;</strong>
							<strong>{nodeData?.position_code || '--'}</strong>
						</div>
						<div>
							<strong>Position Name :&nbsp;</strong>
							<strong>{nodeData?.position_name || '--'}</strong>
						</div>
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Button
							style={{ color: 'white' }}
							type='text'
							onClick={(e) => {
								e.stopPropagation()
								showModal(nodeData?.position_code)
							}}>
							<EyeOutlined />
						</Button>
					</div>
				</div>
				<div>
					<strong>Name :&nbsp;</strong>
					{nodeData?.employee_name || '--'}
				</div>
				<div>
					<strong>Employee Code :&nbsp;</strong>
					{nodeData?.employee_code || '--'}
				</div>
				{nodeData?.essentials_fields?.flat()?.map((data, index) => (
					<div key={index}>
						<strong>{data?.field} :&nbsp;</strong>
						{data?.values.flat().toString() || '--'}
					</div>
				))}
			</div>
		)
	}

	const renderContent = () => {
		if (loading) {
			return <ContentLoading />
		}
		return (
			<>
				{!error && orgChartData ? (
					<>
						<div
							ref={orgParentRef}
							style={{
								overflow: 'auto',
								border: '2px dashed #aaa',
								borderRadius: '5px',
								marginTop: 10,
							}}>
							<OrganizationChart
								ref={orgChartRef}
								datasource={orgChartData}
								NodeTemplate={card}
								pan={true}
								zoom={true}
								zoomoutLimit={0.3}
							/>
						</div>
						<Modal
							title={`Position Code: ${position}`}
							visible={isModalVisible}
							width={1000}
							onOk={handleOk}
							onCancel={handleCancel}
							footer={[
								<Button key='back' onClick={handleCancel}>
									Close
								</Button>,
							]}>
							<Table columns={columns} dataSource={data()} />
						</Modal>
					</>
				) : (
					<Empty
						description={error || 'No data Found'}
						style={{
							height: 500,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					/>
				)}
			</>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					{accessibleFeatures?.length > 0 && accessibleFeatures.includes('wfm-position-view') && (
						<Select
							allowClear
							showSearch
							filterOption={(inputValue, option) =>
								option.children.toString().toLowerCase().includes(inputValue.toLowerCase())
							}
							style={{ width: 180 }}
							placeholder='Enter Position Code'
							onChange={(value) => fetchOrgChartData(value)}>
							{accessGroups &&
								accessGroups.map((data, index) => (
									<Select.Option key={index} value={data?.id}>
										{data?.position_code}
									</Select.Option>
								))}
						</Select>
					)}
					<div className='action-buttons top'>
						{!error && orgChartData && (
							<Tooltip title='Export Org Chart'>
								<Button
									onClick={() => orgChartRef.current.exportTo('org_chart', 'pdf')}
									style={{ marginRight: 10 }}>
									Export Org Chart
								</Button>
							</Tooltip>
						)}
					</div>
				</div>
				{renderContent()}
			</StyledPageContent>
		)
	}

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('organization-chart-view'))
		return <Redirect to='/' />

	return (
		<div className='page-content'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(OrgChart)

import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Form, Input, message } from 'antd'

import { staleWhileRevalidate } from 'utils/render-strategies'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import globalStore from 'store'

import StyledBox from 'components/common/styled-components/StyledBox'
import BudgetHeadSteps from 'components/BudgetHeadSteps'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledUserEditForm = styled.div``

const BudgetCriteriaHeadsNewPage = () => {
	const pageState = globalStore.ui.configuration.budgetCriteriaHeads.new
	const history = useHistory()
	const [budgetCriteriaHeadName, setBudgetCriteriaHeadName] = useState('')
	const [creating, setCreating] = useState(false)
	const [steps, setSteps] = useState([{ id: '', name: '' }])

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}

			await BudgetCriteriaHeadsService.index({ pageState: tempState })

			const serverStatus = tempState.serverStatus
			// const budgetCriteriaHead = tempState.budget_criteria_head

			// const { serverStatus } = tempState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		if (steps.length > 0) {
			setSteps(steps)
		}
	}, [steps])

	const handleCreate = async () => {
		if (!budgetCriteriaHeadName) {
			message.error("Name field can't be blank")
			return
		}
		const tempState = {}
		const postData = {
			budget_criteria_head: {
				name: budgetCriteriaHeadName,
				steps,
			},
		}

		setCreating(true)
		await BudgetCriteriaHeadsService.create({
			pageState: tempState,
			values: postData,
		})
		setCreating(false)

		const { serverStatus, budgetCriteriaHeadError } = tempState

		if (serverStatus.status !== 200) {
			message.error(`${budgetCriteriaHeadName} Hierarchy couldn't be created`)
			return
		}

		if (budgetCriteriaHeadError?.length > 0) {
			message.error(budgetCriteriaHeadError[0])
			return
		}

		message.success(`${budgetCriteriaHeadName} Hierarchy created.`)
		history.push('/configuration/budget-criteria-heads')
	}

	const handleNameChange = (e) => {
		setBudgetCriteriaHeadName(e.target.value)
	}

	const handleInputChange = (i, event) => {
		const values = [...steps]
		values[i].name = event.target.value
		setSteps(values)
	}

	const handleAddStep = () => {
		const stepsCopy = JSON.parse(JSON.stringify(steps))
		setSteps([...stepsCopy, { name: '' }])
	}
	const handleDeleteStep = (index) => {
		const stepsCopy = JSON.parse(JSON.stringify(steps))
		stepsCopy.splice(index, 1)
		setSteps(stepsCopy)
	}

	const renderPageContent = () => {
		// const selectedOrganizationRoleIds = organization_roles.map((sor) => sor.id)

		return (
			<StyledUserEditForm>
				<Form layout='vertical'>
					<Form.Item label='Name'>
						<Input
							placeholder='Enter name'
							value={budgetCriteriaHeadName}
							onChange={handleNameChange}
						/>
					</Form.Item>
					<BudgetHeadSteps
						headSteps={steps}
						onInputChange={handleInputChange}
						onAddStep={handleAddStep}
						onDeleteStep={handleDeleteStep}
						showHierarchy={true}
					/>
					<Form.Item>
						<Button
							type='primary'
							htmlType='submit'
							style={{ cssFloat: 'right' }}
							loading={creating}
							onClick={handleCreate}>
							{creating ? 'Creating...' : 'Create'}
						</Button>
					</Form.Item>
				</Form>
			</StyledUserEditForm>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('criteria-edit'))
		return <Redirect to='/' />

	const { API_BUDGET_CRITERIA_HEADS_SHOW_STATUS } = pageState

	return (
		<div className='page-content user-page'>
			<StyledBox maxWidth={'500px'}>
				{staleWhileRevalidate({
					status: API_BUDGET_CRITERIA_HEADS_SHOW_STATUS,
					data: { budgetCriteriaHeadName, steps },
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetCriteriaHeadsNewPage)

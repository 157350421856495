import React, { useState } from 'react'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Input, message, Row, Col, DatePicker, Select, Space } from 'antd'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'

import StyledBox from 'components/common/styled-components/StyledBox'
import globalStore from 'store/index'
import moment from 'moment'

import WorkForcePeriodService from 'services/users/workforce-period.service'
import { useEffect } from 'react'

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const WorkForcePeriodEdit = () => {
	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})
	const { workforcePeriodID } = useParams()
	const pageState = globalStore.ui.configuration.workforcePeriod.edit
	const history = useHistory()

	useEffect(() => {
		const fetchBudget = async () => {
			const pageState = globalStore.ui.configuration.workforcePeriod.edit
			await WorkForcePeriodService.show({ pageState, workforcePeriodID })

			const { serverStatus, workforcePeriodsErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (workforcePeriodsErrors) {
				message.error(workforcePeriodsErrors[0] || "WorkForce Period couldn't be fetched.")
				return
			}
			const { workforce_period } = pageState
			const { name, start_date, end_date, current } = workforce_period
			setInputValues({ name, start_date, end_date, current })
		}

		fetchBudget()
	}, [workforcePeriodID])

	const handleInputChange = (e, inputType) => {
		if (inputType === 'date') {
			setInputValues({ ...inputValues, start_date: e[0], end_date: e[1] })
			return
		}

		if (inputType === 'current') {
			setInputValues({ ...inputValues, [inputType]: e })
			return
		}

		setInputValues({ ...inputValues, [inputType]: e.target.value })
	}

	const handleUpdate = async () => {
		if (!inputValues['name']) {
			message.error("Name can't be blank")
			return
		}

		if (!inputValues['start_date'] && !inputValues['end_date']) {
			message.error("Date can't be blank")
			return
		}
		const tempState = {}
		const postData = {
			workforce_period: {
				...inputValues,
			},
		}

		setCreateButtonLoading(true)
		await WorkForcePeriodService.update({
			pageState: tempState,
			values: postData,
			workforcePeriodID,
		})
		setCreateButtonLoading(false)

		const { serverStatus, budgetErrors } = tempState

		if (budgetErrors) {
			message.error(budgetErrors[0] || "Workforce Period couldn't be created.")
			return
		}

		if (serverStatus.status === 200) {
			message.success('Workforce Period Created.')
			history.push(`/configuration/workforce-period/${workforcePeriodID}`)
			return
		}

		message.error('Oops!! something went wrong')
	}

	const { workforce_period, API_USERS_WORKFORCE_PERIOD_SHOW_STATUS } = pageState

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<h1>Workforce Period</h1>
				<Row gutter={20}>
					<Col span={24}>
						<div className='input-box'>
							<strong>Name</strong>
							<Input value={inputValues?.name} onChange={(e) => handleInputChange(e, 'name')} />
						</div>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={10}>
						<div className='input-box'>
							<Space align='center'>
								<strong>Date :</strong>
								<DatePicker.RangePicker
									value={[moment.utc(inputValues.start_date), moment.utc(inputValues.end_date)]}
									format='DD-MMM-YYYY'
									onChange={(e) => handleInputChange(e, 'date')}
								/>
							</Space>
						</div>
					</Col>
					<Col span={10}>
						<Space align='center' style={{ marginTop: 10 }}>
							<strong>Current :</strong>
							<Select
								defaultValue={false}
								value={inputValues?.current}
								placeholder='Select option'
								onChange={(e) => handleInputChange(e, 'current')}>
								<Select.Option value={true}>True</Select.Option>
								<Select.Option value={false}>False</Select.Option>
							</Select>
						</Space>
					</Col>
				</Row>

				<div className='action-buttons submit'>
					<Button type='primary' loading={createButtonLoading} onClick={handleUpdate}>
						Update WorkForce Budget
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	const strategy =
		workforce_period && workforce_period.id === workforcePeriodID
			? staleWhileRevalidate
			: networkOnly

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-period-edit'))
		return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_WORKFORCE_PERIOD_SHOW_STATUS,
					data: workforce_period,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(WorkForcePeriodEdit)

import { API, generic } from 'api'

export default class BudgetApprovalsService {
	static approve = async ({ pageState, budgetTentativeValueId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_APPROVAL_APPROVE_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalErrors'],
			apiEndpoint: API.users.budgetApprovals.approve,
			apiUrlReplacements: { budgetTentativeValueId },
			apiData: {},
			errorMessage: 'Budget Tentative Assign Approval Chain could not be submitted.',
		})
	}
	static reject = async ({ pageState, budgetTentativeValueId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_APPROVAL_REJECT_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalValuesErrors'],
			apiEndpoint: API.users.budgetApprovals.reject,
			apiUrlReplacements: { budgetTentativeValueId },
			apiData: { ...values },
			errorMessage: 'Budget Tentative Assign Approval Chain could not be submitted.',
		})
	}
	static pendingApprovals = async ({ pageState, page, pageSize }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_PENDING_APPROVAL_STATUS',
			stateDataKey: ['budget_approvals'],
			stateErrorKey: ['budgetApprovalsValuesErrors'],
			apiEndpoint: API.users.budgetApprovals.pendingApprovals,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize } },
			errorMessage: 'Pending Approval Chain could not be fetched.',
		})
	}
	static show = async ({ pageState, pendingApprovalId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_PENDING_APPROVAL_SHOW_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalValuesErrors'],
			apiEndpoint: API.users.budgetApprovals.show,
			apiUrlReplacements: { pendingApprovalId },
			apiData: {},
			errorMessage: 'Pending Approval Chain could not be fetched.',
		})
	}
	static comment = async ({ pageState, pendingApprovalId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_PENDING_APPROVAL_COMMENT_STATUS',
			stateDataKey: ['budget_approval'],
			stateErrorKey: ['budgetApprovalValuesErrors'],
			apiEndpoint: API.users.budgetApprovals.comment,
			apiUrlReplacements: { pendingApprovalId },
			apiData: { ...values },
			errorMessage: 'Pending Approval Chain could not be fetched.',
		})
	}
}

import { API, generic } from "api"

export default class DocumentsService {
  static index = async ({ pageState, page = 1, pageSize = 25 }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DOCUMENTS_INDEX_STATUS",
      stateDataKey: [
        "documents",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "documentErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.documents.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize } },
      errorMessage: "Documents could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DOCUMENTS_CREATE_STATUS",
      stateDataKey: ["documents"],
      stateErrorKey: ["documentErrors"],
      apiEndpoint: API.users.documents.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Document could not be created.",
    })
  }

  static show = async ({ pageState, documentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DOCUMENTS_SHOW_STATUS",
      stateDataKey: ["document"],
      stateErrorKey: ["documentErrors"],
      apiEndpoint: API.users.documents.show,
      apiUrlReplacements: { documentId },
      apiData: {},
      errorMessage: "Document could not be fetched.",
    })
  }

  static update = async ({ pageState, documentId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DOCUMENTS_UPDATE_STATUS",
      stateDataKey: ["document"],
      stateErrorKey: ["documentErrors"],
      apiEndpoint: API.users.documents.update,
      apiUrlReplacements: { documentId },
      apiData: { ...values },
      errorMessage: "Document could not be updated.",
    })
  }

  static delete = async ({ pageState, documentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DOCUMENT_DELETE_STATUS",
      stateDataKey: ["document"],
      stateErrorKey: ["documentErrors"],
      apiEndpoint: API.users.documents.delete,
      apiUrlReplacements: { documentId },
      apiData: {},
      errorMessage: "Document could not be deleted.",
    })
  }

  static download = async ({ pageState, documentUuid }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DOCUMENTS_DOWNLOAD_STATUS",
      stateDataKey: ["redirect_url"],
      stateErrorKey: ["redirectUrlErrors"],
      apiEndpoint: API.users.documents.download,
      apiUrlReplacements: { documentUuid },
      apiData: {},
      errorMessage: "Document could not be downloaded.",
    })
  }
}

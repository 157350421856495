import React, { useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { Form, Input, Button, message } from "antd"

import globalStore from "store/index"
import UsersService from "services/users/users.service"
import StyledLoginPageContent from "components/common/styled-components/StyledLoginPageContent"
import * as AuthHelper from "helpers/auth.helper"

const ChangePasswordPage = (props) => {
  // const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const onFinish = async (values) => {
    const tempState = {}

    const postData = {
      user: {
        ...values,
      },
    }

    setSubmitting(true)
    await UsersService.resetPassword({ pageState: tempState, values: postData })
    setSubmitting(false)

    const { serverStatus, userErrors } = tempState

    if (serverStatus && serverStatus.status === 201) {
      globalStore.currentUser = null
      message.success("Password updated")
      AuthHelper.logout()
		  window.location.reload()
      return
    }

    message.error(
      (userErrors && userErrors[0]) || "Oops!! something went wrong"
    )
  }

  return (
    <div className="page-content change-password-page">
      <StyledLoginPageContent>
        <div className="form__box">
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Current Password"
              name="current_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your current password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter your current password" />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="password"
              rules={[
                { 
                  required: true,
                  message: "Please enter new password!" 
                },
                {
                  pattern:/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 
                  message: "Must contain at least one number ,symbol, uppercase and lowercase letter and at least 8 or more characters"
                }
              ]}
            >
              <Input.Password placeholder="Enter new password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="password_confirmation"
              rules={[
                {
                  required: true,
                  message: "Please enter password confirmation!",
                },
                {
                  pattern:/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 
                  message: "Must contain at least one number ,symbol, uppercase and lowercase letter and at least 8 or more characters"
                }
              ]}
            >
              <Input.Password placeholder="Enter password confirmation" />
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Update Password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledLoginPageContent>
    </div>
  )
}

export default view(ChangePasswordPage)

const changeTitle = (newTitle) => {
	const pageTitle = document.getElementsByTagName('title')[0]
	pageTitle.innerText = newTitle
}
const setHireReviewFavicon = () => {
	changeFavicon('hirereview')
}
const setSmartBudgetsFavicon = () => {
	changeFavicon('smartbudgets')
}

const setWorkforceFavicon = () => {
	changeFavicon('workforce')
}

const changeFavicon = (faviconName) => {
	let favIconImageName

	if (faviconName === 'hirereview') {
		favIconImageName = 'favicon'
	} else if (faviconName === 'workforce') {
		favIconImageName = 'workforce'
	} else {
		favIconImageName = 'smartbudgets'
	}

	var link = document.querySelector("link[rel~='icon']")
	if (!link) {
		link = document.createElement('link')
		link.rel = 'icon'
		document.getElementsByTagName('head')[0].appendChild(link)
	}
	link.href = `${process.env.PUBLIC_URL}/${favIconImageName}.png?v=1`
}

export { changeTitle, setSmartBudgetsFavicon, setWorkforceFavicon, setHireReviewFavicon }

import { API, generic } from "api"

export default class ExportsService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_EXPORTS_INDEX_STATUS",
      stateDataKey: ["exports"],
      stateErrorKey: ["exportsErrors"],
      apiEndpoint: API.users.exports.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Exports could not be fetched.",
    })
  }

  static download = async ({ pageState, exportId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_EXPORTS_DOWNLOAD_STATUS",
      stateDataKey: ["redirect_url"],
      stateErrorKey: ["redirectUrlErrors"],
      apiEndpoint: API.users.exports.download,
      apiUrlReplacements: { exportId },
      apiData: {},
      errorMessage: "Export could not be downloaded.",
    })
  }
}

import { API, generic } from "api"

export default class OffersService {
  static download = async ({ pageState, offerUuid }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_OFFERS_DOWNLOAD_STATUS",
      stateDataKey: ["redirect_url"],
      stateErrorKey: ["redirectUrlErrors"],
      apiEndpoint: API.users.offers.download,
      apiUrlReplacements: { offerUuid },
      apiData: {},
      errorMessage: "Offer letter could not be downloaded.",
    })
  }
  static docusignDownload = async ({ pageState, offerUuid }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DOCUSIGN_OFFERS_DOWNLOAD_STATUS",
      stateDataKey: ["redirect_url"],
      stateErrorKey: ["redirectUrlErrors"],
      apiEndpoint: API.users.offers.docusignDownload,
      apiUrlReplacements: { offerUuid },
      apiData: {},
      errorMessage: "Docusign Offer letter could not be downloaded.",
    })
  }
}

import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { message, Button, Tooltip } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import CriteriaSet from 'components/CriteriaSet'
import dayjs from 'dayjs'

import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import { Color } from 'constants/theme'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import BudgetTentativeValuesService from 'services/users/budget-tentative-values.service'
import ApprovalChainStatusesBox from 'components/ApprovalChainStatusesBox'
import ApprovalChainsService from 'services/users/approval-chains.service'
import ApplicableApprovalChains from 'components/ApplicableApprovalChains'
import Comments from 'components/Comments'
import { statusColor } from 'helpers/status-color.helper'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`

const StyledItemList = styled.div`
	& > div {
		margin-bottom: 50px;
	}

	& .item-row {
		display: flex;
		margin-bottom: 5px;
		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			max-width: 250px;
			justify-content: flex-end;
			text-transform: uppercase;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
		}
	}
	& .colored.item-row {
		& div:last-child {
			background: #f9f3ff;
			text-transform: capitalize;
		}
	}
`

const AdminBudgetApprovalStatusShowPage = () => {
	const pageState = globalStore.ui.configuration.budgetTentativeValues.show
	const history = useHistory()
	const { budgetTentativeValueId } = useParams()
	// const [isApprover, setIsApprover] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const [budgetTentativeValues, setBudgetTentativeValues] = useState()
	const [tentativeData, setTentativeData] = useState()
	const [commentButtonLoading, setCommentButtonLoading] = useState(false)
	const location = useLocation()

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetValue = async () => {
			const pageState = globalStore.ui.configuration.budgetTentativeValues.show

			await BudgetTentativeValuesService.show({ pageState, budgetTentativeValueId })
			const { serverStatus, budgetValueErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetValueErrors) {
				message.error(budgetValueErrors[0] || "Coundn't fetch Budget Value")
				return
			}
			const { budget_tentative_values } = pageState

			setBudgetTentativeValues(budget_tentative_values)
			const tentativeData =
				budget_tentative_values?.tentative_data[
					budget_tentative_values?.budget_type === 'planned' ? 'budget_value' : 'budget_utilization'
				]
			setTentativeData(tentativeData)
		}

		fetchBudgetValue()
	}, [budgetTentativeValueId])

	const handleRefreshApprovalChain = async () => {
		if (!budgetTentativeValueId) {
			message.error("Budget Tentative Value can't be empty")
			return
		}

		const pageState = globalStore.ui.configuration.budgetTentativeValues.show
		let postData = {
			budget_tentative_value_id: budgetTentativeValueId,
		}

		await ApprovalChainsService.refreshBudgetTentativeValue({ pageState, values: postData })
		const { budget_head, budgetHeadErrors } = pageState

		if (!budget_head) {
			message.error('Approval Chain not found')
			return
		}

		if (budgetHeadErrors) {
			message.error(budgetHeadErrors[0] || "Approval chains couldn't be refreshed.")
			return
		}

		// setBudgetTentativeValues(budget_head)
	}

	const handleSubmitForApproval = async () => {
		const tempState = {}
		setIsSubmitButtonLoading(true)
		await BudgetTentativeValuesService.assignApprovalChain({
			pageState: tempState,
			budgetTentativeValueId,
		})
		const { budgetTentativeValuesErrors } = tempState

		if (budgetTentativeValuesErrors) {
			message.error(budgetTentativeValuesErrors[0] || "Couldn't fetch Budget Value")
			setIsSubmitButtonLoading(false)
			return
		}
		const { budget_tentative_values } = tempState

		setBudgetTentativeValues(budget_tentative_values)
		setIsSubmitButtonLoading(false)
	}

	const handleComment = async (comment) => {
		if (!comment) {
			message.error("Comment couldn't be blank")
			return
		}

		const tempState = {}

		const postData = {
			budget_tentative_value: {
				comment,
			},
		}

		setCommentButtonLoading(true)
		await BudgetTentativeValuesService.comment({
			pageState: tempState,
			budgetTentativeValueId,
			values: postData,
		})
		setCommentButtonLoading(false)

		const { budget_tentative_values, budgetTentativeValuesErrors } = tempState

		if (budgetTentativeValuesErrors) {
			message.error(budgetTentativeValuesErrors[0] || "Comment couldn't be submitted.")
			return
		}

		message.success('Comment submitted.')
		setBudgetTentativeValues(budget_tentative_values)
	}

	const handleEdit = () => {
		if (!budgetTentativeValueId) return null
		if (
			location.pathname ===
			`/configuration/admin-workforce-approval-status/${budgetTentativeValueId}`
		) {
			history.push(`/configuration/admin-workforce-approval-status/${budgetTentativeValueId}/edit`)
			return
		}
		history.push(`/configuration/admin-budget-approval-status/${budgetTentativeValueId}/edit`)
	}

	const { budget_tentative_values, API_USERS_BUDGET_TENTATIVE_VALUES_SHOW_STATUS } = pageState

	const renderContent = () => {
		if (!budget_tentative_values) return null

		return (
			<div>
				<StyledItemList>
					<ApplicableApprovalChains
						approvalChains={budgetTentativeValues?.applicable_chains_details}
					/>
					<ApprovalChainStatusesBox
						approvalsId={budgetTentativeValueId}
						useApi='tentative'
						showRefresh={true}
						approvalChainStatuses={budgetTentativeValues?.budget_approval_statuses}
						refresh={handleRefreshApprovalChain}
						onSetData={setBudgetTentativeValues}
					/>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						{/* {isApprover ? (
							<div className='action-buttons submit'>
								<Button
									type='primary'
									loading={isSubmitButtonLoading}
									onClick={handleSubmitForApproval}
									style={{ marginTop: '10px' }}>
									Approve
								</Button>
							</div>
						) : ( */}
						<div className='action-buttons submit'>
							{budgetTentativeValues &&
							budgetTentativeValues['budget_approval_statuses'].length === 0 &&
							!['in_progress', 'rejected', 'approved'].includes(budgetTentativeValues?.status) ? (
								<Button
									type='primary'
									loading={isSubmitButtonLoading}
									onClick={handleSubmitForApproval}
									style={{ marginTop: '10px' }}>
									Submit For Approval
								</Button>
							) : (
								''
							)}
						</div>
					</div>
					<div>
						<div className='item-row colored'>
							<div className='label'>Budget Name</div>
							<div>{budgetTentativeValues?.budget_head?.name}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Budget Approval Type</div>
							<div className='text-capitalize'>{budgetTentativeValues?.budget_type}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Status</div>
							<div className='text-capitalize'>
								<Tooltip
									title={
										budgetTentativeValues?.status &&
										budgetTentativeValues?.status.split('_').join(' ')
									}>
									<div
										style={{
											background: statusColor(budgetTentativeValues?.status),
											width: 10,
											height: 10,
											borderRadius: '50%',
											margin: '0 5px',
										}}></div>
								</Tooltip>
								{budgetTentativeValues?.status &&
									budgetTentativeValues?.status.split('_').join(' ')}
							</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Currency</div>
							<div>{tentativeData?.currency}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Count</div>
							<div>{tentativeData?.unit}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Rate</div>
							<div>{tentativeData?.rate}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Frequency</div>
							<div>
								{tentativeData?.frequency?.charAt(0).toUpperCase() +
									tentativeData?.frequency?.slice(1)}
							</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Total Cost</div>
							<div>{tentativeData?.total_cost}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Start Date</div>
							<div>{dayjs(tentativeData?.start_date).format('DD-MMM-YYYY')}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'> End Date </div>
							<div>{dayjs(tentativeData?.end_date).format('DD-MMM-YYYY')}</div>
						</div>
						{tentativeData?.budget_type && (
							<div className='item-row colored'>
								<div className='label'> Budget Type </div>
								<div>{tentativeData?.budget_type}</div>
							</div>
						)}
						{tentativeData?.position_type && (
							<div className='item-row colored'>
								<div className='label'> Position Type </div>
								<div>{tentativeData?.position_type}</div>
							</div>
						)}
						{tentativeData?.position_code && (
							<div className='item-row colored'>
								<div className='label'> Position Code </div>
								<div>{tentativeData?.position_code}</div>
							</div>
						)}
						{tentativeData?.position_name && (
							<div className='item-row colored'>
								<div className='label'> Position Name </div>
								<div>{tentativeData?.position_name}</div>
							</div>
						)}
						{tentativeData?.employee_code && (
							<div className='item-row colored'>
								<div className='label'> Employee Code </div>
								<div>{tentativeData?.employee_code}</div>
							</div>
						)}
						{tentativeData?.employee_name && (
							<div className='item-row colored'>
								<div className='label'> Employee Name </div>
								<div>{tentativeData?.employee_name}</div>
							</div>
						)}
						{tentativeData?.allocation_percentage && (
							<div className='item-row colored'>
								<div className='label'> Allocation Percentage </div>
								<div>{tentativeData?.allocation_percentage}</div>
							</div>
						)}
						{tentativeData?.increment_percentage && (
							<div className='item-row colored'>
								<div className='label'> Increment Percentage </div>
								<div>{tentativeData?.increment_percentage}</div>
							</div>
						)}
					</div>
				</StyledItemList>
				<CriteriaSet criteria={tentativeData?.criteria || []} />
				<Comments
					comments={budgetTentativeValues?.comments || []}
					commentButtonLoading={commentButtonLoading}
					commentAble
					onComment={handleComment}
				/>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Button type='primary' ghost onClick={handleEdit}>
						Edit
					</Button>
				</div>
				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	const strategy =
		budget_tentative_values && budget_tentative_values.id === budgetTentativeValueId
			? staleWhileRevalidate
			: networkOnly

	return (
		<div className='page-content budget-values-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_BUDGET_TENTATIVE_VALUES_SHOW_STATUS,
					data: budget_tentative_values,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(AdminBudgetApprovalStatusShowPage)

import { view } from "@risingstack/react-easy-state"
import React from "react"
import styled from "styled-components"
import { Input } from "antd"
import { BulbOutlined } from "@ant-design/icons"

const StyledConfigurationFormBox = styled.div`
  & .ant-input[disabled] {
    color: #505050;
  }
`

const Insight = ({
	insight,
	insightStatus
}) => {
	const statusColor = insightStatus === "warning" ? "orange" : insightStatus === "error" ? "red" : "green"
	return (
		<StyledConfigurationFormBox>
			<div className="input-box" style={{ backgroundColor: "red" }}>
				<Input
					type="text"
					disabled="disabled"
					value={insight}
					prefix={<BulbOutlined />}
					style={{ color: statusColor, backgroundColor: "#fdfdfd" }}
				/>
			</div>
		</StyledConfigurationFormBox>
	)
}

export default view(Insight)

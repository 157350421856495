import styled from "styled-components"

const StyledPageContent = styled.div`
  .page-header {
    display: flex;
    justify-content: space-between;
    & > .action-buttons.top {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      & > button {
        margin-right: 10px;
      }
    }
  }
`
export default StyledPageContent

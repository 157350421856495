import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
	message,
	Button,
	Empty,
	// Pagination,
	Table,
	List,
	Input,
	Tooltip,
	Popconfirm,
	Modal,
} from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import FitmentFieldsConfigurationDND from 'components/FitmentFieldsConfigurationDND'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import FitmentFieldsConfigurationService from 'services/users/fitment-fields-configurations.service'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const FitmentFieldsConfigurationIndexPage = (props) => {
	const history = useHistory()
	const pageState = globalStore.ui.fitmentFieldsConfigurations.index
	const [showOrderModal, setShowOrderModal] = useState(false)
	const [orderIds, setOrderIds] = useState([])
	const [updateOrderLoading, setUpdateOrderLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [tablePageSize, setTablePageSize] = useState(10)
	const [jobFamily, setJobFamily] = useState(null)

	useEffect(() => {
		const fetchFitmentFieldsConfigurations = async () => {
			const pageState = globalStore.ui.fitmentFieldsConfigurations.index
			const prevPath = history?.location?.state?.prevPath
			const prevPathArray = prevPath?.split('/')?.filter((n) => !!n)
			const showPathCheck = prevPathArray?.includes("configuration") && prevPathArray?.includes("fitment-fields") && prevPathArray?.length === 3
			if (!showPathCheck)
			{
				globalStore.ui.fitmentFieldsConfigurations.index.defaultPage ={}
				}
			const { jobFamily, page, tablePageSize } = pageState?.defaultPage
			setPage(page || 1)
			setTablePageSize(tablePageSize || 10)
			setJobFamily(jobFamily)
			await FitmentFieldsConfigurationService.index({
				pageState,
				page,
				pageSize,
				jobFamily,
			})

			const { serverStatus, fitmentFieldsConfigurationErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (fitmentFieldsConfigurationErrors) {
				message.error(
					fitmentFieldsConfigurationErrors[0] || "Couldn't fetch fitment fields configuration"
				)
				return
			}
		}

		fetchFitmentFieldsConfigurations()
		    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const repaginate = async (page, pageSize, jobFamily) => {
		if (jobFamily) {
			setJobFamily(jobFamily)
			await FitmentFieldsConfigurationService.index({
				pageState,
				page,
				pageSize,
				jobFamily,
			})
			return
		}

		await FitmentFieldsConfigurationService.index({ pageState, page, pageSize })
	}

	const handleCreate = () => {
		history.push(`/configuration/fitment-fields/new`)
	}

	const handleCardItemClick = (fitmentFieldsConfigurationId) => {
		globalStore.ui.fitmentFieldsConfigurations.index.defaultPage = {
			page,
			tablePageSize,
			jobFamily,
		}
		history.push(`/configuration/fitment-fields/${fitmentFieldsConfigurationId}`)
	}

	const handleEdit = (fitmentFieldsConfigurationId) => {
		globalStore.ui.fitmentFieldsConfigurations.index.defaultPage = {
			page,
			tablePageSize,
			jobFamily,
		}
		history.push(`/configuration/fitment-fields/${fitmentFieldsConfigurationId}/edit`)
	}

	const handleDelete = async (fitmentFieldsConfigurationId) => {
		const tempState = {}

		await FitmentFieldsConfigurationService.delete({
			pageState: tempState,
			fitmentFieldsConfigurationId,
		})

		const { serverStatus } = tempState

		if (serverStatus.status !== 200) return

		globalStore.ui.fitmentFieldsConfigurations.index = {
			...globalStore.ui.fitmentFieldsConfigurations.index,
			fitment_fields_configuration: fitment_fields_configuration.filter(
				(fitment_fields_configuration) =>
					fitment_fields_configuration.id !== fitmentFieldsConfigurationId
			),
		}
	}

	const handleUpdateOrder = async () => {
		const tempState = {}

		setUpdateOrderLoading(true)
		await FitmentFieldsConfigurationService.sortOrder({
			pageState: tempState,
			values: orderIds,
		})
		setUpdateOrderLoading(false)

		const { serverStatus, fitmentFieldsConfigurationErrors, fitment_fields_configuration } =
			tempState

		if (serverStatus.status !== 200) {
			message.error('Oops!! something went wrong')
			return
		}

		if (fitmentFieldsConfigurationErrors) {
			message.error(
				fitmentFieldsConfigurationErrors[0] || "Couldn't fetch fitment fields configuration"
			)
			return
		}

		message.success('Order sorted')
		setShowOrderModal(false)

		globalStore.ui.fitmentFieldsConfigurations.index = {
			...globalStore.ui.fitmentFieldsConfigurations.index,
			fitment_fields_configuration,
		}
	}

	const {
		fitment_fields_configuration,
		fitment_fields_configurationMeta,
		API_USERS_FITMENT_FIELDS_CONFIGURATIONS_INDEX_STATUS,
		displayType = 'table',
	} = pageState

	const {
		// total,
		// page: currentPage,
		page_size: pageSize,
		criteria_keys,
	} = fitment_fields_configurationMeta || {}

	const renderList = () => {
		return (
			<StyledList>
				{fitment_fields_configuration.map((ffc) => (
					<div
						className='item-card'
						key={`item-card-${ffc.id}`}
						onClick={() => handleCardItemClick(ffc.id)}>
						<div className='details-box'>
							<div className='name-box'>
								<div className='name'>{ffc.name}</div>
								<div>{}</div>
							</div>
						</div>
						<div className='icon-box'>
							<Button type='link'>
								<EyeOutlined />
							</Button>
						</div>
					</div>
				))}
			</StyledList>
		)
	}

	const renderContents = () => {
		if (fitment_fields_configuration.length === 0) {
			return (
				<Empty>
					<Button type='primary' ghost onClick={handleCreate}>
						Create Fitment Fields Configuration
					</Button>
				</Empty>
			)
		}

		// const pagination = (
		//   <Pagination
		//     current={currentPage}
		//     total={total}
		//     onChange={repaginate}
		//     onShowSizeChange={repaginate}
		//     pageSize={pageSize || 25}
		//     pageSizeOptions={[10, 25, 50, 100]}
		//   />
		// )

		const tableColumns = [
			...[
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Data Type',
					dataIndex: 'data_type',
					key: 'data_type',
				},
				...criteria_keys.map((ck) => ({
					title: ck,
					dataIndex: ck,
					key: ck,
					render: (filteredCriteria, row) => {

						if (!filteredCriteria) return '--' // if null
						if (!filteredCriteria[0]) return '--' // If [null]
						if (filteredCriteria.length === 0) return '---' // if []

						const dataSource = filteredCriteria.filter((fc) => fc) // FIXME: need to fix

						return (
							<List
								dataSource={dataSource}
								renderItem={(values) => {
									return values.join(', ')
								}}></List>
						)
					},
				})),
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					align: 'center',
					render: (value, ffc) => {
						return (
							<div>
								<Tooltip title='View'>
									<Button type='link' onClick={() => handleCardItemClick(ffc.id)}>
										<EyeOutlined />
									</Button>
								</Tooltip>
								<Tooltip title='Edit'>
									<Button type='link' onClick={() => handleEdit(ffc.id)}>
										<EditOutlined />
									</Button>
								</Tooltip>
								<Tooltip title='Delete'>
									<Popconfirm
										title='Are you sure to delete?'
										onConfirm={() => handleDelete(ffc.id)}
										onCancel={() => {}}
										okText='Yes'
										cancelText='No'>
										<Button type='link'>
											<DeleteOutlined />
										</Button>
									</Popconfirm>
								</Tooltip>
							</div>
						)
					},
				},
			],
		]

		const dataSource = fitment_fields_configuration.map((ffc) => {
			const retval = { ...ffc, key: ffc.id }
			criteria_keys.forEach((ck) => {
				retval[ck] = ffc.criteria.map((conditions) => {
					const relevantCondition = conditions.find((c) => c.field === ck)
					if (!relevantCondition) return null
					return relevantCondition.values
				})
			})
			return retval
		})

		const renderTable = () => {
			return (
				<Table
					columns={tableColumns}
					dataSource={dataSource}
					pagination={{
						pageSize: tablePageSize,
						defaultCurrent: page,
					}}
					onChange={(paginate) => {
						const { current, pageSize } = paginate
						setTablePageSize(pageSize)
						setPage(current)
					}}
					scroll={{
						x: 'max-content',
					}}></Table>
			)
		}

		const renderInfo = () => {
			if (displayType === 'table') return renderTable()
			return renderList()
		}

		return (
			<div>
				{/* {pagination} */}

				{networkOnly({
					status: API_USERS_FITMENT_FIELDS_CONFIGURATIONS_INDEX_STATUS,
					data: fitment_fields_configuration,
					render: renderInfo,
				})}

				{/* {pagination} */}
			</div>
		)
	}

	const renderPageContent = () => {
		const jobFamilySearch = (
			<Input.Search
				placeholder='Job Family'
				value={jobFamily}
				onSearch={(jobFamily) => repaginate(1, pageSize, jobFamily)}
				style={{ width: 200, marginBottom: '10px' }}
			/>
		)

		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div>{jobFamilySearch}</div>
					<div className='action-buttons top'>
						<Button style={{ marginRight: 20 }} onClick={() => setShowOrderModal(true)}>
							Order Fitment Fields Configuration
						</Button>
						<Tooltip title='Create a new record'>
							<Button type='primary' ghost onClick={handleCreate}>
								Create Fitment Fields Configuration
							</Button>
						</Tooltip>
					</div>
				</div>

				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('fitment-data-view'))
		return <Redirect to='/' />

	return (
		<div className='page-content salary-ranges-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_FITMENT_FIELDS_CONFIGURATIONS_INDEX_STATUS,
					data: fitment_fields_configuration,
					render: renderPageContent,
				})}
			</StyledBox>
			<Modal
				title='Order Fitment Fields Configuration'
				style={{ top: 20 }}
				visible={showOrderModal}
				closable={!updateOrderLoading}
				confirmLoading={updateOrderLoading}
				okText='Update'
				onOk={handleUpdateOrder}
				onCancel={() => setShowOrderModal(false)}>
				<FitmentFieldsConfigurationDND
					fitmentFieldsConfiguration={fitment_fields_configuration}
					updatedOrderIds={setOrderIds}
				/>
			</Modal>
		</div>
	)
}

export default view(FitmentFieldsConfigurationIndexPage)

import React, { useState, useEffect } from "react"
import { useParams, useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Input, message, Row, Col, Select } from "antd"
import { isContainsBlankCriteria } from "utils/criteria"

import StyledBox from "components/common/styled-components/StyledBox"
import ConditionRow from "components/ConditionRow"
import globalStore from "store/index"

import OrganizationsService from "services/users/organizations.service"
import OrganizationMinimumSalariesService from "services/users/organization-minimum-salaries.service"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const blankCondition = {
  field: null,
  operator: null,
  values: null,
}

const OrganizationMinimumSalariesEditPage = () => {
  const history = useHistory()
  const { organizationMinimumSalaryId } = useParams()
  const [criteria, setCriteria] = useState([[blankCondition]])
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const [inputValues, setInputValues] = useState({})

  useEffect(() => {
    const fetchMinimumWage = async () => {
      const pageState =
        globalStore.ui.configuration.organizationMinimumSalaries.edit

      await OrganizationMinimumSalariesService.show({
        pageState,
        organizationMinimumSalaryId,
      })

      const {
        serverStatus,
        organization_minimum_salary,
        organizationMinimumSalaryErrors,
      } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (organizationMinimumSalaryErrors) {
        message.error(
          organizationMinimumSalaryErrors[0] ||
            "Organization minimum salary couldn't be fetched."
        )
        return
      }

      const {
        currency,
        frequency,
        amount,
        criteria,
      } = organization_minimum_salary

      const newCriteria = criteria?.length > 0 ? criteria : [[blankCondition]]

      setCriteria(newCriteria)
      setInputValues({
        currency,
        frequency,
        amount,
      })
    }

    fetchMinimumWage()
  }, [organizationMinimumSalaryId])

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  const handleInputChange = (e, inputType) => {
    let newInputValues = null

    if (inputType === "frequency") {
      newInputValues = { ...inputValues, [inputType]: e }
    } else {
      newInputValues = { ...inputValues, [inputType]: e.target.value }
    }

    setInputValues(newInputValues)
  }

  const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(deletedConditionIndex, 1)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleUpdateConditionValues = ({
    conditionSetIndex,
    conditionIndex,
    condition,
  }) => {
    if (!condition) return
    if (condition.operator !== "not" && !condition.values) return

    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        return newConditionSet.map((c, index) => {
          if (index !== conditionIndex) return c

          return condition
        })
      })

      return newCriteria
    })
  }

  const handleUpdate = async () => {
    if (!inputValues["currency"]) {
      message.error("Currency can't be blank")
      return
    }
    if (!inputValues["amount"]) {
      message.error("Amount can't be blank")
      return
    }
    if (!inputValues["frequency"]) {
      message.error("Frequency can't be blank")
      return
    }

    if(isContainsBlankCriteria(criteria)){
      message.error("Criteria cannot be blank")
      return
    }

    const pageState = {}
    const postData = {
      organization_minimum_salary: {
        criteria: criteria.filter((c) => c.length > 0),
        ...inputValues,
      },
    }

    setCreateButtonLoading(true)
    await OrganizationMinimumSalariesService.update({
      pageState,
      values: postData,
      organizationMinimumSalaryId,
    })
    setCreateButtonLoading(false)

    const { serverStatus, organizationMinimumSalaryErrors } = pageState

    if (organizationMinimumSalaryErrors) {
      message.error(
        organizationMinimumSalaryErrors[0] || "Failed to update minimum wage"
      )
      return
    }

    if (serverStatus.status === 200) {
      message.success("Organization minimum salary updated.")
      history.push(
        `/configuration/organization-minimum-salaries/${organizationMinimumSalaryId}`
      )
      setCriteria([[blankCondition]])
      setInputValues({})

      return
    }

    message.error("Oops!! something went wrong")
  }

  const renderPageContent = () => {
    return (
      <StyledConfigurationFormBox>
        <h1>Organization Minimum Salary</h1>
        <Row gutter={20}>
          <Col span={8}>
            <div className="input-box">
              <strong>Currency</strong>
              <Input
                value={inputValues["currency"]}
                onChange={(e) => handleInputChange(e, "currency")}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="input-box">
              <strong>Amount</strong>
              <Input
                value={inputValues["amount"]}
                onChange={(e) => handleInputChange(e, "amount")}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="input-box">
              <strong>Frequency</strong>
              <Select
                style={{ display: "block" }}
                value={inputValues["frequency"]}
                onChange={(value) => handleInputChange(value, "frequency")}
              >
                <Select.Option value="daily">Daily</Select.Option>
                <Select.Option value="weekly">Weekly</Select.Option>
                <Select.Option value="monthly">Monthly</Select.Option>
                <Select.Option value="quarterly">Quarterly</Select.Option>
                <Select.Option value="half_yearly">Half Yearly</Select.Option>
                <Select.Option value="annually">Annually</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>
        <div className="input-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong>Criteria</strong>
            {criteria.filter((c) => c.length > 0).length === 0 && (
              <Button
                shape="circle"
                onClick={() => setCriteria([[blankCondition]])}
              >
                <PlusOutlined />
              </Button>
            )}
          </div>
          {criteria.map((conditionSet, conditionSetIndex) => {
            return (
              <div key={conditionSetIndex}>
                {conditionSet.map((condition, conditionIndex) => {
                  return (
                    <ConditionRow
                      condition={condition}
                      key={conditionIndex}
                      conditionIndex={conditionSetIndex}
                      lastInputGroup={
                        conditionSet.length === conditionIndex + 1
                      }
                      conditionSetLength={conditionSet.length}
                      firstInputGroup={conditionIndex === 0}
                      inputGroupIndex={conditionIndex}
                      updateConditionValues={(condition) =>
                        handleUpdateConditionValues({
                          conditionSetIndex,
                          conditionIndex,
                          condition,
                        })
                      }
                      addNewCondition={() =>
                        handleAddNewCondition(conditionSetIndex, conditionIndex)
                      }
                      removeCondition={() =>
                        handleRemoveCondition(conditionSetIndex, conditionIndex)
                      }
                      organizationFields={
                        globalStore.currentOrganization.all_fields_with_datatype
                      }
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="action-buttons submit">
          <Button
            type="primary"
            loading={createButtonLoading}
            onClick={handleUpdate}
          >
            Save
          </Button>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-edit")
  )
    return (
      <Redirect
        to={`/configuration/organization-minimum-salaries/${organizationMinimumSalaryId}`}
      />
    )

  return (
    <div className="page-content dashboard-page">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(OrganizationMinimumSalariesEditPage)

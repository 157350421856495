import { API, generic } from "api"

export default class BudgetsService {
	static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_INDEX_STATUS',
			stateDataKey: ['budgets', 'total', 'page', 'page_size', 'criteria_keys'],
			stateErrorKey: [
				'budgetsErrors',
				'totalErrors',
				'pageErrors',
				'pageSizeErrors',
				'criteriaKeysErrors',
			],
			apiEndpoint: API.users.budgets.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, bcsla } },
			errorMessage: 'Budgets could not be fetched.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_CREATE_STATUS',
			stateDataKey: ['budget'],
			stateErrorKey: ['budgetErrors'],
			apiEndpoint: API.users.budgets.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Budget could not be created.',
		})
	}

	static show = async ({ pageState, budgetId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_SHOW_STATUS',
			stateDataKey: ['budget'],
			stateErrorKey: ['budgetErrors'],
			apiEndpoint: API.users.budgets.show,
			apiUrlReplacements: { budgetId },
			apiData: {},
			errorMessage: 'Budget could not be fetched.',
		})
	}

	static update = async ({ pageState, values, budgetId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_UPDATE_STATUS',
			stateDataKey: ['budget'],
			stateErrorKey: ['budgetErrors'],
			apiEndpoint: API.users.budgets.update,
			apiUrlReplacements: { budgetId },
			apiData: { ...values },
			errorMessage: 'Budget could not be updated.',
		})
	}

	static import = async ({ pageState, formData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_IMPORT_STATUS',
			stateDataKey: ['budget'],
			stateErrorKey: ['budgetErrors'],
			apiEndpoint: API.users.budgets.import,
			apiUrlReplacements: {},
			apiData: formData,
			errorMessage: 'Budgets could not be imported.',
		})
	}

	static export = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_EXPORT_STATUS',
			stateDataKey: ['budgets'],
			stateErrorKey: ['budgetsErrors'],
			apiEndpoint: API.users.budgets.export,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Budgets could not be exported.',
		})
	}

	static refresh = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_REFRESH_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.budgets.refresh,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Budget could not be refreshed.',
		})
	}

	static revalidate = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_REVALIDATE_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.budgets.revalidate,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Budget could not be revalidated.',
		})
	}

	static delete = async ({ pageState, budgetId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_DEETE_STATUS',
			stateDataKey: ['budget'],
			stateErrorKey: ['budgetErrors'],
			apiEndpoint: API.users.budgets.delete,
			apiUrlReplacements: { budgetId },
			apiData: {},
			errorMessage: 'Budget could not be deleted.',
		})
	}

	static multipleDestroy = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_MULTIPLE_DESTROY_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['budgetErrors'],
			apiEndpoint: API.users.budgets.multipleDestroy,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Budget could not be deleted.',
			serializedResponse:false,
		})
	}

	static auditReport = async ({ pageState, budgetId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_AUDIT_REPORT_STATUS',
			stateDataKey: ['budget'],
			stateErrorKey: ['budgetErrors'],
			apiEndpoint: API.users.budgets.auditReport,
			apiUrlReplacements: { budgetId },
			apiData: {},
			errorMessage: 'Audit report could not be exported.',
		})
	}

	static auditReports = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGETS_AUDIT_REPORTS_STATUS',
			stateDataKey: ['budgets'],
			stateErrorKey: ['budgetsErrors'],
			apiEndpoint: API.users.budgets.auditReports,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Audit reports could not be exported.',
		})
	}
}

import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledPageContent from "components/common/styled-components/StyledPageContent"
import CriteriaSet from "components/CriteriaSet"
import ApprovalChainStatusesBox from "components/ApprovalChainStatusesBox"

import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import ApprovalChainsService from "services/users/approval-chains.service"

const ApprovalChainsShowPage = (props) => {
  const pageState = globalStore.ui.configuration.approvalChains.show
  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  const history = useHistory()
  const { approvalChainId } = useParams()
  const [publishButtonLoading, setPublishButtonLoading] = useState(false)
  const [unpublishButtonLoading, setUnpublishButtonLoading] = useState(false)

  useEffect(() => {
    const fetchApprovalChain = async () => {
      const pageState = globalStore.ui.configuration.approvalChains.show

      await ApprovalChainsService.show({ pageState, approvalChainId })

      const { serverStatus, approvalChainErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (approvalChainErrors) {
        message.error(approvalChainErrors[0] || "Coundn't fetch approval chain")
        return
      }
    }

    fetchApprovalChain()
  }, [approvalChainId])

  const handleEdit = () => {
    history.push(`/configuration/approval-chains/${approvalChainId}/edit`)
  }

  const handleUnpublish = async () => {
    const tempState = {}

    setUnpublishButtonLoading(true)
    await ApprovalChainsService.unpublish({
      pageState: tempState,
      approvalChainId,
    })
    setUnpublishButtonLoading(false)

    const { serverStatus, approvalChainErrors, approval_chain } = tempState

    if (serverStatus.status !== 200) {
      message.error("Oops!! something went wrong")
      return
    }

    if (approvalChainErrors) {
      message.error(
        approvalChainErrors[0] || "Approval chain couldn't be unpublished"
      )
      return
    }

    message.success("Approval chain unpublished.")
    globalStore.ui.configuration.approvalChains.show.approval_chain =
      approval_chain
  }

  const handlePublish = async () => {
    const tempState = {}

    setPublishButtonLoading(true)
    await ApprovalChainsService.publish({
      pageState: tempState,
      approvalChainId,
    })
    setPublishButtonLoading(false)

    const { serverStatus, approvalChainErrors, approval_chain } = tempState

    if (serverStatus.status !== 200) {
      message.error("Oops!! something went wrong")
      return
    }

    if (approvalChainErrors) {
      message.error(
        approvalChainErrors[0] || "Approval chain couldn't be published"
      )
      return
    }

    message.success("Approval chain published.")
    globalStore.ui.configuration.approvalChains.show.approval_chain =
      approval_chain
  }

  // const handleDelete = async () => {
  //   const pageState = {}

  //   setDeleteLoading(true)
  //   await ApprovalChainsService.delete({ pageState, approvalChainId })
  //   setDeleteLoading(false)

  //   const { serverStatus } = pageState

  //   if (serverStatus.status !== 0) {
  //     message.error("Approval chain couldn't be deleted.")
  //     return
  //   }

  //   message.success("Approval chain deleted.")

  //   history.push("/configuration/approval-chains")
  // }

  const { approval_chain, API_USERS_APPROVAL_CHAINS_SHOW_STATUS } = pageState
  const { criteria, name, approver_emails, status } = approval_chain || {}

  const renderContent = () => {
    if (!approval_chain) return null

    return (
      <div>
        <CriteriaSet criteria={criteria} />
        <ApprovalChainStatusesBox
          approvalChainStatuses={approver_emails.map((approvers) =>
            approvers.map((approver) => ({
              email: approver,
            }))
          )}
        />
      </div>
    )
  }

  const renderActionButtons = () => {
    if (!accessibleFeatures.includes("approval-chain-edit")) return null

    const actionButtons = [
      <Button type="primary" ghost onClick={handleEdit} key="edit-button">
        Edit
      </Button>,
    ]

    if (status === "published") {
      actionButtons.push(
        <Button
          key="unpublish-button"
          type="primary"
          ghost
          loading={unpublishButtonLoading}
          onClick={handleUnpublish}
        >
          Unpublish
        </Button>
      )
    }
    if (status === "draft") {
      actionButtons.push(
        <Button
          key="publish-button"
          type="primary"
          ghost
          loading={publishButtonLoading}
          onClick={handlePublish}
        >
          Publish
        </Button>
      )
    }

    return actionButtons
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="page-header">
          <h1>{name}</h1>
          <div className="action-buttons top">
            {renderActionButtons()}

            {/* {<Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button>} */}
          </div>
        </div>

        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("approval-chain-view")
  )
    return <Redirect to={`/configuration/approval-chains/`} />

  return (
    <div className="page-content approval-chains-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_APPROVAL_CHAINS_SHOW_STATUS,
          data: approval_chain,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(ApprovalChainsShowPage)

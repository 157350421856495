import { EyeOutlined } from '@ant-design/icons'
import { view } from '@risingstack/react-easy-state'
import { Button, Empty, message, Pagination, Table, Tooltip } from 'antd'
import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import React, { useEffect } from 'react'
import {Redirect, useHistory } from 'react-router-dom'
import NotificationsService from 'services/users/notifications.service'
import globalStore from 'store/index'
import styled from 'styled-components'
import { networkOnly, staleWhileRevalidate } from 'utils/render-strategies'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const NotificationIndexPage = () => {
		const history = useHistory()
		const pageState = globalStore.ui.users.notifications.index

		useEffect(() => {
			const fetchApprovalChains = async () => {
				const pageState = globalStore.ui.users.notifications.index
				await NotificationsService.index({ pageState })
				const { serverStatus, notificationErrors } = pageState
				if (serverStatus.status !== 200) {
					message.error('Oops!! something went wrong')
					return
				}

				if (notificationErrors) {
					message.error(notificationErrors[0] || "Couldn't fetch Notification Details")
					return
				}
			}

			fetchApprovalChains()
		}, [])

		const repaginate = async (page, pageSize) => {
			await NotificationsService.index({ pageState, page, pageSize })
		}



		const handleCardItemClick = (notificationId) => {
			history.push(`/notifications/${notificationId}`)
		}


		const handleRowClick = handleCardItemClick

		const { notifications, notificationsMeta, API_USERS_NOTIFICATION_INDEX_STATUS } = pageState

		const { total, page: currentPage, page_size: pageSize } = notificationsMeta || {}

		const renderTable = () => {
			const tableColumns = [
				...[
					{
						title: 'Notification Type',
						dataIndex: 'notification_type',
						key: 'notification_type',
					},
				],
				...[
					{
						title: 'Status',
						dataIndex: 'status',
						key: 'status',
					},
				],
				...[
					{
						title: 'Action',
						dataIndex: 'action',
						key: 'action',
						align: 'center',
						render: (value, sr) => {
							return (
								<div>
									<Tooltip title='View'>
										<Button
											type='link'
											onClick={() => handleRowClick(sr.id)}>
											<EyeOutlined />
										</Button>
									</Tooltip>
								</div>
							)
						},
					},
				],
			]

			const dataSource = notifications.map((data, i) => {
				const list = { ...data, key: i }
				return list
			})

			return (
				<Table
					columns={tableColumns}
					dataSource={dataSource}
					pagination={false}
					scroll={{
						x: 'max-content',
					}}
				/>
			)
		}


		const renderContents = () => {
			if (notifications.length === 0) {
				return (
					<Empty/>
				)
			}

			const pagination = (
				<Pagination
					current={currentPage}
					total={total}
					onChange={repaginate}
					onShowSizeChange={repaginate}
					pageSize={pageSize || 25}
					pageSizeOptions={[10, 25, 50, 100]}
				/>
			)

			return (
				<div>
					{pagination}
					<StyledList>
						{networkOnly({
							status: API_USERS_NOTIFICATION_INDEX_STATUS,
							data: notifications,
							render: renderTable,
						})}
					</StyledList>
					{pagination}
				</div>
			)
		}

		const renderPageContent = () => {
			return (
				<StyledPageContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<div></div>
					</div>
					<div className='content'>{renderContents()}</div>
				</StyledPageContent>
			)
		}

		const accessibleFeatures = globalStore.currentOrganization?.accessible_features
		if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('budget')) return <Redirect to={`/`} />

		return (
			<div className='page-content approval-chains-page'>
				<StyledBox>
					{staleWhileRevalidate({
						status: API_USERS_NOTIFICATION_INDEX_STATUS,
						data: notifications,
						render: renderPageContent,
					})}
				</StyledBox>
			</div>
		)
}

export default view(NotificationIndexPage)

import { API, generic } from "api"

export default class SSConfigurationsService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SS_CONFIGURATIONS_INDEX_STATUS",
      stateDataKey: ["ss_configurations"],
      stateErrorKey: ["ssConfigurationsErrors"],
      apiEndpoint: API.users.ss.configurations.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Salary Structure Configurations could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SS_CONFIGURATIONS_CREATE_STATUS",
      stateDataKey: ["ss_configuration"],
      stateErrorKey: ["ssConfigurationErrors"],
      apiEndpoint: API.users.ss.configurations.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Salary Structure Configuration could not be created.",
    })
  }

  static show = async ({ pageState, ssConfigurationId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SS_CONFIGURATIONS_SHOW_STATUS",
      stateDataKey: ["ss_configuration"],
      stateErrorKey: ["ssConfigurationErrors"],
      apiEndpoint: API.users.ss.configurations.show,
      apiUrlReplacements: { ssConfigurationId },
      apiData: {},
      errorMessage: "Salary Structure Configuration could not be fetched.",
    })
  }

  static update = async ({ pageState, values, ssConfigurationId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SS_CONFIGURATIONS_UPDATE_STATUS",
      stateDataKey: ["ss_configuration"],
      stateErrorKey: ["ssConfigurationErrors"],
      apiEndpoint: API.users.ss.configurations.update,
      apiUrlReplacements: { ssConfigurationId },
      apiData: { ...values },
      errorMessage: "Salary Structure Configuration could not be updated.",
    })
  }

  static refresh = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SS_CONFIGURATIONS_REFRESH_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.ss.configurations.refresh,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Salary Structure Configuration could not be refreshed.",
    })
  }
}

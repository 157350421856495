import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useParams } from "react-router-dom"
import { message } from "antd"

import ContentLoading from "components/common/ContentLoading"
import ContentLoadingError from "components/common/ContentLoadingError"

import FitmentsService from "services/users/fitments.service"

const OfferDraftDownloadRedirector = () => {
  const { fitmentId } = useParams()
  const [error, setError] = useState("")

  useEffect(() => {
    const fetchDataAndRedirect = async () => {
      const tempState = {}

      await FitmentsService.downloadOfferDraft({
        pageState: tempState,
        fitmentId,
      })

      const { redirect_url, redirectUrlErrors } = tempState

      if (redirectUrlErrors) {
        const errorMessage =
          redirectUrlErrors[0] || "Oops!! something went wrong"
        message.error(errorMessage)
        setError(errorMessage)
        return
      }

      // TODO: Handle errors. Show error messages on the same page
      if (redirect_url) window.location = redirect_url
    }
    fetchDataAndRedirect()
  }, [fitmentId])

  if (error) {
    return <ContentLoadingError description={error} />
  }

  return <ContentLoading />
}

export default view(OfferDraftDownloadRedirector)

import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import ControlFeaturesService from "services/users/control-features.service"

import {
  CONTROL_FEATURE_STATUES,
  CONTROL_FEATURE_TYPES,
} from "constants/control-features"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;box-shadow
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const ControlFeaturesShowPage = (props) => {
  const pageState = globalStore.ui.configuration.controlFeatures.show
  const history = useHistory()
  const { controlFeatureId } = useParams()
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    const fetchBudget = async () => {
      const pageState = globalStore.ui.configuration.controlFeatures.show

      await ControlFeaturesService.show({ pageState, controlFeatureId })

      const { serverStatus, controlFeaturesErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (controlFeaturesErrors) {
        message.error(
          controlFeaturesErrors[0] || "Control feature couldn't be fetched."
        )
        return
      }
    }

    fetchBudget()
  }, [controlFeatureId])

  const handleEdit = () => {
    history.push(`/configuration/control-features/${controlFeatureId}/edit`)
  }

  const handleDelete = async () => {
    const pageState = {}

    setDeleteLoading(true)
    await ControlFeaturesService.delete({ pageState, controlFeatureId })
    setDeleteLoading(false)

    const { serverStatus } = pageState

    if (serverStatus.status !== 0) {
      message.error("Control feature couldn't be deleted.")
      return
    }

    message.success("Control feature deleted.")

    history.push("/configuration/control-features")
  }

  const { control_feature, API_USERS_CONTROL_FEATURES_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!control_feature) return null

    const { criteria, name, feature_type, status } = control_feature

    return (
      <div>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Name</div>
              <div>{name}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Feature Type</div>
              <div>
                {
                  CONTROL_FEATURE_TYPES.find((t) => t.value === feature_type)
                    ?.label
                }
              </div>
            </div>
            <div className="item-row colored">
              <div className="label">Status</div>
              <div>
                {CONTROL_FEATURE_STATUES.find((t) => t.value === status)?.label}
              </div>
            </div>
          </div>
        </StyledItemList>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Control Feature
          </Button>
          <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const strategy =
    control_feature && control_feature.id === controlFeatureId
      ? staleWhileRevalidate
      : networkOnly

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []


  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("control_feature")
  )
    return <Redirect to="/configuration/control-features" />

  return (
    <div className="page-content">
      <StyledBox>
        {strategy({
          status: API_USERS_CONTROL_FEATURES_SHOW_STATUS,
          data: control_feature,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(ControlFeaturesShowPage)

import React, { useEffect, useState } from 'react'
import { Table, Button, Tooltip, message, Pagination } from 'antd'
import { EyeOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import StyledBox from 'components/common/styled-components/StyledBox'
import { useHistory } from 'react-router-dom'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store'
import BudgetApprovalsService from 'services/users/budget-approvals.service'
import { statusColor } from 'helpers/status-color.helper'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const PendingBudgetApprovalIndexPage = () => {
	const history = useHistory()
	const [budgetApprovals, setBudgetApprovals] = useState()
	const [tableDataLoading, setTableDataLoading] = useState()
	const pageState = globalStore.ui.budgetApproval.pendingBudgetApprovals.index

	useEffect(() => {
		const fetchPendingApproval = async () => {
			const pageState = globalStore.ui.budgetApproval.pendingBudgetApprovals.index
			setTableDataLoading(true)
			await BudgetApprovalsService.pendingApprovals({ pageState })
			setTableDataLoading(false)
			const { budget_approvals, budgetApprovalValuesErrors } = pageState

			if (budgetApprovalValuesErrors) {
				message.error({
					content: budgetApprovalValuesErrors[0],
				})
				return
			}

			setBudgetApprovals(budget_approvals)
		}
		fetchPendingApproval()
	}, [])

	const openShowPage = (id) => {
		history.push({
			pathname: `/budget-approvals/pending-budget-approvals/${id}`,
		})
	}

	const columns = [
		{
			title: 'Budget Head',
			dataIndex: 'budget_head',
			key: 'budget_head',
			render: (row) => row?.name,
		},
		{
			title: 'Existing Head Count',
			dataIndex: 'existing_data',
			key: 'existing_data',
			align: 'center',
			render: (row) => row?.count,
		},
		{
			title: 'Existing Cost',
			dataIndex: 'existing_data',
			key: 'existing_data',
			align: 'center',
			render: (row) => row?.total_cost,
		},
		{
			title: 'Revised Head Count',
			dataIndex: 'existing_data',
			key: 'existing_data',
			align: 'center',
			render: (row, rowValue) => {
				if (rowValue?.budget_tentative_value?.budget_type === 'planned') {
					return (
						row?.count +
						parseFloat(rowValue?.budget_tentative_value?.tentative_data?.budget_value?.unit || 0)
					)
				}
				return (
					row?.count +
					parseFloat(
						rowValue?.budget_tentative_value?.tentative_data?.budget_utilization?.unit || 0
					)
				)
			},
		},
		{
			title: 'Revised Cost',
			dataIndex: 'existing_data',
			key: 'existing_data',
			align: 'center',
			render: (row, rowValue) => {
				if (rowValue?.budget_tentative_value?.budget_type === 'planned') {
					return (
						row?.total_cost +
						parseFloat(
							rowValue?.budget_tentative_value?.tentative_data?.budget_value?.total_cost || 0
						)
					)
				}
				return (
					row?.total_cost +
					parseFloat(
						rowValue?.budget_tentative_value?.tentative_data?.budget_utilization?.total_cost || 0
					)
				)
			},
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			filters: [
				{
					text: 'Pending',
					value: 'pending',
				},
				{
					text: 'In progress',
					value: 'in_progress',
				},
				{
					text: 'Approved',
					value: 'approved',
				},
				{
					text: 'Rejected',
					value: 'rejected',
				},
			],
			onFilter: (value, record) => record.status.includes(value),
			render: (status, row) => {
				return (
					<div className='fitment__status-box'>
						<Tooltip title={status && status.split('_').join(' ')}>
							<div className={`fitment__status ${statusColor(status)}`}></div>
						</Tooltip>
						<span>{status && status.split('_').join(' ')}</span>
					</div>
				)
			},
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			align: 'center',
			render: (action, rows) => {
				return (
					<div className='icon-box'>
						<Button type='link' onClick={() => openShowPage(rows?.id)}>
							<EyeOutlined />
						</Button>
						{/* <Button type='link' style={{ color: 'green' }}>
							<CheckOutlined />
						</Button>
						<Button type='link' style={{ color: 'red' }}>
							<CloseOutlined />
						</Button> */}
					</div>
				)
			},
		},
	]
	const data =
		budgetApprovals?.map((data, index) => {
			return {
				key: index,
				...data,
			}
		}) || []

	const renderPendingBudgetApprovals = () => {
		return (
			<Table
				columns={columns}
				dataSource={data}
				loading={tableDataLoading}
				pagination={false}
				summary={(pageData) => {
					const data = pageData.map((data) => {
						const existingHeadCount = data?.existing_data?.count
						const existingCost = data?.existing_data?.total_cost
						let revisedHeadCount = 0
						let revisedCost = 0

						if (data?.budget_tentative_value?.budget_type === 'planned') {
							revisedHeadCount =
								existingHeadCount +
								parseFloat(data?.budget_tentative_value?.tentative_data?.budget_value?.unit || 0)
							revisedCost =
								existingCost +
								parseFloat(
									data?.budget_tentative_value?.tentative_data?.budget_value?.total_cost || 0
								)
						} else {
							revisedHeadCount =
								existingHeadCount +
								parseFloat(
									data?.budget_tentative_value?.tentative_data?.budget_utilization?.unit || 0
								)
							revisedCost =
								existingCost +
								parseFloat(
									data?.budget_tentative_value?.tentative_data?.budget_utilization?.total_cost || 0
								)
						}

						return { existingHeadCount, existingCost, revisedHeadCount, revisedCost }
					})
					const totalExistingHeadCount = data?.reduce(
						(a, b) => parseFloat(a?.existingHeadCount || 0) + parseFloat(b?.existingHeadCount || 0),
						0
					)
					const totalExistingCost = data?.reduce(
						(a, b) => parseFloat(a?.existingCost || 0) + parseFloat(b?.existingCost || 0),
						0
					)
					const totalRevisedHeadCount = data?.reduce(
						(a, b) => parseFloat(a?.revisedHeadCount || 0) + parseFloat(b?.revisedHeadCount || 0),
						0
					)
					const totalRevisedCost = data?.reduce(
						(a, b) => parseFloat(a?.revisedCost || 0) + parseFloat(b?.revisedCost || 0),
						0
					)
					return (
						<>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}>
									<strong>Total</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1}>
									<div style={{ textAlign: 'center' }}>{totalExistingHeadCount}</div>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={2} style={{ textAlign: 'center' }}>
									<div style={{ textAlign: 'center' }}>{totalExistingCost}</div>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={2} style={{ textAlign: 'center' }}>
									<div style={{ textAlign: 'center' }}>{totalRevisedHeadCount}</div>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={2} style={{ textAlign: 'center' }}>
									<div style={{ textAlign: 'center' }}>{totalRevisedCost}</div>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</>
					)
				}}
			/>
		)
	}
	const { budget_approvalsMeta } = pageState

	const { total, page: currentPage, page_size: pageSize } = budget_approvalsMeta || {}

	const repaginate = async (page, pageSize) => {
		const tempState = {}

		setTableDataLoading(true)

		await BudgetApprovalsService.pendingApprovals({
			pageState: tempState,
			page,
			pageSize,
		})

		setTableDataLoading(false)

		const { budget_approvals, budgetApprovalValuesErrors } = pageState

		if (budgetApprovalValuesErrors) {
			message.error({
				content: budgetApprovalValuesErrors[0],
			})
			return
		}

		setBudgetApprovals(budget_approvals)
	}

	const pagination = (
		<Pagination
			style={{margin:"10px 0"}}
			current={currentPage}
			total={total}
			onChange={repaginate}
			onShowSizeChange={repaginate}
			pageSize={pageSize || 25}
			pageSizeOptions={[10, 25, 50, 100]}
		/>
	)

	const renderPageContent = () => {
		return (
			<div>
				{pagination}
				{renderPendingBudgetApprovals()}
				{pagination}
			</div>
		)
	}

	const { API_USERS_BUDGET_PENDING_APPROVAL_STATUS } = pageState

	return (
		<div className='page-content'>
			<StyledPageContent>
				<StyledBox>
					{staleWhileRevalidate({
						status: API_USERS_BUDGET_PENDING_APPROVAL_STATUS,
						data: true,
						render: renderPageContent,
					})}
				</StyledBox>
			</StyledPageContent>
		</div>
	)
}

export default PendingBudgetApprovalIndexPage

import { API, generic } from "api"

export default class AccessGroupsService {
  static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ACCESS_GROUPS_INDEX_STATUS",
      stateDataKey: [
        "access_groups",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "accessGroupsErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.accessGroups.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize, bcsla } },
      errorMessage: "Access groups could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ACCESS_GROUPS_CREATE_STATUS",
      stateDataKey: ["access_group"],
      stateErrorKey: ["accessGroupErrors"],
      apiEndpoint: API.users.accessGroups.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Access group could not be created.",
    })
  }

  static show = async ({ pageState, accessGroupId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ACCESS_GROUPS_SHOW_STATUS",
      stateDataKey: ["access_group"],
      stateErrorKey: ["accessGroupErrors"],
      apiEndpoint: API.users.accessGroups.show,
      apiUrlReplacements: { accessGroupId },
      apiData: {},
      errorMessage: "Access group could not be fetched.",
    })
  }

  static update = async ({ pageState, values, accessGroupId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ACCESS_GROUPS_UPDATE_STATUS",
      stateDataKey: ["access_group"],
      stateErrorKey: ["accessGroupErrors"],
      apiEndpoint: API.users.accessGroups.update,
      apiUrlReplacements: { accessGroupId },
      apiData: { ...values },
      errorMessage: "Access group could not be updated.",
    })
  }
}

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Table, Input, Select, Switch, Popconfirm, Form, Button } from "antd"

const StyledOrganizationParameters = styled.div``

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputComponent,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputComponent
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const OrganizationParameters = ({ parameters = [] }) => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState("")

  useEffect(() => {

    setData(parameters)
  }, [parameters])

  const isEditing = (record) => record.key === editingKey

  const edit = (record) => {
    form.setFieldsValue({
      validationParameter: "",
      explanation: "",
      comparisonPoint: "",
      returnValue: "",
      controlFeature: "",
      expectedAction: "",
      controlException: "",
      status: "",
      ...record,
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey("")
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex((item) => key === item.key)

      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
        setEditingKey("")
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey("")
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo)
    }
  }

  const getColumnInputComponent = (dataIndex) => {
    if (dataIndex === "returnValue") {
      return (
        <Select>
          <Select.Option value="absolute">Absolute</Select.Option>
          <Select.Option value="average">Average</Select.Option>
          <Select.Option value="total">Total</Select.Option>
        </Select>
      )
    }
    if (dataIndex === "controlFeature") {
      return (
        <Select>
          <Select.Option value="complete">Complete</Select.Option>
          <Select.Option value="partial">Partial</Select.Option>
        </Select>
      )
    }
    if (dataIndex === "expectedAction") {
      return (
        <Select>
          <Select.Option value="warn">Warn</Select.Option>
          <Select.Option value="stop">Stop</Select.Option>
        </Select>
      )
    }

    if (dataIndex === "status") {
      return <Switch />
    }

    return <Input />
  }

  const columns = [
    {
      title: "Validation Parameter",
      dataIndex: "validationParameter",
      width: 200,
      fixed: true,
      editable: true,
    },
    {
      title: "Explanation",
      dataIndex: "explanation",
      width: 300,
      editable: true,
    },
    {
      title: "Comparison Point",
      dataIndex: "comparisonPoint",
      width: 200,
      editable: true,
    },
    {
      title: "Return Value",
      dataIndex: "returnValue",
      width: 150,
      editable: true,
    },
    {
      title: "Control Feature",
      dataIndex: "controlFeature",
      width: 200,
      editable: true,
    },
    {
      title: "Expected Action",
      dataIndex: "expectedAction",
      width: 200,
      editable: true,
    },
    {
      title: "Control Exception",
      dataIndex: "controlException",
      width: 200,
      editable: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: 200,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to save?"
              onConfirm={() => save(record.key)}
            >
              <Button
                type="link"
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Button>
            </Popconfirm>

            <Button type="link" onClick={cancel}>
              Cancel
            </Button>
          </span>
        ) : (
          <Button
            type="link"
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Button>
        )
      },
    },
  ]
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputComponent: getColumnInputComponent(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })
  return (
    <StyledOrganizationParameters>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: 1000 }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </StyledOrganizationParameters>
  )
}

export default OrganizationParameters

import React, { useEffect, useState } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { EyeOutlined, InboxOutlined } from "@ant-design/icons"
import { Button, message, Empty, Modal, Upload, Tooltip } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import SSConfigurationsService from "services/users/ss/configurations.service"
// import FitmentInputGroup from "components/FitmentInputGroup"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const SSConfigurationsIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.configuration.ssConfigurations.index
  // const [exporting, setExporting] = useState(false)
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [fileList, setFileList] = useState([])
  const [importing, setImporting] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.ssConfigurations.index

      await SSConfigurationsService.index({ pageState })

      const { serverStatus, ssConfigurationsErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (ssConfigurationsErrors) {
        message.error(
          ssConfigurationsErrors[0] ||
            "Coundn't fetch Salary Structure Configuration"
        )
        return
      }
    }

    fetchData()
  }, [])

  const handleCreate = () => {
    history.push("/configuration/ss-configurations/new")
  }

  // const openImportModal = () => {
  //   setImportModalVisible(true)
  // }

  const handleImport = async () => {
    if (fileList.length === 0) return

    const tempState = {}

    const formData = new FormData()

    formData.append("file", fileList[0])

    setImporting(true)
    await SSConfigurationsService.import({ pageState: tempState, formData })
    setImporting(false)

    setImportModalVisible(false)

    message.success(
      "Import started. Kindly check import page in Report Center to see imported incentives"
    )
  }

  // const handleExport = async () => {
  //   const tempState = {}

  //   setExporting(true)
  //   await SSConfigurationsService.export({ pageState: tempState })
  //   setExporting(false)

  //   message.success(
  //     "Export started. Kindly check export page in Report Center to see exported incentives"
  //   )
  // }

  const handleCardItemClick = (ssConfigurationId) => {
    history.push(`/configuration/ss-configurations/${ssConfigurationId}`)
  }

  const {
    ss_configurations,
    API_USERS_SS_CONFIGURATIONS_INDEX_STATUS,
  } = pageState

  const renderContents = () => {
    if (ss_configurations.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            New Salary Structure Configuration
          </Button>
        </Empty>
      )
    }

    return (
      <StyledList>
        {ss_configurations.map((ss_configuration) => (
          <div
            className="item-card"
            key={`item-card-${ss_configuration.id}`}
            onClick={() => handleCardItemClick(ss_configuration.id)}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{ss_configuration.name}</div>
                <div>{}</div>
              </div>
            </div>
            <div className="icon-box">
              <Button type="link">
                <EyeOutlined />
              </Button>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div></div>
          <div className="action-buttons top">
            {/* <Tooltip title="Create or update multiple records by upload">
              <Button onClick={openImportModal} style={{ marginRight: 10 }}>
                Import
              </Button>
            </Tooltip>
            <Tooltip title="Prepares report for download, available under export section">
              <Button
                loading={exporting}
                onClick={handleExport}
                style={{ marginRight: 10 }}
              >
                Export
              </Button>
            </Tooltip> */}
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                New Salary Structure Configuration
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const uploadProps = {
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([file])
      return false
    },
    fileList,
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content ss-configurations-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_SS_CONFIGURATIONS_INDEX_STATUS,
          data: ss_configurations,
          render: renderPageContent,
        })}
      </StyledBox>
      <Modal
        title="Import"
        visible={importModalVisible}
        confirmLoading={importing}
        closable={false}
        maskClosable={false}
        destroyOnClose
        onOk={handleImport}
        okText="Import"
        onCancel={() => setImportModalVisible(false)}
      >
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Please ensure data accuracy in your file for upload. After upload,
            each associated records will be updated directly.
          </p>
        </Upload.Dragger>
      </Modal>
    </div>
  )
}

export default view(SSConfigurationsIndexPage)

import React, { useState, useEffect } from "react"
import { Redirect, useParams, useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Button, Form, message, Input } from "antd"

import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store"

import StyledBox from "components/common/styled-components/StyledBox"
import VendorsService from "services/users/vendors.service"

const StyledUserEditForm = styled.div``

const VendorEditPage = () => {
  const pageState = globalStore.ui.configuration.budgetHeads.edit
  const { vendorId } = useParams()
  const history = useHistory()
  const [vendorName, setVendorName] = useState("")
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const tempState = {}

      await VendorsService.index({ pageState: tempState })
      const serverStatus = tempState.serverStatus

      if (serverStatus.status !== 200) {
        message.error("Oops! Something went wrong.")
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const tempState = {}
      await VendorsService.show({ pageState: tempState, vendorId })
      const { serverStatus } = tempState
      const vendor = tempState.vendor

      if (serverStatus && serverStatus.status === 200) {
        const { name } = vendor
        setVendorName(name)
      }
    }
    fetchData()
  }, [vendorId])

  const handleUpdate = async () => {
    const tempState = {}
    const postData = {
      vendor: {
        name: vendorName
      },
    }

    setUpdating(true)
    await VendorsService.update({
      pageState: tempState,
      values: postData,
      vendorId,
    })
    setUpdating(false)

    const { serverStatus, vendorError } = tempState

    if (serverStatus.status !== 200) {
      message.error("budgetHead couldn't be updated")
      return
    }

    if (vendorError?.length > 0) {
      message.error(vendorError[0])
      return
    }

    message.success("Vendor updated.")
    history.push("/configuration/vendors")
  }

  const handleNameChange = (e) => {
    setVendorName(e.target.value)
  }

  const renderPageContent = () => {

    // const selectedOrganizationRoleIds = organization_roles.map((sor) => sor.id)
    return (
      <StyledUserEditForm>
        <Form layout="vertical">
        <Form.Item label="Vendor Name">
            <Input
              placeholder="Enter Vendor Name"
              value={vendorName}
              onChange={handleNameChange}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "right" }}
              loading={updating}
              onClick={handleUpdate}
            >
              {updating ? "Updating..." : "Update"}
            </Button>
          </Form.Item>
        </Form>
      </StyledUserEditForm>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("user-edit")
  )
    return <Redirect to="/" />

  const { API_BUDGET_HEADS_SHOW_STATUS } = pageState

  return (
    <div className="page-content user-page">
      <StyledBox maxWidth={"500px"}>
        {staleWhileRevalidate({
          status: API_BUDGET_HEADS_SHOW_STATUS,
          data: vendorName,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(VendorEditPage)

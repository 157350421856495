import React from "react"
import { view } from "@risingstack/react-easy-state"

import FitmentApproval from "components/FitmentApproval"

const PendingHireReviewApprovalShowPage = () => {
  return <FitmentApproval />
}

export default view(PendingHireReviewApprovalShowPage)

import React, { useState, useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Form, message } from 'antd'

import { staleWhileRevalidate } from 'utils/render-strategies'
import BudgetHeadsService from 'services/users/budget-heads.service'
import BudgetHeadSteps from 'components/BudgetHeadSteps'

import globalStore from 'store'

import StyledBox from 'components/common/styled-components/StyledBox'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledUserEditForm = styled.div``

const BudgetChildrenNewPage = () => {
	const pageState = globalStore.ui.configuration.budgetHeads.create_children
	const history = useHistory()
	const { budgetHeadId } = useParams()
	// const [budgetHeadName, setBudgetHeadName] = useState("")
	const [steps, setSteps] = useState([{ id: '', name: '' }])
	const [creating, setCreating] = useState(false)

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}

			await BudgetHeadsService.index({ pageState: tempState })

			const serverStatus = tempState.serverStatus
			// const { serverStatus } = tempState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
			}
		}

		fetchData()
	}, [])

	const handleCreate = async () => {
		// if (!budgetHeadName) {
		//   message.error("Name field can't be blank")
		//   return
		// }
		const tempState = {}
		const postData = {
			budget_head: {
				steps,
			},
		}

		setCreating(true)
		await BudgetHeadsService.createChildren({
			pageState: tempState,
			values: postData,
			id: budgetHeadId,
		})
		setCreating(false)

		const { serverStatus, budgetHeadError } = tempState

		if (serverStatus.status !== 200) {
			message.error("budgetHead couldn't be created")
			return
		}

		if (budgetHeadError?.length > 0) {
			message.error(budgetHeadError[0])
			return
		}

		message.success('Budget Criteria created.')
		history.push('/configuration/budget-heads')
	}

	const handleInputChange = (i, event) => {
		const values = [...steps]
		values[i].name = event.target.value
		setSteps(values)
	}

	const handleAddStep = () => {
		const stepsCopy = JSON.parse(JSON.stringify(steps))
		setSteps([...stepsCopy, { name: '' }])
	}
	const handleDeleteStep = (index) => {
		const stepsCopy = JSON.parse(JSON.stringify(steps))
		stepsCopy.splice(index, 1)
		setSteps(stepsCopy)
	}

	const renderPageContent = () => {
		// const selectedOrganizationRoleIds = organization_roles.map((sor) => sor.id)
		return (
			<StyledUserEditForm>
				<Form layout='vertical'>
					<BudgetHeadSteps
						headSteps={steps}
						onInputChange={handleInputChange}
						onAddStep={handleAddStep}
						onDeleteStep={handleDeleteStep}
						showHierarchy={false}
						type='sub-budget'
					/>
					<Form.Item>
						<Button
							type='primary'
							htmlType='submit'
							style={{ cssFloat: 'right' }}
							loading={creating}
							onClick={handleCreate}>
							{creating ? 'Creating...' : 'Create'}
						</Button>
					</Form.Item>
				</Form>
			</StyledUserEditForm>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['budget-data-edit', 'smart-budget'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/' />

	const { API_BUDGET_HEADS_SHOW_STATUS } = pageState

	return (
		<div className='page-content user-page'>
			<StyledBox maxWidth={'500px'}>
				{staleWhileRevalidate({
					status: API_BUDGET_HEADS_SHOW_STATUS,
					data: { steps },
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetChildrenNewPage)

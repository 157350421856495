import React, { useState } from "react"
import { view } from "@risingstack/react-easy-state"
// import styled from "styled-components"
// import { Button } from "antd"

// import globalStore from "store/index"
import SuperAdminDashboard from "components/SuperAdminDashboard"
// import StyledBox from "components/common/styled-components/StyledBox"

import DashboardService from "services/users/dashboard.service"
// import OrganizationFieldsService from "services/users/organization-fields.service"

// const StyledOAuthButton = styled(StyledBox)`
//   text-align: center;
//   margin-top: 30px;
// `

const DashboardPage = (props) => {
  // const user = globalStore.currentUser
  // const [organizationId, setOrganizationId] = useState(null)
  // const [oAuthToken, setOAuthToken] = useState(null)
  const [dashboardData, setDashboardData] = useState(null)
  // const [syncingOrganizationFields, setSyncingOrganizationFields] = useState(
  //   false
  // )

  const fecthDashboardData = async (startDate, endDate) => {
    const tempState = {}
    const values = {date_range: {start_date: startDate, end_date: endDate}}
    await DashboardService.index({ pageState: tempState, values })
      const { unserialized } = tempState
      if (unserialized) {
        setDashboardData(unserialized)
        return
      }
  }

  // useEffect(() => {
  //   if (user) {
  //     const { organization_id, organization_access_token_response } = user

  //     setOrganizationId(organization_id)
  //     setOAuthToken(organization_access_token_response?.access_token)
  //   }
  // }, [user])

  // const connectToOAuth = () => {
  //   if (!organizationId) return

  //   const url = `${process.env.REACT_APP_API_BASE_URL}/auth/smart-recruiters/${organizationId}/oauth-login`

  //   window.location = url
  // }

  // const syncOrganizationFields = async () => {
  //   const tempState = {}

  //   setSyncingOrganizationFields(true)
  //   await OrganizationFieldsService.sync({ pageState: tempState })
  //   setSyncingOrganizationFields(false)
  // }

  // TODO: Move sync and oauth button to other setting/integration
  return (
    <div className="page-content dashboard-page">
      <SuperAdminDashboard data={dashboardData} fecthDashboardData={fecthDashboardData} />
      {/* <StyledOAuthButton>
        <Button
          type="primary"
          onClick={syncOrganizationFields}
          loading={syncingOrganizationFields}
        >
          Sync Organization Fields
        </Button>
      </StyledOAuthButton> */}
      {/* {organizationId && (
        <StyledOAuthButton>
          <Button disabled={oAuthToken} onClick={connectToOAuth}>
            {oAuthToken ? "Already connected" : "Connect"} to OAuth
          </Button>
        </StyledOAuthButton>
      )} */}
    </div>
  )
}

export default view(DashboardPage)

import React, { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Button, Select, message, Input, Row, Col } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import globalStore from "store/index"

import FitmentsService from "services/users/fitments.service"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const UpdateDesignationPage = () => {
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const history = useHistory()
  const { fitmentId } = useParams()

  const [inputValues, setInputValues] = useState({})
  const [designations, setDesignations] = useState([])
  const [workLocations, setWorkLocations] = useState([])
  const [subRoleBand, setSubRoleBand] = useState(null)
  const [jobCode, setJobCode] = useState(null)



  useEffect(() => {
    const getDesignationValues = async () => {
      const tempState = {}
      await FitmentsService.designationValues({
        pageState: tempState,
        fitmentId,
      })

      const { designations } = tempState

      if (Array.isArray(designations)) {
        setDesignations(designations)
      }
    }

    getDesignationValues()
  }, [fitmentId])


  useEffect(() => {
    const getWorkLocationValues = async () => {
      const tempState = {}
      await FitmentsService.workLocationValues({
        pageState: tempState,
        fitmentId,
      })

      const { workLocations } = tempState

      if (Array.isArray(workLocations)) {
        setWorkLocations(workLocations)
      }
    }

    getWorkLocationValues()
  }, [fitmentId])

  const handleInputChange = (index, inputType) => {
    const designationData = designations[index]
    setSubRoleBand(designationData.sub_role_band)
    setJobCode(designationData.job_code)

    const newInputValues = {  ...inputValues, [inputType]: `${designationData.name} (${designationData.job_code})`,
                             "Sub Role Band": designationData.sub_role_band,
                             "Job Code": designationData.job_code
                           }


    setInputValues(newInputValues)
  }

  const handleWorkLocationInputChange = (index, inputType) => {
    const workLocationData = workLocations[index]
    const newInputValues = { ...inputValues, [inputType]: workLocationData.name, "Work Location Code": workLocationData.code  }
    setInputValues(newInputValues)
  }

  const handleUpdate = async () => {
    // if (!inputValues["Designation"]) {
    //   message.error("Name can't be blank")
    //   return
    // }
    // NOte:  commented this code because user can only update the work location code so it is not require

    const pageState = {}
    const postData = {
      ...inputValues,
    }

    setCreateButtonLoading(true)
    await FitmentsService.updateDesignation({
      pageState,
      values: {data: postData},
      fitmentId,
    })
    setCreateButtonLoading(false)

    const { serverStatus, fitmentErrors } = pageState

    if (fitmentErrors) {
      message.error(fitmentErrors[0] || "Designation couldn't be updated.")
      return
    }

    if (serverStatus.status === 200) {
      message.success("Designation updated.")
      setInputValues({})
      history.push(`/fitments/${fitmentId}`)
      return
    }

    message.error("Oops!! something went wrong")
  }

  const renderPageContent = () => {
    return (
      <StyledConfigurationFormBox>
        <Row gutter={20}>
          <Col span={5}>
            <div className="input-box">
              <div style={{ marginBottom: 5}}>
                <strong>Designation Name</strong>
              </div>
              <Select
                style={{ width: 250 }}
                value={inputValues["Designation"]}
                onChange={(value) =>
                  handleInputChange(value, "Designation")
                }
              >
                {designations.map((d, i) => (

                  <Select.Option key={i} value={i}>
                    {`${d.name} (${d.job_code}))`}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={5}>
            <div className="input-box">
              <div style={{ marginBottom: 5}}>
                <strong>Job Code</strong>
              </div>
              <Input
                  value={jobCode}
                  onChange={()=>{}}
                  disabled={true}
                  placeholder="Job Code"
                />
            </div>
          </Col>

          <Col span={5}>
            <div className="input-box">
              <div style={{ marginBottom: 5}}>
                <strong>Sub Role Band</strong>
              </div>
              <Input
                value={subRoleBand}
                onChange={()=>{}}
                disabled={true}
                placeholder="Sub Role Band"
              />
            </div>
          </Col>
          <Col span={5}>
            <div className="input-box">
              <div style={{ marginBottom: 5}}>
                <strong>Work Location</strong>
              </div>
              <Select
                style={{ width: 250 }}
                value={inputValues["Work Location"]}
                onChange={(value) =>
                  handleWorkLocationInputChange(value, "Work Location")
                }
              >
                {workLocations.map((wl, i) => (

                  <Select.Option key={i} value={i}>
                    {wl.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <div className="action-buttons submit">
          <Button
            type="primary"
            loading={createButtonLoading}
            onClick={handleUpdate}
          >
            Update Designation
          </Button>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("offer-edit")
  )
    return <Redirect to={`/fitments/${fitmentId}`} />

  return (
    <div className="page-content dashboard-page">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(UpdateDesignationPage)

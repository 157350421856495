import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useParams, Redirect, useHistory } from "react-router-dom"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledPageContent from "components/common/styled-components/StyledPageContent"
import TemplateEditor from "components/TemplateEditor"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import EmailTemplatesService from "services/users/email-templates.service"

const EmailTemplatesShowPage = () => {
  const pageState = globalStore.ui.configuration.emailTemplates.show
  const history = useHistory()
  const { emailTemplateId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.emailTemplates.show

      await EmailTemplatesService.show({
        pageState,
        emailTemplateId,
      })

      const { serverStatus, emailTemplateErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (emailTemplateErrors) {
        message.error(
          emailTemplateErrors[0] || "Email Template couldn't be fetched."
        )
        return
      }
    }

    fetchData()
  }, [emailTemplateId])

  const handleEdit = () => {
    history.push(`/configuration/email-templates/${emailTemplateId}/edit`)
  }

  const { email_template, API_USERS_EMAIL_TEMPLATES_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!email_template) return null

    const { content } = email_template
    return (
      <div>
        <div className="input-box">
          <strong>Template</strong>
          <TemplateEditor content={content} editable={false} />
        </div>
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="page-header">
          <h1>{email_template.template_type}</h1>
          <div className="action-buttons top">
            <Button type="primary" ghost onClick={handleEdit}>
              Edit
            </Button>
          </div>
        </div>

        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content email-template-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_EMAIL_TEMPLATES_SHOW_STATUS,
          data: email_template,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(EmailTemplatesShowPage)

import { API, generic } from "api"

export default class ImportsService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_IMPORTS_INDEX_STATUS",
      stateDataKey: ["imports"],
      stateErrorKey: ["importsErrors"],
      apiEndpoint: API.users.imports.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Imports could not be fetched.",
    })
  }

  static download = async ({ pageState, importId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_IMPORTS_DOWNLOAD_STATUS",
      stateDataKey: ["redirect_url"],
      stateErrorKey: ["redirectUrlErrors"],
      apiEndpoint: API.users.imports.download,
      apiUrlReplacements: { importId },
      apiData: {},
      errorMessage: "Import could not be downloaded.",
    })
  }
}

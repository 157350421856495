import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { EyeOutlined, InboxOutlined } from '@ant-design/icons'
import {
	message,
	Button,
	Empty,
	Pagination,
	Table,
	Tooltip,
	Modal,
	Upload,
	Popconfirm,
	Switch,
} from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const BudgetCriteriaHeadsIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.configuration.budgetCriteriaHeads.index
	const [importModalVisible, setImportModalVisible] = useState(false)
	const [fileList, setFileList] = useState([])
	const [importing, setImporting] = useState(false)
	const [importId, setImportId] = useState('')

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetCriteriaHeads = async () => {
			const pageState = globalStore.ui.configuration.budgetCriteriaHeads.index
			await BudgetCriteriaHeadsService.index({ pageState })

			const { serverStatus, budgetCriteriaHeadsErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetCriteriaHeadsErrors) {
				message.error(budgetCriteriaHeadsErrors[0] || "Budget Criteria couldn't be fetched.")
				return
			}
		}

		fetchBudgetCriteriaHeads()
	}, [])

	const repaginate = async (page, pageSize, bcsla = null) => {
		if (bcsla) {
			await BudgetCriteriaHeadsService.index({
				pageState,
				page,
				pageSize,
				bcsla,
			})
			return
		}
		await BudgetCriteriaHeadsService.index({ pageState, page, pageSize })
	}

	const handleCreate = () => {
		history.push('/configuration/budget-criteria-heads/new')
	}

	const openImportModal = (id) => {
		setImportId(id)
		setImportModalVisible(true)
	}

	const handleImport = async () => {
		if (fileList.length === 0) return

		const tempState = {}

		const formData = new FormData()

		formData.append('file', fileList[0])

		setImporting(true)
		await BudgetCriteriaHeadsService.import({ pageState: tempState, formData, id: importId })
		setImporting(false)

		setImportModalVisible(false)

		message.success('Import started. Kindly check import page in Report Center')
	}

	const handleValidate = async (budgetCriteriaHeadId) => {
		const tempState = {}

		await BudgetCriteriaHeadsService.validateCriteriaValues({
			pageState: tempState,
			budgetCriteriaHeadId,
		})
		const { validateCriteriaValuesErrors } = tempState
		if (validateCriteriaValuesErrors) {
			message.error(validateCriteriaValuesErrors[0])
			return
		}

		message.success('Criteria Hierarchy Valid!')
	}
	const handleExport = async (id) => {
		const tempState = {}

		await BudgetCriteriaHeadsService.export({ pageState: tempState, id })

		message.success('Export started. Kindly check export page in Report Center')
	}

	const handleCardItemClick = (budgetCriteriaHeadId) => {
		history.push(`/configuration/budget-criteria-heads/${budgetCriteriaHeadId}`)
	}

	const handleEditItemClick = (budgetCriteriaHeadId) => {
		history.push(`/configuration/budget-criteria-heads/${budgetCriteriaHeadId}/edit`)
	}

	const handleViewItemClick = (budgetCriteriaHeadId) => {
		history.push(
			`/configuration/budget-criteria-heads/${budgetCriteriaHeadId}/budget-criteria-values`
		)
	}

	const handleStatus = async (budgetCriteriaHeadId, activeStatus) => {
		const tempState = {}
		const postData = {
			budget_criteria_head: {
				active_status: !activeStatus,
			},
		}

		await BudgetCriteriaHeadsService.updateActiveStatus({
			pageState: tempState,
			values: postData,
			budgetCriteriaHeadId,
		})

		const { serverStatus, budgetCriteriaHeadError } = tempState

		if (serverStatus.status !== 200) {
			message.error("Budget Criteria status couldn't be updated")
			return
		}

		if (budgetCriteriaHeadError?.length > 0) {
			message.error(budgetCriteriaHeadError[0])
			return
		}

		history.push('/configuration/budget-criteria-heads')
	}

	const handleDelete = async (budgetCriteriaHeadId) => {
		const tempState = {}

		await BudgetCriteriaHeadsService.delete({
			pageState: tempState,
			budgetCriteriaHeadId,
		})

		const { serverStatus } = tempState

		if (serverStatus.status !== 200) return

		globalStore.ui.configuration.budgetCriteriaHeads.index = {
			...globalStore.ui.configuration.budgetCriteriaHeads.index,
			budget_criteria_heads: budget_criteria_heads.filter(
				(budget_criteria_head) => budget_criteria_head.id !== budgetCriteriaHeadId
			),
		}
	}

	const handleCreateBudgetValue = (budgetCriteriaHeadId) => {
		history.push(
			`/configuration/budget-criteria-heads/${budgetCriteriaHeadId}/budget-criteria-values/new`
		)
	}

	const {
		budget_criteria_heads,
		budget_criteria_headsMeta,
		API_USERS_BUDGET_CRITERIA_HEADS_INDEX_STATUS,
		displayType = 'table',
	} = pageState

	const { total, page: currentPage, page_size: pageSize } = budget_criteria_headsMeta || {}

	const renderList = () => {
		return (
			<StyledList>
				{budget_criteria_heads.map((ic) => (
					<div
						className='item-card'
						key={`item-card-${ic.id}`}
						onClick={() => handleCardItemClick(ic.id)}>
						<div className='details-box'>
							<div className='name-box'>
								<div className='name'>{ic.name}</div>
							</div>
							<div>{}</div>
						</div>
						<div className='icon-box'>
							<Button type='link'>
								<EyeOutlined />
							</Button>
						</div>
					</div>
				))}
			</StyledList>
		)
	}

	const renderContents = () => {
		if (budget_criteria_heads.length === 0) {
			return (
				<Empty>
					<Button type='primary' ghost onClick={handleCreate}>
						Create Organization Hierarchy
					</Button>
				</Empty>
			)
		}

		const pagination = (
			<Pagination
				current={currentPage}
				total={total}
				onChange={repaginate}
				onShowSizeChange={repaginate}
				pageSize={pageSize || 25}
				pageSizeOptions={[10, 25, 50, 100]}
			/>
		)

		const tableColumns = [
			...[
				{
					title: 'Name',
					dataIndex: 'display_name',
					key: 'display_name',
				},
				{
					title: 'Hierarchy',
					dataIndex: 'childrens_hierarchy',
					key: 'childrens_hierarchy',
					render: (value, ic, index) => {
						return (
							<div>
								{ic.childrens_hierarchy.map((step, i) => {
									return (
										<span key={i}>
											<Button
												type='link'
												className='textDecoration'
												style={{ padding: 0 }}
												onClick={() => handleViewItemClick(step.id)}>
												<Tooltip title='Show List'>{step.name}</Tooltip>
											</Button>
											<Button
												type='link'
												style={{ padding: 0 }}
												onClick={() => handleCreateBudgetValue(step.id)}>
												<Tooltip title='Add New'>(+)</Tooltip>
											</Button>
											<span>
												{step === '' || i === ic.childrens_hierarchy.length - 1 ? '' : '>'}
											</span>
										</span>
									)
								})}
							</div>
						)
					},
				},
				{
					title: 'Active Status',
					dataIndex: 'status',
					key: 'status',
					render: (value, ic) => {
						return (
							<>
								<Switch
									checked={ic.active_status}
									onChange={() => handleStatus(ic.id, ic.active_status)}
								/>
							</>
						)
					},
				},
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					render: (value, ic) => {
						return (
							<>
								<Button style={{ padding: 0 }} type='link' onClick={() => handleValidate(ic.id)}>
									Validate
								</Button>
								<span> | </span>
								<Button type='link' onClick={() => handleEditItemClick(ic.id)}>Edit</Button>
								<span> | </span>
								<Popconfirm
									title='Are you sure to delete?'
									onConfirm={() => handleDelete(ic.id)}
									onCancel={() => {}}
									okText='Yes'
									cancelText='No'>
									<Button style={{ padding: 0 }} type='link'>
										Delete
									</Button>
								</Popconfirm>
							</>
						)
					},
				},
				{
					title: 'Bulk Action',
					dataIndex: 'use_value',
					key: 'use_value',
					render: (value, ic) => {
						return (
							<>
								<Button style={{ padding: 0 }} type='link' onClick={() => handleExport(ic.id)}>
									Download
								</Button>
								<span> | </span>
								<Button style={{ padding: 0 }} type='link' onClick={() => openImportModal(ic.id)}>
									Import
								</Button>
							</>
						)
					},
				},
			],
		]

		const dataSource = budget_criteria_heads.map((ic) => {
			const retval = { ...ic, key: ic.id }
			return retval
		})

		const renderTable = () => {
			return (
				<Table
					columns={tableColumns}
					dataSource={dataSource}
					pagination={false}
					scroll={{
						x: 'max-content',
					}}></Table>
			)
		}

		const renderInfo = () => {
			if (displayType === 'table') return renderTable()
			return renderList()
		}

		return (
			<div>
				{pagination}
				{networkOnly({
					status: API_USERS_BUDGET_CRITERIA_HEADS_INDEX_STATUS,
					data: budget_criteria_heads,
					render: renderInfo,
				})}

				{pagination}
			</div>
		)
	}

	const renderPageContent = () => {
		// const bcslaSearch = (
		//   <Input.Search
		//     placeholder="BCSLA"
		//     onSearch={(bcsla) => repaginate(1, pageSize, bcsla)}
		//     style={{ width: 200, marginBottom: "10px" }}
		//   />
		// )

		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div>{/* bcslaSearch */}</div>
					<div className='action-buttons top'>
						<Tooltip title='Create a new record'>
							<Button type='primary' ghost onClick={handleCreate}>
								Create Organization Hierarchy
							</Button>
						</Tooltip>
					</div>
				</div>

				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const uploadProps = {
		accept:
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
		onRemove: (file) => {
			const index = fileList.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)

			setFileList(newFileList)
		},
		beforeUpload: (file) => {
			setFileList([file])
			return false
		},
		fileList,
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('criteria-edit'))
		return <Redirect to='/' />

	return (
		<div className='page-content budgets-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_CRITERIA_HEADS_INDEX_STATUS,
					data: budget_criteria_heads,
					render: renderPageContent,
				})}
			</StyledBox>
			<Modal
				title='Import'
				visible={importModalVisible}
				confirmLoading={importing}
				closable={false}
				maskClosable={false}
				destroyOnClose
				onOk={handleImport}
				okText='Import'
				onCancel={() => setImportModalVisible(false)}>
				<Upload.Dragger {...uploadProps}>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>Click or drag file to this area to upload</p>
					<p className='ant-upload-hint'>
						Please ensure data accuracy in your file for upload. All the rows must be filled. After
						upload, each associated records will be updated directly.
					</p>
				</Upload.Dragger>
			</Modal>
		</div>
	)
}

export default view(BudgetCriteriaHeadsIndexPage)

import React from "react"
import styled from "styled-components"
import { LoadingOutlined } from "@ant-design/icons"

const StyledContentLoading = styled.div`
  // width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: content-box;
  padding: 30px;
  & > div {
    text-align: center;
    font-size: 1.2rem;
    & > p {
      margin: 15px 0px 0px 0px;
    }
  }
`

const ContentLoading = ({ title = "Please wait...", style = {} }) => {
  return (
    <StyledContentLoading style={style}>
      <div>
        <LoadingOutlined />
        <p>{title}</p>
      </div>
    </StyledContentLoading>
  )
}

export default ContentLoading

import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import { isMobile } from "react-device-detect"
import { generateThemeColor, changeAntdTheme } from "mini-dynamic-antd-theme"

import { Color } from "constants/theme"

import Routes from "routes"

import ErrorBoundary from "components/common/ErrorBoundary"

import "antd/dist/antd.less"
import "./App.css"

changeAntdTheme(generateThemeColor(Color.brandColor))

function App() {
  return (
    <div className={isMobile ? "mobile" : ""}>
      <ErrorBoundary>
        <Router>
          <Routes />
        </Router>
      </ErrorBoundary>
    </div>
  )
}

export default view(App)

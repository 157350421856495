import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { message } from 'antd'

import * as AuthHelper from 'helpers/auth.helper'
import AppLayout from 'components/common/layouts/AppLayout'
import { fetchFromLocalStorage } from 'store/localStorage'

const PrivateRoute = ({
	withAppLayout = false,
	component: Component,
	loginRoute = '/login',
	headerTitle = '',
	goBackLink,
	match,
	history,
	...rest
}) => {
	const renderer = (props) => {
		if (!AuthHelper.isLoggedIn()) {
			message.info('You must be logged in to do that')

			return (
				<Redirect
					to={{
						pathname: loginRoute,
						search: `?redirectTo=${history.location.pathname}`,
						state: { from: props.location },
					}}
				/>
			)
		}

		const superAdminPathSuffix = '/admin-users'
		const userType = fetchFromLocalStorage({ key: 'userType' })

		if (
			(history.location.pathname.indexOf(superAdminPathSuffix) === 0 &&
				userType !== 'admin-users') ||
			(history.location.pathname.indexOf(superAdminPathSuffix) !== 0 && userType === 'admin-users')
		) {
			message.info('You are not allowed to view the page')
			return (
				<Redirect
					to={{
						pathname:
							history.location.pathname.indexOf(superAdminPathSuffix) === 0 ? '/' : '/admin-users',
					}}
				/>
			)
		}

		if (withAppLayout) {
			return (
				<AppLayout headerTitle={headerTitle} goBackLink={goBackLink}>
					<Component {...props} />
				</AppLayout>
			)
		}

		return <Component {...props} />
	}

	return <Route {...rest} render={renderer} />
}

export default withRouter(view(PrivateRoute))

import { view } from '@risingstack/react-easy-state'
import { Redirect, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import BudgetHeadsService from 'services/users/budget-heads.service'
import globalStore from 'store/index'
import ContentLoading from 'components/common/ContentLoading'
import LABELS from 'constants/labels'

import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, message, Row, Col, Select, DatePicker } from 'antd'
import dayjs from 'dayjs'

import StyledBox from 'components/common/styled-components/StyledBox'
import ConditionRow from 'components/budget-analysis/ConditionRow'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import BudgetValuesService from 'services/users/budget-values.service'
import moment from 'moment'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const blankCondition = {
	field: null,
	operator: null,
	values: null,
}

const PositionOccupancyReportEditPage = () => {
	const { budgetValueId } = useParams()
	const [loading, setLoading] = useState(false)
	const [workforceBudgetHeadId, setWorkforceBudgetHeadId] = useState()
	const [criteria, setCriteria] = useState([[blankCondition]])
	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [rate, setRate] = useState(1)
	const [count, setCount] = useState(1)
	const [total, setTotal] = useState(0)
	const [frequency, setFrequency] = useState('annually')
	const [annualizedRate, setAnnualizedRate] = useState(0)
	const [proportioned, setProportioned] = useState(1)
	const dateFormat = 'DD-MMM-YYYY'
	const currentYear = new Date().getFullYear()
	const [dateRange, setDateRange] = useState([
		moment(currentYear + '-04-01'),
		moment(currentYear + 1 + '-03-31'),
	])
	const [currency, setCurrency] = useState()
	const [criteriaChildren, setCriteriaChildren] = useState([])
	// const [createVia, setCreateVia] = useState('withoutApproval')
	const enableAllocationFeature = globalStore?.currentOrganization?.enable_allocation_feature
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []
	// const roles = globalStore.currentUser?.roles || []

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		setCurrency(globalStore.currentOrganization.currency || 'INR')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalStore.currentOrganization.currency])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetHeads.index

		const fetchBudgetHeads = async () => {
			setLoading(true)
			await BudgetHeadsService.index({ pageState })
			const { budget_heads } = pageState
			if (!budget_heads) {
				message.error('Budget Heads not found')
				return
			}
			const workforceBudgetHead = budget_heads.find((bh) => bh.name === 'Workforce Plan')
			setWorkforceBudgetHeadId(workforceBudgetHead?.id)
			setLoading(false)
		}

		fetchBudgetHeads()

		const fetchAllFieldsData = async () => {
			const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		fetchAllFieldsData()
	}, [])

	useEffect(() => {
		if (criteriaChildren.length) {
			const updateCriteriaData = criteria[0].map((data) => {
				if (criteriaChildren.some((items) => items.name === data.field)) {
					return { ...data, values: null, operator: null }
				}
				return data
			})
			setCriteria([updateCriteriaData])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteriaChildren])

	// useEffect(() => {
	// 	if (!roles.includes('super-admin') && accessibleFeatures.includes('initiate-budget-approval')) {
	// 		setCreateVia('withApproval')
	// 	}
	// }, [accessibleFeatures, roles])

	useEffect(() => {
		// const fetchEssentialFieldsList = async () => {
		// const tempState = {}
		// await BudgetValuesService.essentialFieldsList({
		// 	pageState: tempState,
		// 	budgetHeadId: workforceBudgetHeadId,
		// })

		// const { budget_criteria_heads, dataErrors } = tempState
		// if (dataErrors) {
		// 	message.error(dataErrors || 'Sample File could not be fetched.')
		// 	return
		// }
		// const formatCriteriaList = budget_criteria_heads?.map((data) => {
		// 	const returnData = { field: data?.name, values: null, operator: null }
		// 	return returnData
		// })
		// setCriteria(formatCriteriaList?.length ? [formatCriteriaList] : [[blankCondition]])
		// }
		if (workforceBudgetHeadId) {
			// fetchEssentialFieldsList()
		}
	}, [workforceBudgetHeadId])

	useEffect(() => {
		const fetchBudgetValue = async () => {
			const pageState = globalStore.ui.configuration.budgetValues.edit

			await BudgetValuesService.show({ pageState, budgetValueId })

			const { serverStatus, budget_value, budgetValueErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetValueErrors) {
				message.error(budgetValueErrors || "Couldn't fetch minimum wage")
				return
			}

			const {
				currency,
				frequency,
				rate,
				criteria,
				start_date,
				end_date,
				unit,
				total_cost_number,
				annualized_amount,
				workforce_position = {},
				budget_type,
				allocation_percentage,
				increment_percentage,
			} = budget_value

			const newCriteria = criteria?.length > 0 ? criteria : [[blankCondition]]
			setCriteria(newCriteria)
			const { name, position_code } = workforce_position
			setInputValues({
				currency,
				frequency,
				rate,
				unit,
				total_cost: total_cost_number,
				start_date,
				end_date,
				annualized_amount,
				position_name: name,
				position_code,
				budget_type,
				allocation_percentage,
				increment_percentage,
			})

			const newFrequency = frequency ? frequency : 'annually'
			const newAnnualizedAmount = annualized_amount
				? annualized_amount
				: getAnnualizedRate(rate, newFrequency)

			// setBudgetHeadName(budget_head_name)

			setCount(unit)
			setRate(rate)
			setTotal(total_cost_number)
			setFrequency(newFrequency)
			setProportioned(getProportionedFactor(moment(start_date || dateRange[0]), moment(end_date || dateRange[1])))
			setAnnualizedRate(newAnnualizedAmount)
			setDateRange([moment(start_date || dateRange[0]), moment(end_date || dateRange[1])])
		}
		fetchBudgetValue()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [budgetValueId, workforceBudgetHeadId])

	const handleInputChange = (value, inputType) => {
		let newInputValues = null

		if (inputType === 'frequency') {
			newInputValues = { ...inputValues, [inputType]: value }
			setFrequency(value)
			setAnnualizedRate(getAnnualizedRate(rate, value))
			setTotal(getTotal(count, rate * getFrequencyDays(value), proportioned, value))
		} else {
			newInputValues = { ...inputValues, [inputType]: value }
		}

		if (inputType === 'rate') {
			setRate(value)
			setTotal(getTotal(count, getAnnualizedRate(value, frequency), proportioned, frequency))
			setAnnualizedRate(getAnnualizedRate(value, frequency))
		}

		if (inputType === 'unit') {
			setCount(value)
			setTotal(getTotal(value, annualizedRate, proportioned, frequency))
		}

		if (inputType === 'total_cost') {
			setTotal(value)
			setRate(value / count)
			setAnnualizedRate(getAnnualizedRate(value / count, frequency))
		}

		if (inputType === 'currency') {
			setCurrency(value)
		}

		setInputValues(newInputValues)
	}

	const handleDateChange = (range) => {
		if (range) {
			setDateRange([range[0], range[1]])

			setProportioned(getProportionedFactor(range[0], range[1]))

			setInputValues({
				...inputValues,
				start_date: dateRange[0],
				end_date: dateRange[1],
			})
			setTotal(
				getTotal(count, annualizedRate, getProportionedFactor(range[0], range[1]), frequency)
			)
		}
	}

	const getTotal = (count, annualizedRate, proportioned, frequency) => {
		if (frequency === 'one_time') {
			return Math.round(count * annualizedRate * 100) / 100
		} else {
			return Math.round(count * annualizedRate * proportioned * 100) / 100
		}
	}

	const getProportionedFactor = (startDate, endDate) => {
		return Math.round((getPeriodDays(startDate, endDate) / 365) * 100) / 100
	}

	const getAnnualizedRate = (rate, frequency) => {
		return rate * getFrequencyDays(frequency)
	}

	const getFrequencyDays = (frequency) => {
		if (frequency === 'daily') {
			return 365.0
		} else if (frequency === 'half_yearly') {
			return 2 // 2.027
		} else if (frequency === 'quarterly') {
			return 4 // 4.05
		} else if (frequency === 'monthly') {
			return 12 // 12.16
		} else if (frequency === 'weekly') {
			return 52 // 52.14
		} else if (frequency === 'annually') {
			return 1
		} else if (frequency === 'one_time') {
			return 1
		}
	}

	const getPeriodDays = (startDate, endDate) => {
		const oneDay = 24 * 60 * 60 * 1000
		const days = Math.round(Math.abs((endDate - startDate) / oneDay))
		return days + 1
	}

	const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(deletedConditionIndex, 1)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleCreateConditionValues = ({ conditionSetIndex, conditionIndex, condition }) => {
		if (!condition) return
		if (!condition.values) return

		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				return newConditionSet.map((c, index) => {
					if (index !== conditionIndex) return c

					return condition
				})
			})

			return newCriteria
		})
	}

	const handleCreate = async () => {
		inputValues['currency'] = currency
		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues['total_cost'] = total
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()
		inputValues['frequency'] = frequency
		inputValues['annualized_amount'] = annualizedRate

		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}
		if (!inputValues['frequency']) {
			message.error("Frequency can't be blank")
			return
		}

		if (count < 1 && !inputValues['position_code']) {
			message.error("Position Code can't be blank")
			return
		}

		const pageState = {}
		const tentativeData = {
			budget_value: {
				criteria: criteria.filter((c) => c.length > 0),
				...inputValues,
			},
		}
		// const postData = {
		// 	budget_tentative_value: {
		// 		budget_head_id: workforceBudgetHeadId,
		// 		tentative_data: tentativeData,
		// 		budget_type: 'planned',
		// 	},
		// }

		setCreateButtonLoading(true)
		// if (createVia === 'withApproval')
		// 	await BudgetTentativeValuesService.editPosition({
		// 		pageState,
		// 		values: postData,
		// 	})
		// else
		await BudgetValuesService.editPosition({
			pageState,
			values: tentativeData,
			budgetHeadId: workforceBudgetHeadId,
			budgetValueId,
		})

		setCreateButtonLoading(false)

		const { serverStatus, budgetValueErrors } = pageState

		if (budgetValueErrors) {
			message.error(budgetValueErrors)
			return
		}

		if (serverStatus.status === 200) {
			message.success('Position updated successfully.')
			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h1>Planned Value for Workforce Plan</h1>
				</div>
				<Row gutter={20}>
					<Col span={16}>
						<div className='input-box'>
							<div>
								<strong>
									Position Period <span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<DatePicker.RangePicker
								format={dateFormat}
								value={dateRange}
								onChange={handleDateChange}
							/>
						</div>
					</Col>
					<Col span={8} pull={1}>
						<div className='input-box'>
							<strong>Proportioned Factor</strong>
							<Input placeholder='Proportioned Factor' value={proportioned} disabled={true} />
						</div>
					</Col>
					<Col span={2}>
						<div className='input-box'>
							<strong>Currency</strong>
							<Input
								placeholder='Currency'
								value={currency}
								onChange={(e) => handleInputChange(e.target.value, 'currency')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>
								Headcount
								<span style={{ color: 'red' }}>*</span>
							</strong>
							<Input
								placeholder='Enter Count'
								value={count || inputValues['unit']}
								onChange={(e) => handleInputChange(e.target.value, 'unit')}
								disabled
							/>
						</div>
					</Col>
					<Col span={5}>
						<div className='input-box'>
							<strong>
								Salary Rate per person
								<span style={{ color: 'red' }}>*</span>
							</strong>
							<Input
								placeholder='Enter Rate'
								value={rate || inputValues['rate']}
								onChange={(e) => handleInputChange(e.target.value, 'rate')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>
								Frequency <span style={{ color: 'red' }}>*</span>
							</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose frequency'
								defaultValue={frequency}
								onChange={(value) => handleInputChange(value, 'frequency')}>
								<Select.Option value='daily'>Daily</Select.Option>
								<Select.Option value='weekly'>Weekly</Select.Option>
								<Select.Option value='monthly'>Monthly</Select.Option>
								<Select.Option value='quarterly'>Quarterly</Select.Option>
								<Select.Option value='half_yearly'>Half Yearly</Select.Option>
								<Select.Option value='annually'>Annually</Select.Option>
								<Select.Option value='one_time'>One Time</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Annualised Rate</strong>
							<Input placeholder='Annualised Rate' value={annualizedRate} disabled={true} />
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Total Cost</strong>
							<Input
								placeholder='Total Cost'
								value={total || inputValues['total_cost']}
								onChange={(e) => handleInputChange(e.target.value, 'total_cost')}
							/>
						</div>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={6}>
						<div className='input-box'>
							<strong>Budget Type</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose Budget Type'
								value={inputValues['budget_type']}
								onChange={(value) => handleInputChange(value, 'budget_type')}>
								<Select.Option value='budgeted'>Budgeted</Select.Option>
								<Select.Option value='non_budgeted'>Non-budgeted</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={6}>
						<div className='input-box'>
							<div>
								<strong>
									Position Code <span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<Input
								placeholder='Enter Position Code'
								value={inputValues['position_code']}
								disabled={count > 1}
								onChange={(e) => handleInputChange(e.target.value, 'position_code')}
							/>
						</div>
					</Col>
					<Col span={6}>
						<div className='input-box'>
							<strong>Position Name</strong>
							<Input
								placeholder='Enter Position Name'
								value={inputValues['position_name']}
								onChange={(e) => handleInputChange(e.target.value, 'position_name')}
							/>
						</div>
					</Col>
				</Row>
				{enableAllocationFeature && (
					<Row gutter={20}>
						<Col span={6}>
							<div className='input-box'>
								<strong>Allocation Percentage</strong>
								<Input
									placeholder='Enter Allocation Percentage'
									value={inputValues['allocation_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'allocation_percentage')}
								/>
							</div>
						</Col>
						<Col span={6}>
							<div className='input-box'>
								<strong>Increment Percentage</strong>
								<Input
									placeholder='Enter Increment Percentage'
									value={inputValues['increment_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'increment_percentage')}
								/>
							</div>
						</Col>
					</Row>
				)}
				<div className='input-box'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<strong>Criteria</strong>
						{criteria.filter((c) => c.length > 0).length === 0 && (
							<Button shape='circle' onClick={() => setCriteria([[blankCondition]])}>
								<PlusOutlined />
							</Button>
						)}
					</div>
					{criteria.map((conditionSet, conditionSetIndex) => {
						return (
							<div key={conditionSetIndex}>
								{conditionSet.map((condition, conditionIndex) => {
									return (
										<ConditionRow
											criteria={criteria}
											condition={condition}
											key={conditionIndex}
											conditionIndex={conditionSetIndex}
											clearChildren={setCriteriaChildren}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											conditionSetLength={conditionSet.length}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleCreateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											budgetCriteriaHeads={budgetCriteriaHeads}
										/>
									)
								})}
							</div>
						)
					})}
				</div>
				<div className='action-buttons submit'>
					{/* Todo approval workflow in position */}
					{/* {roles.includes('super-admin') && (
						<>
							<strong>Create Position via :</strong>
							<Select
								style={{ margin: '0 20px' }}
								placeholder='Choose'
								value={createVia}
								onChange={(value) => setCreateVia(value)}>
								<Select.Option value='withoutApproval'>Without Approval</Select.Option>
								<Select.Option value='withApproval'>With Approval</Select.Option>
							</Select>
						</>
					)} */}
					<Button type='primary' loading={createButtonLoading} onClick={handleCreate}>
						Update Position
						{/* {createVia === 'withApproval' ? 'Send Position for Approval' : 'Update Position'} */}
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	if (
		accessibleFeatures?.length > 0 &&
		!['criteria-edit', 'wfm-position-edit', 'budget-data-edit'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/' />

	if (loading) {
		return <ContentLoading />
	}

	if (workforceBudgetHeadId)
		return (
			<div className='page-content dashboard-page'>
				<StyledBox>{renderPageContent()}</StyledBox>
			</div>
		)
}

export default view(PositionOccupancyReportEditPage)

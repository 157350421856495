import { view } from '@risingstack/react-easy-state'
import { Button, message } from 'antd'
import StyledBox from 'components/common/styled-components/StyledBox'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import OrganizationConfigurationsService from 'services/users/organization-configurations.service'

import globalStore from 'store/index'
import styled from 'styled-components'
import DropDownConditionalRow from 'components/common/DropDownConditionalRow'

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const OrganizationConfigurationPage = () => {
	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [configuration, setConfiguration] = useState([{ name: null, value: null }])
	const [candidatePropertiesMapping, setCandidatePropertiesMapping] = useState([
		{ name: null, value: null },
	])

	useEffect(() => {
		const fetchAllFieldsData = async () => {
			const tempState = {}
			await OrganizationConfigurationsService.me({ pageState: tempState })
			const { organization } = tempState
			const configuration = organization?.conf_attributes
			const candidatePropertiesMapping = organization?.sr_candidate_properties_mapping
			setConfiguration(
				configuration && JSON.stringify(configuration) !== '{}'
					? Object.keys(configuration).map((key) => ({
							name: key,
							value: configuration[key],
					  }))
					: [{ name: null, value: null }]
			)
			setCandidatePropertiesMapping(
				candidatePropertiesMapping && JSON.stringify(candidatePropertiesMapping) !== '{}'
					? Object.keys(candidatePropertiesMapping).map((key) => ({
							name: key,
							value: candidatePropertiesMapping[key],
					  }))
					: [{ name: null, value: null }]
			)
		}
		fetchAllFieldsData()
	}, [])

	const handleCreate = async () => {
		const lastConfigurationFieldItem = configuration[configuration.length - 1]
		const lastCandidatePropertiesMappingFieldItem =
			candidatePropertiesMapping[candidatePropertiesMapping.length - 1]

		if (
			configuration.length > 1 &&
			(!lastConfigurationFieldItem.name || !lastConfigurationFieldItem.value)
		) {
			message.error('Configuration Details cannot be blank')
			return
		}
		if (
			candidatePropertiesMapping.length > 1 &&
			(!lastCandidatePropertiesMappingFieldItem.name ||
				!lastCandidatePropertiesMappingFieldItem.value)
		) {
			message.error('Candidate Properties Mapping Details cannot be blank')
			return
		}

		const pageState = {}
		const postData = {
			configuration: {
				conf_attributes: configuration.reduce(
					(obj, item) => (item.name !== null ? ((obj[item.name] = item.value), obj) : obj),
					{}
				),
				sr_candidate_properties_mapping: candidatePropertiesMapping.reduce(
					(obj, item) => (item.name !== null ? ((obj[item.name] = item.value), obj) : obj),
					{}
				),
			},
		}
		const id = globalStore.currentOrganization.id

		setCreateButtonLoading(true)
		await OrganizationConfigurationsService.update({ pageState, id, values: postData })
		setCreateButtonLoading(false)

		const { serverStatus, incentiveVariableErrors } = pageState

		if (incentiveVariableErrors) {
			message.error(incentiveVariableErrors[0] || 'Failed to create country details')
			return
		}

		if (serverStatus.status === 200) {
			message.success('Organization Configuration updated.')
			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<div className='input-box'>
					<strong>Configuration</strong>
					<DropDownConditionalRow fieldData={configuration} setFieldData={setConfiguration} />
				</div>
				<div className='input-box'>
					<strong>Candidate Properties Mapping for Smart Recruiter</strong>
					<DropDownConditionalRow
						fieldData={candidatePropertiesMapping}
						setFieldData={setCandidatePropertiesMapping}
						placeholder={{ name: 'Choose Name', value: 'Enter Property Id' }}
					/>
				</div>

				<div className='action-buttons submit'>
					<Button type='primary' loading={createButtonLoading} onClick={handleCreate}>
						Update Organization Configuration
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('organization-edit'))
		return <Redirect to={`/`} />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(OrganizationConfigurationPage)

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { message, Button, Tooltip, Pagination, Table, Popconfirm } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'

import globalStore from 'store/index'
import BudgetTentativeValuesService from 'services/users/budget-tentative-values.service'

const AdminBudgetApprovalStatusIndexPage = () => {
	const history = useHistory()
	const location = useLocation()
	const pageState = globalStore.ui.configuration.budgetTentativeValues.index
	const [budgetTentativeValues, setBudgetTentativeValues] = useState(null)
	const [tableDataLoading, setTableDataLoading] = useState(false)
	const ifApproval = location.pathname === '/configuration/admin-workforce-approval-status'

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.configuration.budgetTentativeValues.index
			setTableDataLoading(true)
			await BudgetTentativeValuesService.index({
				pageState,
				module: ifApproval ? 'Workforce Plan' : '',
			})
			setTableDataLoading(false)
			const { budget_tentative_values } = pageState

			if (budget_tentative_values) {
				setBudgetTentativeValues(budget_tentative_values)
				return
			}

			message.error('Failed to load Budget Tentative Values')
		}

		fetchData()
	}, [ifApproval])

	const { budget_tentative_valuesMeta } = pageState

	const repaginate = async (page, pageSize) => {
		const tempState = {}

		setTableDataLoading(true)

		await BudgetTentativeValuesService.index({ pageState: tempState, page, pageSize })

		setTableDataLoading(false)

		const { budget_tentative_values, budget_tentative_valuesMeta } = tempState

		if (budget_tentative_values) {
			setBudgetTentativeValues(budget_tentative_values)
			pageState.budget_tentative_valuesMeta = budget_tentative_valuesMeta
			return
		}
	}

	const { total, page: currentPage, page_size: pageSize } = budget_tentative_valuesMeta || {}

	const pagination = (
		<Pagination
			current={currentPage}
			total={total}
			onChange={repaginate}
			onShowSizeChange={repaginate}
			pageSize={pageSize || 25}
			pageSizeOptions={[10, 25, 50, 100]}
		/>
	)

	const handleBudgetTentativeValueClick = (budgetTentativeValueId) => {
		if (!budgetTentativeValueId) return null
		if (ifApproval) {
			history.push(`/configuration/admin-workforce-approval-status/${budgetTentativeValueId}`)
			return
		}
		history.push(`/configuration/admin-budget-approval-status/${budgetTentativeValueId}`)
	}

	const handleEdit = (budgetTentativeValueId) => {
		if (!budgetTentativeValueId) return null
		if (ifApproval){
			history.push(`/configuration/admin-workforce-approval-status/${budgetTentativeValueId}/edit`)
		return
		}
		history.push(`/configuration/admin-budget-approval-status/${budgetTentativeValueId}/edit`)
	}

	const formatBudgetTentativeValues = () => {
		if (!budgetTentativeValues) return []
		if (!Array.isArray(budgetTentativeValues)) return []
		return budgetTentativeValues.map((budgetTentativeValue) => {
			return {
				key: budgetTentativeValue?.id,
				budgetTentativeValueId: budgetTentativeValue?.id,
				status: budgetTentativeValue?.status,
				budget_head: budgetTentativeValue?.budget_head?.name,
				budget_type: budgetTentativeValue?.budget_type,
			}
		})
	}

	const statusColor = (status) => {
		if (['details_updated', 'validated', 'in_progress'].includes(status)) return 'orange'
		if (['approved'].includes(status)) return 'green'
		if (['rejected', 'cancelled'].includes(status)) return 'red'
		return 'gray'
	}

	const handleDelete = async (budgetTentativeValueId) => {
		const tempState = {}

		await BudgetTentativeValuesService.delete({
			pageState: tempState,
			budgetTentativeValueId,
		})

		const { serverStatus } = tempState

		if (serverStatus.status !== 200) return
		setBudgetTentativeValues(
			budgetTentativeValues.filter((items) => items.id !== budgetTentativeValueId)
		)
	}

	const renderTable = () => {
		const columns = [
			{
				title: 'Budget Head',
				dataIndex: 'budget_head',
				key: 'budget_head',
			},
			{
				title: 'Budget Type',
				dataIndex: 'budget_type',
				key: 'budget_type',
				render: (value) => <span className='text-capitalize'>{value}</span>,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				filters: [
					{
						text: 'Pending',
						value: 'pending',
					},
					{
						text: 'In progress',
						value: 'in_progress',
					},
					{
						text: 'Approved',
						value: 'approved',
					},
					{
						text: 'Rejected',
						value: 'rejected',
					},
				],
				onFilter: (value, record) => record.status.includes(value),
				render: (status, row) => {
					return (
						<div className='fitment__status-box'>
							<Tooltip title={status && status.split('_').join(' ')}>
								<div className={`fitment__status ${statusColor(status)}`}></div>
							</Tooltip>
							<span>{status && status.split('_').join(' ')}</span>
						</div>
					)
				},
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				align: 'right',
				render: (action, row) => {
					return (
						<div className='icon-box'>
							<Button
								type='link'
								onClick={() => handleBudgetTentativeValueClick(row?.budgetTentativeValueId)}>
								<EyeOutlined />
							</Button>
							{row.status === 'pending' && (
								<>
									<Button type='link' onClick={() => handleEdit(row?.budgetTentativeValueId)}>
										<EditOutlined />
									</Button>
									<Tooltip title='Delete'>
										<Popconfirm
											title='Are you sure to delete?'
											onConfirm={() => handleDelete(row?.budgetTentativeValueId)}
											onCancel={() => {}}
											okText='Yes'
											cancelText='No'>
											<Button type='link'>
												<DeleteOutlined />
											</Button>
										</Popconfirm>
									</Tooltip>
								</>
							)}
						</div>
					)
				},
			},
		]
		return (
			<div style={{ margin: '10px 0px' }}>
				<Table
					columns={columns}
					dataSource={formatBudgetTentativeValues()}
					loading={tableDataLoading}
					pagination={false}
				/>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
					}}>
					{pagination}
				</div>
				{renderTable()}
				{pagination}
			</div>
		)
	}

	const { API_USERS_BUDGET_TENTATIVE_VALUES_INDEX_STATUS } = pageState

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_TENTATIVE_VALUES_INDEX_STATUS,
					data: true,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(AdminBudgetApprovalStatusIndexPage)

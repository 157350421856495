import React, { useState, useEffect } from 'react'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Form, message, Input } from 'antd'

import { staleWhileRevalidate } from 'utils/render-strategies'
import BudgetHeadsService from 'services/users/budget-heads.service'
import globalStore from 'store'

import StyledBox from 'components/common/styled-components/StyledBox'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledUserEditForm = styled.div``

const BudgetHeadsEditPage = () => {
	const pageState = globalStore.ui.configuration.budgetHeads.edit
	const { budgetHeadId } = useParams()
	const history = useHistory()
	const [budgetHeadName, setBudgetHeadName] = useState('')
	const [updating, setUpdating] = useState(false)

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}

			await BudgetHeadsService.index({ pageState: tempState })
			const serverStatus = tempState.serverStatus
			// const { serverStatus } = tempState

			if (serverStatus.status !== 200) {
				message.error('Oops! Something went wrong.')
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}
			await BudgetHeadsService.show({ pageState: tempState, budgetHeadId })
			const { serverStatus } = tempState
			const budgetHead = tempState.budget_head
			// const serverStatus = tempState.serverStatu
			// const parent_id = tempState.budget_heads
			// const { serverStatus } = tempState

			if (serverStatus && serverStatus.status === 200) {
				const { name } = budgetHead
				setBudgetHeadName(name)
			}
		}
		fetchData()
	}, [budgetHeadId])

	const handleUpdate = async () => {
		const tempState = {}
		const postData = {
			budget_head: {
				name: budgetHeadName,
			},
		}

		setUpdating(true)
		await BudgetHeadsService.update({
			pageState: tempState,
			values: postData,
			budgetHeadId,
		})
		setUpdating(false)

		const { serverStatus, budgetHeadError } = tempState

		if (serverStatus.status !== 200) {
			message.error("budgetHead couldn't be updated")
			return
		}

		if (budgetHeadError?.length > 0) {
			message.error(budgetHeadError[0])
			return
		}

		message.success('Budget Head updated.')
		history.push('/configuration/budget-heads')
	}

	const handleNameChange = (e) => {
		setBudgetHeadName(e.target.value)
	}

	const renderPageContent = () => {
		// const selectedOrganizationRoleIds = organization_roles.map((sor) => sor.id)
		return (
			<StyledUserEditForm>
				<Form layout='vertical'>
					<Form.Item label='Budget Name'>
						<Input
							placeholder='Enter Budget Name'
							value={budgetHeadName}
							onChange={handleNameChange}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							type='primary'
							htmlType='submit'
							style={{ cssFloat: 'right' }}
							loading={updating}
							onClick={handleUpdate}>
							{updating ? 'Updating...' : 'Update'}
						</Button>
					</Form.Item>
				</Form>
			</StyledUserEditForm>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['budget-data-edit', 'smart-budget'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/' />

	const { API_BUDGET_HEADS_SHOW_STATUS } = pageState

	return (
		<div className='page-content user-page'>
			<StyledBox maxWidth={'500px'}>
				{staleWhileRevalidate({
					status: API_BUDGET_HEADS_SHOW_STATUS,
					data: budgetHeadName,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetHeadsEditPage)

import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { EditOutlined, RedoOutlined } from "@ant-design/icons"
import { Button } from "antd"
import moment from 'moment'

import IncentivesService from "services/users/incentives.service"
import FitmentsService from "services/users/fitments.service"

import { hideStandardFields } from "utils/fitment"
import { MONTHLY_DEMINIMIS_ALLOWANCES } from "constants/fitment"
import { strftimeTOMomentFormat } from "utils/strftime-to-moment-format"
import globalStore from "store/index"

const localizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(localizedFormat)

const Rows = ({
  applicableFitmentFieldsConfigurations = [],
  applicableFitmentFields = [],
  dateFormat
}) => {
  const filteredApplicableFitmentFieldsConfigurations =
    applicableFitmentFieldsConfigurations.filter((f) => {
      if (!f.parent_variable_id) return true
      const parent_applicable_fitment_field = applicableFitmentFields.find(
        (aff) => aff.fitment_fields_configuration_id === f.parent_variable_id
      )
      
      if(f.parent_variable_value){
        const parentVariableValue = f.parent_variable_value?.map(data => data?.toLowerCase())
        if (parentVariableValue?.includes(parent_applicable_fitment_field?.value?.toLowerCase())) return true
}
      return false
    })

  const fitmentFieldsByFitmentFieldsConfigurationId = Object.fromEntries(
    applicableFitmentFields.map((ff) => [
      ff.fitment_fields_configuration_id,
      ff,
    ])
  )

  return filteredApplicableFitmentFieldsConfigurations.map((ffc, i) => {
    const fitmentField = fitmentFieldsByFitmentFieldsConfigurationId[ffc.id]

    if (ffc.name.toUpperCase() === "PHL MONTHLY DE-MINIMIS ALLOWANCES")
      return (
        <div className="item-row fitment-field" key={`item-${i}`}>
          <div className="label">{ffc.name}</div>
          <div>{MONTHLY_DEMINIMIS_ALLOWANCES}</div>
        </div>
      )
    return (
		<div className='item-row fitment-field' key={`item-${i}`}>
			<div className='label'>{ffc.name}</div>
			<div>
				{fitmentField?.data_type === 'date'
					? moment(fitmentField?.value).format(strftimeTOMomentFormat(dateFormat))
					: fitmentField?.value}
			</div>
		</div>
    )
  })
}

const FieldsWithCTC = ({
  fitment,
  editable = false,
  externalView = false,
  incentiveConfigurationMsg,
  onSetFitment,
}) => {
  const history = useHistory()
  const {
    previous_ctc_fixed,
    previous_ctc_variable,
    ctc,
    joining_date,
    joining_bonus,
    status,
    rate,
    frequency,
    date_format,
  } = fitment

  const [incentiveRefreshing, setIncentiveRefreshing] = useState(false)
  const [joiningDateRefreshing, setJoiningDateRefreshing] = useState(false)
  const currentOrg = globalStore.currentOrganization

  const handleEdit = () => {
    history.push(`/fitments/${fitment.id}/edit`)
  }

  const handleDesignationChange = () => {
    history.push(`/fitments/${fitment.id}/update-designation`)
  }

  const handleRefreshIncentive = async () => {
    const tempState = {}

    setIncentiveRefreshing(true)
    await IncentivesService.refresh({
      pageState: tempState,
      fitmentId: fitment.id,
    })
    setIncentiveRefreshing(false)

    if (tempState.fitment) {
      onSetFitment(tempState.fitment)
    }
  }

  const handleRefreshJoiningDate = async () => {
    const tempState = {}

    setJoiningDateRefreshing(true)
    await FitmentsService.syncJoiningDate({
      pageState: tempState,
      fitmentId: fitment.id,
    })
    setJoiningDateRefreshing(false)

    if (tempState.fitment) {
      onSetFitment(tempState.fitment)
    }
  }

  const {
    applicable_fitment_fields_configurations,
    applicable_fitment_fields,
  } = fitment
  // const pseudoRoleBand = fitment?.job?.pseudo_role_band
  // Note: Commented code because we allow update designation for all the role band

  const incentiveMessage = () => {
    const { incentive_configuration, calculated_incentive } = fitment
    if (!incentive_configuration) return null

    if (incentive_configuration.payout === "percentage") {
      const calculatedStr = calculated_incentive
        ? `(${calculated_incentive.currency} ${calculated_incentive.annual_amount} Annually)`
        : ""
      // const calculated = fitment.ctc
      //   ? `(${incentive_configuration.currency} ${Math.round(
      //       (fitment.ctc * incentive_configuration.payout_value) / 100
      //     )} Annually)`
      //   : ""
      return `${incentive_configuration.payout_value}% ${incentive_configuration.frequency_display} ${calculatedStr}`
    }

    return `${incentive_configuration.currency} ${incentive_configuration.payout_value} ${incentive_configuration.frequency_display}`
  }

  const hideStandardFieldsObject = hideStandardFields({ fitment })
  return (
		<div className='card fitment-fields'>
			<div className='header'>
				<h1>Candidate Salary Data</h1>
				{currentOrg['is_wns?'] && editable && (
					<div className='action-buttons'>
						{status !== 'approved' &&
							status !== 'docusign_inprogress' &&
							status !== 'docusign_completed' &&
							status !== 'in_progress' && (
								<Button
									type='primary'
									style={{ marginRight: '10px' }}
									ghost
									onClick={handleDesignationChange}>
									Update Designation
								</Button>
							)}
							<Button type='primary' ghost onClick={handleEdit}>
								<EditOutlined /> {ctc ? 'Update' : 'Edit'}
							</Button>
					</div>
				)}
			</div>

			<div className='item-list'>
				{rate && !hideStandardFieldsObject['rate'] && (
					<div className='item-row fitment-field'>
						<div className='label'>Rate</div>
						<div>
							{rate} {frequency}
						</div>
					</div>
				)}
				<div className='item-row fitment-field'>
					<div className='label'>Joining Date</div>
					<div>
						<span>
							{joining_date && moment(joining_date).format(strftimeTOMomentFormat(date_format))}
						</span>
						{!externalView && (
							<Button type='link' onClick={handleRefreshJoiningDate}>
								<RedoOutlined spin={joiningDateRefreshing} rotate={270} />
							</Button>
						)}
					</div>
				</div>
				{!hideStandardFieldsObject['offered_fixed_compensation'] && (
					<div className='item-row fitment-field'>
						<div className='label'>Offered Fixed Compensation</div>
						<div>{ctc}</div>
					</div>
				)}
				{!hideStandardFieldsObject['previous_fixed_compensation'] && (
					<div className='item-row fitment-field'>
						<div className='label'>Previous Fixed Compensation</div>
						<div>{previous_ctc_fixed}</div>
					</div>
				)}
				{!hideStandardFieldsObject['previous_variable_compensation'] && (
					<div className='item-row fitment-field'>
						<div className='label'>Previous Variable Compensation</div>
						<div>{previous_ctc_variable}</div>
					</div>
				)}
				<div className='item-row fitment-field'>
					<div className='label'>Joining Bonus</div>
					<div>{joining_bonus}</div>
				</div>
				{!fitment.disable_features.includes('incentive_configuration') && (
					<div className='item-row fitment-field'>
						<div className='label'>Incentive/Variables</div>
						<div>
							<span>{incentiveMessage() || incentiveConfigurationMsg}</span>
							{!externalView && !['in_progress', 'approved'].includes(fitment.status) && (
								<Button type='link' onClick={handleRefreshIncentive}>
									<RedoOutlined spin={incentiveRefreshing} rotate={270} />
								</Button>
							)}
						</div>
					</div>
				)}
			</div>
			<div className='item-list'>
				<Rows
					applicableFitmentFields={applicable_fitment_fields}
					applicableFitmentFieldsConfigurations={applicable_fitment_fields_configurations}
					dateFormat={date_format}
				/>
			</div>
		</div>
	)
}

export default FieldsWithCTC

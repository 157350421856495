import { API, generic } from "api"

export default class NotificationsService {
  static index = async ({ pageState , page = 1, pageSize = 25}) => {
    await generic({
		pageState,
		stateApiStatusKey: 'API_USERS_NOTIFICATION_INDEX_STATUS',
		stateDataKey: ['notifications'],
		stateErrorKey: ['notificationErrors'],
		apiEndpoint: API.users.notifications.index,
		apiUrlReplacements: {},
		apiData: { params: { page, page_size: pageSize } },
		errorMessage: 'Notification could not be fetched.',
    })
  }
  static show = async ({ pageState , notificationId}) => {
    await generic({
		pageState,
		stateApiStatusKey: 'API_USERS_NOTIFICATION_SHOW_STATUS',
		stateDataKey: ['notification'],
		stateErrorKey: ['notificationErrors'],
		apiEndpoint: API.users.notifications.show,
		apiUrlReplacements: { notificationId },
		apiData: {},
		errorMessage: 'Notification could not be fetched.',
    })
  }
}

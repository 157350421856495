import React from "react"
import { Button, Form, Input } from "antd"
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"

const BudgetHeadSteps = ({
  headSteps,
  onInputChange,
  onAddStep,
  onDeleteStep,
  showHierarchy,
  type,
}) => {
  let name = type === "sub-budget" ? "Sub Budget" : "Step"
  return (
    <div>
      <Form.Item label={name.concat("s")}>
        <div className="input-box">
          {headSteps &&
            headSteps.map((step, index) => {
              return (
                <div
                  key={`${step}-${index}`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Input
                    placeholder={name.concat(index + 1)}
                    value={step.name}
                    onChange={(e) => onInputChange(index, e)}
                  />
                  <Button
                    shape="circle"
                    className="bh_steps"
                    onClick={onAddStep}
                  >
                    <PlusOutlined />
                  </Button>
                  {headSteps.length > 1 && (
                    <Button shape="circle" onClick={() => onDeleteStep(index)}>
                      <MinusOutlined />
                    </Button>
                  )}
                </div>
              )
            })}
        </div>
      </Form.Item>
      { showHierarchy &&
      <Form.Item label="Hierarchy">
        <Input
          type="text"
          disabled="disabled"
          value={headSteps
            .map(
              (s, i) =>
                s.name + (s === "" || i === headSteps.length - 1 ? "" : ">")
            )
            .join("")}
        />
      </Form.Item>}
    </div>
  )
}

export default BudgetHeadSteps

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import OrganizationMinimumSalariesService from "services/users/organization-minimum-salaries.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const OrganizationMinimumSalariesShowPage = (props) => {
  const pageState =
    globalStore.ui.configuration.organizationMinimumSalaries.show
  const history = useHistory()
  const { organizationMinimumSalaryId } = useParams()

  useEffect(() => {
    const fetchMinimumWage = async () => {
      const pageState =
        globalStore.ui.configuration.organizationMinimumSalaries.show

      await OrganizationMinimumSalariesService.show({
        pageState,
        organizationMinimumSalaryId,
      })

      const { serverStatus, organizationMinimumSalaryErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (organizationMinimumSalaryErrors) {
        message.error(
          organizationMinimumSalaryErrors[0] ||
            "Organization minimum salary couldn't be fetched."
        )
        return
      }
    }

    fetchMinimumWage()
  }, [organizationMinimumSalaryId])

  const handleEdit = () => {
    history.push(
      `/configuration/organization-minimum-salaries/${organizationMinimumSalaryId}/edit`
    )
  }

  const {
    organization_minimum_salary,
    API_USERS_ORGANIZATION_MINIMUM_SALARIES_SHOW_STATUS,
  } = pageState

  const renderContent = () => {
    if (!organization_minimum_salary) return null

    const {
      criteria,
      currency,
      amount,
      frequency,
    } = organization_minimum_salary

    return (
      <div>
        <h1>{organization_minimum_salary.name}</h1>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Currency</div>
              <div>{currency}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Amount</div>
              <div>{amount}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Frequency</div>
              <div style={{textTransform: "capitalize"}}>{frequency}</div>
            </div>
          </div>
        </StyledItemList>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/configuration/organization-minimum-salaries" />

  const strategy =
    organization_minimum_salary &&
    organization_minimum_salary.id === organizationMinimumSalaryId
      ? staleWhileRevalidate
      : networkOnly

  return (
    <div className="page-content organization-minimum-salaries-page">
      <StyledBox>
        {strategy({
          status: API_USERS_ORGANIZATION_MINIMUM_SALARIES_SHOW_STATUS,
          data: organization_minimum_salary,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(OrganizationMinimumSalariesShowPage)

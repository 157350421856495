import { API, generic } from "api"

export default class MinimumWagesService {
  static index = async ({ pageState, page = 1, pageSize = 25 }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_INDEX_STATUS",
      stateDataKey: [
        "minimum_wages",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "minimumWagesErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.minimumWages.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize } },
      errorMessage: "Minimum Wages could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_CREATE_STATUS",
      stateDataKey: ["minimum_wage"],
      stateErrorKey: ["minimumWageErrors"],
      apiEndpoint: API.users.minimumWages.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Minimum Wage could not be created.",
    })
  }

  static show = async ({ pageState, minimumWageId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_SHOW_STATUS",
      stateDataKey: ["minimum_wage"],
      stateErrorKey: ["minimumWageErrors"],
      apiEndpoint: API.users.minimumWages.show,
      apiUrlReplacements: { minimumWageId },
      apiData: {},
      errorMessage: "Minimum Wage could not be fetched.",
    })
  }

  static update = async ({ pageState, values, minimumWageId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_UPDATE_STATUS",
      stateDataKey: ["minimum_wage"],
      stateErrorKey: ["minimumWageErrors"],
      apiEndpoint: API.users.minimumWages.update,
      apiUrlReplacements: { minimumWageId },
      apiData: { ...values },
      errorMessage: "Minimum Wage could not be updated.",
    })
  }

  static import = async ({ pageState, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_IMPORT_STATUS",
      stateDataKey: ["minimum_wage"],
      stateErrorKey: ["minimumWageErrors"],
      apiEndpoint: API.users.minimumWages.import,
      apiUrlReplacements: {},
      apiData: formData,
      errorMessage: "Minimum wages could not be imported.",
    })
  }

  static export = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_EXPORT_STATUS",
      stateDataKey: ["minimum_wages"],
      stateErrorKey: ["minimumWagesErrors"],
      apiEndpoint: API.users.minimumWages.export,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Minimum wages could not be exported.",
    })
  }

  static refresh = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_REFRESH_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.minimumWages.refresh,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Minimum Wage could not be refreshed.",
    })
  }

  static revalidate = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_REVALIDATE_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.minimumWages.revalidate,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Minimum Wage could not be revalidated.",
    })
  }

  static delete = async ({ pageState, minimumWageId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_DELETE_STATUS",
      stateDataKey: ["minimum_wage"],
      stateErrorKey: ["minimumWageErrors"],
      apiEndpoint: API.users.minimumWages.delete,
      apiUrlReplacements: { minimumWageId },
      apiData: {},
      errorMessage: "Minimum wage could not be deleted.",
    })
  }

  static auditReport = async ({ pageState, minimumWageId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_AUDIT_REPORT_STATUS",
      stateDataKey: ["minimum_wage"],
      stateErrorKey: ["minimumWageErrors"],
      apiEndpoint: API.users.minimumWages.auditReport,
      apiUrlReplacements: { minimumWageId },
      apiData: {},
      errorMessage: "Audit report could not be exported.",
    })
  }

  static auditReports = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_MINIMUM_WAGES_AUDIT_REPORTS_STATUS",
      stateDataKey: ["minimum_wages"],
      stateErrorKey: ["minimumWagesErrors"],
      apiEndpoint: API.users.minimumWages.auditReports,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Audit reports could not be exported.",
    })
  }
}

import React, { useState, useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { Table, Switch } from 'antd'
import OrganizationRolesService from 'services/users/organization-roles.service'
import styled from 'styled-components'

const DashboardPage = (props) => {
	const RolesContent = styled.div`
		padding: 20px 20px 0 20px;
	`

	const [features, setFeatures] = useState([])
	const [organizationRoles, setOrganizationRoles] = useState([])
	const [tableLoading, setTableLoading] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const pageState = {}

			setTableLoading(true)
			await OrganizationRolesService.index({ pageState })
			setTableLoading(false)

			const { features, organization_roles, serverStatus } = pageState

			if (serverStatus && serverStatus.status === 200) {
				setFeatures(features || [])
				setOrganizationRoles(organization_roles || [])
			}
		}

		fetchData()
	}, [])

	const handleRoleToggle = async ({ roleId, featureKey, checked }) => {
		const pageState = {}
		if (checked) {
			await OrganizationRolesService.addFeature({
				pageState,
				roleId,
				values: { key: featureKey },
			})
			return
		}
		await OrganizationRolesService.removeFeature({
			pageState,
			roleId,
			values: { key: featureKey },
		})
	}

	const getColumns = (roles) => {
		let column = [
			{
				title: '#',
				dataIndex: 'sn',
				key: 'sn',
				width: 50,
				fixed: 'left',
			},
			{
				title: 'Features',
				dataIndex: 'feature',
				key: 'feature',
				width: 220,
				fixed: 'left',
			},
		]

		const rolesColumns = roles.map(({ name, key, id }) => ({
			title: name,
			dataIndex: key,
			key: key,
			width: 100,
			render: (value, row) => {
				return (
					<Switch
						defaultChecked={value}
						size='small'
						onChange={(checked) =>
							handleRoleToggle({
								roleId: id,
								featureKey: row.featureKey,
								checked,
							})
						}
					/>
				)
			},
		}))

		return [...column, ...rolesColumns]
	}

	const columns = getColumns(organizationRoles)

	const tableData = features.map((f, i) => {
		const retval = {
			key: f.id,
			sn: i + 1,
			feature: f.name,
			featureKey: f.key,
		}

		organizationRoles.forEach((or) => {
			retval[or.key] = or.accessible_features.includes(f.key)
		})

		return retval
	})

	return (
		<RolesContent>
			<Table
				loading={tableLoading}
				dataSource={tableData}
				columns={columns}
				scroll={{ x: 1300, y: 440 }}
				pagination={false}
			/>
		</RolesContent>
	)
}

export default view(DashboardPage)

import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { message, Button } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'

import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import { Color } from 'constants/theme'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`

const StyledItemList = styled.div`
	& > div {
		margin-bottom: 50px;
	}

	& .item-row {
		display: flex;
		margin-bottom: 5px;
		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			max-width: 250px;
			justify-content: flex-end;
			text-transform: uppercase;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
			// box-shadow: 0px 5px 20px #0000000d;box-shadowbox-shadowbox-shadowbox-shadowbox-shadow
		}
	}
	& .colored.item-row {
		& div:last-child {
			background: #f9f3ff;
		}
	}
`

const BudgetHeadsShowPage = (props) => {
	const pageState = globalStore.ui.configuration.budgetHeads.show
	const history = useHistory()
	const { budgetHeadId } = useParams()
	// const [deleteLoading, setDeleteLoading] = useState(false)

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetHead = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.show

			await BudgetHeadsService.show({ pageState, budgetHeadId })

			const { serverStatus, budgetHeads } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetHeads) {
				message.error(budgetHeads[0] || "Coundn't fetch Budget Heads")
				return
			}
		}

		fetchBudgetHead()
	}, [budgetHeadId])

	const handleBudgetValueClick = () => {
		history.push(`/configuration/budget-heads/${budgetHeadId}/budget-values`)
	}

	const handleBudgetUtilizationClick = () => {
		history.push(`/configuration/budget-heads/${budgetHeadId}/budget-utilizations`)
	}

	const { budget_head, API_USERS_BUDGET_HEADS_SHOW_STATUS } = pageState

	const renderContent = () => {
		if (!budget_head) return null
		const { name } = budget_head

		return (
			<div>
				<StyledItemList>
					<strong>{name}</strong>
					<Button type='link' ghost onClick={handleBudgetValueClick}>
						Planned Value
					</Button>
					<Button type='link' ghost onClick={handleBudgetUtilizationClick}>
						Actual Value
					</Button>
				</StyledItemList>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					{/* <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button> */}
				</div>
				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	const strategy =
		budget_head && budget_head.id === budgetHeadId ? staleWhileRevalidate : networkOnly

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['smart-budget'].every((featureKey) => accessibleFeatures.includes(featureKey))
	)
		return <Redirect to='/' />

	return (
		<div className='page-content budget-heads-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_BUDGET_HEADS_SHOW_STATUS,
					data: budget_head,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetHeadsShowPage)

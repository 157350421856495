import React, { useState } from 'react'
// import { useHistory } from "react-router-dom"
import dayjs from 'dayjs'
import { RedoOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import FitmentsService from 'services/users/fitments.service'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const Address = ({ fitment, editable = false, onSetFitment }) => {
	const [addressSyncing, setAddressSyncing] = useState(false)
	// const history = useHistory()
	const candidate = fitment['candidate']

	// const handleEdit = () => {
	//   history.push(`/candidates/${candidate.id}/edit`)
	// }

	const handleAddressSync = async () => {
		const tempState = {}

		setAddressSyncing(true)
		await FitmentsService.syncCandidateAddress({
			pageState: tempState,
			fitmentId: fitment.id,
		})
		setAddressSyncing(false)

		if (tempState.fitment) {
			onSetFitment(tempState.fitment)
		}
	}
	return (
		<div className='card fitment-fields'>
			<div className='header'>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<h1 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Address</h1>
					<Button type='link' onClick={handleAddressSync}>
						<RedoOutlined spin={addressSyncing} rotate={270} />
					</Button>
				</div>
				{/* {editable && (
          <div className="action-buttons">
            <Button type="primary" ghost onClick={handleEdit}>
              <EditOutlined />{" "}
              {candidate.applicant_street_one ? "Update" : "Edit"}
            </Button>
          </div>
        )} */}
			</div>

			<div className='item-list'>
				<div className='item-row fitment-field'>
					<div className='label'>Address line 1</div>
					<div>{candidate.applicant_street_one}</div>
				</div>
				<div className='item-row fitment-field'>
					<div className='label'>Address line 2</div>
					<div>{candidate.applicant_street_two}</div>
				</div>
				<div className='item-row fitment-field'>
					<div className='label'>Source</div>
					<div>{fitment.source}</div>
				</div>
				{fitment.source_type && (
					<div className='item-row fitment-field'>
						<div className='label'>Source Type</div>
						<div>{fitment.source_type}</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Address

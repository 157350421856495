import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import SalaryRangesService from "services/users/salary-ranges.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const SalaryRangesShowPage = (props) => {
  const pageState = globalStore.ui.configuration.salaryRanges.show
  const history = useHistory()
  const { salaryRangeId } = useParams()
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    const fetchSalaryRange = async () => {
      const pageState = globalStore.ui.configuration.salaryRanges.show

      await SalaryRangesService.show({ pageState, salaryRangeId })

      const { serverStatus, salaryRangeErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (salaryRangeErrors) {
        message.error(salaryRangeErrors[0] || "Coundn't fetch salary range")
        return
      }
    }

    fetchSalaryRange()
  }, [salaryRangeId])

  const handleEdit = () => {
    history.push(`/configuration/salary-ranges/${salaryRangeId}/edit`)
  }

  const handleDelete = async () => {
    const pageState = {}

    setDeleteLoading(true)
    await SalaryRangesService.delete({ pageState, salaryRangeId })
    setDeleteLoading(false)

    const { serverStatus } = pageState

    if (serverStatus.status !== 0) {
      message.error("Failed to delete this salary range")
      return
    }

    message.success("Salary range deleted.")

    history.push("/configuration/salary-ranges")
  }

  const { salary_range, API_USERS_SALARY_RANGES_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!salary_range) return null

    const { criteria, minimum, median, maximum } = salary_range

    return (
      <div>
        <h1>{salary_range.name}</h1>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Minimum</div>
              <div>{minimum}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Median</div>
              <div>{median}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Maximum</div>
              <div>{maximum}</div>
            </div>
          </div>
        </StyledItemList>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Salary Range
          </Button>
          <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/configuration/salary-ranges" />

  const strategy =
    salary_range && salary_range.id === salaryRangeId
      ? staleWhileRevalidate
      : networkOnly

  return (
    <div className="page-content salary-ranges-page">
      <StyledBox>
        {strategy({
          status: API_USERS_SALARY_RANGES_SHOW_STATUS,
          data: salary_range,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(SalaryRangesShowPage)

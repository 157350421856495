import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { message, Button, Empty, Pagination, Table, List, Select, Tooltip ,Popconfirm} from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetValuesService from 'services/users/budget-values.service'
import BudgetHeadsService from 'services/users/budget-heads.service'
import moment from 'moment'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import { Redirect, useHistory } from 'react-router-dom'
import { DeleteOutlined,EditOutlined } from '@ant-design/icons'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const PositionOccupancyReportIndexPage = () => {
	const history = useHistory()
	const [selectedFilterKey, setSelectedFilterKey] = useState(null)
	const [searchValue, setSearchValue] = useState(null)
	const [budgetHeadId, setBudgetHeadID] = useState()
	const [dropdownLoading, setDropdownLoading] = useState(false)
	const [dropdownValues, setDropdownValues] = useState([])
	const pageState = globalStore.ui.configuration.budgetValues.index
	const searchPageState = globalStore.ui.configuration.budgetValues.search
	const roles = globalStore.currentUser?.roles || []

	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetValues = async () => {
			const tempState = {}
			await BudgetHeadsService.index({ pageState: tempState })
			const { budget_heads } = tempState
			if (!budget_heads) {
				message.error('Budget Heads not found')
				return
			}
			const workforceBudgetHead = budget_heads.find((bh) => bh.name === 'Workforce Plan')

			const pageState = globalStore.ui.configuration.budgetValues.index
			setBudgetHeadID(workforceBudgetHead?.id)
			await BudgetValuesService.index({ pageState, budgetHeadId: workforceBudgetHead?.id })
			const { serverStatus, budgetValuesErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetValuesErrors) {
				message.error(budgetValuesErrors[0] || "Couldn't fetch Budget Values")
				return
			}
		}

		fetchBudgetValues()
	}, [])

	const repaginate = async (page, pageSize) => {
		if (Object.keys(globalStore.ui.configuration.budgetValues).includes('search')) {
			await BudgetValuesService.search({ pageState, page, pageSize, budgetHeadId })
			return
		}
		await BudgetValuesService.index({ pageState, page, pageSize, budgetHeadId })
	}

	const handleClearFilter = async () => {
		const pageState = globalStore.ui.configuration.budgetValues.index
		setSelectedFilterKey(null)
		setSearchValue(null)
		globalStore.ui.configuration.budgetValues.search = {}
		await BudgetValuesService.index({ pageState, budgetHeadId })
	}

	const handleFilterSelectInputChange = async (value) => {
		if (!value) return
		setSelectedFilterKey(value)
		setSearchValue()

		const tempState = {}
		setDropdownLoading(true)
		await BudgetCriteriaHeadsService.criteriaValuesList({
			pageState: tempState,
			values: { name: value, criteria: [] },
		})

		const { budget_criteria_values, serverStatus } = tempState
		if (serverStatus?.status !== 200) {
			message.error('Oops!! something went wrong')
			setDropdownLoading(false)
			return
		}
		const ddv = budget_criteria_values
		if (!ddv || ddv.length === 0) {
			message.error(`No Hierarchy value found in ${value}`)
			setDropdownValues(ddv)
			setDropdownLoading(false)
			return
		}

		setDropdownValues(ddv)
		setDropdownLoading(false)
	}

	const handleSearch = async (value) => {
		if (!value) return
		setSearchValue(value)

		const searchData = {
			field: selectedFilterKey,
			value,
		}
		await BudgetValuesService.search({
			pageState: searchPageState,
			values: searchData,
			budgetHeadId,
		})
	}

	const renderFieldValueOptions = () => {
		if (!dropdownValues) return
		return dropdownValues.map((f, i) => (
			<Select.Option key={i} value={f.name}>
				{f.name}
			</Select.Option>
		))
	}

	const { budget_values, budget_valuesMeta, API_USERS_BUDGET_VALUES_INDEX_STATUS } = pageState
	const { API_USERS_BUDGET_VALUES_SEARCH_STATUS } = searchPageState

	const { total, page: currentPage, page_size: pageSize, criteria_keys } = budget_valuesMeta || {}

	const getFilteredOption = () => {
		const displayCriteriaForWorkforce = globalStore.currentOrganization.workforce_criteria
		if (displayCriteriaForWorkforce && displayCriteriaForWorkforce.length)
			return displayCriteriaForWorkforce.map((filteredCriteria, index) => {
				if (!filteredCriteria) return null
				if (!filteredCriteria[0]) return null
				if (filteredCriteria.length === 0) return null

				return (
					<Select.Option key={`option-${index}`} value={filteredCriteria}>
						{filteredCriteria}
					</Select.Option>
				)
			})

		return criteria_keys.map((filteredCriteria, index) => {
			if (!filteredCriteria) return null
			if (!filteredCriteria[0]) return null
			if (filteredCriteria.length === 0) return null

			return (
				<Select.Option key={`option-${index}`} value={filteredCriteria}>
					{filteredCriteria}
				</Select.Option>
			)
		})
	}

	const renderContents = () => {
		if (budget_values.length === 0) {
			return (
				<div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: 10,
							margin: '15px 0px',
						}}>
						<div></div>
						<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
							{selectedFilterKey ? (
								<Button type='link' onClick={handleClearFilter}>
									Clear filter
								</Button>
							) : null}
							<Select
								placeholder='Filter on'
								style={{ width: 200 }}
								value={selectedFilterKey}
								onChange={handleFilterSelectInputChange}>
								<Select.Option disabled>Filter on</Select.Option>
								{getFilteredOption()}
							</Select>
							<Select
								placeholder='Choose a value'
								showSearch
								style={{ width: '100%' }}
								disabled={!selectedFilterKey}
								value={searchValue || []}
								optionFilterProp='children'
								loading={dropdownLoading}
								onChange={handleSearch}>
								{renderFieldValueOptions()}
							</Select>
						</div>
					</div>
					<Empty />
				</div>
			)
		}

		const handleDelete = async (budgetValueId) => {
			const tempState = {}

			await BudgetValuesService.delete({
				pageState: tempState,
				budgetValueId,
			})

			const { serverStatus } = tempState

			if (serverStatus.status !== 200) return

			if (Object.keys(searchPageState).length !== 0) {
				globalStore.ui.configuration.budgetValues.search = {
					...globalStore.ui.configuration.budgetValues.search,
					budget_values: budget_values.filter((budget_value) => budget_value.id !== budgetValueId),
				}
				return
			}

			globalStore.ui.configuration.budgetValues.index = {
				...globalStore.ui.configuration.budgetValues.index,
				budget_values: budget_values.filter((budget_value) => budget_value.id !== budgetValueId),
			}
		}

		const handleEdit = (budgetValueId) => {
			history.push(`/configuration/budget-heads/position-occupancy-report/${budgetValueId}/edit`)
		}

		const pagination = () => {
			if (Object.keys(searchPageState).length !== 0) {
				const { budget_valuesMeta } = searchPageState

				const { total, page: currentPage, page_size: pageSize } = budget_valuesMeta || {}
				return (
					<Pagination
						current={currentPage}
						total={total}
						onChange={repaginate}
						onShowSizeChange={repaginate}
						pageSize={pageSize || 25}
						pageSizeOptions={[10, 25, 50, 100]}
					/>
				)
			}
			return (
				<Pagination
					current={currentPage}
					total={total}
					onChange={repaginate}
					onShowSizeChange={repaginate}
					pageSize={pageSize || 25}
					pageSizeOptions={[10, 25, 50, 100]}
				/>
			)
		}

		const getCriteriaKeys = () => {
			const displayCriteriaForWorkforce = globalStore.currentOrganization.workforce_criteria
			if (displayCriteriaForWorkforce && displayCriteriaForWorkforce.length)
				return displayCriteriaForWorkforce.map((ck) => ({
					title: ck,
					dataIndex: ck,
					key: ck,
					render: (filteredCriteria, row) => {
						if (!filteredCriteria) return '--' // if null
						if (!filteredCriteria[0]) return '--' // If [null]
						if (filteredCriteria.length === 0) return '---' // if []

						const dataSource = filteredCriteria.filter((fc) => fc) // FIXME: need to fix
						return (
							<List
								dataSource={dataSource}
								renderItem={(values) => {
									return values.join(', ')
								}}
							/>
						)
					},
				}))

			return criteria_keys.map((ck) => ({
				title: ck,
				dataIndex: ck,
				key: ck,
				render: (filteredCriteria, row) => {
					if (!filteredCriteria) return '--' // if null
					if (!filteredCriteria[0]) return '--' // If [null]
					if (filteredCriteria.length === 0) return '---' // if []

					const dataSource = filteredCriteria.filter((fc) => fc) // FIXME: need to fix

					return (
						<List
							dataSource={dataSource}
							renderItem={(values) => {
								return values.join(', ')
							}}
						/>
					)
				},
			}))
		}

		const tableColumns = [
			...[
				{
					title: 'Position Code',
					dataIndex: 'workforce_position',
					key: 'workforce_position',
					render: (value) => value?.position_code || '--',
				},
				{
					title: 'Position Name',
					dataIndex: 'workforce_position',
					key: 'workforce_position',
					render: (value) => value?.name || '--',
				},
			],
			...[...getCriteriaKeys()],
			...[
				{
					title: 'Currency',
					dataIndex: 'currency',
					key: 'currency',
					align: 'center',
					render: (value) => value || '--',
				},
				{
					title: 'Count',
					dataIndex: 'unit',
					key: 'count',
					align: 'center',
					render: (value) => value || '--',
				},
				{
					title: 'Salary Cost',
					dataIndex: 'display_rate',
					key: 'salary_cost',
					render: (value) => {
						return value || '--'
					},
				},
				{
					title: 'Frequency',
					dataIndex: 'frequency',
					key: 'frequency',
					render: (value, ic) => {
						value = value?.replace(/_/g, ' ')
						return value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
					},
				},
				{
					title: 'Start Date',
					dataIndex: 'start_date',
					key: 'start_date',
					render: (value) => <span>{moment.utc(value).format('DD-MMM-YYYY')}</span>,
				},
				{
					title: 'End Date',
					dataIndex: 'end_date',
					key: 'end_date',
					render: (value) => <span>{moment.utc(value).format('DD-MMM-YYYY')}</span>,
				},
				{
					title: 'Current Year Cost',
					dataIndex: 'total_cost',
					key: 'current_year_cost',
					render: (value) => value || '--',
				},
				{
					title: 'Full Year Cost',
					dataIndex: 'display_annualized_amount',
					key: 'full_year_cost',
					render: (value) => value || '--',
				},
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					align: 'center',
					render: (value, sr) => {
						return (
							<>
								{roles.includes('super-admin') && (
									<Tooltip title='Edit'>
										<Button
											type='link'
											onClick={() => {
												handleEdit(sr.id)
											}}>
											<EditOutlined />
										</Button>
									</Tooltip>
								)}
								<Tooltip title='Delete'>
									<Popconfirm
										title='Are you sure to delete?'
										onConfirm={() => handleDelete(sr.id)}
										onCancel={() => {}}
										okText='Yes'
										cancelText='No'>
										<Button type='link'>
											<DeleteOutlined />
										</Button>
									</Popconfirm>
								</Tooltip>
							</>
						)
					},
				},
			],
		]

		const dataSource = () => {
			if (Object.keys(searchPageState).length !== 0) {
				const { budget_values, budget_valuesMeta } = searchPageState

				const { criteria_keys } = budget_valuesMeta || {}

				return budget_values.map((sr) => {
					const retval = { ...sr, key: sr.id }
					criteria_keys.forEach((ck) => {
						retval[ck] = sr.criteria.map((conditions) => {
							const relevantCondition = conditions.find((c) => c.field === ck)
							if (!relevantCondition) return null
							return relevantCondition.values
						})
					})
					return retval
				})
			}

			return budget_values.map((sr) => {
				const retval = { ...sr, key: sr.id }
				criteria_keys.forEach((ck) => {
					retval[ck] = sr.criteria.map((conditions) => {
						const relevantCondition = conditions.find((c) => c.field === ck)
						if (!relevantCondition) return null
						return relevantCondition.values
					})
				})
				return retval
			})
		}
		const { budget_valuesMeta: budgetValuesMeta } = pageState
		const renderTable = () => {
			return (
				<div style={{ margin: '10px 0px' }}>
					<Table
						className='budget_values'
						columns={tableColumns}
						dataSource={dataSource()}
						pagination={false}
						scroll={{
							x: 'max-content',
						}}
						summary={() => {
							return (
								<Table.Summary.Row>
									<Table.Summary.Cell index={0} colSpan={3 + getCriteriaKeys()?.length || 0}>
										<strong>Total</strong>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={1}>
										<div style={{ textAlign: 'center' }}>{budgetValuesMeta?.total_count}</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={2} colSpan={4}>
										<div>{budgetValuesMeta?.salary_cost}</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={2}>
										<div>{budgetValuesMeta?.current_year_cost}</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={2}>
										<div>{budgetValuesMeta?.full_year_cost}</div>
									</Table.Summary.Cell>
								</Table.Summary.Row>
							)
						}}
					/>
				</div>
			)
		}

		const renderInfo = () => {
			return renderTable()
		}

		const handleExportPositionOccupancyReport = async () => {
			const tempState = {}
			await BudgetValuesService.exportPositionReport({
				pageState: tempState,
				budgetHeadId,
			})

			message.success(
				'Export started. Kindly check export page in Report Center to see exported reports'
			)
		}

		const handleExportPositionWiseReport = async () => {
			const tempState = {}
			await BudgetHeadsService.exportPositionWiseReport({
				pageState: tempState,
			})

			message.success(
				'Export started. Kindly check export page in Report Center to see exported reports'
			)
		}

		return (
			<div>
				<div className='action-buttons top' style={{ display: 'flex', justifyContent: 'end',gap:10 }}>
					<Tooltip title='Export Position Occupancy Report'>
						<Button onClick={handleExportPositionOccupancyReport}>Export Position Report</Button>
					</Tooltip>
					<Tooltip title='Export Position vs Employee Report'>
						<Button onClick={handleExportPositionWiseReport}>
							Export Position vs Employee Report
						</Button>
					</Tooltip>
				</div>
				<div
					style={{
						marginTop: 10,
						display: 'flex',
						justifyContent:'space-between',
						alignItems: 'center',
					}}>
					{pagination()}
					<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
						{selectedFilterKey ? (
							<Button type='link' onClick={handleClearFilter}>
								Clear filter
							</Button>
						) : null}
						<Select
							placeholder='Filter on'
							style={{ width: 200 }}
							value={selectedFilterKey}
							onChange={handleFilterSelectInputChange}>
							<Select.Option disabled>Filter on</Select.Option>
							{getFilteredOption()}
						</Select>
						<Select
							placeholder='Choose a value'
							showSearch
							style={{ width: '100%' }}
							disabled={!selectedFilterKey}
							optionFilterProp='children'
							loading={dropdownLoading}
							onChange={handleSearch}>
							{renderFieldValueOptions()}
						</Select>
					</div>
				</div>

				{networkOnly({
					status: API_USERS_BUDGET_VALUES_SEARCH_STATUS
						? API_USERS_BUDGET_VALUES_SEARCH_STATUS
						: API_USERS_BUDGET_VALUES_INDEX_STATUS,
					data: budget_values,
					render: renderInfo,
				})}

				{pagination()}
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-report'))
		return <Redirect to='/' />

	return (
		<div className='page-content budget_values-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_VALUES_SEARCH_STATUS
						? API_USERS_BUDGET_VALUES_SEARCH_STATUS
						: API_USERS_BUDGET_VALUES_INDEX_STATUS,
					data: budget_values,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(PositionOccupancyReportIndexPage)

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import VendorsService from "services/users/vendors.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const VendorsShowPage = (props) => {
  const pageState = globalStore.ui.vendors.show
  const history = useHistory()
  const { vendorId } = useParams()

  useEffect(() => {
    const fetchDocument = async () => {
      const pageState = globalStore.ui.vendors.show

      await VendorsService.show({
        pageState,
        vendorId,
      })

      const { serverStatus, vendorErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (vendorErrors) {
        message.error(
          vendorErrors[0] ||
            "Couldn't fetch fitment fields configuration"
        )
        return
      }
    }

    fetchDocument()
  }, [vendorId])

  const handleEdit = () => {
    history.push(
      `/configuration/vendors/${vendorId}/edit`
    )
  }

  const {
    vendor,
    API_USERS_VENDORS_SHOW_STATUS,
  } = pageState

  const renderContent = () => {
    if (!vendor) return null

    return (
      <div>
        <h1>{vendor.name}</h1>
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Vendor
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/configuration/vendors" />

  const strategy =
    vendor &&
    vendor.id === vendorId
      ? staleWhileRevalidate
      : networkOnly

  return (
    <div className="page-content salary-ranges-page">
      <StyledBox>
        {strategy({
          status: API_USERS_VENDORS_SHOW_STATUS,
          data: vendor,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(VendorsShowPage)

import React from "react"
import { Row, Col, Collapse } from "antd"

import StyledFitmentItemCard from "components/common/styled-components/StyledFitmentItemCard"

const JobDetails = ({ fitment }) => {
  const { title, tags } = fitment["job"]
  // const { external_id } = fitment["candidate"]

  if (!tags || !Array.isArray(tags)) return null

  const leftRows = tags
    .filter((t, i) => i <= tags.length / 2)
    .map((t) => (
      <div className="item-row" style={{ width: "100%" }} key={t.id}>
        <div className="label" style={{ width: "50%" }}>
          {t.label}
        </div>
        <div style={{ width: "50%" }}>{t.value.label}</div>
      </div>
    ))

  const rightRows = tags
    .filter((t, i) => i > tags.length / 2)
    .map((t) => (
      <div className="item-row" style={{ width: "100%" }} key={t.id}>
        <div className="label" style={{ width: "50%" }}>
          {t.label}
        </div>
        <div style={{ width: "50%" }}>{t.value.label}</div>
      </div>
    ))

  const designation = tags.find((t) => t.label === "Designation")?.value?.label

  return (
    <StyledFitmentItemCard>
      <div className="header">
        <h1>Job Details</h1>
      </div>
      {/* <div className="job-card">
        <div className="email">{designation || title}</div>
        <div className="external-id">External ID: {external_id}</div>
      </div> */}
      <Collapse>
        <Collapse.Panel
          header={<h1 style={{ margin: 0 }}>{designation || title}</h1>}
        >
          <Row gutter={20}>
            <Col span={12}>
              <div className="item-list" style={{ width: "100%" }}>
                {leftRows}
              </div>
            </Col>
            <Col span={12}>
              <div className="item-list" style={{ width: "100%" }}>
                {rightRows}
              </div>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </StyledFitmentItemCard>
  )
}

export default JobDetails

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, Redirect, useParams } from "react-router-dom"
import styled from "styled-components"
import { EyeOutlined } from "@ant-design/icons"
import { message, Button, Empty, Pagination, Table, Tooltip } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import UsersService from "services/admin-users/organizations/users.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const UsersIndexPage = (props) => {
  const history = useHistory()
  const { organizationId } = useParams()
  const pageState = globalStore.ui.adminUsers.organizations.users.index

  useEffect(() => {
    const fetchUsers = async () => {
      const pageState = globalStore.ui.adminUsers.organizations.users.index

      await UsersService.index({ pageState, organizationId })

      const { serverStatus, usersErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (usersErrors) {
        message.error(usersErrors[0] || "Users couldn't be fetched.")
        return
      }
    }

    fetchUsers()
  }, [organizationId])

  const repaginate = async (page, pageSize, bcsla = null) => {
    if (bcsla) {
      await UsersService.index({
        pageState,
        page,
        pageSize,
        bcsla,
        organizationId,
      })
      return
    }
    await UsersService.index({ pageState, page, pageSize, organizationId })
  }

  const handleCreate = () => {
    history.push(`/admin-users/organizations/${organizationId}/users/new`)
  }

  const handleCardItemClick = (userId) => {
    history.push(`/admin-users/organizations/${organizationId}/users/${userId}`)
  }

  const {
    users,
    usersMeta,
    API_ADMIN_USERS_ORGANIZATIONS_USERS_INDEX_STATUS,
  } = pageState

  const { total, page: currentPage, page_size: pageSize } = usersMeta || {}

  const renderContents = () => {
    if (users.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            Add User
          </Button>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
        style={{ margin: "10px 0px" }}
      />
    )

    const tableColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 200,
        render: (value, ic) => {
          return (
            <div>
              <Button type="link" onClick={() => handleCardItemClick(ic.id)}>
                <EyeOutlined />
              </Button>
            </div>
          )
        },
      },
    ]

    const dataSource = users.map((ic) => {
      const retval = { ...ic, key: ic.id }
      return retval
    })

    const renderTable = () => {
      return (
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        ></Table>
      )
    }

    return (
      <div>
        {pagination}
        {networkOnly({
          status: API_ADMIN_USERS_ORGANIZATIONS_USERS_INDEX_STATUS,
          data: users,
          render: renderTable,
        })}

        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div></div>
          <div className="action-buttons top">
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                Add User
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content organizations-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_ADMIN_USERS_ORGANIZATIONS_USERS_INDEX_STATUS,
          data: users,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(UsersIndexPage)

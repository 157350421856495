import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, message, Row, Col, Select } from 'antd'
import dayjs from 'dayjs'

import ConditionRow from 'components/budget-analysis/ConditionRow'
import globalStore from 'store/index'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import { DatePicker } from 'antd'
import moment from 'moment'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import BudgetTentativeValuesService from 'services/users/budget-tentative-values.service'
import PositionService from 'services/users/positions.service'
import BudgetValuesService from 'services/users/budget-values.service'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const blankCondition = {
	field: null,
	operator: null,
	values: null,
}
const BudgetApprovalWorkFlowForm = ({
	budgetHeadId,
	budgetHead,
	recordType,
	budgetTentativeData,
	budgetTentativeValueId,
	edit = false,
}) => {
	const [criteria, setCriteria] = useState([[blankCondition]])
	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [rate, setRate] = useState(1)
	const [count, setCount] = useState(1)
	const [total, setTotal] = useState(0)
	const [frequency, setFrequency] = useState('annually')
	const [annualizedRate, setAnnualizedRate] = useState(0)
	const [proportioned, setProportioned] = useState(1)
	const dateFormat = 'DD-MMM-YYYY'
	const currentYear = new Date().getFullYear()
	const [dateRange, setDateRange] = useState([
		moment(currentYear + '-04-01'),
		moment(currentYear + 1 + '-03-31'),
	])
	const [currency, setCurrency] = useState()
	const [accessGroups, setAccessGroups] = useState(null)
	const [criteriaChildren, setCriteriaChildren] = useState([])
	const [allocationPercentage, setAllocationPercentage] = useState(null)
	const [incrementPercentage, setIncrementPercentage] = useState(null)
	const [positionType, setPositionType] = useState(null)
	const [positionCode, setPositionCode] = useState(null)
	const [positionName, setPositionName] = useState(null)
	const [employeeCode, setEmployeeCode] = useState(null)
	const [employeeName, setEmployeeName] = useState(null)
	const [budgetType, setBudgetType] = useState(null)
	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()
		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])
	const enableAllocationFeature = globalStore?.currentOrganization?.enable_allocation_feature

	useEffect(() => {
		setCurrency(globalStore.currentOrganization.currency || 'INR')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalStore.currentOrganization.currency])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list

		const fetchAllFieldsData = async () => {
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		const fetchPosition = async () => {
			const tempState = {}
			await PositionService.workforcePositions({ pageState: tempState })
			const { access_groups } = tempState
			setAccessGroups(access_groups)
		}
		fetchPosition()

		fetchAllFieldsData()
	}, [])

	useEffect(() => {
		if (criteriaChildren.length) {
			const updateCriteriaData = criteria[0].map((data) => {
				if (criteriaChildren.some((items) => items.name === data.field)) {
					return { ...data, values: null, operator: null }
				}
				return data
			})
			setCriteria([updateCriteriaData])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteriaChildren])

	useEffect(() => {
		if (edit) {
			if (!budgetTentativeData) return
			if (!recordType) return
			const tentativeData =
				budgetTentativeData[recordType?.id === 1 ? 'budget_value' : 'budget_utilization']
			setCurrency(tentativeData?.currency)
			setRate(tentativeData?.rate)
			setCount(tentativeData?.unit)
			setDateRange([moment(tentativeData?.start_date), moment(tentativeData?.end_date)])
			setFrequency(tentativeData?.frequency)
			setAnnualizedRate(tentativeData?.annualized_amount)
			setTotal(tentativeData[recordType?.id === 1 ? 'total_cost' : 'total_value'])
			setBudgetType(tentativeData?.budget_type)
			setPositionType(tentativeData?.position_type)
			setPositionCode(tentativeData?.position_code)
			setPositionName(tentativeData?.position_name)
			setEmployeeCode(tentativeData?.employee_code)
			setEmployeeName(tentativeData?.employee_name)
			setAllocationPercentage(tentativeData?.allocation_percentage)
			setIncrementPercentage(tentativeData?.increment_percentage)
			setCriteria(tentativeData?.criteria)
		}
	}, [budgetTentativeData, edit, recordType])

	useEffect(() => {
		if (edit) return
		const fetchEssentialFieldsList = async () => {
			const tempState = {}
			await BudgetValuesService.essentialFieldsList({
				pageState: tempState,
				budgetHeadId: budgetHeadId,
			})

			const { budget_criteria_heads, dataErrors } = tempState
			if (dataErrors) {
				message.error(dataErrors || 'Sample File could not be fetched.')
				return
			}
			const formatCriteriaList = budget_criteria_heads?.map((data) => {
				const returnData = { field: data?.name, values: null, operator: null }
				return returnData
			}) || [blankCondition]
			setCriteria([formatCriteriaList])
		}
		if (budgetHead === 'Workforce Plan') fetchEssentialFieldsList()
		else {
			setCriteria([[blankCondition]])
		}
	}, [budgetHead, budgetHeadId, edit])

	useEffect(() => {
		if (edit) return
		const setCriteriaForAddPosition = () => {
			if (budgetHead === 'Workforce Plan') {
				const vacantCriteria = [[{ field: 'Position Status', values: ['Vacant'], operator: 'any' }]]
				setCriteria(vacantCriteria)
			}
		}
		setCriteriaForAddPosition()
	}, [budgetHead, edit])

	const handleInputChange = (value, inputType) => {
		let newInputValues = null

		if (inputType === 'frequency') {
			newInputValues = { ...inputValues, [inputType]: value }
			setFrequency(value)
			setAnnualizedRate(getAnnualizedRate(rate, value))
			setTotal(getTotal(count, rate * getFrequencyDays(value), proportioned, value))
		} else {
			newInputValues = { ...inputValues, [inputType]: value }
		}

		if (inputType === 'rate') {
			setRate(value)
			setTotal(getTotal(count, getAnnualizedRate(value, frequency), proportioned, frequency))
			setAnnualizedRate(getAnnualizedRate(value, frequency))
		}

		if (inputType === 'unit') {
			setCount(value)
			setTotal(getTotal(value, annualizedRate, proportioned, frequency))
		}

		if (inputType === 'total_cost' || inputType === 'total_value') {
			setTotal(value)
			setRate(value / count)
			setAnnualizedRate(getAnnualizedRate(value / count, frequency))
		}

		if (inputType === 'currency') {
			setCurrency(value)
		}
		if (inputType === 'allocation_percentage') {
			setAllocationPercentage(value)
		}
		if (inputType === 'increment_percentage') {
			setIncrementPercentage(value)
		}
		if (inputType === 'position_type') {
			setPositionType(value)
		}
		if (inputType === 'position_code') {
			setPositionCode(value)
		}
		if (inputType === 'position_name') {
			setPositionName(value)
		}
		if (inputType === 'employee_code') {
			setEmployeeCode(value)
		}
		if (inputType === 'employee_name') {
			setEmployeeName(value)
		}
		if (inputType === 'budget_type') {
			setBudgetType(value)
		}

		setInputValues(newInputValues)
	}

	const handleDateChange = (range) => {
		if (range) {
			setDateRange([range[0], range[1]])

			setProportioned(getProportionedFactor(range[0], range[1]))

			setInputValues({
				...inputValues,
				start_date: dateRange[0],
				end_date: dateRange[1],
			})
			setTotal(
				getTotal(count, annualizedRate, getProportionedFactor(range[0], range[1]), frequency)
			)
		}
	}

	const getTotal = (count, annualizedRate, proportioned, frequency) => {
		if (frequency === 'one_time') {
			return Math.round(count * annualizedRate * 100) / 100
		} else {
			return Math.round(count * annualizedRate * proportioned * 100) / 100
		}
	}

	const getProportionedFactor = (startDate, endDate) => {
		return Math.round((getPeriodDays(startDate, endDate) / 365) * 100) / 100
	}

	const getAnnualizedRate = (rate, frequency) => {
		return rate * getFrequencyDays(frequency)
	}

	const getFrequencyDays = (frequency) => {
		if (frequency === 'daily') {
			return 365.0
		} else if (frequency === 'half_yearly') {
			return 2 // 2.027
		} else if (frequency === 'quarterly') {
			return 4 // 4.05
		} else if (frequency === 'monthly') {
			return 12 // 12.16
		} else if (frequency === 'weekly') {
			return 52 // 52.14
		} else if (frequency === 'annually') {
			return 1
		} else if (frequency === 'one_time') {
			return 1
		}
	}

	const getPeriodDays = (startDate, endDate) => {
		const oneDay = 24 * 60 * 60 * 1000
		const days = Math.round(Math.abs((endDate - startDate) / oneDay))
		return days + 1
	}

	const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(deletedConditionIndex, 1)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleCreateConditionValues = ({ conditionSetIndex, conditionIndex, condition }) => {
		if (!condition) return
		if (!condition.values) return

		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				return newConditionSet.map((c, index) => {
					if (index !== conditionIndex) return c

					return condition
				})
			})

			return newCriteria
		})
	}

	const handleCreate = async () => {
		inputValues['currency'] = currency
		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues[recordType?.id === 1 ? 'total_cost' : 'total_value'] = total
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()
		inputValues['frequency'] = frequency
		inputValues['annualized_amount'] = annualizedRate
		if (positionCode) inputValues['position_code'] = positionCode
		if (positionType) inputValues['position_type'] = positionType
		if (positionName) inputValues['position_name'] = positionName
		if (employeeCode) inputValues['employee_code'] = employeeCode
		if (employeeName) inputValues['employee_name'] = employeeName
		if (allocationPercentage) inputValues['allocation_percentage'] = allocationPercentage
		if (incrementPercentage) inputValues['increment_percentage'] = incrementPercentage

		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}
		if (!inputValues['frequency']) {
			message.error("Frequency can't be blank")
			return
		}

		if (budgetHead === 'Workforce Plan' && !inputValues['position_code']) {
			message.error("Position Code can't be blank")
			return
		}
		const tentativeData = {}
		tentativeData[recordType?.id === 1 ? 'budget_value' : 'budget_utilization'] = {
			criteria: criteria.filter((c) => c.length > 0),
			...inputValues,
		}

		const tempState = {}
		const postData = {
			budget_tentative_value: {
				budget_head_id: budgetHeadId,
				tentative_data: {
					...tentativeData,
				},
				budget_type: recordType?.id === 1 ? 'planned' : 'actual',
			},
		}
		setCreateButtonLoading(true)
		if (budgetTentativeData)
			await BudgetTentativeValuesService.update({
				pageState: tempState,
				values: postData,
				budgetTentativeValueId,
			})
		else
			await BudgetTentativeValuesService.create({
				pageState: tempState,
				values: postData,
			})
		setCreateButtonLoading(false)

		const { serverStatus, budgetTentativeValuesErrors } = tempState

		if (budgetTentativeValuesErrors) {
			message.error(budgetTentativeValuesErrors || "Budget Approval Workflow couldn't be created.")
			return
		}

		if (serverStatus.status === 200) {
			message.success('Budget Approval Workflow created.')
			setCriteria([[blankCondition]])
			setInputValues({})

			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				{/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h1>
						Budget Approval Workflow for
						{budgetHead === 'Workforce Plan' ? 'Workforce Plan' : 'Budget'}
					</h1>
				</div> */}
				<Row gutter={20}>
					<Col span={16}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}>
								<strong>
									Position Period <span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<DatePicker.RangePicker
								format={dateFormat}
								value={dateRange}
								onChange={handleDateChange}
							/>
						</div>
					</Col>
					<Col span={8} pull={1}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}>
								<strong>Proportioned Factor</strong>
							</div>
							<Input placeholder='Proportioned Factor' value={proportioned} disabled={true} />
						</div>
					</Col>
					<Col span={2}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}>
								<strong>Currency</strong>
							</div>
							<Input
								placeholder='Currency'
								value={currency}
								onChange={(e) => handleInputChange(e.target.value, 'currency')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}>
								<strong>
									{budgetHead === 'Workforce Plan' ? 'Headcount' : 'Count'}
									<span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<Input
								placeholder='Enter Count'
								disabled={budgetHead === 'Workforce Plan'}
								value={count || inputValues['unit']}
								onChange={(e) => handleInputChange(e.target.value, 'unit')}
							/>
						</div>
					</Col>
					<Col span={5}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}>
								<strong>
									{budgetHead === 'Workforce Plan' ? 'Salary Rate per person' : 'Rate'}
									<span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<Input
								placeholder='Enter Rate'
								value={rate || inputValues['rate']}
								onChange={(e) => handleInputChange(e.target.value, 'rate')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}></div>
							<strong>
								Frequency <span style={{ color: 'red' }}>*</span>
							</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose frequency'
								value={frequency}
								onChange={(value) => handleInputChange(value, 'frequency')}>
								<Select.Option value='daily'>Daily</Select.Option>
								<Select.Option value='weekly'>Weekly</Select.Option>
								<Select.Option value='monthly'>Monthly</Select.Option>
								<Select.Option value='quarterly'>Quarterly</Select.Option>
								<Select.Option value='half_yearly'>Half Yearly</Select.Option>
								<Select.Option value='annually'>Annually</Select.Option>
								<Select.Option value='one_time'>One Time</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}>
								<strong>Annualised Rate</strong>
							</div>
							<Input placeholder='Annualised Rate' value={annualizedRate} disabled={true} />
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<div style={{ marginBottom: 5 }}>
								<strong>Total Cost</strong>
							</div>
							<Input
								placeholder='Total Cost'
								value={total || inputValues[recordType?.id === 1 ? 'total_cost' : 'total_value']}
								onChange={(e) =>
									handleInputChange(
										e.target.value,
										recordType?.id === 1 ? 'total_cost' : 'total_value'
									)
								}
							/>
						</div>
					</Col>
				</Row>
				{budgetHead === 'Workforce Plan' && recordType?.id === 1 && (
					<Row gutter={20}>
						<Col span={6}>
							<div className='input-box'>
								<div style={{ marginBottom: 5 }}>
									<strong>Budget Type</strong>
								</div>
								<Select
									style={{ display: 'block' }}
									placeholder='Choose Budget Type'
									value={budgetType}
									onChange={(value) => handleInputChange(value, 'budget_type')}>
									<Select.Option value='budgeted'>Budgeted</Select.Option>
									<Select.Option value='non_budgeted'>Non-budgeted</Select.Option>
								</Select>
							</div>
						</Col>
						<Col span={5}>
							<div className='input-box'>
								<div>
									<div style={{ marginBottom: 5 }}>
										<strong>
											Position Code <span style={{ color: 'red' }}>*</span>
										</strong>
									</div>
								</div>
								<Input
									placeholder='Enter Position Code'
									value={positionCode || inputValues['position_code']}
									onChange={(e) => handleInputChange(e.target.value, 'position_code')}
								/>
							</div>
						</Col>
						<Col span={6}>
							<div className='input-box'>
								<div style={{ marginBottom: 5 }}>
									<strong>Position Name</strong>
								</div>
								<Input
									placeholder='Enter Position Name'
									value={positionName || inputValues['position_name']}
									onChange={(e) => handleInputChange(e.target.value, 'position_name')}
								/>
							</div>
						</Col>
					</Row>
				)}
				{budgetHead === 'Workforce Plan' && recordType?.id === 2 && (
					<Row gutter={20}>
						<Col span={6}>
							<div className='input-box'>
								<div style={{ marginBottom: 5 }}>
									<strong>Position Type</strong>
								</div>
								<Select
									style={{ display: 'block' }}
									placeholder='Choose Position Type'
									value={positionType || inputValues['position_type']}
									onChange={(value) => handleInputChange(value, 'position_type')}>
									<Select.Option value='replacement'>Replacement</Select.Option>
									<Select.Option value='new'>New</Select.Option>
								</Select>
							</div>
						</Col>
						<Col span={5}>
							<div className='input-box'>
								<div>
									<div style={{ marginBottom: 5 }}>
										<strong>
											Position Code <span style={{ color: 'red' }}>*</span>
										</strong>
									</div>
								</div>
								<Select
									style={{ width: '100%' }}
									placeholder='Choose Position Code'
									value={positionCode || inputValues['position_code']}
									onChange={(value) => handleInputChange(value, 'position_code')}>
									{accessGroups &&
										accessGroups.map((data, index) => (
											<Select.Option key={index} value={data?.id}>
												{data?.position_code}
											</Select.Option>
										))}
								</Select>
							</div>
						</Col>
						<Col span={5}>
							<div className='input-box'>
								<div style={{ marginBottom: 5 }}>
									<strong>
										Employee Code <span style={{ color: 'red' }}>*</span>
									</strong>
								</div>
								<Input
									placeholder='Enter Employee Code'
									value={employeeCode || inputValues['employee_code']}
									onChange={(e) => handleInputChange(e.target.value, 'employee_code')}
								/>
							</div>
						</Col>
						<Col span={7}>
							<div className='input-box'>
								<div style={{ marginBottom: 5 }}>
									<strong>Employee Name</strong>
								</div>
								<Input
									placeholder='Enter Employee Name'
									value={employeeName || inputValues['employee_name']}
									onChange={(e) => handleInputChange(e.target.value, 'employee_name')}
								/>
							</div>
						</Col>
					</Row>
				)}
				{enableAllocationFeature && (
					<Row gutter={20}>
						<Col span={6}>
							<div className='input-box'>
								<strong>Allocation Percentage</strong>
								<Input
									placeholder='Enter Allocation Percentage'
									value={allocationPercentage || inputValues['allocation_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'allocation_percentage')}
								/>
							</div>
						</Col>
						<Col span={6}>
							<div className='input-box'>
								<strong>Increment Percentage</strong>
								<Input
									placeholder='Enter Increment Percentage'
									value={incrementPercentage || inputValues['increment_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'increment_percentage')}
								/>
							</div>
						</Col>
					</Row>
				)}
				<div className='input-box'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<strong>Criteria</strong>
						{criteria.filter((c) => c.length > 0).length === 0 && (
							<Button shape='circle' onClick={() => setCriteria([[blankCondition]])}>
								<PlusOutlined />
							</Button>
						)}
					</div>
					{criteria.map((conditionSet, conditionSetIndex) => {
						return (
							<div key={conditionSetIndex}>
								{conditionSet.map((condition, conditionIndex) => {
									return (
										<ConditionRow
											criteria={criteria}
											condition={condition}
											key={conditionIndex}
											clearChildren={setCriteriaChildren}
											conditionIndex={conditionSetIndex}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											conditionSetLength={conditionSet.length}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleCreateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											budgetCriteriaHeads={budgetCriteriaHeads}
										/>
									)
								})}
							</div>
						)
					})}
				</div>
				<div className='action-buttons submit'>
					<Button type='primary' loading={createButtonLoading} onClick={handleCreate}>
						{budgetTentativeData ? 'Update Budget for approval' : 'Send Budget for Approval'}
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures.length &&
		!['criteria-edit', 'budget-data-edit'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/configuration/minimum-wages/' />

	return <div style={{ marginTop: 20 }}>{renderPageContent()}</div>
}

export default view(BudgetApprovalWorkFlowForm)

import React, { useState, useEffect } from "react"
import { useParams, useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Input, message, Row, Col, Select } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import ConditionRow from "components/ConditionRow"
import globalStore from "store/index"

import OrganizationsService from "services/users/organizations.service"
import ControlFeaturesService from "services/users/control-features.service"

import {
  CONTROL_FEATURE_STATUES,
  CONTROL_FEATURE_TYPES,
} from "constants/control-features"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const blankCondition = {
  field: null,
  operator: null,
  values: null,
}

const ControlFeaturesEditPage = () => {
  const history = useHistory()
  const { controlFeatureId } = useParams()
  const [criteria, setCriteria] = useState([[blankCondition]])
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const [inputValues, setInputValues] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.controlFeatures.edit

      await ControlFeaturesService.show({ pageState, controlFeatureId })

      const { serverStatus, control_feature, controlFeatureErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (controlFeatureErrors) {
        message.error(
          controlFeatureErrors[0] || "Control feature couldn't be fetched."
        )
        return
      }

      const { name, feature_type, status, criteria } = control_feature

      const newCriteria = criteria?.length > 0 ? criteria : [[blankCondition]]

      setCriteria(newCriteria)
      setInputValues({
        name,
        feature_type,
        status,
      })
    }

    fetchData()
  }, [controlFeatureId])

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  const handleInputChange = (value, inputType) => {
    const newInputValues = { ...inputValues, [inputType]: value }

    setInputValues(newInputValues)
  }

  const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(deletedConditionIndex, 1)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleUpdateConditionValues = ({
    conditionSetIndex,
    conditionIndex,
    condition,
  }) => {
    if (!condition) return
    if (condition.operator !== "not" && !condition.values) return

    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        return newConditionSet.map((c, index) => {
          if (index !== conditionIndex) return c

          return condition
        })
      })

      return newCriteria
    })
  }

  const handleUpdate = async () => {
    if (!inputValues["name"]) {
      message.error("Name can't be blank")
      return
    }
    if (!inputValues["feature_type"]) {
      message.error("Feature type can't be blank")
      return
    }
    if (!inputValues["status"]) {
      message.error("Status can't be blank")
      return
    }

    const pageState = {}
    const postData = {
      control_feature: {
        criteria,
        ...inputValues,
      },
    }

    setCreateButtonLoading(true)
    await ControlFeaturesService.update({
      pageState,
      values: postData,
      controlFeatureId,
    })
    setCreateButtonLoading(false)

    const { serverStatus, controlFeatureErrors } = pageState

    if (controlFeatureErrors) {
      message.error(
        controlFeatureErrors[0] || "Control feature couldn't be updated."
      )
      return
    }

    if (serverStatus.status === 200) {
      message.success("Control feature updated.")
      setCriteria([[blankCondition]])
      setInputValues({})
      history.push(`/configuration/control-features/${controlFeatureId}`)
      return
    }

    message.error("Oops!! something went wrong")
  }

  const renderPageContent = () => {
    return (
      <StyledConfigurationFormBox>
        <h1>Control Feature</h1>
        <Row gutter={20}>
          <Col span={10}>
            <div className="input-box">
              <strong>Name</strong>
              <Input
                value={inputValues["name"]}
                onChange={(e) => handleInputChange(e.target.value, "name")}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="input-box">
              <strong>Feature type</strong>
              <div>
                <Select
                  value={inputValues["feature_type"]}
                  onChange={(e) => handleInputChange(e, "feature_type")}
                  style={{ width: "100%" }}
                >
                  {CONTROL_FEATURE_TYPES.map((type, index) => (
                    <Select.Option value={type.value} key={index}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="input-box">
              <strong>Status</strong>
              <div>
                <Select
                  value={inputValues["status"]}
                  onChange={(e) => handleInputChange(e, "status")}
                  style={{ width: "100%" }}
                >
                  {CONTROL_FEATURE_STATUES.map((type, index) => (
                    <Select.Option value={type.value} key={index}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
        <div className="input-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong>Criteria</strong>
            {criteria.filter((c) => c.length > 0).length === 0 && (
              <Button
                shape="circle"
                onClick={() => setCriteria([[blankCondition]])}
              >
                <PlusOutlined />
              </Button>
            )}
          </div>
          {criteria.map((conditionSet, conditionSetIndex) => {
            return (
              <div key={conditionSetIndex}>
                {conditionSet.map((condition, conditionIndex) => {
                  return (
                    <ConditionRow
                      condition={condition}
                      key={conditionIndex}
                      lastInputGroup={
                        conditionSet.length === conditionIndex + 1
                      }
                      firstInputGroup={conditionIndex === 0}
                      inputGroupIndex={conditionIndex}
                      updateConditionValues={(condition) =>
                        handleUpdateConditionValues({
                          conditionSetIndex,
                          conditionIndex,
                          condition,
                        })
                      }
                      addNewCondition={() =>
                        handleAddNewCondition(conditionSetIndex, conditionIndex)
                      }
                      removeCondition={() =>
                        handleRemoveCondition(conditionSetIndex, conditionIndex)
                      }
                      organizationFields={
                        globalStore.currentOrganization.all_fields_with_datatype
                      }
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="action-buttons submit">
          <Button
            type="primary"
            loading={createButtonLoading}
            onClick={handleUpdate}
          >
            Update Control Feature
          </Button>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("control_feature")
  )
    return (
      <Redirect to={`/configuration/control-features/${controlFeatureId}`} />
    )

  return (
    <div className="page-content">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(ControlFeaturesEditPage)

import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, useParams, Redirect, Link } from 'react-router-dom'
import styled from 'styled-components'
import { message, Table } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'

import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`
const BudgetCriteriaHeadsShowPage = (props) => {
	const pageState = globalStore.ui.configuration.budgetCriteriaHeads.show
	const history = useHistory()
	const { budgetCriteriaHeadId } = useParams()
	// const [deleteLoading, setDeleteLoading] = useState(false)

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetCriteriaHead = async () => {
			const pageState = globalStore.ui.configuration.budgetCriteriaHeads.show

			await BudgetCriteriaHeadsService.show({ pageState, budgetCriteriaHeadId })

			const { serverStatus, budgetCriteriaHeads } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetCriteriaHeads) {
				message.error(budgetCriteriaHeads[0] || "Coundn't fetch Budget Criteria")
				return
			}
		}

		fetchBudgetCriteriaHead()
	}, [budgetCriteriaHeadId])

	const handleEdit = () => {
		history.push(`/configuration/budget-criteria-heads/${budgetCriteriaHeadId}/edit`)
	}
	const handleDelete = () => {}

	const { budget_criteria_head, API_USERS_BUDGET_CRITERIA_HEADS_CRITERIA_VALUES_STATUS } = pageState

	const renderContents = () => {
		const tableColumns = [
			...[
				{
					title: budget_criteria_head.name,
					dataIndex: 'display_name',
					key: 'display_name',
				},
				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					render: (value, ic) => {
						return (
							<>
								<Link onClick={() => handleEdit(ic.id)}>Edit</Link>
								<span> | </span>
								<Link type='link' onClick={() => handleDelete(ic.id)}>
									Delete
								</Link>
							</>
						)
					},
				},
			],
		]

		const renderTable = () => {
			return (
				<Table
					columns={tableColumns}
					scroll={{
						x: 'max-content',
					}}></Table>
			)
		}

		const renderInfo = () => {
			return renderTable()
		}

		return (
			<div>
				{networkOnly({
					status: API_USERS_BUDGET_CRITERIA_HEADS_CRITERIA_VALUES_STATUS,
					data: budget_criteria_head,
					render: renderInfo,
				})}
			</div>
		)
	}

	const renderPageContent = () => {
		// const bcslaSearch = (
		//   <Input.Search
		//     placeholder="BCSLA"
		//     onSearch={(bcsla) => repaginate(1, pageSize, bcsla)}
		//     style={{ width: 200, marginBottom: "10px" }}
		//   />
		// )

		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}></div>

				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const strategy =
		budget_criteria_head && budget_criteria_head.id === budgetCriteriaHeadId
			? staleWhileRevalidate
			: networkOnly

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('criteria-edit'))
		return <Redirect to='/' />

	return (
		<div className='page-content budget-criteria-heads-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_BUDGET_CRITERIA_HEADS_CRITERIA_VALUES_STATUS,
					data: budget_criteria_head,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetCriteriaHeadsShowPage)

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { view } from "@risingstack/react-easy-state"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Select, Button, message, Input, DatePicker } from "antd"

import OrganizationFieldsService from "services/users/organization-fields.service"
import ContentLoading from "components/common/ContentLoading"
import BudgetCriteriaHeadsService from "services/users/budget-criteria-heads.service"

const isNumeric = (data) => {
  if (!data) return false
  return parseInt(data, 10).toString() === data.toString()
}

const StyledCriteria = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  & > .input {
    flex: 2;
  }
  & > .input:nth-child(2) {
    margin: 0px 10px;
    flex: 1;
  }
  & > .action-buttons {
    display: flex;
    justify-content: space-between;
    width: 85px;
    & button {
      margin-left: 10px;
    }
  }
`

const ConditionRow = ({
  condition,
  lastInputGroup = false,
  addNewCondition,
  removeCondition,
  conditionIndex,
  conditionSetLength,
  updateConditionValues,
  organizationFields = [],
  canDelete,
  hideNotOperator = false,
}) => {
  const [fieldName, setFieldName] = useState(null)
  const [fieldId, setFieldId] = useState(null)
  const [fieldDataType, setFieldDataType] = useState(null)
  const [fieldType, setFieldType] = useState(null)
  const [operator, setOperatorValue] = useState(null)
  const [value, setValue] = useState(null)
  const [dropdownLoading, setDropdownLoading] = useState(false)
  const [dropdownValues, setDropdownValues] = useState([])
  const [selectedFieldValue, setSelectedFieldValue] =useState('')

  useEffect(() => {
    if (organizationFields.length === 0) return
    const defaultSelectedField =
      organizationFields.find((oField) => {
        if (condition.field_id) {
          // find by field id
          const oFieldKey = oField.id || oField.key
          const conditionKey = condition.field_id

          return oFieldKey && conditionKey && oFieldKey === conditionKey && oField.type === condition.type
        }
        if (condition.field) {
          // find by field
          const oFieldKey = oField.name
          const conditionKey = condition.field

          return oFieldKey && conditionKey && oFieldKey === conditionKey
        }
        return false
      }) || {}


    setFieldId(defaultSelectedField.id || defaultSelectedField.key)
    // setFieldName(defaultSelectedField.name)

    // setFieldDataType(defaultSelectedField.data_type)
    const key = defaultSelectedField.id ? defaultSelectedField.type + '-' + defaultSelectedField.id : defaultSelectedField.key
    setFieldType(defaultSelectedField.type)
    setSelectedFieldValue(key)
    setOperatorValue(condition.operator)

    setValue(condition.values)
  }, [condition, organizationFields])

  /*
    Current Row:
      condition {field_id, field, operator, values}

    Selected Field: state
    when this is changed, dropdown values need to be populated

    in case of organization fields, field_id = organization_field.id
    in case of fitment_std_fields, field_id = fitment_field.key

    if field_id is a string it is a fitment field
    if field_id is a number it is an organization field

    if it is an organization_field, load "dropdown values" data
    else, do not make any query

    Row can represent the following:
    1. Condition as stored in the criteria in the criteria set.
    2. Selected field which is different from the condition

    But we will make sure that the row represents only the selected field always.
    For this, to display the first time (i.e. without any user input),
    we need to create a mapper that maps condition to a selected field

  */

  useEffect(() => {
    if (!fieldId) return
    if (!organizationFields) return

    const field = organizationFields.find(
      (oField) => {
        return ((oField.id === fieldId && oField.type === fieldType) || oField.key === fieldId)
      }
    )
    if (!field) return

    setFieldName(field.name)
    setFieldDataType(field.data_type)
    setFieldType(field.type)
    if (!isNumeric(fieldId)) return

    const fetchOrganizationFieldsData = async () => {
      const tempState = {}

      setDropdownLoading(true)
      await OrganizationFieldsService.show({ pageState: tempState, fieldId })

      const { organization_field } = tempState
      if (!organization_field) {
        message.error("Oops!! something went wrong")
        return
      }

      const ddv = organization_field.organization_field_values

      if (!ddv || ddv.length === 0) {
        message.error("No value found for selected organization field")
        return
      }
      setDropdownValues(ddv)
      setDropdownLoading(false)
    }

    const fetchBudgetCriteriaHead = async ()=>{
      const tempState = {}

      setDropdownLoading(true)
      await BudgetCriteriaHeadsService.criteriaValues({ pageState: tempState, id:fieldId })

      const { budget_criteria_values } = tempState
      if (!budget_criteria_values) {
        message.error("Oops!! something went wrong")
        return
      }
    
      const ddv = budget_criteria_values.map((data)=>data?.name)
      if (!ddv || ddv.length === 0) {
        message.error("No value found for selected budget criteria field")
        return
      }

      setDropdownValues(ddv)
      setDropdownLoading(false)
    }
    if (fieldType === 'budget_criteria_head') {
      fetchBudgetCriteriaHead()
    return
    }
    fetchOrganizationFieldsData()
  
  }, [fieldId,fieldType, organizationFields])

  const handleFieldChange = (key) => {
    const [type,id]=key.split('-')
    setSelectedFieldValue(key)
    if(!id){
      setFieldId(key)
      return
    }
    setFieldId(parseInt(id))
    setFieldType(type)
  }

  const handleOperatorChange = (operator) => {
    setOperatorValue(operator)
    if ((operator !== "not" && value?.length > 0) || operator === "not") {
      updateConditionValues({
        field_id: fieldId,
        type: fieldType,
        field: fieldName,
        operator,
        values: value,
      })
    }
  }

  const handleValueChange = (values, dataType) => {
    if (!values) return

    let tempValues = values

    if (dataType === "boolean") {
      tempValues = JSON.parse(values)
    }
    setValue(tempValues)

    updateConditionValues({
      field_id: fieldId,
      type: fieldType,
      field: fieldName,
      operator,
      values: tempValues,
    })
  }

  const renderFieldOptions = () => {
    if (!organizationFields) return
    return organizationFields.map((f) => {
      const key = f.id ? f.type+'-'+f.id:f.key
      return (
        <Select.Option key={key} value={key}>
          {f.name}
        </Select.Option>
      )
    })
  }

  const renderOperatorOptions = () => {
    if (fieldDataType === "decimal" && fieldName !== "Restricted Stock Units") {
      return (
        <React.Fragment>
          <Select.Option value="=">{`=`}</Select.Option>
          <Select.Option value="<">{`<`}</Select.Option>
          <Select.Option value=">">{`>`}</Select.Option>
          <Select.Option value="<->">{`<->`}</Select.Option>
        </React.Fragment>
      )
    }

    if (fieldDataType === "boolean" || fieldName === "Restricted Stock Units") {
      return (
        <React.Fragment>
          <Select.Option value="=">{`=`}</Select.Option>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Select.Option value="any">Any</Select.Option>
        {!hideNotOperator && <Select.Option value="not">Not</Select.Option>}
      </React.Fragment>
    )
  }

  const renderFieldValueOptions = () => {
    if (!dropdownValues) return
    return dropdownValues.map((f, i) => (
      <Select.Option key={i} value={f.label || f}>
        {f.label || f}
      </Select.Option>
    ))
  }

  const renderFieldValueInput = () => {
    if (fieldDataType === "date") {
      return (
        <DatePicker
          placeholder="DD-MMM-YYYY"
          style={{ width: "100%" }}
          defaultValue={
            value?.length > 0 ? moment(value[0], "DD-MMM-YYYY") : null
          }
          onChange={(date, dateString) => handleValueChange([dateString])}
        />
      )
    }

    if (fieldDataType === "list") {
      const fieldValues = organizationFields.find((of) => of.name === fieldName)
        ?.values
      return (
        <Select
          mode={
            ["any", "not"].includes(operator?.toLowerCase()) ? "multiple" : null
          }
          placeholder="Choose a value"
          style={{ width: "100%" }}
          disabled={!operator}
          value={value || []}
          loading={dropdownLoading}
          onChange={handleValueChange}
        >
          {fieldValues.map((v, i) => (
            <Select.Option key={i} value={v}>
              {v}
            </Select.Option>
          ))}
        </Select>
      )
    }

    if (Number.isInteger(fieldId)) {
      return (
        <Select
          mode={
            ["any", "not"].includes(operator?.toLowerCase()) ? "multiple" : null
          }
          placeholder="Choose a value"
          style={{ width: "100%" }}
          disabled={!operator}
          value={value || []}
          loading={dropdownLoading}
          onChange={handleValueChange}
        >
          {renderFieldValueOptions()}
        </Select>
      )
    }

    if (fieldDataType === "boolean" || fieldName === "Restricted Stock Units") {
      return (
        <Select
          placeholder="Choose a value"
          style={{ width: "100%" }}
          disabled={!operator}
          value={JSON.stringify(value) || []}
          loading={dropdownLoading}
          onChange={(values) => handleValueChange(values, "boolean")}
        >
          <Select.Option key={`option-true`} value={JSON.stringify(["true"])}>
            True
          </Select.Option>
          <Select.Option key={`option-false`} value={JSON.stringify(["false"])}>
            False
          </Select.Option>
        </Select>
      )
    }

    return (
      <Input
        placeholder="Enter field's value"
        disabled={!operator}
        value={value}
        onChange={(e) => handleValueChange([e.target.value])}
      />
    )
  }
  if (organizationFields.length === 0) {
    return <ContentLoading />
  }
  return (
    <StyledCriteria>
      <div className="input">
        <Select
          style={{ width: "100%" }}
          showSearch
          placeholder="Choose field"
          value={selectedFieldValue}
          filterOption={(inputValue, option) => {
            if (!option.children) return false
            return option.children
              .toLowerCase()
              .includes(inputValue.toLowerCase())
              ? true
              : false
          }}
          onChange={handleFieldChange}
        >
          {renderFieldOptions()}
        </Select>
      </div>
      <div className="input">
        <Select
          placeholder="Choose an operator"
          style={{ width: "100%" }}
          value={operator}
          onChange={handleOperatorChange}
        >
          {renderOperatorOptions()}
        </Select>
      </div>
      <div className="input">{renderFieldValueInput()}</div>
      <div className="action-buttons">
          <div>
            <Button
              danger
              shape="circle"
              onClick={removeCondition}
              disabled={canDelete}
            >
              <DeleteOutlined />
            </Button>
          </div>
        {lastInputGroup && (
          <div>
            <Button
              type="primary"
              disabled={
                dropdownLoading || (operator === "any" && !value) || !operator
              }
              ghost
              shape="circle"
              onClick={addNewCondition}
            >
              <PlusOutlined />
            </Button>
          </div>
        )}
      </div>
    </StyledCriteria>
  )
}

export default view(ConditionRow)

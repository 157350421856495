import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import queryString from "query-string"
import { Form, Input, Button, message, Row, Col } from "antd"

import StyledLoginPageContent from "components/common/styled-components/StyledLoginPageContent"
import globalStore from "store/index"
import * as AuthHelper from "helpers/auth.helper"
import TokensService from "services/users/tokens.service"
import { storeInLocalStorage } from "store/localStorage"

const LoginPage = (props) => {
  const history = useHistory()
  const params = queryString.parse(history.location.search)

  const { redirectTo, companyId, redirect, integration } = params

  const [submitting, setSubmitting] = useState(false)
  const [recaptchaVerified, setRecaptchaVerified] = useState(true)

  const onFinish = async (values) => {
    var recaptchaToken = window.grecaptcha.enterprise.getResponse()
    if (!recaptchaToken) {
      setRecaptchaVerified(false)
      return
    }
    setRecaptchaVerified(true)
    const pageState = {}

    const postData = {
      auth: {
        ...values,
      },
      "g-recaptcha-response": recaptchaToken,
    }

    setSubmitting(true)
    await TokensService.login({ pageState, values: postData })
    setSubmitting(false)

    const { serverStatus, user, userErrors } = pageState

    if (serverStatus && serverStatus.status === 201) {
      globalStore.currentUser = null
      AuthHelper.setToken(user.jwt)
      storeInLocalStorage({ key: "userType", value: null })
      message.success("LoggedIn successfully")
      if (integration === "SmartRecruiter") {
        history.push({
          pathname: "/consent-form",
          state: {
            companyId,
            redirect,
          },
        })
        return
      }
      if (redirectTo && !redirectTo.includes("change-password")) {
        history.push(redirectTo)
        return
      }
      history.push("/")
      return
    }

    message.error((userErrors && userErrors[0]) || "Oops!! something went wrong")
  }

  return (
    <div className="page-content login-page">
      <StyledLoginPageContent>
        <div className="form__box">
          <h1>Login</h1>
          <p>Enter your details below to continue</p>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}>
              <Input placeholder="Enter your email or username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}>
              <Input.Password placeholder="Enter your password" />
            </Form.Item>

            <Form.Item>
              <Row justify="center">
                <Col>
                  <div
                    className="g-recaptcha"
                    data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  />
                </Col>
              </Row>
              {!recaptchaVerified ? (
                <div style={{ color: "red", margin: "15px" }}>Please verify the captcha</div>
              ) : (
                ""
              )}
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Submit
                </Button>
                <Button
                  type="primary"
                  ghost
                  disabled={!process.env.REACT_APP_WNS_SSO_URL}
                  onClick={() => {
                    window.location = process.env.REACT_APP_WNS_SSO_URL
                  }}>
                  ADFS Login
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledLoginPageContent>
    </div>
  )
}

export default view(LoginPage)

import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Row, Col, Select, Input, Button, message } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"

import OrganizationsService from "services/admin-users/organizations.service"
import CountriesService from "services/admin-users/countries.service"
import IndustriesService from "services/admin-users/industries.service"

const StyledPageContent = styled.div`
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & input:nth-child(2) {
      margin-left: 15px;
    }
    & .action-buttons {
      margin: 0px;
      & > button {
        margin: 0px 0px 0px 15px;
      }
    }
  }
`

const OrganizationEditPage = (props) => {
  const history = useHistory()

  const [name, setName] = useState(null)
  const [industryId, setIndustryId] = useState(null)
  const [address, setAddress] = useState(null)
  const [city, setCity] = useState(null)
  const [countryId, setCountryId] = useState(null)
  const [countries, setCountries] = useState([])
  const [industries, setIndustries] = useState([])
  const [createButtonLoading, setCreateButtonLoading] = useState(false)

  useEffect(() => {
    const tempState = {}

    const fetchCountries = async () => {
      await CountriesService.index({ pageState: tempState })

      const { countries } = tempState

      if (countries) {
        setCountries(countries)
      }
    }

    fetchCountries()
  }, [])

  useEffect(() => {
    const tempState = {}

    const fetchIndustries = async () => {
      await IndustriesService.index({ pageState: tempState })

      const { industries } = tempState

      if (industries) {
        setIndustries(industries)
      }
    }

    fetchIndustries()
  }, [])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleIndustryChange = (industryId) => {
    setIndustryId(industryId)
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value)
  }

  const handleCityChange = (e) => {
    setCity(e.target.value)
  }

  const handleCountryChange = (countryId) => {
    setCountryId(countryId)
  }

  const handleCreate = async () => {
    const tempState = {}
    const postData = {
      organization: {
        name,
        address,
        city,
        country_id: countryId,
        industry_id: industryId,
      },
    }

    setCreateButtonLoading(true)
    await OrganizationsService.create({
      pageState: tempState,
      values: postData,
    })
    setCreateButtonLoading(false)

    const { serverStatus } = tempState

    if (serverStatus.status !== 200) {
      message.error("Organization couldn't be created.")
      return
    }

    message.destroy()
    message.success("Organization created.")
    history.push("/admin-users/organizations")
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="input-box">
              <h1>Name</h1>
              <Input
                value={name}
                onChange={handleNameChange}
                placeholder="Enter organization name"
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="input-box">
              <h1>Industry</h1>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select industry"
                optionFilterProp="children"
                onChange={handleIndustryChange}
                value={industryId}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {industries.map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <div className="input-box">
          <h1>Address</h1>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Input
                value={address}
                placeholder="Enter address"
                onChange={handleAddressChange}
              />
            </Col>
            <Col span={6}>
              <Input
                value={city}
                placeholder="Enter city"
                onChange={handleCityChange}
              />
            </Col>
            <Col span={6}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select country"
                optionFilterProp="children"
                value={countryId}
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countries.map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        <div>
          <div className="action-buttons">
            <Button
              type="primary"
              loading={createButtonLoading}
              onClick={handleCreate}
            >
              Create
            </Button>
          </div>
        </div>
      </StyledPageContent>
    )
  }

  return (
    <div className="page-content dashboard-page">
      <StyledBox maxWidth="800px">{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(OrganizationEditPage)

import React, { useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { RedoOutlined, SyncOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const RefreshButton = ({ refresh, type, ...props }) => {
	const [loading, setLoading] = useState(false)
	if (!refresh) return null

	const handleClick = async () => {
		setLoading(true)
		await refresh()
		setLoading(false)
	}

	const renderIcon = () => {
		if (loading) return <SyncOutlined spin />
		return <RedoOutlined spin={loading} rotate={270} />
	}

	return (
		<Button
			type={type || 'link'}
			onClick={handleClick}
			title='Refresh'
			disabled={loading}
			{...props}>
			{renderIcon()}
		</Button>
	)
}

export default view(RefreshButton)

import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"

import { message, Button, Tag } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"

import { staleWhileRevalidate } from "utils/render-strategies"

import globalStore from "store/index"

import UsersService from "services/admin-users/organizations/users.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const UsersShowPage = () => {
  const history = useHistory()
  const pageState = globalStore.ui.adminUsers.organizations.users.show

  const { organizationId, userId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.adminUsers.organizations.users.show

      await UsersService.show({ pageState, organizationId, userId })

      const { user } = pageState

      if (!user) {
        message.error("User data couldn't be fetched")
        return
      }
    }

    fetchData()

    return () => {
      globalStore.ui.adminUsers.organizations.users.show = {}
    }
  }, [organizationId, userId])

  const handleEdit = () => {
    history.push(
      `/admin-users/organizations/${organizationId}/users/${userId}/edit`
    )
  }

  const renderPageContent = () => {
    const { user } = pageState

    const { name, email, organization_roles } = user

    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit User
          </Button>
        </div>
        <div className="content">
          <div style={{ marginBottom: 5 }}>
            <strong>Name:</strong> {name}
          </div>
          <div style={{ marginBottom: 5 }}>
            <strong>Email:</strong> {email}
          </div>
          <div style={{ marginBottom: 5 }}>
            <strong>Organization Roles:</strong>{" "}
            {organization_roles.map((r) => (
              <Tag>{r.name}</Tag>
            ))}
          </div>
        </div>
      </StyledPageContent>
    )
  }

  const { user, API_ADMIN_USERS_ORGANIZATIONS_USERS_SHOW_STATUS } = pageState

  return (
    <div className="page-content">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_ADMIN_USERS_ORGANIZATIONS_USERS_SHOW_STATUS,
          data: user,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(UsersShowPage)

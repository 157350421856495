import React, { useEffect } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Tag, Button } from "antd"

import { staleWhileRevalidate } from "utils/render-strategies"
import UsersService from "services/users/users.service"
import globalStore from "store"

import StyledBox from "components/common/styled-components/StyledBox"
import UserCard from "components/UserCard"

const StyledUserDetails = styled.div`
  & > .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    & > button {
      margin-left: 10px;
    }
  }
  & > .role-card {
    h1 {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
`

const UsersShowPage = () => {
  const history = useHistory()
  const pageState = globalStore.ui.users.show
  const { userId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.users.show

      await UsersService.show({ pageState, userId })
    }

    fetchData()
  }, [userId])

  // const handleDelete = () => {}

  const renderPageContent = () => {
    const { user } = pageState

    return (
      <StyledUserDetails>
        <div className="action-buttons">
          <Button
            type="primary"
            ghost
            onClick={() => history.push(`/users/${userId}/edit`)}
          >
            Edit
          </Button>
        </div>
        <UserCard name={user?.name} email={user?.email} />
        <div className="role-card">
          <h1>Organization Roles</h1>
          <div className="roles">
            {user?.organization_roles?.map((r) => (
              <Tag>{r.name}</Tag>
            ))}
          </div>
        </div>
        <div className="role-card" style={{ marginTop: 30 }}>
          <h1>Access Groups</h1>
          <div className="roles">
            {user?.access_groups?.map((r) => (
              <Tag>{r.name}</Tag>
            ))}
          </div>
        </div>
      </StyledUserDetails>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("user-view")
  )
    return <Redirect to="/" />

  const { API_USERS_SHOW_STATUS, user } = pageState

  return (
    <div className="page-content user-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_SHOW_STATUS,
          data: user,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(UsersShowPage)

import { API, generic } from "api"

export default class LettersService {
  static download = async ({ pageState, letterUuid }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_LETTERS_DOWNLOAD_STATUS",
      stateDataKey: ["redirect_url"],
      stateErrorKey: ["redirectUrlErrors"],
      apiEndpoint: API.users.letters.download,
      apiUrlReplacements: { letterUuid },
      apiData: {},
      errorMessage: "Letter could not be downloaded.",
    })
  }
  // static docusignDownload = async ({ pageState, letterUuid }) => {
  //   await generic({
  //     pageState,
  //     stateApiStatusKey: "API_USERS_DOCUSIGN_LETTERS_DOWNLOAD_STATUS",
  //     stateDataKey: ["redirect_url"],
  //     stateErrorKey: ["redirectUrlErrors"],
  //     apiEndpoint: API.users.letters.docusignDownload,
  //     apiUrlReplacements: { letterUuid },
  //     apiData: {},
  //     errorMessage: "Docusign letter could not be downloaded.",
  //   })
  // }
}

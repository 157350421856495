import { API, generic } from "api"

export default class UsersService {
  static index = async ({ pageState, email }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INDEX_STATUS",
      stateDataKey: ["users"],
      stateErrorKey: ["usersErrors"],
      apiEndpoint: API.users.index,
      apiUrlReplacements: {},
      apiData: { params: { email } },
      errorMessage: "Users data could not be fetched.",
    })
  }

  static show = async ({ pageState, userId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SHOW_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.show,
      apiUrlReplacements: { userId },
      apiData: {},
      errorMessage: "User data could not be fetched.",
    })
  }

  static update = async ({ pageState, values, userId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_UPDATE_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.update,
      apiUrlReplacements: { userId },
      apiData: { ...values },
      errorMessage: "User data could not be updated.",
    })
  }

  static updateStatus = async ({ pageState, userId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_UPDATE_STATUSES_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.updateStatus,
      apiUrlReplacements: { userId },
      apiData: {},
      errorMessage: "User data could not be updated.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_CREATE_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "User could not be created.",
    })
  }

  static me = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ME_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.me,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "User data could not be created.",
    })
  }

  static resetPassword = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_RESET_PASSWORD_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.resetPassword,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Password could not be updated.",
      serializedResponse: false,
    })
  }

  static smartRecruitersConsent = async ({ pageState ,values}) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SMART_RECRUITERS_CONSENT_STATUS",
      stateDataKey: ["message"],
      stateErrorKey: ["error"],
      apiEndpoint: API.users.smartRecruitersConsent,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Smart recruiters consent data could not be validated.",
      serializedResponse: false,
    })
  }
}

import React, { useEffect } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { EyeOutlined } from "@ant-design/icons"
import { Button, message, Empty } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import EmailTemplatesService from "services/users/email-templates.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const EmailTemplatesIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.configuration.emailTemplates.index

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.emailTemplates.index

      await EmailTemplatesService.index({ pageState })

      const { serverStatus, emailTemplatesErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (emailTemplatesErrors) {
        message.error(
          emailTemplatesErrors[0] || "Email templates couldn't be fetched."
        )
        return
      }
    }

    fetchData()
  }, [])

  const handleCreate = () => {
    history.push("/configuration/email-templates/new")
  }

  const handleCardItemClick = (emailId) => {
    history.push(`/configuration/email-templates/${emailId}`)
  }

  const { email_templates, API_USERS_EMAIL_TEMPLATES_INDEX_STATUS } = pageState

  const renderContents = () => {
    if (email_templates.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            New Email Template
          </Button>
        </Empty>
      )
    }

    return (
      <StyledList>
        {email_templates.map((emailTemplate) => (
          <div
            className="item-card"
            key={`item-card-${emailTemplate.id}`}
            onClick={() => handleCardItemClick(emailTemplate.id)}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{emailTemplate.template_type}</div>
                <div>{}</div>
              </div>
            </div>
            <div className="icon-box">
              <Button type="link">
                <EyeOutlined />
              </Button>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        {/* <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleCreate}>
            New Email Template
          </Button>
        </div> */}
        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content email-templates-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_EMAIL_TEMPLATES_INDEX_STATUS,
          data: email_templates,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(EmailTemplatesIndexPage)

import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import LABELS from 'constants/labels'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import StyledBox from 'components/common/styled-components/StyledBox'
import globalStore from 'store/index'
import { Button, message, Row, Col, Table } from 'antd'
import { DatePicker } from '../../../../node_modules/antd/lib/index'
import moment from '../../../../node_modules/moment/moment'
import { PlusOutlined } from '@ant-design/icons'
import ConditionRow from 'components/budget-analysis/ConditionRow'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'

import BudgetHeadServices from 'services/users/budget-heads.service'
import { Redirect } from 'react-router-dom'

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const blankCondition = {
	field: null,
	operator: null,
	values: null,
}

const resourcePlanCondition = [
	{
		field: 'Unit Code',
		operator: null,
		values: null,
	},
	{
		field: 'Unit Name',
		operator: null,
		values: null,
	},
	{
		field: 'Template Name',
		operator: null,
		values: null,
	},
]

const ResourcePlanPage = () => {
	const dateFormat = 'DD-MMM-YYYY'
	const currentYear = new Date().getFullYear()
	const [inputValues, setInputValues] = useState({})
	const [criteria, setCriteria] = useState([resourcePlanCondition])
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [calculateButtonLoading, setCalculateButtonLoading] = useState(false)
	const [tableLoading, setTableLoading] = useState(false)
	const [tableDataSource, setTableDataSource] = useState([])
	const [tableVisibility, setTableVisibility] = useState(false)
	const [dateRange, setDateRange] = useState([
		moment.utc(currentYear + '-04-01'),
		moment.utc(currentYear + 1 + '-03-31'),
	])
	const [criteriaChildren, setCriteriaChildren] = useState([])
	const wfmCurrentPeriod = globalStore.ui.configuration.currentWorkforcePeriod

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list

		const fetchAllFieldsData = async () => {
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		fetchAllFieldsData()
	}, [])

	useEffect(() => {
		if (wfmCurrentPeriod.workforce_period) {
			const startDate = wfmCurrentPeriod?.workforce_period?.start_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.start_date)
				: moment.utc().set('month', 3)
			const endDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.end_date)
				: moment.utc().set('month', 4).add(1, 'y')
			setDateRange([startDate, endDate])
		}
	}, [wfmCurrentPeriod, wfmCurrentPeriod.workforce_period])

	useEffect(() => {
		if (criteriaChildren.length) {
			const updateCriteriaData = criteria[0].map((data) => {
				if (criteriaChildren.some((items) => items.name === data.field)) {
					return { ...data, values: null, operator: null }
				}
				return data
			})
			setCriteria([updateCriteriaData])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteriaChildren])

	const getTableDataSource = (data) => {
		let resourcePlanData = []
		let totalCost = 0
		let totalCount = 0
		data.forEach((rpData) => {
			let role_name = rpData.role_name
			let proposition_formula = rpData.proposition_formula
			let headcount = rpData.headcount
			let annual_cost = rpData.annual_cost
			let unit_code = rpData.unit_code
			totalCost += Number(annual_cost)
			totalCount += Number(headcount)
			resourcePlanData.push({
				key: Math.random().toString(),
				unit_code,
				role_name,
				proposition_formula,
				headcount,
				annual_cost,
			})
		})

		resourcePlanData.push({
			key: Math.random().toString(),
			unit_code: <strong>Total</strong>,
			role_name: '',
			proposition_formula: '',
			headcount: totalCount || 0,
			annual_cost: totalCost || 0,
		})
		return resourcePlanData
	}

	const handleDateChange = (range) => {
		if (range) {
			setDateRange([range[0], range[1]])
			setInputValues({
				...inputValues,
				start_date: dateRange[0],
				end_date: dateRange[1],
			})
		}
	}

	const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(deletedConditionIndex, 1)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleCreateConditionValues = ({ conditionSetIndex, conditionIndex, condition }) => {
		if (!condition) return
		if (!condition.values) return

		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				return newConditionSet.map((c, index) => {
					if (index !== conditionIndex) return c

					return condition
				})
			})

			return newCriteria
		})
	}

	const handleCalculate = async () => {
		setCalculateButtonLoading(true)
		if (!tableVisibility) {
			setTableVisibility(true)
		}

		let pageState = globalStore.ui.configuration.budgetHeads.resourcePlan
		pageState = {}
		const postData = {
			budget_utilization: {
				criteria,
				start_date: dateRange[0].startOf('month').format('DD-MM-YYYY'),
				end_date: dateRange[1].endOf('month').format('DD-MM-YYYY'),
			},
		}
		setTableLoading(true)
		await BudgetHeadServices.calculateResourcePlan({
			pageState,
			postData,
		})
		// TODO: Using mock data here, after getting the api, use the api.
		// await BudgetHeadsService.resourcePlanning({ pageState, postData })
		// await resourcePlanningService({ pageState, postData })
		setTableLoading(false)
		const { data, serverStatus, dataErrors } = pageState
		if (serverStatus.status !== 200) {
			message.error('Oops!! something went wrong')
			return
		}

		if (dataErrors) {
			message.error(dataErrors[0] || "Resource Planning data couldn't be fetched.")
			setCalculateButtonLoading(false)
			setTableVisibility(false)
			return
		}

		setTableDataSource(getTableDataSource(data))

		setCalculateButtonLoading(false)
	}
	const handleCalculateAll = async () => {
		// TODO: Call an api and get table source data
		// then populate the table with the data
		setCalculateButtonLoading(true)
		if (!tableVisibility) {
			setTableVisibility(true)
		}

		let pageState = globalStore.ui.configuration.budgetHeads.resourcePlan
		pageState = {}
		const postData = {
			budget_utilization: {
				criteria,
				start_date: dateRange[0].startOf('month').format('DD-MM-YYYY'),
				end_date: dateRange[1].endOf('month').format('DD-MM-YYYY'),
			},
		}
		setTableLoading(true)
		await BudgetHeadServices.calculateAllResourcePlan({
			pageState,
			postData,
		})

		setTableLoading(false)
		const { data, serverStatus, dataErrors } = pageState
		if (serverStatus.status !== 200) {
			message.error('Oops!! something went wrong')
			return
		}

		if (dataErrors) {
			message.error(dataErrors[0] || "Resource Planning data couldn't be fetched.")
			setCalculateButtonLoading(false)
			setTableVisibility(false)
			return
		}

		setTableDataSource(getTableDataSource(data))

		setCalculateButtonLoading(false)
	}

	const handleDownloadReport = () => {
		// TODO: Call the export api, and show message export started
	}

	const columns = [
		{
			title: 'Unit Code',
			dataIndex: 'unit_code',
			key: 'unit_code',
		},
		{
			title: 'Role Name',
			dataIndex: 'role_name',
			key: 'role_name',
		},
		{
			title: 'Proposition Used',
			dataIndex: 'proposition_formula',
			key: 'proposition_formula',
		},
		{
			title: 'Headcount',
			dataIndex: 'headcount',
			key: 'headcount',
			align: 'right',
		},
		{
			title: 'Annual Cost',
			dataIndex: 'annual_cost',
			key: 'annual_cost',
			align: 'right',
		},
	]

	const renderContent = () => {
		const renderTable = () => (
			<Table
				className='resource-plan-table'
				pagination={{
					defaultPageSize: 12,
				}}
				loading={tableLoading}
				bordered
				columns={columns}
				dataSource={tableDataSource}
				scroll={{
					x: 'max_content',
				}}
				style={{
					margin: '20px 0px',
				}}
			/>
		)
		return renderTable()
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<Row gutter={20}>
					<Col span={16}>
						<div className='input-box'>
							<div>
								<strong>Period</strong>
							</div>
							<DatePicker.RangePicker
								format={dateFormat}
								value={dateRange} // fix this
								onChange={handleDateChange}
							/>
						</div>
					</Col>
					<Col push={4}>
						<div className='action-buttons submit' style={{ marginTop: '25px' }}>
							<Button type='ghost' loading={calculateButtonLoading} onClick={handleCalculateAll}>
								Calculate All
							</Button>
						</div>
					</Col>
				</Row>
				<div className='input-box'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<strong>Criteria</strong>
						{criteria.filter((c) => c.length > 0).length === 0 && (
							<Button shape='circle' onClick={() => setCriteria([[blankCondition]])}>
								<PlusOutlined />
							</Button>
						)}
					</div>
					{criteria.map((conditionSet, conditionSetIndex) => {
						return (
							<div key={conditionSetIndex}>
								{conditionSet.map((condition, conditionIndex) => {
									return (
										<ConditionRow
											criteria={criteria}
											clearChildren={setCriteriaChildren}
											condition={condition}
											key={conditionIndex}
											conditionIndex={conditionSetIndex}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											conditionSetLength={conditionSet.length}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleCreateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											budgetCriteriaHeads={budgetCriteriaHeads}
										/>
									)
								})}
							</div>
						)
					})}
				</div>
				<div className='action-buttons submit'>
					<Button type='primary' loading={calculateButtonLoading} onClick={handleCalculate}>
						Calculate
					</Button>
				</div>
				{tableVisibility && <div className='content'>{renderContent()}</div>}
				{tableVisibility && !tableLoading && (
					<div className='action-buttons submit'>
						<Button type='primary' loading={calculateButtonLoading} onClick={handleDownloadReport}>
							Download Report
						</Button>
					</div>
				)}
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['workforce'].every((featureKey) => accessibleFeatures.includes(featureKey))
	)
		return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(ResourcePlanPage)

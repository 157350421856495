import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { TreeTable, TreeState } from 'cp-react-tree-table'
import { message, Button, Tooltip, Popconfirm } from 'antd'
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons'
import StyledBox from 'components/common/styled-components/StyledBox'
import { networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-top: 5px;
		margin-bottom: 10px;
	}
	& > .action-buttons.bottom {
		display: flex;
		justify-content: flex-end;
		margin-top: 5px;
	}
`
//create plan and actual value

const BudgetHeadsIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.configuration.budgetHeads.budget_analysis
	const [treeValue, setTreeValue] = useState([])
	const TREE_HEIGHT = 320

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetHeads = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.budget_analysis
			await BudgetHeadsService.budget_analysis({ pageState })

			const { serverStatus, budgetHeadsErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetHeadsErrors) {
				message.error(budgetHeadsErrors[0] || "Budget heads couldn't be fetched.")
				return
			}
			setTreeValue(TreeState.create(pageState.data))
		}

		fetchBudgetHeads()
	}, [])

	useEffect(() => {
		if (!Array.isArray(pageState.data)) return
		setTreeValue(TreeState.create(pageState.data))
	}, [pageState.data])

	const handleOnChange = (newValue) => {
		setTreeValue(newValue)
	}

	const handleCreate = () => {
		history.push('/configuration/budget-heads/new')
	}

	const handleEdit = (budgetHeadId) => {
		history.push(`/configuration/budget-heads/${budgetHeadId}/edit`)
	}

	const handleBudgetValueClick = (budgetHeadId) => {
		history.push(`/configuration/budget-heads/${budgetHeadId}/budget-values`)
	}

	const handleBudgetUtilizationClick = (budgetHeadId) => {
		history.push(`/configuration/budget-heads/${budgetHeadId}/budget-utilizations`)
	}

	const handleCreateBudgetChildren = (id) => {
		history.push(`/configuration/budget-heads/${id}/budget_children`)
	}

	const handleDelete = async (budgetHeadId) => {
		const tempState = {}

		await BudgetHeadsService.delete({
			pageState: tempState,
			budgetHeadId,
		})

		const { serverStatus } = tempState

		if (serverStatus.status !== 200) return

		history.push(`/configuration/budget-heads`)

		// globalStore.ui.configuration.budgetHeads.index = {
		//   ...globalStore.ui.configuration.budgetHeads.index,
		//   budget_heads: budget_heads.filter(
		//     (budget_head) =>
		//     budget_head.id !== budgetHeadId
		//   ),
		// }
	}

	const renderIndexCell = (row) => {
		return (
			<div
				style={{ paddingLeft: row.metadata.depth * 15 + 'px' }}
				className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
				{row.metadata.hasChildren ? (
					<button className='toggle-button' onClick={row.toggleChildren}>
						<span className={'toggle-button-icon'}>
							{row.$state.isExpanded ? (
								<CaretDownOutlined style={{ color: '#7614a7' }} />
							) : (
								<CaretRightOutlined />
							)}
						</span>
					</button>
				) : (
					''
				)}

				<span>
					{row.data.name}
					<Button type='link' onClick={() => handleCreateBudgetChildren(row.data.id)}>
						<Tooltip title='Add New'>(+)</Tooltip>
					</Button>
				</span>
			</div>
		)
	}

	const renderPlannedCell = (row) => {
		return (
			<>
				<Button
					type='link'
					style={{ padding: 0 }}
					onClick={() => handleBudgetValueClick(row.data.id)}>
					<span style={{ textDecorationLine: 'underline' }}>Planned Values</span>
				</Button>
				<span style={{ marginLeft: '3px', marginRight: '3px' }}>|</span>
				<Button
					type='link'
					style={{ padding: 0 }}
					onClick={() => handleBudgetUtilizationClick(row.data.id)}>
					<span style={{ textDecorationLine: 'underline' }}>Actual Values</span>
				</Button>
			</>
		)
	}

	const renderActionCell = (row) => {
		return (
			<>
				<Button type='link' style={{ padding: 0 }} onClick={() => handleEdit(row.data.id)}>
					Edit
				</Button>
				<span style={{ marginLeft: '3px', marginRight: '3px' }}>|</span>
				<Popconfirm
					title='Are you sure to delete?'
					onConfirm={() => handleDelete(row.data.id)}
					onCancel={() => {}}
					okText='Yes'
					cancelText='No'>
					<Button style={{ padding: 0 }} type='link'>
						Delete
					</Button>
				</Popconfirm>
			</>
		)
	}

	const { API_USERS_BUDGET_HEADS_ANALYSIS_STATUS } = pageState

	const renderList = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Tooltip title='Create a new record'>
						<Button type='primary' ghost onClick={handleCreate}>
							Create Budget Head
						</Button>
					</Tooltip>
				</div>
				<TreeTable value={treeValue} height={TREE_HEIGHT} onChange={(e) => handleOnChange(e)}>
					<TreeTable.Column
						basis='180px'
						grow='1'
						renderCell={renderIndexCell}
						renderHeaderCell={() => <span>Name</span>}
					/>
					<TreeTable.Column
						basis='180px'
						renderCell={renderPlannedCell}
						renderHeaderCell={() => <span>Budget Actions</span>}
					/>
					<TreeTable.Column
						renderCell={renderActionCell}
						renderHeaderCell={() => <span>Action</span>}
					/>
				</TreeTable>
				<div className='action-buttons bottom'>
					<Button
						type='primary'
						ghost
						onClick={() => history.push('/configuration/budget-heads/budget-entry')}>
						Create Planned & Actual Values
					</Button>
				</div>
			</StyledPageContent>
		)
	}

	const renderPageContent = () => {
		return <StyledBox>{renderList()}</StyledBox>
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures.length &&
		!['smart-budget'].every((featureKey) => accessibleFeatures.includes(featureKey))
	)
		return <Redirect to='/' />

	return (
		<div className='page-content'>
			{networkOnly({
				status: API_USERS_BUDGET_HEADS_ANALYSIS_STATUS,
				data: treeValue,
				render: renderPageContent,
			})}
		</div>
	)
}

export default view(BudgetHeadsIndexPage)

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { view } from '@risingstack/react-easy-state'

import ContentLoading from 'components/common/ContentLoading'
import OrganizationsService from 'services/users/organizations.service'

const StyledTemplateEditor = styled.div`
	margin: 10px 0;
	& .cke_top {
		display: ${(props) => (props.editable ? 'block' : 'none')};
	}
`

const TemplateEditor = ({
	content,
	onChange = () => {},
	editable = true,
	customVariable,
	customId = 'editor',
}) => {
	const [fetchingVariables, setFetchingVariables] = useState(false)
	const [variables, setVariables] = useState(null)
	const [editorLoaded, setEditorLoaded] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}

			setFetchingVariables(true)
			await OrganizationsService.templateVariable({ pageState: tempState })
			setFetchingVariables(false)

			const { unserialized } = tempState

			setVariables(unserialized?.variables?.map((v) => [v.name, v.key]) || [])
		}
		if (customVariable) {
			setVariables(customVariable)
			return
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (variables) {
			window.CKEDITOR.replace(customId, {
				language: 'en',
				height: 300,
				toolbarCanCollapse: true,
				tokenStart: '{{',
				tokenEnd: '}}',
				availableTokens: variables,
				basicEntities: false,
				readOnly: !editable,
			})

			setEditorLoaded(true)
		}
	}, [customId, editable, variables])

	useEffect(() => {
		if (!editorLoaded) return
		content && window.CKEDITOR.instances[customId].setData(content)
	}, [editorLoaded, content, customId])

	useEffect(() => {
		if (!editorLoaded) return

		editable &&
			window.CKEDITOR.instances[customId].on('change', function (evt) {
				onChange(evt.editor.getData())
			})

		editable &&
			window.CKEDITOR.on('instanceReady', function (event) {
				event.editor.on('beforeCommandExec', function (event) {
					// Show the paste dialog for the paste buttons and right-click paste
					if (event.data.name === 'paste') {
						event.editor._.forcePasteDialog = true
					}
					// Don't show the paste dialog for Ctrl+Shift+V
					if (
						event.data.name === 'pastetext' &&
						event.data.commandData.from === 'keystrokeHandler'
					) {
						event.cancel()
					}
				})
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editable, editorLoaded])

	if (fetchingVariables) return <ContentLoading />

	return (
		<StyledTemplateEditor editable={editable}>
			<textarea id={customId} name='editor' />
		</StyledTemplateEditor>
	)
}

export default view(TemplateEditor)

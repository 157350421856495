import React, { useEffect, useState } from 'react'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Checkbox, Input, Button, Popconfirm, message, Collapse, Tooltip, Row, Col } from 'antd'

import StyledFitmentItemCard from 'components/common/styled-components/StyledFitmentItemCard'
import RefreshButton from 'components/RefreshButton'

import FitmentsService from 'services/users/fitments.service'
import globalStore from 'store/index'

const blankApproverObject = {
	name: '',
	email: '',
}

const Approvers = ({ approvers, onInputChange, onAddRow, onRemoveRow }) => {
	return (
		<div style={{ display: 'flex', gap: 20, margin: 20, paddingLeft: 30 }}>
			Approvers:
			<div>
				{approvers.map((approver, index) => (
					<div style={{ display: 'flex', gap: 20, marginBottom: 10 }} key={`approver-${index}`}>
						<Input
							placeholder='Please enter name'
							value={approver.name}
							onChange={(e) => onInputChange({ value: e.target.value, inputType: 'name', index })}
						/>
						<Input
							placeholder='Please enter email'
							value={approver.email}
							onChange={(e) =>
								onInputChange({
									value: e.target.value,
									inputType: 'email',
									index,
								})
							}
						/>
						<div style={{ width: 100, flex: 'none' }}>
							<div style={{ display: 'flex', gap: 20 }}>
								{approvers.length !== 1 && (
									<Button danger shape='circle' onClick={() => onRemoveRow(index)}>
										<DeleteOutlined />
									</Button>
								)}
								{approvers.length - 1 === index && (
									<Button type='primary' ghost shape='circle' onClick={onAddRow}>
										<PlusOutlined />
									</Button>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

const DocusignSettingForm = ({
	approverChecked,
	handleApproverCheckboxChange,
	confirmDocusign,
	updating,
	approvers,
	setApprovers,
	setEditable,
	includePasswordProtectionChecked,
	handleIncludePasswordProtectionCheckboxChange,
}) => {
	const handleApproverInputChange = ({ value, inputType, index }) => {
		const clonedApprovers = JSON.parse(JSON.stringify(approvers))

		setApprovers(clonedApprovers.map((a, i) => (i === index ? { ...a, [inputType]: value } : a)))
	}

	const handleAddRow = () => {
		const clonedApprovers = JSON.parse(JSON.stringify(approvers))

		setApprovers([...clonedApprovers, blankApproverObject])
	}

	const handleRemoveRow = (index) => {
		const clonedApprovers = JSON.parse(JSON.stringify(approvers))
		clonedApprovers.splice(index, 1)
		setApprovers(clonedApprovers)
	}

	const sendDocusignDisable = () => {
		const { approver_required_for_docusign } = globalStore.currentOrganization
		const firstApprover = approvers[0] || {}

		return (
			approver_required_for_docusign && 
			!(approverChecked && approvers.length > 0 && firstApprover.name && firstApprover.email)
		)
	}
	const { approver_required_for_docusign } = globalStore.currentOrganization
	return (
		<div className='item-list'>
			<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
				<div>
					<Checkbox checked={true} disabled={true} />
					<span style={{ margin: '0px 5px' }}>Send to Candidate:</span>
				</div>
				<div>
					<Checkbox checked={approverChecked} onChange={handleApproverCheckboxChange} />
					<span style={{ margin: '0px 5px' }}>Send to approver:</span>
					{approver_required_for_docusign && <span style={{ color: 'red' }}>*</span>}
				</div>
				<div>
					<Checkbox
						checked={includePasswordProtectionChecked}
						onChange={handleIncludePasswordProtectionCheckboxChange}
					/>
					<span style={{ margin: '0px 5px' }}>Include password protection:</span>
				</div>
			</div>

			{approverChecked && (
				<Approvers
					approvers={approvers}
					onInputChange={handleApproverInputChange}
					onAddRow={handleAddRow}
					onRemoveRow={handleRemoveRow}
				/>
			)}
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: 20,
					marginRight: 5,
					gap: 20,
				}}>
				<Button onClick={() => setEditable(false)}>Cancel</Button>
				<Popconfirm
					title='Are you sure?'
					onConfirm={confirmDocusign}
					onCancel={() => {}}
					okText='Yes'
					cancelText='No'
					disabled={sendDocusignDisable()}>
					<Button loading={updating} disabled={sendDocusignDisable()}>
						Send via Docusign
					</Button>
				</Popconfirm>
			</div>
		</div>
	)
}

const DocusignSettingFields = ({
	fitmentId,
	approverChecked,
	approvers,
	docusignStatus,
	setEditable,
	docusignRecipientStatus,
	onSetFitment,
	includePasswordProtectionChecked,
	candidate,
}) => {
	const handleRefresh = async () => {
		const tempState = {}

		await FitmentsService.refreshDocusignStatus({
			pageState: tempState,
			fitmentId,
		})

		const { fitment } = tempState

		if (!fitment) {
			message.error('Failed to update Docusign status')
			return
		}

		message.success('Docusign status updated')

		onSetFitment(fitment)
	}

	return (
		<div>
			<Row gutter={20}>
				<Col span={12}>
					<div className='item-list' style={{ width: '100%' }}>
						<div className='item-row fitment-field'>
							<div className='label'>Send to Candidate</div>
							<div>
								<Checkbox defaultChecked={true} disabled />
							</div>
						</div>
						<div className='item-row fitment-field'>
							<div className='label'>Include password protection</div>
							<div>
								<Checkbox defaultChecked={includePasswordProtectionChecked} disabled />
							</div>
						</div>
					</div>
				</Col>
				<Col span={12}>
					<div className='item-list' style={{ width: '100%' }}>
						<div className='item-row fitment-field'>
							<div className='label'>Send to approver</div>
							<div>
								<Checkbox defaultChecked={approverChecked} disabled />
							</div>
						</div>
						<div className='item-row fitment-field'>
							<div className='label'>Letter Status</div>
							<div>
								<span>{docusignStatus}</span>
								<RefreshButton refresh={handleRefresh} />
							</div>
						</div>
					</div>
				</Col>
			</Row>
			<Row gutter={20}>
				<Col span={15}>
					<div className='item-row fitment-field'>
						<div className='label'>Approval Status</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								padding: 0,
								alignItems: 'stretch',
							}}>
							{docusignRecipientStatus.map((a, i) => (
								<Tooltip title={a.docusign_status} key={i}>
									<div
										style={{
											display: 'flex',
											borderRadius: 4,
											background: 'white',
											margin: '8px',
											width: 'auto',
											padding: 0,
										}}>
										<div
											style={{
												background: 'transparent',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'start',
											}}>
											<div className={`docusign-status ${a.docusign_status}`}></div>
											<div
												style={{
													background: 'transparent',
													display: 'flex',
													flexDirection: 'column',
												}}>
												<strong>
													{candidate.email === a.email
														? 'Candidate'
														: a.name || `Approver ${i + 1}`}
												</strong>
												<span style={{ background: 'transparent' }}>{a.email}</span>
											</div>
										</div>
									</div>
								</Tooltip>
							))}
						</div>
					</div>
				</Col>
			</Row>
			<Row justify='center' gutter={20}>
				<Col>
					<Button onClick={() => setEditable(true)}>Edit</Button>
				</Col>
			</Row>
		</div>
	)
}

const DocusignSettings = ({
	approverOrder,
	docusignApproverEmails = [],
	docusignStatus,
	fitmentId,
	onSetFitment,
	docusignRecipientStatus,
	passwordRequireForDocusign,
	candidate,
}) => {
	const [approverChecked, setApproverChecked] = useState(false)
	const [updating, setUpdating] = useState(false)
	const [collapsed, setCollapsed] = useState(true)
	const [approvers, setApprovers] = useState([blankApproverObject])
	const [editable, setEditable] = useState(false)
	const [includePasswordProtectionChecked, setIncludePasswordProtectionChecked] = useState(false)

	useEffect(() => {
		setApproverChecked(approverOrder ? true : false)
		setApprovers(docusignApproverEmails.length > 0 ? docusignApproverEmails : [blankApproverObject])
		setIncludePasswordProtectionChecked(passwordRequireForDocusign || false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const confirmDocusign = async () => {
		const postData = {
			fitment: {
				approver_order: approverChecked ? 1 : 0,
				password_require_for_docusign: includePasswordProtectionChecked,
			},
		}

		postData['fitment']['docusign_approver_emails'] = approverChecked ? approvers : []

		const tempState = {}

		setUpdating(true)
		await FitmentsService.updateDocusignSetting({
			pageState: tempState,
			fitmentId,
			values: postData,
		})
		setUpdating(false)

		const { fitment } = tempState

		if (!fitment) {
			message.error('Oops!! something went wrong')
			return
		}

		message.success('Docusign Initiated')

		setEditable(false)
		onSetFitment(fitment)
	}

	const handleApproverCheckboxChange = (e) => {
		setApproverChecked(e.target.checked)
	}
	const handleIncludePasswordProtectionCheckboxChange = (e) => {
		setIncludePasswordProtectionChecked(e.target.checked)
	}

	const handleCollapseChange = (values) => {
		setCollapsed(values.length === 0)
	}

	return (
		<StyledFitmentItemCard>
			<div className='header'>
				<h1>Docusign Settings</h1>
			</div>
			<div>
				<Collapse onChange={handleCollapseChange}>
					<Collapse.Panel header={`Click to ${collapsed ? 'expand' : 'collapse'}`}>
						{editable ? (
							<DocusignSettingForm
								approverChecked={approverChecked}
								handleApproverCheckboxChange={handleApproverCheckboxChange}
								confirmDocusign={confirmDocusign}
								updating={updating}
								approvers={approvers}
								setApprovers={setApprovers}
								setEditable={setEditable}
								includePasswordProtectionChecked={includePasswordProtectionChecked}
								handleIncludePasswordProtectionCheckboxChange={
									handleIncludePasswordProtectionCheckboxChange
								}
							/>
						) : (
							<DocusignSettingFields
								approverChecked={approverChecked}
								approvers={approvers || []}
								docusignStatus={docusignStatus}
								setEditable={setEditable}
								docusignRecipientStatus={docusignRecipientStatus}
								fitmentId={fitmentId}
								onSetFitment={onSetFitment}
								includePasswordProtectionChecked={includePasswordProtectionChecked}
								candidate={candidate}
							/>
						)}
					</Collapse.Panel>
				</Collapse>
			</div>
		</StyledFitmentItemCard>
	)
}

export default DocusignSettings

import React from "react"
import { view } from "@risingstack/react-easy-state"
import { Link } from "react-router-dom"

const Error404Page = (props) => (
  <div className="page-content error-page">
    <h1>Page not found</h1>
    <Link to="/">Goto Dashboard</Link>
  </div>
)

export default view(Error404Page)

import { API, generic } from 'api'

export default class BudgetUtilizationsService {
	static index = async ({
		pageState,
		page = 1,
		pageSize = 25,
		budgetHeadId,
		bcsla,
		startDate,
		endDate,
	}) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_INDEX_STATUS',
			stateDataKey: ['budget_utilizations', 'total', 'page', 'page_size'],
			stateErrorKey: ['budgetUtilizationsErrors', 'totalErrors', 'pageErrors', 'pageSizeErrors'],
			apiEndpoint: API.users.budget_utilizations.index,
			apiUrlReplacements: { budgetHeadId },
			apiData: {
				params: { page, page_size: pageSize, bcsla, start_date: startDate, end_date: endDate },
			},
			errorMessage: 'Budget Criteria could not be fetched.',
		})
	}

	static create = async ({ pageState, values, budgetHeadId, validate }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_CREATE_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.create,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values, validate },
			errorMessage: 'Budget Criteria could not be created.',
		})
	}
	static addEmployee = async ({ pageState, values, budgetHeadId, validate }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_ADD_EMPLOYEE_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.addEmployee,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values, validate },
			errorMessage: 'Budget Criteria could not be created.',
		})
	}

	static validateActualBudget = async ({ pageState, values, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_VALIDATE_ACTUAL_BUDGET_STATUS',
			stateDataKey: ['insight'],
			stateErrorKey: ['insightErrors'],
			apiEndpoint: API.users.budget_utilizations.validate_actual_budget,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values },
			errorMessage: 'Budget Criteria could not be validated.',
			serializedResponse: false,
		})
	}

	static show = async ({ pageState, budgetUtilizationId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATION_SHOW_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.show,
			apiUrlReplacements: { budgetUtilizationId },
			apiData: {},
			errorMessage: 'Actual Budget could not be fetched.',
		})
	}

	static update = async ({ pageState, values, budgetUtilizationId, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_UPDATE_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.update,
			apiUrlReplacements: { budgetUtilizationId },
			apiData: { ...values, budget_head_id: budgetHeadId },
			errorMessage: 'Actual Budget could not be updated.',
		})
	}

	static sampleFileData = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_ACTUAL_SAMPLE_FILE_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budget_utilizations.sampleFileData,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Sample File could not be fetched.',
		})
	}

	static essentialFieldsList = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_ACTUAL_ESSENTIAL_FIELD_LIST_STATUS',
			stateDataKey: ['budget_criteria_heads'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budget_utilizations.essentialFieldsList,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Sample File could not be fetched.',
		})
	}

	static delete = async ({ pageState, budgetUtilizationId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_DELETE_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.delete,
			apiUrlReplacements: { budgetUtilizationId },
			apiData: {},
			errorMessage: 'Actual Budget could not be deleted.',
		})
	}

	static upsertActualBudgetEntry = async ({ pageState, values, id, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEAD_UPDATE_ACTUAL_BUDGET_ENTRY_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.upsert_actual_budget_entry,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values, id },
			errorMessage: 'Actual Budget could not be updated.',
		})
	}

	static import = async ({ pageState, formData, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_IMPORT_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.import,
			apiUrlReplacements: { budgetHeadId },
			apiData: formData,
			errorMessage: 'Actual Budget could not be imported.',
		})
	}
	static importVacancy = async ({ pageState, formData, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_IMPORT_VACANCY_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.importVacancy,
			apiUrlReplacements: { budgetHeadId },
			apiData: formData,
			errorMessage: 'Actual Budget could not be imported.',
		})
	}

	static export = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_EXPORT_STATUS',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.export,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Actual Budget could not be exported.',
		})
	}

	static sync = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_SYNC_WORKFORCE_DATA',
			stateDataKey: ['budget_utilization'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budget_utilizations.sync_workforce_data,
			apiData: { sync_for: values },
			errorMessage: 'Sync not successful',
		})
	}

	static search = async ({ pageState, page = 1, pageSize = 25, values, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_SEARCH_STATUS',
			stateDataKey: ['budget_utilizations'],
			stateErrorKey: ['budgetUtilizationErrors'],
			apiEndpoint: API.users.budgetHeads.budgetUtilizations.search,
			apiUrlReplacements: { budgetHeadId },
			apiData: { page, page_size: pageSize, ...values },
			errorMessage: 'Actual Budget could not be searched.',
		})
	}
}

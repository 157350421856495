import { API, generic } from "api"

export default class SalaryRangesService {
  static index = async ({ pageState, page = 1, pageSize = 25, jobFamily }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_INDEX_STATUS",
      stateDataKey: [
        "salary_ranges",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "salaryRangesErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.salaryRanges.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize, jobFamily } },
      errorMessage: "Salary ranges could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_CREATE_STATUS",
      stateDataKey: ["salary_range"],
      stateErrorKey: ["salaryRangeErrors"],
      apiEndpoint: API.users.salaryRanges.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Salary range could not be created.",
    })
  }

  static show = async ({ pageState, salaryRangeId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_SHOW_STATUS",
      stateDataKey: ["salary_range"],
      stateErrorKey: ["salaryRangeErrors"],
      apiEndpoint: API.users.salaryRanges.show,
      apiUrlReplacements: { salaryRangeId },
      apiData: {},
      errorMessage: "Salary range could not be fetched.",
    })
  }

  static update = async ({ pageState, values, salaryRangeId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_UPDATE_STATUS",
      stateDataKey: ["salary_range"],
      stateErrorKey: ["salaryRangeErrors"],
      apiEndpoint: API.users.salaryRanges.update,
      apiUrlReplacements: { salaryRangeId },
      apiData: { ...values },
      errorMessage: "Salary range could not be updated.",
    })
  }

  static import = async ({ pageState, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_IMPORT_STATUS",
      stateDataKey: ["salary_range"],
      stateErrorKey: ["salaryRangeErrors"],
      apiEndpoint: API.users.salaryRanges.import,
      apiUrlReplacements: {},
      apiData: formData,
      errorMessage: "Salary ranges could not be imported.",
    })
  }

  static export = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_EXPORT_STATUS",
      stateDataKey: ["salary_ranges"],
      stateErrorKey: ["salaryRangesErrors"],
      apiEndpoint: API.users.salaryRanges.export,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Salary ranges could not be exported.",
    })
  }

  static refresh = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_REFRESH_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.salaryRanges.refresh,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Salary range could not be refreshed.",
    })
  }

  static revalidate = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_REVALIDATE_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.salaryRanges.revalidate,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Salary Range could not be revalidated.",
    })
  }

  static delete = async ({ pageState, salaryRangeId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_DELETE_STATUS",
      stateDataKey: ["salary_range"],
      stateErrorKey: ["salaryRangeErrors"],
      apiEndpoint: API.users.salaryRanges.delete,
      apiUrlReplacements: { salaryRangeId },
      apiData: {},
      errorMessage: "Salary Range could not be deleted.",
    })
  }

  static auditReport = async ({ pageState, salaryRangeId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_AUDIT_REPORT_STATUS",
      stateDataKey: ["salary_range"],
      stateErrorKey: ["salaryRangeErrors"],
      apiEndpoint: API.users.salaryRanges.auditReport,
      apiUrlReplacements: { salaryRangeId },
      apiData: {},
      errorMessage: "Audit report could not be exported.",
    })
  }

  static auditReports = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SALARY_RANGES_AUDIT_REPORTS_STATUS",
      stateDataKey: ["salary_ranges"],
      stateErrorKey: ["salaryRangesErrors"],
      apiEndpoint: API.users.salaryRanges.auditReports,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Audit reports could not be exported.",
    })
  }
}

import React, { useState, useEffect } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { Button, Input, message, Row, Col, Select } from "antd"
import { isContainsBlankCriteria } from "utils/criteria"

import StyledBox from "components/common/styled-components/StyledBox"
import ConditionRow from "components/ConditionRow"
import globalStore from "store/index"

import OrganizationsService from "services/users/organizations.service"
import IncentivesService from "services/users/incentives.service"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const blankCondition = {
  field: null,
  operator: null,
  values: null,
}

const IncentiveNewPage = () => {
  const history = useHistory()
  const [criteria, setCriteria] = useState([[blankCondition]])
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const [inputValues, setInputValues] = useState({})

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  const handleInputChange = (e, inputType) => {
    let newInputValues = null

    if (["frequency", "payout"].includes(inputType)) {
      newInputValues = { ...inputValues, [inputType]: e }
    } else {
      newInputValues = { ...inputValues, [inputType]: e.target.value }
    }

    setInputValues(newInputValues)
  }

  const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(deletedConditionIndex, 1)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleCreateConditionValues = ({
    conditionSetIndex,
    conditionIndex,
    condition,
  }) => {
    if (!condition) return
    if (!condition.values) return

    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        return newConditionSet.map((c, index) => {
          if (index !== conditionIndex) return c

          return condition
        })
      })

      return newCriteria
    })
  }

  const handleCreate = async () => {
    if (!inputValues["currency"]) {
      message.error("Currency can't be blank")
      return
    }
    if (!inputValues["payout"]) {
      message.error("Amount can't be blank")
      return
    }
    if (!inputValues["frequency"]) {
      message.error("Frequency can't be blank")
      return
    }
    if (!inputValues["payout_value"]) {
      message.error("Payout value can't be blank")
      return
    }

    if (isContainsBlankCriteria(criteria)) {
      message.error("Criteria cannot be blank")
      return
    }

    const pageState = {}
    const postData = {
      incentive_configuration: {
        criteria: criteria.filter((c) => c.length > 0),
        ...inputValues,
      },
    }

    setCreateButtonLoading(true)
    await IncentivesService.create({
      pageState,
      values: postData,
    })
    setCreateButtonLoading(false)

    const { serverStatus, incentiveErrors } = pageState

    if (incentiveErrors) {
      message.error(incentiveErrors[0] || "Incentive couldn't be created.")
      return
    }

    if (serverStatus.status === 200) {
      message.success("Incentive created.")
      history.push(`/configuration/incentives`)
      setCriteria([[blankCondition]])
      setInputValues({})

      return
    }

    message.error("Oops!! something went wrong")
  }

  const renderPageContent = () => {
    return (
      <StyledConfigurationFormBox>
        <h1>Incentive</h1>
        <Row gutter={20}>
          <Col span={12}>
            <div className="input-box">
              <strong>Currency</strong>
              <Input
                value={inputValues["currency"]}
                placeholder="Currency"
                onChange={(e) => handleInputChange(e, "currency")}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="input-box">
              <strong>Frequency</strong>
              <Select
                style={{ display: "block" }}
                placeholder="Choose frequency"
                onChange={(value) => handleInputChange(value, "frequency")}
              >
                <Select.Option value="daily">Daily</Select.Option>
                <Select.Option value="weekly">Weekly</Select.Option>
                <Select.Option value="monthly">Monthly</Select.Option>
                <Select.Option value="quarterly">Quarterly</Select.Option>
                <Select.Option value="half_yearly">Half Yearly</Select.Option>
                <Select.Option value="annually">Annually</Select.Option>
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div className="input-box">
              <strong>Payout</strong>
              <Select
                style={{ display: "block" }}
                placeholder="Choose payout"
                onChange={(value) => handleInputChange(value, "payout")}
              >
                <Select.Option value="percentage">Percentage</Select.Option>
                <Select.Option value="absolute">Absolute</Select.Option>
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div className="input-box">
              <strong>Payout Value</strong>
              <Input
                value={inputValues["payout_value"]}
                placeholder="Enter payout value"
                onChange={(e) => handleInputChange(e, "payout_value")}
              />
            </div>
          </Col>
        </Row>
        <div className="input-box">
          <strong>Criteria</strong>
          {criteria.map((conditionSet, conditionSetIndex) => {
            return (
              <div key={conditionSetIndex}>
                {conditionSet.map((condition, conditionIndex) => {
                  return (
                    <ConditionRow
                      condition={condition}
                      key={conditionIndex}
                      lastInputGroup={
                        conditionSet.length === conditionIndex + 1
                      }
                      firstInputGroup={conditionIndex === 0}
                      conditionSetLength={conditionSet.length}
                      inputGroupIndex={conditionIndex}
                      updateConditionValues={(condition) =>
                        handleCreateConditionValues({
                          conditionSetIndex,
                          conditionIndex,
                          condition,
                        })
                      }
                      addNewCondition={() =>
                        handleAddNewCondition(conditionSetIndex, conditionIndex)
                      }
                      removeCondition={() =>
                        handleRemoveCondition(conditionSetIndex, conditionIndex)
                      }
                      organizationFields={
                        globalStore.currentOrganization.all_fields_with_datatype
                      }
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="action-buttons submit">
          <Button
            type="primary"
            loading={createButtonLoading}
            onClick={handleCreate}
          >
            Create Incentive
          </Button>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-edit")
  )
    return <Redirect to="/configuration/incentives" />

  return (
    <div className="page-content dashboard-page">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(IncentiveNewPage)

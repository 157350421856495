import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Badge, Collapse, message } from 'antd'

import RefreshButton from 'components/RefreshButton'
import StyledFitmentItemCard from 'components/common/styled-components/StyledFitmentItemCard'

import globalStore from 'store'

import MinimumWagesService from 'services/users/minimum-wages.service'
import OfferLetterTemplatesService from 'services/users/offer-letter-templates.service'
import SalaryRangesService from 'services/users/salary-ranges.service'
import BudgetsService from 'services/users/budgets.service'
import OrganizationMinimumSalariesService from 'services/users/organization-minimum-salaries.service'
import IncentiveConfigurationsService from 'services/users/incentives.service'
import SSConfigurationsService from 'services/users/ss/configurations.service'
import LetterTemplatesService from 'services/users/letter-templates.service'

const Guidelines = ({ fitment, onSetFitment }) => {
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []
	const handleRefresh = async ({ service, errorMessage = '' }) => {
		const tempState = {}
		await service.refresh({
			pageState: tempState,
			fitmentId: fitment.id,
		})
		const { fitment: newFitment, fitmentErrors } = tempState
		if (fitmentErrors) {
			message.error(fitmentErrors[0] || errorMessage)
		}
		globalStore.ui.fitments.show.fitment = newFitment
		onSetFitment(newFitment)
	}

	const handleRefreshMinimumWage = async () => {
		await handleRefresh({
			service: MinimumWagesService,
			errorMessage: "Minimum wage couldn't be refreshed.",
		})
	}

	const handleRefreshBudget = async () => {
		await handleRefresh({
			service: BudgetsService,
			errorMessage: "Budget couldn't be refreshed.",
		})
	}

	const handleRefreshSalaryRange = async () => {
		await handleRefresh({
			service: SalaryRangesService,
			errorMessage: "Salary Range couldn't be refreshed.",
		})
	}

	const handleRefreshOrganizationMinimumSalary = async () => {
		await handleRefresh({
			service: OrganizationMinimumSalariesService,
			errorMessage: "Organization minimum salary couldn't be refreshed.",
		})
	}

	const handleRefreshAdditionalLetterTemplate = async () => {
		await handleRefresh({
			service: LetterTemplatesService,
			errorMessage: "Additional Letter Template couldn't be refreshed.",
		})
	}
	const handleRefreshOfferLetterTemplate = async () => {
		await handleRefresh({
			service: OfferLetterTemplatesService,
			errorMessage: "Offer Letter Template couldn't be refreshed.",
		})
	}

	const handleRefreshIncentiveConfiguration = async () => {
		await handleRefresh({
			service: IncentiveConfigurationsService,
			errorMessage: "Incentive Configuration couldn't be refreshed.",
		})
	}

	const handleRefreshSSConfiguration = async () => {
		await handleRefresh({
			service: SSConfigurationsService,
			errorMessage: "Salary Structure Configuration couldn't be refreshed.",
		})
	}

	const incentiveMessage = (fitment) => {
		if (!fitment) return null
		const { incentive_configuration, calculated_incentive } = fitment
		if (!incentive_configuration) return null

		if (incentive_configuration.payout === 'percentage') {
			const calculatedStr = calculated_incentive
				? `(${calculated_incentive.currency} ${calculated_incentive.annual_amount} Annually)`
				: ''
			// const calculated = fitment.ctc
			//   ? `(${incentive_configuration.currency} ${Math.round(
			//       (fitment.ctc * incentive_configuration.payout_value) / 100
			//     )} Annually)`
			//   : ""
			return `${incentive_configuration.payout_value}% ${incentive_configuration.frequency_display} ${calculatedStr}`
		}

		return `${incentive_configuration.currency} ${incentive_configuration.payout_value} ${incentive_configuration.frequency_display}`
	}

	const budgetMessage = (fitment) => {
		if (!fitment) return null
		if (!fitment.budget) return null
		return `${fitment.budget?.currency} ${fitment.budget?.amount_display}`
	}

	const data = [
		{
			key: 'minimum-wage',
			title: 'Minimum Wage',
			length: fitment.assignable_ids?.minimum_wages_ids?.length || 0,
			resource: fitment.minimum_wage,
			disabled: fitment.disable_features.includes('minimum_wage'),
			details: `${fitment.minimum_wage?.currency} ${fitment.minimum_wage?.amount} ${fitment.minimum_wage?.frequency}`,
			refresh: handleRefreshMinimumWage,
		},
		{
			key: 'organization-minimum-salary',
			title: 'Organization Minimum Salary',
			length: fitment.assignable_ids?.organization_minimum_salaries_ids?.length || 0,
			resource: fitment.organization_minimum_salary,
			disabled: fitment.disable_features.includes('organization_minimum_salary'),
			details: `${fitment.organization_minimum_salary?.currency} ${fitment.organization_minimum_salary?.amount} ${fitment.organization_minimum_salary?.frequency}`,
			refresh: handleRefreshOrganizationMinimumSalary,
		},
		{
			key: 'salary-range',
			title: 'Salary Range',
			length: fitment.assignable_ids?.salary_ranges_ids?.length || 0,
			resource: fitment.salary_range,
			disabled: fitment.disable_features.includes('salary_range'),
			details: `Min: ${fitment.salary_range?.minimum_display} | Mid: ${fitment.salary_range?.median_display} | Max: ${fitment.salary_range?.maximum_display}`,
			refresh: handleRefreshSalaryRange,
		},
		{
			key: 'budget',
			title: 'Budget',
			length: fitment.assignable_ids?.budgets_ids?.length || 0,
			resource: fitment.budget,
			disabled: fitment.disable_features.includes('budget'),
			details: budgetMessage(fitment),
			refresh: handleRefreshBudget,
		},
		{
			key: 'incentive_configuration',
			title: 'Incentive / Variable Pay',
			length: fitment.assignable_ids?.incentive_configurations_ids?.length || 0,
			resource: fitment.incentive_configuration,
			disabled: fitment.disable_features.includes('incentive_configuration'),
			details: incentiveMessage(fitment),
			refresh: handleRefreshIncentiveConfiguration,
		},
		{
			key: 'ss_configuration',
			title: 'Salary Structure Template',
			length: fitment.assignable_ids?.ss_configurations_ids?.length || 0,
			resource: fitment.ss_configuration,
			disabled: fitment.disable_features.includes('ss_configuration'),
			details: `${fitment.ss_configuration?.name}`,
			refresh: handleRefreshSSConfiguration,
		},
		{
			key: 'offer_letter_template',
			title: 'Offer Letter Templates',
			length: fitment.assignable_ids?.offer_letter_templates_ids?.length || 0,
			resource: fitment.offer_letter_template,
			disabled: fitment.disable_features.includes('offer_letter_template'),
			details: `${fitment.offer_letter_template?.name}`,
			refresh: handleRefreshOfferLetterTemplate,
		},
		{
			key: 'additional_letter_templates',
			title: 'Additional Letter Templates',
			length: fitment.letters?.length || 0,
			details: `${fitment.applicable_letters_details?.map((data) => data.name).join(',')}`,
			disabled:
				!accessibleFeatures.includes('additional-document-send') &&
				![('approved', 'docusign_completed', 'docusign_inprogress')].includes(fitment?.status),
			refresh: handleRefreshAdditionalLetterTemplate,
		},
	]

	const renderBudgetDetails = (item) => {
		if (item.length > 1) {
			return (
				<div>
					Multiple found with matching criteria
					{renderRefreshButton(item)} {/* <RefreshButton refresh={item.refresh} /> */}
				</div>
			)
		}

		if (item.length === 0) {
			if (fitment?.fitment_validation?.budget_validation?.amount) {
				return (
					<div style={{ color: 'black' }}>
						Average Amount: {fitment.fitment_validation.budget_validation.amount}
						{renderRefreshButton(item)} {/* <RefreshButton refresh={item.refresh} /> */}
					</div>
				)
			}
			return (
				<div>
					None found with matching criteria. Average value will be considered
					{renderRefreshButton(item)} {/* <RefreshButton refresh={item.refresh} /> */}
				</div>
			)
		}

		return (
			<div style={{ color: 'black' }}>
				{item.details}
				{renderRefreshButton(item)} {/* <RefreshButton refresh={item.refresh} /> */}
			</div>
		)
	}

	const renderDetails = (item) => {
		if (item.key === 'additional_letter_templates') {
			return (
				<div style={{ color: 'black' }}>
					{item.details}
					{renderAdditionalDetails(item)}
				</div>
			)
		}
		if (item.key === 'budget') {
			return renderBudgetDetails(item)
		}

		if (item.length === 0) {
			return (
				<div>
					None found with matching criteria
					{renderRefreshButton(item)}
				</div>
			)
		}

		if (item.length > 1) {
			return (
				<div>
					Multiple found with matching criteria
					{renderRefreshButton(item)} {/* <RefreshButton refresh={item.refresh} /> */}
				</div>
			)
		}

		if (!item.resource) {
			return (
				<div>
					Found but not assigned yet. {renderRefreshButton(item)}{' '}
					{/* <RefreshButton refresh={item.refresh} /> */}
				</div>
			)
		}
		return (
			<div style={{ color: 'black' }}>
				{item.details}
				{/* <RefreshButton refresh={item.refresh} /> */}
				{renderRefreshButton(item)}
			</div>
		)
	}

	const statusFor = (item) => {
		if (item.key === 'additional_letter_templates') {
			if (item.length) return 'success'
			return 'default'
		}
		if (item.key === 'budget' && item.length === 0) return 'warning'
		if (item.length !== 1) return 'error'
		return 'success'
	}
	const renderRefreshButton = (item) => {
		var status = fitment.status
		if (status !== 'in_progress' && status !== 'approved') {
			return <RefreshButton refresh={item.refresh} />
		}
	}
	const renderAdditionalDetails = (item) => {
		return <RefreshButton refresh={item.refresh} />
	}

	// const renderRefreshButton = (refresh) => {
	//   var status = fitment.status
	//   if (status !== "in_progress" && status !== "approved") {
	//     return <RefreshButton refresh={refresh} />
	//   }
	// }

	const renderHeader = (item) => {
		return <Badge status={statusFor(item)} text={item.title} />
	}

	return (
		<StyledFitmentItemCard>
			<div className='header'>
				<h1>Guidelines</h1>
			</div>
			<Collapse>
				{data
					.filter((item) => {
						const { resource, disabled } = item
						if (disabled) return false
						if (resource?.name === 'Philippines-dummy') return false

						return true
					})
					.map((item, i) => {
						return (
							<Collapse.Panel
								key={i}
								header={renderHeader(item)}
								showArrow={false}
								disabled={item.length <= 1 || item.key === 'additional_letter_templates'}
								extra={renderDetails(item)}></Collapse.Panel>
						)
					})}
			</Collapse>
		</StyledFitmentItemCard>

		// <List
		//   dataSource={data}
		//   renderItem={(item) => (
		//     <div className="item-row" key={item.key}>
		//       <Badge
		//         status={item.length === 1 ? "success" : "error"}
		//         text={item.title}
		//       />
		//       <div>
		//         {renderDetails(item)} {renderRefreshButton(item)}
		//       </div>
		//     </div>
		//   )}
		//   header={<h1>Guidelines</h1>}
		// />
	)
}

export default view(Guidelines)

import { API, generic } from 'api'

export default class BudgetCriteriaHeadsService {
	static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_INDEX_STATUS',
			stateDataKey: ['budget_criteria_heads', 'total', 'page', 'page_size'],
			stateErrorKey: ['budgetCriteriaHeadsErrors', 'totalErrors', 'pageErrors', 'pageSizeErrors'],
			apiEndpoint: API.users.budget_criteria_heads.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, bcsla } },
			errorMessage: 'Budget Criteria could not be fetched.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_CREATE_STATUS',
			stateDataKey: ['budget_criteria_head'],
			stateErrorKey: ['budgetCriteriaHeadErrors'],
			apiEndpoint: API.users.budget_criteria_heads.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Budget Criteria could not be created.',
		})
	}

	static show = async ({ pageState, budgetCriteriaHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_SHOW_STATUS',
			stateDataKey: ['budget_criteria_head'],
			stateErrorKey: ['budgetCriteriaHeadsErrors'],
			apiEndpoint: API.users.budget_criteria_heads.show,
			apiUrlReplacements: { budgetCriteriaHeadId },
			apiData: {},
			errorMessage: 'Budget Criteria could not be fetched.',
		})
	}

	static validateCriteriaValues = async ({ pageState, budgetCriteriaHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_Validate_Criteria_Values',
			stateDataKey: ['validate_criteria_values'],
			stateErrorKey: ['validateCriteriaValuesErrors'],
			apiEndpoint: API.users.budget_criteria_heads.validateCriteriaValues,
			apiUrlReplacements: { budgetCriteriaHeadId },
			apiData: {},
			errorMessage: 'Budget Criteria could not be validated.',
		})
	}

	static update = async ({ pageState, values, budgetCriteriaHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_UPDATE_STATUS',
			stateDataKey: ['budget_criteria_head'],
			stateErrorKey: ['budgetCriteriaHeadErrors'],
			apiEndpoint: API.users.budget_criteria_heads.update,
			apiUrlReplacements: { budgetCriteriaHeadId },
			apiData: { ...values },
			errorMessage: 'Budget Criteria could not be updated.',
		})
	}

	static delete = async ({ pageState, budgetCriteriaHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_DELETE_STATUS',
			stateDataKey: ['budget_criteria_head'],
			stateErrorKey: ['budgetCriteriaHeadErrors'],
			apiEndpoint: API.users.budget_criteria_heads.delete,
			apiUrlReplacements: { budgetCriteriaHeadId },
			apiData: {},
			errorMessage: 'Budget criteria could not be deleted.',
		})
	}

	static updateActiveStatus = async ({ pageState, values, budgetCriteriaHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_UPDATE_ACTIVE_STATUS_STATUS',
			stateDataKey: ['budget_criteria_head'],
			stateErrorKey: ['budgetCriteriaHeadErrors'],
			apiEndpoint: API.users.budget_criteria_heads.update_active_status,
			apiUrlReplacements: { budgetCriteriaHeadId },
			apiData: { ...values },
			errorMessage: 'Budget Criteria status could not be updated.',
		})
	}

	static import = async ({ pageState, formData, id }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_IMPORT_STATUS',
			stateDataKey: ['budget_criteria_head'],
			stateErrorKey: ['budgetCriteriaHeadErrors'],
			apiEndpoint: API.users.budget_criteria_heads.import,
			apiUrlReplacements: { id },
			apiData: formData,
			errorMessage: 'Budget Criteria could not be imported.',
		})
	}

	static criteriaValues = async ({ pageState, id }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_CRITERIA_VALUES_STATUS',
			stateDataKey: ['budget_criteria_values'],
			stateErrorKey: ['budgetCriteriaValuesErrors'],
			apiEndpoint: API.users.budget_criteria_heads.criteriaValues,
			apiUrlReplacements: { id },
			apiData: {},
			errorMessage: 'Budget Criteria could not be imported.',
		})
	}
	static criteriaValuesList = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_CRITERIA_VALUES_STATUS',
			stateDataKey: ['budget_criteria_values', 'children_info'],
			stateErrorKey: ['budgetCriteriaValuesErrors', 'childrenInfoError'],
			apiEndpoint: API.users.budget_criteria_heads.criteriaValuesList,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Budget Criteria could not be imported.',
		})
	}

	static criteriaHeadList = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_CRITERIA_HEAD_LIST_STATUS',
			stateDataKey: ['budget_criteria_heads'],
			stateErrorKey: ['budgetCriteriaHeadsErrors'],
			apiEndpoint: API.users.budget_criteria_heads.criteria_head_list,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Budget Criteria could not be fetched.',
		})
	}

	static criteriaList = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey:
				'API_USERS_BUDGET_CRITERIA_HEADS_CRITERIA_HEAD_LIST_WITH_BUDGET_HEAD_STATUS',
			stateDataKey: ['budget_criteria_heads'],
			stateErrorKey: ['budgetCriteriaHeadsErrors'],
			apiEndpoint: API.users.budget_criteria_heads.criteriaList,
			apiUrlReplacements: {},
			apiData: {},
			serializedResponse: false,
			errorMessage: 'Budget Criteria could not be fetched.',
		})
	}

	static export = async ({ pageState, id }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_CRITERIA_HEADS_EXPORT_STATUS',
			stateDataKey: ['budgetCriteriaHeads'],
			stateErrorKey: ['budgetCriteriaHeadsErrors'],
			apiEndpoint: API.users.budget_criteria_heads.export,
			apiUrlReplacements: { id },
			apiData: {},
			errorMessage: 'Budget Criteria could not be exported.',
		})
	}

	// static refresh = async ({ pageState, fitmentId }) => {
	//   await generic({
	//     pageState,
	//     stateApiStatusKey: "API_USERS_BUDGETS_REFRESH_STATUS",
	//     stateDataKey: ["fitment"],
	//     stateErrorKey: ["fitmentErrors"],
	//     apiEndpoint: API.users.budgets.refresh,
	//     apiUrlReplacements: { fitmentId },
	//     apiData: {},
	//     errorMessage: "Budget could not be refreshed.",
	//   })
	// }

	// static revalidate = async ({ pageState, fitmentId }) => {
	//   await generic({
	//     pageState,
	//     stateApiStatusKey: "API_USERS_BUDGETS_REVALIDATE_STATUS",
	//     stateDataKey: ["fitment"],
	//     stateErrorKey: ["fitmentErrors"],
	//     apiEndpoint: API.users.budgets.revalidate,
	//     apiUrlReplacements: { fitmentId },
	//     apiData: {},
	//     errorMessage: "Budget could not be revalidated.",
	//   })
	// }
}

import { API, generic } from 'api'

export default class OrganizationConfigurationsService {
	static me = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_ORGANIZATION_CONFIGURATIONS_ME_STATUS',
			stateDataKey: ['organization'],
			stateErrorKey: ['organizationErrors'],
			apiEndpoint: API.users.organizationConfigurations.me,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Organization data could not be fetched.',
		})
	}

	static update = async ({ pageState, id, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_ORGANIZATION_CONFIGURATIONS_UPDATE_STATUS',
			stateDataKey: ['organization'],
			stateErrorKey: ['organizationErrors'],
			apiEndpoint: API.users.organizationConfigurations.update,
			apiUrlReplacements: { id },
			apiData: { ...values },
			errorMessage: 'Organization data could not be fetched.',
		})
	}
}

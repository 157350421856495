import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { SaveOutlined } from '@ant-design/icons'
import { Table, Button, Input, message, Select } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'

import StyledBox from 'components/common/styled-components/StyledBox'
import globalStore from 'store/index'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import BudgetHeadsService from 'services/users/budget-heads.service'
import FilterForm from 'components/budget-analysis/FilterForm'
import BudgetCriteriaValuesService from 'services/users/budget-criteria-values.service'
import BudgetValuesService from 'services/users/budget-values.service'
import BudgetUtilizationsService from 'services/users/budget-utilizations.service'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const BudgetEntry = (props) => {
	const budgetEntryDataPageState = globalStore.ui.configuration.budgetHeads.budget_data_entry
	const budgetCriteriaValuesPageState =
		globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.index
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [budgetHeads, setBudgetHeads] = useState([])
	const [budgetCriteria, setBudgetCriteria] = useState({})
	const [budgetEntryData, setBudgetEntryData] = useState([])
	const [tableData, setTableData] = useState([])
	const [dateRange, setDateRange] = useState([
		moment().set('month', 3),
		moment().set('month', 4).add(1, 'y'),
	])

	const { budget_criteria_values } = budgetCriteriaValuesPageState

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list

		const fetchAllFieldsData = async () => {
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		fetchAllFieldsData()
	}, [])

	useEffect(() => {
		setBudgetEntryData(budgetEntryDataPageState.data)
	}, [budgetEntryDataPageState])

	useEffect(() => {
		const fetchBudgetCriteriaValues = async () => {
			const pageState = globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.index
			await BudgetCriteriaValuesService.index({
				pageState,
				budgetCriteriaHeadId: budgetCriteria?.id,
			})
		}

		fetchBudgetCriteriaValues()
	}, [budgetCriteria])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetHeads.index

		const fetchBudgetHeads = async () => {
			await BudgetHeadsService.index({ pageState })
			const { budget_heads } = pageState

			if (!budget_heads) {
				message.error('Budget Heads not found')
				return
			}
			setBudgetHeads(budget_heads)
		}

		fetchBudgetHeads()
	}, [])

	useEffect(() => {
		const dataSource = budget_criteria_values?.map((ic, index) => {
			const budgetEntryRowData = {}
			budgetEntryData &&
				budgetEntryData.forEach((d) => {
					if (
						d.actual_criteria &&
						d.actual_criteria[0]?.some(
							(c) => c.field === budgetCriteria.name && c.values.includes(ic.name)
						)
					) {
						budgetEntryRowData['actual_rate'] = d.actual_rate
						budgetEntryRowData['actual_unit'] = d.actual_unit || 1
						budgetEntryRowData['actual_total_cost'] = d.actual_total_cost
						budgetEntryRowData['actual_id'] = d.actual_id
					}

					if (
						d.planned_criteria &&
						d.planned_criteria[0]?.some(
							(c) => c.field === budgetCriteria.name && c.values.includes(ic.name)
						)
					) {
						budgetEntryRowData['planned_rate'] = d.planned_rate
						budgetEntryRowData['planned_unit'] = d.planned_unit || 1
						budgetEntryRowData['planned_total_cost'] = d.planned_total_cost
						budgetEntryRowData['planned_id'] = d.planned_id
					}

					budgetEntryRowData['budget_head_id'] = d.budget_head_id
					budgetEntryRowData['start_date'] = d.start_date
					budgetEntryRowData['end_date'] = d.end_date
				})

			const retval = {
				name: ic.name,
				key: ic.id,
				parent: Object.values(ic.parent_heirarchy),
				...budgetEntryRowData,
			}
			return retval
		})
		setTableData(dataSource)
	}, [budget_criteria_values, budgetEntryData, budgetCriteria])

	const handleDropDownChange = (value, inputType) => {
		if (inputType === 'budget_criteria') {
			setBudgetCriteria(budgetCriteriaHeads.find((bh) => bh.name === value))
		}
	}

	const handleActualBudgetUpdate = async (index) => {
		const row = tableData[index]
		if (!row) return

		if (!row['actual_rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!row['actual_unit']) {
			message.error("Count can't be blank")
			return
		}

		const {
			actual_id,
			budget_head_id,
			actual_rate,
			actual_unit,
			actual_total_cost,
			start_date,
			end_date,
			name,
		} = row

		const criteria = [[{ field: budgetCriteria.name, operator: 'any', values: [name] }]]

		const pageState = {}
		const postData = {
			budget_utilization: {
				actual_id,
				actual_rate,
				actual_total_cost,
				actual_unit,
				criteria,
				start_date,
				end_date,
			},
		}

		await BudgetUtilizationsService.upsertActualBudgetEntry({
			pageState,
			values: postData,
			id: actual_id,
			budgetHeadId: budget_head_id,
		})

		const { serverStatus, budgetUtilizationErrors } = pageState

		if (budgetUtilizationErrors) {
			message.error(budgetUtilizationErrors[0] || 'Failed to update budget Value')
			return
		}

		if (serverStatus.status === 200) {
			message.success('Actual Budget updated.')
			return
		}

		message.error('Oops!! something went wrong')
	}
	const plannedFieldData = (value, inputType, row) => {
		let unit = row['planned_unit'] ?? 1
		let rate = row['planned_rate'] ?? 0
		let cost = row['planned_total_cost'] ?? 0

		if (inputType === 'planned_rate') {
			rate = Number(value)
			return { planned_rate: rate, planned_total_cost: Math.round(rate * unit), planned_unit: unit }
		}
		if (inputType === 'planned_unit') {
			unit = Number(value)
			return { planned_rate: rate, planned_total_cost: Math.round(rate * unit), planned_unit: unit }
		}
		if (inputType === 'planned_total_cost') {
			cost = Number(value)
			return { planned_rate: Math.round(cost / unit), planned_total_cost: cost, planned_unit: unit }
		}
	}

	const actualFieldData = (value, inputType, row) => {
		let unit = row['actual_unit'] ?? 1
		let rate = row['actual_rate'] ?? 0
		let cost = row['actual_total_cost'] ?? 0

		if (inputType === 'actual_rate') {
			rate = Number(value)
			return { actual_rate: rate, actual_total_cost: Math.round(rate * unit), actual_unit: unit }
		}
		if (inputType === 'actual_unit') {
			unit = Number(value)
			return { actual_rate: rate, actual_total_cost: Math.round(rate * unit), actual_unit: unit }
		}
		if (inputType === 'actual_total_cost') {
			cost = Number(value)
			return { actual_rate: Math.round(cost / unit), actual_total_cost: cost, actual_unit: unit }
		}
	}
	const handleRowInputChange = (value, inputType, index) => {
		const clonedTableData = JSON.parse(JSON.stringify(tableData))
		const updatedTableData = clonedTableData.map((row, i) => {
			if (i === index) {
				let fieldData = {}
				if (inputType.includes('planned')) {
					fieldData = plannedFieldData(value, inputType, row)
				} else {
					fieldData = actualFieldData(value, inputType, row)
				}
				return { ...row, ...fieldData }
			}
			return row
		})
		setTableData(updatedTableData)
	}

	const handlePlannedBudgetUpdate = async (index) => {
		const row = tableData[index]
		if (!row) return

		if (!row['planned_rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!row['planned_unit']) {
			message.error("Count can't be blank")
			return
		}

		const {
			planned_id,
			budget_head_id,
			planned_rate,
			planned_unit,
			planned_total_cost,
			start_date,
			end_date,
			name,
		} = row

		const criteria = [[{ field: budgetCriteria.name, operator: 'any', values: [name] }]]

		const pageState = {}
		const postData = {
			budget_value: {
				planned_id,
				planned_rate,
				planned_total_cost,
				planned_unit,
				criteria,
				start_date,
				end_date,
			},
		}

		await BudgetValuesService.upsertPlannedBudgetEntry({
			pageState,
			values: postData,
			id: planned_id,
			budgetHeadId: budget_head_id,
		})
		const { serverStatus, budgetValueErrors } = pageState

		if (budgetValueErrors) {
			message.error(budgetValueErrors[0] || 'Failed to update budget Value')
			return
		}

		if (serverStatus.status === 200) {
			message.success('Planned Budget updated.')
			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderContents = () => {
		const tableColumns = [
			...[
				{
					title: '',
					children: [
						{
							title: (
								<div>
									<Select
										placeholder='Choose Criteria'
										style={{ minWidth: '150px' }}
										onChange={(e) => handleDropDownChange(e, 'budget_criteria')}>
										{budgetCriteriaHeads.map((bh) => (
											<Select.Option key={bh.id} value={bh.name}>
												{bh.name}
											</Select.Option>
										))}
									</Select>
								</div>
							),
							dataIndex: 'name',
							key: 'hierarchy',
							render: (value, ic, index) => {
								return ic.parent.length === 0
									? ic.name
									: ic.parent.map((p, i) => {
											return Object.values(p) + (i === ic.parent.length - 1 ? '>' + ic.name : '>')
									  })
							},
						},
					],
				},
				{
					title: 'Planned Budget',
					children: [
						{
							title: 'Count',
							dataIndex: 'planned_unit',
							key: 'unit',
							render: (value, ic, index) => {
								return (
									<>
										<Input
											placeholder='Enter Count'
											value={value}
											onChange={(e) => handleRowInputChange(e.target.value, 'planned_unit', index)}
										/>
									</>
								)
							},
						},
						{
							title: 'Rate',
							dataIndex: 'planned_rate',
							key: 'rate',
							render: (value, ic, index) => {
								return (
									<>
										<Input
											placeholder='Enter Rate'
											value={value}
											onChange={(e) => handleRowInputChange(e.target.value, 'planned_rate', index)}
										/>
									</>
								)
							},
						},
						{
							title: 'Total Cost',
							dataIndex: 'planned_total_cost',
							key: 'total_cost',
							render: (value, ic, index) => {
								return (
									<>
										<Input
											placeholder='Enter Total Cost'
											value={value}
											onChange={(e) =>
												handleRowInputChange(e.target.value, 'planned_total_cost', index)
											}
										/>
									</>
								)
							},
						},
						{
							title: '',
							dataIndex: 'action',
							key: 'action',
							render: (value, ic, index) => {
								return (
									<>
										<Button type='link' onClick={() => handlePlannedBudgetUpdate(index)}>
											<SaveOutlined />
										</Button>
									</>
								)
							},
						},
					],
				},
				{
					title: 'Actual Budget',
					children: [
						{
							title: 'Count',
							dataIndex: 'actual_unit',
							key: 'actual_unit',
							render: (value, ic, index) => {
								return (
									<>
										<Input
											placeholder='Enter Count'
											value={value}
											onChange={(e) => handleRowInputChange(e.target.value, 'actual_unit', index)}
										/>
									</>
								)
							},
						},
						{
							title: 'Rate',
							dataIndex: 'actual_rate',
							key: 'actual_rate',
							render: (value, ic, index) => {
								return (
									<>
										<Input
											placeholder='Enter Rate'
											value={value}
											onChange={(e) => handleRowInputChange(e.target.value, 'actual_rate', index)}
										/>
									</>
								)
							},
						},
						{
							title: 'Total Cost',
							dataIndex: 'actual_total_cost',
							key: 'total_value',
							render: (value, ic, index) => {
								return (
									<>
										<Input
											placeholder='Enter Total Cost'
											// value={actualTotal || ic.actual_total_cost}
											value={value}
											onChange={(e) =>
												handleRowInputChange(e.target.value, 'actual_total_cost', index)
											}
										/>
									</>
								)
							},
						},
						{
							title: '',
							dataIndex: 'action',
							key: 'action',
							render: (value, ic, index) => {
								return (
									<>
										<Button type='link' onClick={() => handleActualBudgetUpdate(index)}>
											<SaveOutlined />
										</Button>
									</>
								)
							},
						},
					],
				},
			],
		]

		const renderTable = () => {
			return (
				<>
					<Table
						columns={tableColumns}
						dataSource={tableData}
						bordered
						pagination={false}
						scroll={{
							x: 1000,
						}}></Table>
				</>
			)
		}

		// const renderInfo = () => {
		// 	return renderTable()
		// }

		return <div>{renderTable()}</div>
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<div>
					<FilterForm
						type='budget-entry'
						budgetHeads={budgetHeads}
						defaultHeadId={budgetHeads[0]?.id}
						dateRange={dateRange}
						setDateRange={setDateRange}
					/>
				</div>
				<div className='content'>{renderContents()}</div>
				<div className='input-box'>
					<Button type='primary' disabled={true}>
						Save All
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['criteria-edit', 'smart-budget', 'budget-data-edit'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/configuration/minimum-wages/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(BudgetEntry)

import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Input, message, Row, Col } from "antd"
import { isContainsBlankCriteria } from "utils/criteria"

import StyledBox from "components/common/styled-components/StyledBox"
import ConditionRow from "components/ConditionRow"
import globalStore from "store/index"

import OrganizationsService from "services/users/organizations.service"
import SalaryRangesService from "services/users/salary-ranges.service"

const StyledConfigurationFormBox = styled.div`
  & .input-box {
    margin: 10px 0px;
  }
  & .action-buttons.submit {
    margin-top: 20px;
  }
`

const blankCondition = {
  field: null,
  operator: null,
  values: null,
}

const SalaryRangeEditPage = () => {
  const [criteria, setCriteria] = useState([[blankCondition]])
  const [createButtonLoading, setCreateButtonLoading] = useState(false)
  const [inputValues, setInputValues] = useState({})

  useEffect(() => {
    const pageState = globalStore.currentOrganization
    const organizationFields = pageState.organization?.all_fields_with_datatype
    if (organizationFields && organizationFields.length !== 0) return

    const fetchAllFieldsData = async () => {
      await OrganizationsService.me({ pageState })

      const { organization } = pageState

      if (!organization?.all_fields_with_datatype) {
        message.error("Organization fields not found")
        return
      }
    }

    fetchAllFieldsData()
  }, [])

  const handleInputChange = (e, inputType) => {
    const newInputValues = { ...inputValues, [inputType]: e.target.value }

    setInputValues(newInputValues)
  }

  const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        newConditionSet.splice(deletedConditionIndex, 1)
        return newConditionSet
      })

      return newCriteria
    })
  }

  const handleUpdateConditionValues = ({
    conditionSetIndex,
    conditionIndex,
    condition,
  }) => {
    if (!condition) return
    if (condition.operator !== "not" && !condition.values) return

    setCriteria((prevCriteria) => {
      const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
        if (conSetIndex !== conditionSetIndex) return conditionSet
        const newConditionSet = [...conditionSet]
        return newConditionSet.map((c, index) => {
          if (index !== conditionIndex) return c

          return condition
        })
      })

      return newCriteria
    })
  }

  const handleUpdate = async () => {
    if (!inputValues["name"]) {
      message.error("Name can't be blank")
      return
    }

    if (!inputValues["median"]) {
      message.error("Median can't be blank")
      return
    }
    if (!inputValues["minimum"]) {
      message.error("Minimum can't be blank")
      return
    }
    if (!inputValues["maximum"]) {
      message.error("Maximum can't be blank")
      return
    }

    if(isContainsBlankCriteria(criteria)){
      message.error("Criteria cannot be blank")
      return
    }

    const pageState = {}
    const postData = {
      salary_range: {
        criteria,
        ...inputValues,
      },
    }

    setCreateButtonLoading(true)
    await SalaryRangesService.create({
      pageState,
      values: postData,
    })
    setCreateButtonLoading(false)

    const { serverStatus, salaryRangeErrors } = pageState

    if (salaryRangeErrors) {
      message.error(salaryRangeErrors[0] || "Salary range couldn't be created.")
      return
    }

    if (serverStatus.status === 200) {
      message.success("Salary range created.")
      setCriteria([[blankCondition]])
      setInputValues({})
      return
    }

    message.error("Oops!! something went wrong")
  }

  const renderPageContent = () => {
    return (
      <StyledConfigurationFormBox>
        <h1>Salary Range</h1>
        <Row gutter={20}>
          <Col span={16}>
            <div className="input-box">
              <strong>Name</strong>
              <Input
                value={inputValues["name"]}
                onChange={(e) => handleInputChange(e, "name")}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="input-box">
              <strong>Currency</strong>
              <Input
                value={inputValues["currency"]}
                onChange={(e) => handleInputChange(e, "currency")}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <div className="input-box">
              <strong>Minimum</strong>
              <Input
                value={inputValues["minimum"]}
                onChange={(e) => handleInputChange(e, "minimum")}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="input-box">
              <strong>Median</strong>
              <Input
                value={inputValues["median"]}
                onChange={(e) => handleInputChange(e, "median")}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="input-box">
              <strong>Maximum</strong>
              <Input
                value={inputValues["maximum"]}
                onChange={(e) => handleInputChange(e, "maximum")}
              />
            </div>
          </Col>
        </Row>
        <div className="input-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong>Criteria</strong>
            {criteria.filter((c) => c.length > 0).length === 0 && (
              <Button
                shape="circle"
                onClick={() => setCriteria([[blankCondition]])}
              >
                <PlusOutlined />
              </Button>
            )}
          </div>
          {criteria.map((conditionSet, conditionSetIndex) => {
            return (
              <div key={conditionSetIndex}>
                {conditionSet.map((condition, conditionIndex) => {
                  return (
                    <ConditionRow
                      condition={condition}
                      key={conditionIndex}
                      lastInputGroup={
                        conditionSet.length === conditionIndex + 1
                      }
                      conditionSetLength={conditionSet.length}
                      firstInputGroup={conditionIndex === 0}
                      inputGroupIndex={conditionIndex}
                      updateConditionValues={(condition) =>
                        handleUpdateConditionValues({
                          conditionSetIndex,
                          conditionIndex,
                          condition,
                        })
                      }
                      addNewCondition={() =>
                        handleAddNewCondition(conditionSetIndex, conditionIndex)
                      }
                      removeCondition={() =>
                        handleRemoveCondition(conditionSetIndex, conditionIndex)
                      }
                      organizationFields={
                        globalStore.currentOrganization.all_fields_with_datatype
                      }
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="action-buttons submit">
          <Button
            type="primary"
            loading={createButtonLoading}
            onClick={handleUpdate}
          >
            Create Salary Range
          </Button>
        </div>
      </StyledConfigurationFormBox>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-edit")
  )
    return <Redirect to="/configuration/salary-ranges" />

  return (
    <div className="page-content dashboard-page">
      <StyledBox>{renderPageContent()}</StyledBox>
    </div>
  )
}

export default view(SalaryRangeEditPage)

import React, { useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, message, Empty, Popconfirm } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store/index'
import OfferLetterTemplatesService from 'services/users/offer-letter-templates.service'
// import FitmentInputGroup from "components/FitmentInputGroup"

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const OfferLetterTemplatesIndexPage = (props) => {
	const history = useHistory()
	const pageState = globalStore.ui.configuration.offerLetterTemplates.index

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.configuration.offerLetterTemplates.index

			await OfferLetterTemplatesService.index({ pageState })

			const { serverStatus, offerLetterTemplatesErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (offerLetterTemplatesErrors) {
				message.error(
					offerLetterTemplatesErrors[0] || "Offer letter templates couldn't be fetched."
				)
				return
			}
		}

		fetchData()
	}, [])

	const handleCreate = () => {
		history.push('/configuration/offer-letter-templates/new')
	}

	const handleCardItemClick = (offerLetterTemplateId) => {
		history.push(`/configuration/offer-letter-templates/${offerLetterTemplateId}`)
	}

	const handleDelete = async (e, offerLetterTemplateId) => {
		e.stopPropagation()
		const tempState = {}

		await OfferLetterTemplatesService.delete({ pageState: tempState, offerLetterTemplateId })

		const { message: messages, offerLetterTemplateErrors } = tempState

		if (offerLetterTemplateErrors) {
			message.error(offerLetterTemplateErrors[0] || 'Failed to delete this Offer Letter Template')
			return
		}

		pageState.offer_letter_templates = pageState.offer_letter_templates.filter(
			(data) => data.id !== offerLetterTemplateId
		)
		message.success(messages)
	}

	const { offer_letter_templates, API_USERS_OFFER_LETTER_TEMPLATES_INDEX_STATUS } = pageState

	const renderContents = () => {
		if (offer_letter_templates.length === 0) {
			return (
				<Empty>
					<Button type='primary' ghost onClick={handleCreate}>
						New Offer Letter Template
					</Button>
				</Empty>
			)
		}

		return (
			<StyledList>
				{offer_letter_templates.map((offerLetterTemplate) => (
					<div
						className='item-card'
						key={`item-card-${offerLetterTemplate.id}`}
						>
						<div className='details-box'>
							<div className='name-box'>
								<div className='name'>{offerLetterTemplate.name}</div>
								<div>{}</div>
							</div>
						</div>
						<div className='icon-box' style={{ display: 'flex' }}>
							<Button type='link' onClick={() => handleCardItemClick(offerLetterTemplate.id)}>
								<EyeOutlined />
							</Button>

              <Popconfirm
                  title='Are you sure?'
                  onConfirm={(e) => handleDelete(e, offerLetterTemplate.id)}
                  onCancel={() => {}}
                  okText='Yes'
                  cancelText='No'>
                  <Button type='link'>
                    <DeleteOutlined />
                  </Button>
              </Popconfirm>
						</div>
					</div>
				))}
			</StyledList>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Button type='primary' ghost onClick={handleCreate}>
						New Offer Letter Template
					</Button>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('letter-template-view'))
		return <Redirect to='/' />

	return (
		<div className='page-content offer-letter-templates-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_OFFER_LETTER_TEMPLATES_INDEX_STATUS,
					data: offer_letter_templates,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(OfferLetterTemplatesIndexPage)

import { API, generic } from "api"

export default class SalaryApprovalsService {
  static show = async ({ pageState, token }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_SALARY_APPROVAL_SHOW_STATUS",
      stateDataKey: [
        "salary_approval",
        "salary_configuration",
        "organization",
      ],
      stateErrorKey: [
        "salaryApprovalErrors",
        "salaryConfigurationErrors",
        "organizationErrors",
      ],
      apiEndpoint: API.visitors.salaryApprovals.show,
      apiUrlReplacements: { token },
      apiData: {},
      errorMessage: "Salary approval could not be fetched.",
    })
  }

  static approve = async ({ pageState, token }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_SALARY_APPROVAL_APPROVE_STATUS",
      stateDataKey: ["salary_approval"],
      stateErrorKey: ["salaryApprovalErrors"],
      apiEndpoint: API.visitors.salaryApprovals.approve,
      apiUrlReplacements: { token },
      apiData: {},
      errorMessage: "Salary approval could not be approved.",
    })
  }

  static reject = async ({ pageState, token, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_SALARY_APPROVAL_REJECT_STATUS",
      stateDataKey: ["salary_approval"],
      stateErrorKey: ["salaryApprovalErrors"],
      apiEndpoint: API.visitors.salaryApprovals.reject,
      apiUrlReplacements: { token },
      apiData: { ...values },
      errorMessage: "Salary approval could not be rejected.",
    })
  }

  static comment = async ({ pageState, token, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_SALARY_APPROVAL_COMMENT_STATUS",
      stateDataKey: ["salary_approval"],
      stateErrorKey: ["salaryApprovalErrors"],
      apiEndpoint: API.visitors.salaryApprovals.comment,
      apiUrlReplacements: { token },
      apiData: { ...values },
      errorMessage: "Comment could not be submitted.",
    })
  }
}

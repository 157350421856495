export const strftimeTOMomentFormat = (dateFormat) => {
	const replacements = {
		a: 'ddd',
		A: 'dddd',
		b: 'MMM',
		B: 'MMMM',
		c: 'lll',
		d: 'DD',
		'-d': 'D',
		e: 'D',
		F: 'YYYY-MM-DD',
		H: 'HH',
		'-H': 'H',
		I: 'hh',
		'-I': 'h',
		j: 'DDDD',
		'-j': 'DDD',
		k: 'H',
		l: 'h',
		m: 'MM',
		'-m': 'M',
		M: 'mm',
		'-M': 'm',
		p: 'A',
		P: 'a',
		S: 'ss',
		'-S': 's',
		u: 'E',
		w: 'd',
		W: 'WW',
		x: 'll',
		X: 'LTS',
		y: 'YY',
		Y: 'YYYY',
		z: 'ZZ',
		Z: 'z',
		f: 'SSS',
		'%': '%',
  }
  if (!dateFormat) {
    return 'DD-MMM-YYYY'
  }
  // eslint-disable-next-line no-useless-escape
  const dateFormatArray = dateFormat?.split(/(%\-?.)/)
  const dateMomentFormat = dateFormatArray
		.map((dateFormat) => {
			if (dateFormat[0] === '%' && replacements.hasOwnProperty(dateFormat.substr(1))) {
				return replacements[dateFormat.substr(1)]
			}
			return dateFormat
		})
		.join('')
  
  return dateMomentFormat
}

import { API, generic } from 'api'

export default class BudgetValuesService {
	static index = async ({
		pageState,
		page = 1,
		pageSize = 25,
		budgetHeadId,
		bcsla,
		startDate,
		endDate,
		budgetCriteria,
	}) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_INDEX_STATUS',
			stateDataKey: ['budget_values', 'total', 'page', 'page_size'],
			stateErrorKey: ['budgetValuesErrors', 'totalErrors', 'pageErrors', 'pageSizeErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.index,
			apiUrlReplacements: { budgetHeadId },
			apiData: {
				params: {
					page,
					page_size: pageSize,
					bcsla,
					start_date: startDate,
					end_date: endDate,
					criteria: budgetCriteria,
				},
			},
			errorMessage: 'Budget Criteria could not be fetched.',
		})
	}

	static create = async ({ pageState, values, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_CREATE_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.create,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values },
			errorMessage: 'Budget Criteria could not be created.',
		})
	}
	static addPosition = async ({ pageState, values, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_ADD_POSITION_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.addPosition,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values },
			errorMessage: 'Budget Criteria could not be created.',
		})
	}
	static sampleFileData = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_PLANNED_SAMPLE_FILE_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.sampleFileData,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Sample File could not be fetched.',
		})
	}
	static essentialFieldsList = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_PLANNED_ESSENTIAL_FIELD_LIST_STATUS',
			stateDataKey: ['budget_criteria_heads'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.essentialFieldsList,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Sample File could not be fetched.',
		})
	}

	static show = async ({ pageState, budgetValueId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUE_SHOW_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.show,
			apiUrlReplacements: { budgetValueId },
			apiData: {},
			errorMessage: 'Planned Budget could not be fetched.',
		})
	}

	static update = async ({ pageState, values, budgetValueId, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_UPDATE_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.update,
			apiUrlReplacements: { budgetValueId },
			apiData: { ...values, budget_head_id: budgetHeadId },
			errorMessage: 'Planned Budget could not be updated.',
		})
	}

	static editPosition = async ({ pageState, values, budgetValueId, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_EDIT_POSITION_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.editPosition,
			apiUrlReplacements: { budgetValueId },
			apiData: { ...values, budget_head_id: budgetHeadId },
			errorMessage: 'Position could not be updated.',
		})
	}

	static delete = async ({ pageState, budgetValueId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_DELETE_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.delete,
			apiUrlReplacements: { budgetValueId },
			apiData: {},
			errorMessage: 'Planned Budget could not be deleted.',
		})
	}

	static upsertPlannedBudgetEntry = async ({ pageState, values, id, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEAD_UPDATE_PLANNED_BUDGET_ENTRY_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.upsert_planned_budget_entry,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values, id },
			errorMessage: 'Planned Budget could not be updated.',
		})
	}

	static import = async ({ pageState, formData, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_IMPORT_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.import,
			apiUrlReplacements: { budgetHeadId },
			apiData: formData,
			errorMessage: 'Planned Budget could not be imported.',
		})
	}
	static importVacancy = async ({ pageState, formData, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_IMPORT_VACANCY_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.importVacancy,
			apiUrlReplacements: { budgetHeadId },
			apiData: formData,
			errorMessage: 'Planned Budget could not be imported.',
		})
	}

	static export = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_EXPORT_STATUS',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.export,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Planned Budget could not be exported.',
		})
	}

	static sync = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_SYNC_WORKFORCE_DATA',
			stateDataKey: ['budget_value'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.syncPayreviewPlannedWorkforceData,
			apiData: { sync_for: values },
			errorMessage: 'Sync not successful',
		})
	}

	static search = async ({ pageState, page = 1, pageSize = 25, values, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_SEARCH_STATUS',
			stateDataKey: ['budget_values'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.search,
			apiUrlReplacements: { budgetHeadId },
			apiData: { page, page_size: pageSize, ...values },
			errorMessage: 'Planned Budget could not be searched.',
		})
	}
	static exportPositionReport = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_VALUES_SEARCH_STATUS',
			stateDataKey: ['budget_values'],
			stateErrorKey: ['budgetValueErrors'],
			apiEndpoint: API.users.budgetHeads.budgetValues.exportPositionReport,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Export position Report.',
		})
	}
}

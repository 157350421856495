import React, { useState, useEffect } from "react"
import { withRouter, useHistory, useParams, Redirect, useLocation } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { ArrowLeftOutlined, UserOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons"
import { Layout, Button, Avatar, Dropdown, Menu, message, Modal, Upload } from "antd"

import SideMenu from "components/common/layouts/SideMenu"

import siteConfig from "siteConfig"
import { createUrl } from "api"
import globalStore from "store"
import * as AuthHelper from "helpers/auth.helper"
import UsersService from "services/users/users.service"
import OrganizationsService from "services/users/organizations.service"

import { fetchFromLocalStorage } from "store/localStorage"
import SsoService from "services/payreview/sso.service"
import WorkForcePeriodService from "services/users/workforce-period.service"

const StyledLogo = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	background: #01152a;
	padding: 2px;
	height: 50px;
	& > img {
		width: 80%;
	}
	& > .upload {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffffdb;
		opacity: 0;
		transition: all 0.5s;
	}
	&:hover {
		& > .upload {
			opacity: 1;
		}
	}
`
const StyledOrgName = styled.h1`
	text-align: center;
	color: white;
	padding: 10px 0px;
	margin: 0px;
	background: #012240;
	font-weight: bold;
	width: 100%;
`

const { Header, Footer, Sider, Content } = Layout

const AppLayout = ({ children, headerTitle, goBackLink }) => {
	const params = useParams()
	const history = useHistory()
	const location = useLocation()
	const [collapsed, setCollapsed] = useState(false)
	const [uploadModalVisiable, setUploadModalVisiable] = useState(false)
	const [fileList, setFileList] = useState([])
	const [logoUploading, setLogoUploading] = useState(false)
	const [hideSwitch,setHideSwitch] =useState(true)
	const userType = fetchFromLocalStorage({ key: "userType" })
  const { SubMenu } = Menu
	useEffect(() => {
		setCollapsed(globalStore.siderCollapsed)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalStore.siderCollapsed])

	useEffect(() => {
		if (userType === "admin-users") return

		const fetchCurrentUser = async () => {
			// const { currentUser } = globalStore
			// if (currentUser) return

			const tempState = {}

			await UsersService.me({ pageState: tempState })
			const { serverStatus, user } = tempState
			if (user?.organization_id === 1) {
				setHideSwitch(false)
			}
			if (serverStatus.status !== 200) return

			globalStore.currentUser = user

			if (user?.accessible_features.includes('workforce')) {
				const pageState = globalStore.ui.configuration.currentWorkforcePeriod
				await WorkForcePeriodService.currentWorkforcePeriod({ pageState })
			}
		}

		fetchCurrentUser()
	}, [history.location.pathname, userType])

	useEffect(() => {
		if (userType === "admin-users") return

		const fetchCurrentOrganization = async () => {
			const tempState = {}

			await OrganizationsService.me({ pageState: tempState })
			const { serverStatus, organization } = tempState

			if (serverStatus.status !== 200) return

			globalStore.currentOrganization = organization
		}

		fetchCurrentOrganization()
	}, [history.location.pathname, userType])

	const handleLogout = () => {
		AuthHelper.logout()
		window.location.reload()
		// history.push("/")
	}
	const handlePayrereviewLogin = async() => {
		const tempState = {}
		await SsoService.payreviewLogin({ pageState: tempState })
		const { redirect_url, payreviewLoginError } = tempState
		if (payreviewLoginError) {
			message.error(payreviewLoginError)
			return
		}
		window.open(redirect_url)
	}

	const onCollapse = () => {
		setCollapsed((prev) => !prev)
	}

	const handleBack = () => {
		const backLink = createUrl(
			location?.state?.goBackLink ? '/configuration/workforce-dashboard' : goBackLink,
			params
		)
		const state ={prevPath: history.location.pathname}
		history.push({pathname:backLink,state})
	}

	const uploadLogo = async () => {
		if (fileList.length === 0) {
			message.warning("Please select logo to upload")
			return
		}

		const tempState = {}

		const formData = new FormData()

		formData.append("file", fileList[0])

		setLogoUploading(true)
		await OrganizationsService.uploadLogo({ pageState: tempState, formData })
		setLogoUploading(false)

		const { serverStatus, organization } = tempState

		if (serverStatus.status !== 200) {
			message.error("Oops!! something went wrong.")
			return
		}

		globalStore.currentOrganization = organization

		message.success("Organization logo updated.")
		setFileList([])
		setUploadModalVisiable(false)
	}

	const renderOrgName = () => {

		return (
			<StyledLogo>
				{renderImageOrLogo()}

				<div className='upload'>
					<Button
						type='primary'
						shape='circle'
						onClick={() =>
							setUploadModalVisiable(true)
						}>
						<UploadOutlined />
					</Button>
				</div>
			</StyledLogo>
		)
	}
	const renderImageOrLogo = () => {
		if (!globalStore.currentOrganization.logo_url) {
			return (<StyledOrgName>
				<p className='logo__long'>HireReview</p>
				<p className='logo__short'>HR</p>
			</StyledOrgName>)
		}

		if (collapsed) {
			return (<StyledOrgName>
				<p className='logo__short'>HR</p>
			</StyledOrgName>)
		}
		return (<img
			src={
				globalStore
					.currentOrganization
					.logo_url
			}
			alt='Logo'
		/>)

	}

	const headerUserMenu = (
		<Dropdown
			overlay={
				<Menu>
					<SubMenu title='Report Center'>
						<Menu.Item
							onClick={() =>
								history.push(
									'/exports'
								)
							}>
							Exports
						</Menu.Item>
						<Menu.Item
							onClick={() =>
								history.push(
									'/imports'
								)
							}>
							Imports
						</Menu.Item>
					</SubMenu>
					{userType !== 'admin-users' && (
						<Menu.Item
							onClick={() =>
								history.push(
									'/change-password'
								)
							}>
							Change Password
						</Menu.Item>
					)}
					{userType !== 'admin-users' && (
						<Menu.Item
							onClick={() =>
								history.push(
									'/settings'
								)
							}>
							Settings
						</Menu.Item>
					)}
					{hideSwitch?<Menu.Item onClick={handlePayrereviewLogin}>
						Switch to Payreview
					</Menu.Item>:''}
					<Menu.Item onClick={handleLogout}>
						Logout
					</Menu.Item>
				</Menu>
			}>
			<Avatar icon={<UserOutlined />} />
		</Dropdown>
	)

	const logoUploaderProps = {
		accept: "image/png,image/jpg,image/jpeg",
		onRemove: (file) => {
			const index = fileList.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)

			setFileList(newFileList)
		},
		beforeUpload: (file) => {
			setFileList([file])
			return false
		},
		fileList,
	}

	if (
		globalStore.currentUser?.force_update_password &&
		history.location?.pathname !== "/change-password"
	) {
		message.destroy()
		message.error("You need to change your password to perform this action.")
		return <Redirect to='/change-password' />
	}

	return (
		<div className='private-page'>
			<Layout>
				<Sider
					collapsible
					collapsed={collapsed}
					width={300}
					style={{
						overflow: "auto",
						height: "100vh",
						position: "fixed",
						left: 0,
					}}
					onCollapse={onCollapse}>
					{renderOrgName()}
					<SideMenu />
				</Sider>
				<Layout style={{ marginLeft: collapsed ? 80 : 300 }}>
					<Header>
						<div>
							<div className='page-title'>
								{(goBackLink || location?.state?.goBackLink) && (
									<Button
										type='link'
										onClick={
											handleBack
										}>
										<ArrowLeftOutlined />
									</Button>
								)}
								<h2>
									{
										headerTitle
									}
								</h2>
							</div>
							<div>{headerUserMenu}</div>
						</div>
					</Header>
					<Content>{children}</Content>
					<Footer>
						<div>
							<div>
								Copyright &copy;{" "}
								{
									siteConfig
										.site
										.companyName
								}
							</div>
							<div className='links'>
								{siteConfig.site.footerLinks.map(
									(
										{
											href,
											label,
										},
										i
									) => {
										return (
											<Button
												type='link'
												href={
													href
												}
												key={
													i
												}>
												{
													label
												}
											</Button>
										)
									}
								)}
							</div>
						</div>
					</Footer>
				</Layout>
			</Layout>
			<Modal
				title='Upload Logo'
				visible={uploadModalVisiable}
				okText='Upload'
				confirmLoading={logoUploading}
				closable={!logoUploading}
				destroyOnClose={true}
				onOk={uploadLogo}
				onCancel={() => setUploadModalVisiable(false)}>
				<Upload.Dragger {...logoUploaderProps}>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>
						Click or drag file to this area to
						upload
					</p>
					<p className='ant-upload-hint'>
						Support for a single or bulk upload.
						Strictly prohibit from uploading company
						data or other band files
					</p>
				</Upload.Dragger>
			</Modal>
		</div>
	)
}

export default withRouter(view(AppLayout))

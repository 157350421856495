import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { Button, DatePicker, Collapse, Row, Col, Select } from 'antd'
import Criteria from 'components/budget-analysis/Criteria'
import globalStore from 'store/index'
import BudgetHeadsService from 'services/users/budget-heads.service'

const FilterForm = ({
	type,
	budgetHeads,
	defaultHeadId,
	dateRange,
	setDateRange,
	setPeriod,
	onFilterChange = () => {},
}) => {
	const [filterData, setFilterData] = useState({})
	const [budgetHeadId, setBudgetHeadId] = useState('')
	const [applyButtonLoading, setApplyButtonLoading] = useState(false)
	useEffect(() => {
		if (!dateRange) return
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange])

	useEffect(() => {
		onFilterChange({
			criteria: filterData,
			start_date: dateRange ? dateRange[0] : undefined,
			end_date: dateRange ? dateRange[1] : undefined,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterData, dateRange])

	const handleCriteriaUpdate = (criteria) => {
		if (!criteria) return
		const updatedCriteria = criteria[0]?.filter((c) => {
			if (!c.field) return false
			if (!c.operator) return false
			if (!c.values) return false

			return true
		})
		setFilterData({
			...filterData,
			criteria: [updatedCriteria],
		})
	}

	const handleApplyFilter = async () => {
		const tempState = {}

		setApplyButtonLoading(true)

		if (type === 'budget-analysis') {
			const { criteria } = filterData

			await BudgetHeadsService.budget_analysis({
				pageState: tempState,
				criteria,
				start_date: dateRange ? dateRange[0] : undefined,
				end_date: dateRange ? dateRange[1] : undefined,
			})
			if (tempState.API_USERS_BUDGET_HEADS_ANALYSIS_STATUS === 'fulfilled') {
				globalStore.ui.configuration.budgetHeads.budget_analysis = tempState
			}
		}

		if (type === 'budget-entry') {
			await BudgetHeadsService.budget_entry_data({
				pageState: tempState,
				startDate: dateRange[0],
				id: budgetHeadId || defaultHeadId,
				endDate: dateRange[1],
			})
			if (tempState.API_USERS_BUDGET_HEADS_ENTRY_STATUS === 'fulfilled') {
				globalStore.ui.configuration.budgetHeads.budget_data_entry = tempState
			}
		}

		if (type === 'budget-utilization-data') {
			const { criteria } = filterData

			const postData = {
				criteria,
				start_date: dateRange[0].startOf('month').format(),
				end_date: dateRange[1].endOf('month').format(),
			}
			await BudgetHeadsService.budgetUtilizationData({
				pageState: tempState,
				postData,
			})
			if (tempState.API_USERS_BUDGET_UTILIZATIONS_DATA_STATUS === 'fulfilled') {
				globalStore.ui.configuration.budgetHeads.budgetUtilizationsData = tempState
			}
		}

		if (type === 'workforce-report') {
			const { criteria } = filterData

			const postData = {
				criteria,
				start_date: dateRange[0].startOf('month').format(),
				end_date: dateRange[1].endOf('month').format(),
			}
			await BudgetHeadsService.workforceReport({
				pageState: tempState,
				postData,
			})
			if (tempState.API_USERS_WORKFORCE_DATA_STATUS === 'fulfilled') {
				globalStore.ui.configuration.budgetHeads.workforce_data = tempState
			}
		}

		if (type === 'workforce-monitoring') {
			const { criteria } = filterData

			const postData = {
				criteria,
				start_date: dateRange[0].startOf('month').format(),
				end_date: dateRange[1].endOf('month').format(),
			}
			await BudgetHeadsService.workforceMonitor({
				pageState: tempState,
				postData,
			})
			if (tempState.API_USERS_WORKFORCE_MONITOR_STATUS === 'fulfilled') {
				globalStore.ui.configuration.budgetHeads.workForceMonitoring = tempState
			}
		}
		if (type === 'workforce-timeline') {
			const { criteria } = filterData

			const postData = {
				criteria,
				start_date: dateRange[0].startOf('month').format(),
				end_date: dateRange[1].endOf('month').format(),
			}
			await BudgetHeadsService.workforceTimeline({
				pageState: tempState,
				postData,
			})
			if (tempState.API_USERS_WORKFORCE_TIMELINE_STATUS === 'fulfilled') {
				globalStore.ui.configuration.budgetHeads.workForceTimeline = tempState
			}
		}
		setApplyButtonLoading(false)
	}

	const handleDateChange = (range) => {
		setDateRange(range)
		if (setPeriod) {
			setPeriod(range)
		}
		setFilterData({
			...filterData,
		})
	}

	const handleDropDownChange = (e, inputType) => {
		if (inputType === 'budget_heads') {
			setBudgetHeadId(budgetHeads.find((bh) => bh.name === e)?.id)
		}
	}

	return (
		<div style={{ marginBottom: 10 }}>
			<Collapse defaultActiveKey={['0']}>
				<Collapse.Panel header='Filter' key='1'>
					<Row gutter={20}>
						{[
							'budget-analysis',
							'budget-utilization-data',
							'workforce-report',
							'workforce-monitoring',
							'workforce-timeline',
						].includes(type) && (
							<Col span={20}>
								<div>
									<div className='input-box'>
										<div>
											<strong>Criteria</strong>
											<div style={{ marginTop: 10 }}>
												<Criteria onCriteriaUpdate={handleCriteriaUpdate} />
											</div>
										</div>
									</div>
								</div>
							</Col>
						)}
						{![
							'budget-utilization-data',
							'workforce-report',
							'workforce-monitoring',
							'workforce-timeline',
						].includes(type) && (
							<Col span={8}>
								<div className='input-box'>
									<div style={{ width: 260, marginBottom: '10px' }}>
										<strong>Period</strong>
									</div>
									{/* {type === "budget-utilization-data" ? (
                  <DatePicker.RangePicker
                    picker="month"
                    format="YYYY MMM"
                    defaultValue={dateRange}
                    onChange={handleDateChange}
                  />
                ) : ( */}
									<DatePicker.RangePicker
										format='DD-MMM-YYYY'
										defaultValue={dateRange}
										onChange={handleDateChange}
									/>
									{/* )} */}
								</div>
							</Col>
						)}
						<Col span={8}>
							{type === 'budget-entry' && (
								<div className='input-box'>
									<div style={{ marginBottom: '10px' }}>
										<strong>Budget Head</strong>
									</div>
									<Select
										style={{ width: 260 }}
										placeholder='Choose Budget Head'
										defaultValue={budgetHeads[0]?.name}
										optionLabelProp='label'
										onChange={(e) => handleDropDownChange(e, 'budget_heads')}>
										{budgetHeads.map((bh) => (
											<Select.Option key={bh.id} value={bh.name} title={bh.name}>
												{bh.display_name}
											</Select.Option>
										))}
									</Select>
								</div>
							)}
						</Col>
					</Row>
					<div style={{ marginTop: '10px' }}>
						<Button type='primary' loading={applyButtonLoading} onClick={handleApplyFilter}>
							Apply Filter
						</Button>
					</div>
				</Collapse.Panel>
			</Collapse>
		</div>
	)
}

export default view(FilterForm)

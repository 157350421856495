import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Select, Button, Form, Input, message } from 'antd'

import { staleWhileRevalidate } from 'utils/render-strategies'
import UsersService from 'services/users/users.service'
import OrganizationRolesService from 'services/users/organization-roles.service'
import globalStore from 'store'

import StyledBox from 'components/common/styled-components/StyledBox'

const StyledUserEditForm = styled.div``

const initialUser = {
	name: '',
	email: '',
	password: '',
	organization_roles: [],
}

const UsersNewPage = () => {
	const pageState = globalStore.ui.users.new
	const history = useHistory()
	const [user, setUser] = useState(initialUser)
	const [organizationRoles, setOrganizationRoles] = useState([])
	const [creating, setCreating] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}

			await OrganizationRolesService.index({ pageState: tempState })

			const { organization_roles, serverStatus } = tempState

			if (serverStatus && serverStatus.status === 200) {
				setOrganizationRoles(organization_roles || [])
			}
		}

		fetchData()
	}, [])

	const handleCreate = async () => {
		if (!user.name) {
			message.error("Name field can't be blank")
			return
		}
		if (!user.email) {
			message.error("Email field can't be blank")
			return
		}
		if (!user.password) {
			message.error("Password field can't be blank")
			return
		}

		const tempState = {}
		const postData = {
			user: {
				...user,
				organization_role_ids: user.organization_roles.map((or) => or.id),
				organization_roles: null,
			},
		}

		setCreating(true)
		await UsersService.create({
			pageState: tempState,
			values: postData,
		})
		setCreating(false)

		const { serverStatus, userErrors } = tempState

		if (serverStatus.status !== 200) {
			message.error("User couldn't be created")
			return
		}
		if (userErrors) {
			message.error(userErrors?.email ? 'Email already exists' : userErrors[0])
			return
		}

		message.success('User created.')
		history.push('/users')
	}

	const handleNameChange = (e) => {
		setUser({
			...user,
			name: e.target.value,
		})
	}

	const handleEmailChange = (e) => {
		setUser({
			...user,
			email: e.target.value?.trim(),
		})
	}

	const handlePasswordChange = (e) => {
		setUser({
			...user,
			password: e.target.value,
		})
	}

	const handleOrganizationRoleChange = (selectedRoleIds) => {
		const seletedRoles = organizationRoles.filter((or) => selectedRoleIds.includes(or.id))

		setUser({
			...user,
			organization_roles: seletedRoles,
		})
	}

	const renderPageContent = () => {
		const { name, email, organization_roles, password } = user

		const selectedOrganizationRoleIds = organization_roles.map((sor) => sor.id)

		return (
			<StyledUserEditForm>
				<Form layout='vertical'>
					<Form.Item label='Name'>
						<Input placeholder='Enter name' value={name} onChange={handleNameChange} />
					</Form.Item>
					<Form.Item label='Email'>
						<Input placeholder='Enter email' value={email} onChange={handleEmailChange} />
					</Form.Item>
					<Form.Item label='Organization Roles'>
						<Select
							mode='multiple'
							placeholder='Choose organization roles'
							value={selectedOrganizationRoleIds}
							onChange={handleOrganizationRoleChange}
							style={{ width: '100%' }}>
							{organizationRoles.map((item, i) => (
								<Select.Option key={item.key} value={item.id}>
									{item.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label='Set Password'>
						<Input.Password
							placeholder='Set password'
							value={password}
							onChange={handlePasswordChange}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							type='primary'
							htmlType='submit'
							style={{ cssFloat: 'right' }}
							loading={creating}
							onClick={handleCreate}>
							{creating ? 'Creating...' : 'Create'}
						</Button>
					</Form.Item>
				</Form>
			</StyledUserEditForm>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('user-edit'))
		return <Redirect to='/' />

	const { API_USERS_SHOW_STATUS } = pageState

	return (
		<div className='page-content user-page'>
			<StyledBox maxWidth={'500px'}>
				{staleWhileRevalidate({
					status: API_USERS_SHOW_STATUS,
					data: user,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(UsersNewPage)

import { API, generic } from "api"

export default class DesignationsService {
  static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DESIGNATIONS_INDEX_STATUS",
      stateDataKey: ["designations", "total", "page", "page_size"],
      stateErrorKey: [
        "designationsErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
      ],
      apiEndpoint: API.users.designations.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize, bcsla } },
      errorMessage: "Designations could not be fetched.",
    })
  }

  static import = async ({ pageState, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_DESIGNATIONS_IMPORT_STATUS",
      stateDataKey: ["designations"],
      stateErrorKey: ["designationsErrors"],
      apiEndpoint: API.users.designations.import,
      apiUrlReplacements: {},
      apiData: formData,
      errorMessage: "Designations could not be imported.",
    })
  }
}

import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import dayjs from "dayjs"
import { Table, Modal, Button } from "antd"
import {
  EyeOutlined,
} from "@ant-design/icons"

import StyledBox from "components/common/styled-components/StyledBox"
import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"

import ImportsService from "services/users/imports.service"

const localizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(localizedFormat)

const StyledPageContent = styled.div`
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & input:nth-child(2) {
      margin-left: 15px;
    }
    & .action-buttons {
      margin: 0px;
      & > button {
        margin: 0px 0px 0px 15px;
      }
    }
  }
  & .status {
    display: flex;
    align-items: baseline;
    & .status-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: gray;
      margin-right: 10px;
    }
    & .status-circle.orange {
      background: orange;
    }
    & .status-circle.green {
      background: green;
    }
  }
`

const ImportPage = (props) => {
  const pageState = globalStore.ui.import
  const [data, setData] = useState(null)
  const [isShown, setIsShown] = useState({state: false, error: []})

  useEffect(() => {
    const fetchData = async () => {
      const tempState = {}

      await ImportsService.index({ pageState: tempState })

      const { imports } = tempState

      if (Array.isArray(imports)) {
        setData(formateData(imports))
        return
      }
    }

    fetchData()
  }, [])

  const formateData = (imports) => {
    return imports.map(
      ({ id, status_display, import_type_display, created_at, user, error_messages }, i) => {
        return {
          key: i,
          id,
          user,
          status: status_display,
          importedTable: import_type_display,
          importedTime: dayjs(created_at).format("DD-MMM-YYYY hh:mm A"),
          error_messages
        }
      }
    )
  }

  const getStatusColor = (status) => {
    if (status === "Completed") {
      return "green"
    }
    if (status === "Pending") {
      return "orange"
    }
    return ""
  }

  const handleOk = () => {
    setIsShown({status: false})
  }

  const columns = [
    {
      title: "Imported Data",
      dataIndex: "importedTable",
      key: "importedTable",
    },
    {
      title: "Imported By",
      dataIndex: "user",
      key: "user",
      render: (user) => {
        return user && user.email
      },
    },
    {
      title: "Imported Time",
      dataIndex: "importedTime",
      key: "importedTime",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, row) => {
        return (
          <div className="status">
            <div className={`status-circle ${getStatusColor(value)}`}></div>{" "}
            {value}
          </div>
        )
      },
    },
    {
      title: "",
      dataIndex: "error_messages",
      key: "error_messages",
      render: (value, row) => {
        return (
          <div className="status">
            {row.status === "Failed" && row.error_messages.length > 0 && <Button type="link" onMouseEnter={() => setIsShown({state: true, error: value})}
            > <EyeOutlined />
            </Button>}
          </div>
        )
      },
    },
  ]

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <Table dataSource={data} columns={columns} />
        <Modal title="Error messages" visible={isShown.state} onOk={handleOk} onCancel={handleOk}>
          {isShown.error?.map((err) => {
            return <p>{err}</p>
          })}
        </Modal>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("report-view")
  )
    return <Redirect to="/" />

  const { API_USERS_IMPORTS_INDEX_STATUS } = pageState

  return (
    <div className="page-content dashboard-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_IMPORTS_INDEX_STATUS,
          data: data,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(ImportPage)

import React, { useState, useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Select, Button, Form, Input, message } from 'antd'

import { staleWhileRevalidate } from 'utils/render-strategies'
import BudgetCriteriaValuesService from 'services/users/budget-criteria-values.service'
import globalStore from 'store'

import StyledBox from 'components/common/styled-components/StyledBox'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

const StyledUserEditForm = styled.div``

const BudgetCriteriaValuesNewPage = () => {
	const pageState = globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues.new
	const history = useHistory()
	const { budgetCriteriaHeadId, budgetCriteriaValueId } = useParams()
	const [budgetCriteriaValueName, setBudgetCriteriaValueName] = useState('')
	const [parentCriteriaValues, setParentCriteriaValues] = useState([{}])
	const [parentHierarchyValues, setParentHirarchyValues] = useState([])
	const [parentValue, setParentValue] = useState('')
	const [parentKey, setParentKey] = useState('')
	const [updating, setUpdating] = useState(false)
	const [budgetCriteriaHeadName, setBudgetCriteriaHeadName] = useState('')

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}

			await BudgetCriteriaValuesService.show({
				pageState: tempState,
				budgetCriteriaValueId,
			})
			const { serverStatus } = tempState
			const budgetCriteriaValue = tempState.budget_criteria_value

			if (serverStatus && serverStatus.status === 200) {
				const { name, budget_criteria_head_name, parent_heirarchy } = budgetCriteriaValue
				setParentHirarchyValues(parent_heirarchy)
				setBudgetCriteriaValueName(name || [])
				setBudgetCriteriaHeadName(budget_criteria_head_name)
				if (parent_heirarchy[parent_heirarchy.length - 1]) {
					setParentValue(Object.values(parent_heirarchy[parent_heirarchy.length - 1]))
					setParentKey(Object.keys(parent_heirarchy[parent_heirarchy.length - 1]))
				}
			}
		}
		fetchData()
	}, [budgetCriteriaValueId])

	useEffect(() => {
		const fetchParentData = async () => {
			const pageState =
				globalStore.ui.configuration.budgetCriteriaHeads.budgetCriteriaValues
					.parentBudgetCriteriaValue
			await BudgetCriteriaValuesService.parent_budget_criteria_value({
				pageState,
				budgetCriteriaHeadId,
			})

			const { serverStatus, budgetCriteriaValuesErrors, parent_budget_criteria_values } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetCriteriaValuesErrors) {
				message.error(budgetCriteriaValuesErrors[0] || "Budget Criteria couldn't be fetched.")
				return
			}

			setParentCriteriaValues(parent_budget_criteria_values)
		}

		fetchParentData()
	}, [budgetCriteriaHeadId])

	const handleUpdate = async () => {
		if (!budgetCriteriaValueName) {
			message.error("Name field can't be blank")
			return
		}
		const tempState = {}
		const postData = {
			budget_criteria_value: {
				name: budgetCriteriaValueName,
				parent_id: parentValue,
			},
		}
		setUpdating(true)
		await BudgetCriteriaValuesService.update({
			pageState: tempState,
			values: postData,
			budgetCriteriaValueId,
		})
		setUpdating(false)

		const { serverStatus, budgetCriteriaValueError } = tempState

		if (serverStatus.status !== 200) {
			message.error("budgetCriteriaValue couldn't be created")
			return
		}

		if (budgetCriteriaValueError?.length > 0) {
			message.error(budgetCriteriaValueError[0])
			return
		}

		message.success('Planned Budget updated.')
		history.push('/configuration/budget-criteria-heads')
	}

	const handleNameChange = (e) => {
		setBudgetCriteriaValueName(e.target.value)
	}

	const handleParentNameChange = (e) => {
		setParentValue(e)
	}

	const renderPageContent = () => {
		return (
			<StyledUserEditForm>
				<Form layout='vertical'>
					<Form.Item label={budgetCriteriaHeadName}>
						<Input
							placeholder='Enter value'
							value={budgetCriteriaValueName}
							onChange={handleNameChange}
						/>
					</Form.Item>
					{parentCriteriaValues && (
						<Form.Item label={parentKey}>
							<Select
								placeholder='Choose value'
								value={parentValue}
								onChange={(e) => handleParentNameChange(e)}>
								{parentCriteriaValues.map((parentValue) => {
									const key = parentValue.id
									return (
										<Select.Option key={key} value={key}>
											{parentValue.name}
										</Select.Option>
									)
								})}
							</Select>
						</Form.Item>
					)}
					<Form.Item label='Hierarchy'>
						<Input
							type='text'
							disabled='disabled'
							value={parentHierarchyValues
								.map(
									(s, i) =>
										Object.values(s) +
										(Object.values(s) === '' || i === parentHierarchyValues.length - 1 ? '' : '>')
								)
								.join('')}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							type='primary'
							htmlType='submit'
							style={{ cssFloat: 'right' }}
							loading={updating}
							onClick={handleUpdate}>
							{updating ? 'Updating...' : 'Update'}
						</Button>
					</Form.Item>
				</Form>
			</StyledUserEditForm>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('criteria-edit'))
		return <Redirect to='/' />

	const { API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_INDEX_STATUS } = pageState

	return (
		<div className='page-content user-page'>
			<StyledBox maxWidth={'500px'}>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_CRITERIA_HEADS_BUDGET_CRITERIA_VALUES_INDEX_STATUS,
					data: { budgetCriteriaValueName },
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetCriteriaValuesNewPage)

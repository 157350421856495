import { API, generic } from "api"

export default class FitmentConfigurationsService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_CONFIGURATIONS_INDEX_STATUS",
      stateDataKey: ["fitment_configuration"],
      stateErrorKey: ["fitmentConfigurationErrors"],
      apiEndpoint: API.users.fitmentConfigurations.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Fitment configguartion could not be fetched.",
    })
  }

  static update = async ({ pageState, values, fitmentConfigurationId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_FITMENT_CONFIGURATIONS_UPDATE_STATUS",
      stateDataKey: ["fitmentFields"],
      stateErrorKey: ["fitmentFieldsErrors"],
      apiEndpoint: API.users.fitmentConfigurations.update,
      apiUrlReplacements: { fitmentConfigurationId },
      apiData: { ...values },
      errorMessage: "Fitment configguartions could not be updated.",
    })
  }

  static auditReports = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey:
        "API_USERS_FITMENT_CONFIGURATIONS_AUDIT_REPORTS_STATUS",
      stateDataKey: ["fitmentFields"],
      stateErrorKey: ["fitmentFieldsErrors"],
      apiEndpoint: API.users.fitmentConfigurations.auditReports,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Audit reports could not be updated.",
    })
  }
}

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams, Redirect } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import { Color } from "constants/theme"
import IncentivesService from "services/users/incentives.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const StyledItemList = styled.div`
  & > div {
    margin-bottom: 50px;
  }

  & .item-row {
    display: flex;
    margin-bottom: 5px;
    & > div {
      flex: auto;
      min-height: 42px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Color.secondaryColor};
      background: white;
      justify-content: flex-start;
      padding: 5px 15px;
    }
    & > div:first-child {
      max-width: 250px;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: bold;
      background: ${Color.secondaryColor};
      color: ${Color.textColor};
      // box-shadow: 0px 5px 20px #0000000d;
    }
  }
  & .colored.item-row {
    & div:last-child {
      background: #f9f3ff;
    }
  }
`

const IncentivesShowPage = (props) => {
  const pageState = globalStore.ui.configuration.incentives.show
  const history = useHistory()
  const { incentiveId } = useParams()
  // const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    const fetchIncentive = async () => {
      const pageState = globalStore.ui.configuration.incentives.show

      await IncentivesService.show({ pageState, incentiveId })

      const { serverStatus, incentiveErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (incentiveErrors) {
        message.error(incentiveErrors[0] || "Incentive couldn't be fetched.")
        return
      }
    }

    fetchIncentive()
  }, [incentiveId])

  const handleEdit = () => {
    history.push(`/configuration/incentives/${incentiveId}/edit`)
  }

  // const handleDelete = async () => {
  //   const pageState = {}

  //   setDeleteLoading(true)
  //   await IncentivesService.delete({ pageState, incentiveId })
  //   setDeleteLoading(false)

  //   const { serverStatus } = pageState

  //   if (serverStatus.status !== 0) {
  //     message.error("Failed to delete this incentive")
  //     return
  //   }

  //   message.success("Minimum Wage deleted.")

  //   history.push("/configuration/incentives")
  // }

  const {
    incentive_configuration,
    API_USERS_INCENTIVES_SHOW_STATUS,
  } = pageState

  const renderContent = () => {
    if (!incentive_configuration) return null

    const {
      criteria,
      currency,
      payout_value,
      payout,
      frequency,
    } = incentive_configuration

    return (
      <div>
        <h1>{incentive_configuration.name}</h1>
        <StyledItemList>
          <div>
            <div className="item-row colored">
              <div className="label">Currency</div>
              <div>{currency}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Frequency</div>
              <div style={{textTransform: "capitalize"}}>{frequency}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Payout</div>
              <div style={{textTransform: "capitalize"}}>{payout}</div>
            </div>
            <div className="item-row colored">
              <div className="label">Payout Value</div>
              <div>{payout_value}</div>
            </div>
          </div>
        </StyledItemList>
        <CriteriaSet criteria={criteria} />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit
          </Button>
          {/* <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
            Delete
          </Button> */}
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const strategy =
    incentive_configuration && incentive_configuration.id === incentiveId
      ? staleWhileRevalidate
      : networkOnly

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/configuration/incentives" />

  return (
    <div className="page-content incentives-page">
      <StyledBox>
        {strategy({
          status: API_USERS_INCENTIVES_SHOW_STATUS,
          data: incentive_configuration,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(IncentivesShowPage)

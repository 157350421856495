import { view } from '@risingstack/react-easy-state'
import { Button, Col, Input, message, Row } from 'antd'
import StyledBox from 'components/common/styled-components/StyledBox'
import CountryDetailsConditionalRow from 'components/CountryDetailsConditionalRow'
import TemplateEditor from 'components/TemplateEditor'
import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import CountryDetailsService from 'services/users/country-details.service'
import OrganizationsService from 'services/users/organizations.service'
import globalStore from 'store/index'
import styled from 'styled-components'

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const CountryDetailsEditPage = () => {
	const { countryId } = useParams()

	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState('')
	const [field, setField] = useState([{ name: null, value: null }])
	const [content, setContent] = useState('')
	const [contentData, setContentData] = useState('')
	const [contentLetters, setContentLetters] = useState('')
	const [contentLettersData, setContentLettersData] = useState('')

	useEffect(() => {
		const fetchAllFieldsData = async () => {
			const pageState = globalStore.currentOrganization
			const organizationFields = pageState.organization?.all_fields_with_datatype
			if (organizationFields && organizationFields.length !== 0) return
			await OrganizationsService.me({ pageState })
			const { organization } = pageState
			if (!organization?.all_fields_with_datatype) {
				message.error('Organization fields not found')
				return
			}
		}
		const fetchShowData = async () => {
			const pageState = globalStore.ui.configuration.approvalChains.show
			await CountryDetailsService.show({ pageState, countryId })
			const { serverStatus, countriesDetailErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (countriesDetailErrors) {
				message.error(countriesDetailErrors[0] || "Couldn't fetch Country Details")
				return
			}
			const { country_detail } = pageState
			const { details, name, envelope_body_content, envelope_body_content_for_letters } =
				country_detail || {}
			setInputValues(name)
			setField(details)
			setContent(envelope_body_content)
			setContentData(envelope_body_content)
			setContentLetters(envelope_body_content_for_letters)
			setContentLettersData(envelope_body_content_for_letters)
		}
		fetchAllFieldsData()
		fetchShowData()
		return () => {
			setField([{ name: null, value: null }])
			setInputValues('')
		}
	}, [countryId])

	const handleInputChange = (e) => {
		setInputValues(e.target.value)
	}

	const handleCreate = async () => {
		if (!inputValues) {
			message.error("Name can't be blank")
			return
		}
	const hasInvalidField = field.some(
		(item) => !item.name || item.value === undefined || item.value === null
	)
		if (hasInvalidField) {
			message.error('Details cannot be blank')
			return
		}

		const pageState = {}
		const postData = {
			country_detail: {
				name: inputValues,
				details: field,
				envelope_body_content: contentData,
				envelope_body_content_for_letters: contentLettersData,
			},
		}

		setCreateButtonLoading(true)
		await CountryDetailsService.update({ pageState, data: postData, countryId })
		setCreateButtonLoading(false)

		const { serverStatus, incentiveVariableErrors } = pageState

		if (incentiveVariableErrors) {
			message.error(incentiveVariableErrors[0] || 'Failed to create country details')
			return
		}

		if (serverStatus.status === 200) {
			message.success('Country Details created.')
			return
		}

		message.error('Oops!! something went wrong')
	}

	const handleContentChange = (value) => {
		setContentData(value)
	}
	const handleContentLettersChange = (value) => {
		setContentLettersData(value)
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<Row gutter={20}>
					<Col span={24}>
						<div className='input-box'>
							<strong>Country Name</strong>
							<Input
								style={{ marginTop: 10 }}
								value={inputValues}
								onChange={(e) => handleInputChange(e, 'name')}
							/>
						</div>
					</Col>
				</Row>
				<div className='input-box'>
					<strong>Details</strong>
					<CountryDetailsConditionalRow field={field} setField={setField} />
					<span>
						For date format refer to this &nbsp;
						<a
							href='https://apidock.com/ruby/v1_9_2_180/DateTime/strftime'
							target='_blank'
							rel='noopener noreferrer'>
							link
						</a>
						.
					</span>
				</div>
				<div className='input-box'>
					<strong>DocuSign Mail Content for Offer Letters :</strong>
					<TemplateEditor
						onChange={handleContentChange}
						customId='editor1'
						content={content}
						customVariable={[
							['job_title', 'job_title'],
							['candidate_name', 'candidate_name'],
							['candidate_email', 'candidate_email'],
							['joining_date', 'joining_date'],
							['role_band', 'role_band'],
							['job_designation', 'job_designation'],
						]}
					/>
				</div>
				<div className='input-box'>
					<strong>DocuSign Mail Content for Additional Letters :</strong>
					<TemplateEditor
						onChange={handleContentLettersChange}
						customId='editor2'
						content={contentLetters}
						customVariable={[
							['job_title', 'job_title'],
							['candidate_name', 'candidate_name'],
							['candidate_email', 'candidate_email'],
							['joining_date', 'joining_date'],
							['role_band', 'role_band'],
							['job_designation', 'job_designation'],
						]}
					/>
				</div>
				<div className='action-buttons submit'>
					<Button type='primary' loading={createButtonLoading} onClick={handleCreate}>
						Update Country Details
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('organization-edit'))
		return <Redirect to={`/configuration/country-details/`} />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(CountryDetailsEditPage)

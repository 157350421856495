import React from "react"
import { view } from "@risingstack/react-easy-state"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import StyledFitmentItemCard from "components/common/styled-components/StyledFitmentItemCard"

const chartOption = ({ min, mid, max, currentSalary, offeredSalary }) => ({
  chart: {
    marginLeft: 135,
    type: "bar",
    margin: [5, 15, 10, 100],
    height: "48px",
  },
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { enabled: false },
  title: { text: "" },
  tooltip: {
    enabled: true,
    backgroundColor: "rgba(255, 255, 255, .85)",
    borderWidth: 0,
    shadow: true,
    style: { fontSize: "10px", padding: 2 },
    formatter: function () {
      return (
        this.series.name +
        ": <strong>" +
        Highcharts.numberFormat(this.y, 2) +
        "</strong>"
      )
    },
  },
  plotOptions: {
    bar: {
      color: "#000",
      shadow: false,
      borderWidth: 0,
    },
    scatter: {
      marker: {
        symbol: "line",
        lineWidth: 3,
        radius: 9,
        lineColor: "#000",
      },
    },
  },
  xAxis: {
    categories: ['<span class="hc-cat-title">Salary Range</span><br/>INR'],
  },
  yAxis: {
    gridLineWidth: 0,
    max: offeredSalary > max ? offeredSalary : max, //Max of offered salary or maximum of range
    labels: { y: 10, style: { fontSize: "12px" } },
    plotBands: [
      { from: 0, to: min, color: "#d39e00" },
      { from: min, to: mid, color: "#ffc000" },
      {
        from: mid,
        to: max,
        color: "#ffd9a0",
      },
      {
        from: max,
        to: 9e9,
        color: "#ffe8c5",
      },
    ],
  },
  series: [
    { name: "Current Salary", pointWidth: 12, data: [currentSalary] },
    { name: "Offered Salary", type: "scatter", data: [offeredSalary] },
  ],
})

const FitmentCharts = ({ fitment }) => {
  const { salary_range, salary_table } = fitment

  if (!salary_table || !salary_range) return

  // For testing purpose
  // const salary_range = {
  //   minimum: 200000,
  //   median: 397000,
  //   maximum: 438000,
  // }

  // const salary_table = {
  //   previous_gross: 320000,
  //   new_gross: 866560,
  // }

  const { minimum, median, maximum } = salary_range
  const { previous_gross, new_gross } = salary_table

  const possibleZeros = ["0.0", "0", 0]

  if (
    possibleZeros.includes(minimum) &&
    possibleZeros.includes(median) &&
    possibleZeros.includes(maximum)
  )
    return null

  return (
    <StyledFitmentItemCard>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOption({
          min: parseInt(minimum),
          mid: parseInt(median),
          max: parseInt(maximum),
          currentSalary: previous_gross,
          offeredSalary: new_gross,
        })}
      />
    </StyledFitmentItemCard>
  )
}

export default view(FitmentCharts)

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const StyledFitmentFieldsConfigurationDND = styled.div``

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fdfdfd",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
})

const FitmentFieldsConfigurationDND = ({
  fitmentFieldsConfiguration = [],
  updatedOrderIds,
}) => {
  const [fields, setFields] = useState([])

  useEffect(() => {
    setFields(fitmentFieldsConfiguration)
  }, [fitmentFieldsConfiguration])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(fields, result.source.index, result.destination.index)

    const orderIds = items.map((item) => item.id)

    updatedOrderIds(orderIds)
    setFields(items)
  }

  return (
    <StyledFitmentFieldsConfigurationDND>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {fields.map((field, index) => (
                <Draggable
                  key={field.id}
                  draggableId={field.name}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {field.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </StyledFitmentFieldsConfigurationDND>
  )
}

export default view(FitmentFieldsConfigurationDND)

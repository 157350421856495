import styled from "styled-components"

const StyledBox = styled.div`
  padding: 30px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px #d9d9d912;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  margin: auto;
  min-height: 75%
`

export default StyledBox

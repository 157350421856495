import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useParams } from 'react-router-dom'
import ContentLoading from 'components/common/ContentLoading'

import FitmentsService from 'services/users/fitments.service'

const DocusignLettersDownloadRedirector = () => {
	const { letterUuid } = useParams()

	useEffect(() => {
		const fetchDataAndRedirect = async () => {
			const tempState = {}

			await FitmentsService.downloadDocusignLetter({ pageState: tempState, letterUuid })

			const { redirect_url } = tempState

			// TODO: Handle errors. Show error messages on the same page
			if (redirect_url) window.location = redirect_url
		}
		fetchDataAndRedirect()
	}, [letterUuid])

	return <ContentLoading />
}

export default view(DocusignLettersDownloadRedirector)

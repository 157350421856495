import React from 'react'
import { view } from '@risingstack/react-easy-state'

import { FileOutlined } from '@ant-design/icons'
import { Button, List, Avatar, message } from 'antd'
import FitmentsService from 'services/users/fitments.service'

const FitmentDocumentsList = ({ documents, setFitment }) => {
	if (!documents) return null
	const attachedDocuments = documents.map((fd) => {
		return {
			...fd.attached_document,
			id: fd.id,
			deleted: fd.deleted,
			document_type: fd.document_type,
			frontend_download_url: fd.frontend_download_url,
		}
	})

	const onDocumentDelete = async (fitmentId) => {
		const tempState = {}
		await FitmentsService.fitmentDocumentDelete({ pageState: tempState, fitmentId })
		const { fitment, fitmentErrors } = tempState
		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Document not Deleted')
			return
		}
		setFitment(fitment)
		message.success('Document successfully deleted ')
	}
	return (
		<List
			itemLayout='horizontal'
			dataSource={attachedDocuments}
			renderItem={(item) => {
				if (item?.deleted) {
					return null
				}
				return (
					<List.Item
						actions={[
							<Button
								type='link'
								target='_blank'
								href={item.frontend_download_url}
								downloadable='true'>
								Download
							</Button>,
							<Button
								type='link'
								target='_blank'
								onClick={() => {
									onDocumentDelete(item.id)
								}}>
								Delete
							</Button>,
						]}>
						<List.Item.Meta
							avatar={<Avatar icon={<FileOutlined />} />}
							title={item.file_name}
							description={item.document_type}
						/>
					</List.Item>
				)
			}}
		/>
	)
}

export default view(FitmentDocumentsList)

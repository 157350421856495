import React, { useState, useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, message, Row, Col, Select } from 'antd'
import { isContainsBlankCriteria } from 'utils/criteria'

import StyledBox from 'components/common/styled-components/StyledBox'
import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import { default as FitmentConditionRow } from 'components/ConditionRow'
import { default as BudgetConditionRow } from 'components/budget-analysis/ConditionRow'
import ApprovalChainsService from 'services/users/approval-chains.service'
import OrganizationsService from 'services/users/organizations.service'
import globalStore from 'store/index'
import ApproversForm from 'components/ApproversForm'

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const blankCondition = {
	field: null,
	operator: null,
	values: null,
}

const initialApprover = [['']]
const approvalTypes = ['fitment', 'budget','salary']

const ApprovalChainsEditPage = () => {
	const history = useHistory()
	const { approvalChainId } = useParams()
	const [criteria, setCriteria] = useState([[blankCondition]])
	const [updateButtonLoading, setUpdateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})
	const [approvers, setApprovers] = useState(initialApprover)
	const [approvalType, setApprovalType] = useState(approvalTypes[0])
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [criteriaChildren, setCriteriaChildren] = useState([])
	useEffect(() => {
		const fetchApprovalChain = async () => {
			const pageState = globalStore.ui.configuration.approvalChains.edit

			await ApprovalChainsService.show({ pageState, approvalChainId })

			const { serverStatus, approval_chain, approvalChainErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong. 1')
				return
			}

			if (approvalChainErrors) {
				message.error(approvalChainErrors[0] || "Approval chain couldn't be fetched.")
				return
			}

			const { criteria, approver_emails, name, approval_type } = approval_chain

			const newCriteria = criteria?.length > 0 ? criteria : [[blankCondition]]

			setCriteria(newCriteria)
			setApprovers(approver_emails)
			setInputValues({ name })
			setApprovalType(approval_type)
		}

		fetchApprovalChain()
	}, [approvalChainId])

	useEffect(() => {
		if (criteriaChildren.length) {
			const updateCriteriaData = criteria[0].map((data) => {
				if (criteriaChildren.some((items) => items.name === data.field)) {
					return { ...data, values: null, operator: null }
				}
				return data
			})
			setCriteria([updateCriteriaData])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteriaChildren])

	useEffect(() => {
		const pageState = globalStore.currentOrganization
		const organizationFields = pageState.organization?.all_fields_with_datatype
		if (organizationFields && organizationFields.length !== 0) return

		const fetchAllFieldsData = async () => {
			await OrganizationsService.me({ pageState })

			const { organization } = pageState

			if (!organization?.all_fields_with_datatype) {
				message.error('Organization fields not found')
				return
			}
		}

		fetchAllFieldsData()
	}, [])

	useEffect(() => {
		const tempState = {}

		const fetchBudgetCriteriaHeads = async () => {
			await BudgetCriteriaHeadsService.criteriaList({ pageState: tempState })
			const { budget_criteria_heads } = tempState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		fetchBudgetCriteriaHeads()
	}, [])

	useEffect(() => {
		if (criteria.length !== 1 || criteria[0].length !== 0) return
		setCriteria([[blankCondition]])
	}, [criteria])

	const handleInputChange = (e, inputType) => {
		if (inputType === 'approvalType') {
			setApprovalType(e)
			return
		}

		const newInputValues = { ...inputValues, [inputType]: e.target.value }

		setInputValues(newInputValues)
	}

	// Before
	// criteria = [
	//   [
	//     { field: "cs-conset1-f1", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset1-f2", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset1-f3", operator: "any", values: ["a", "b"] },
	//   ],
	//   [
	//     { field: "cs-conset2-f1", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset2-f2", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset2-f3", operator: "any", values: ["a", "b"] },
	//   ],
	// ]

	// Called with {field: "new-field", operator: "any", values: ["a", "b"] }
	// and conditionSetIndex = 1, parentConditionIndex = 0

	// After
	// criteria = [
	//   [
	//     { field: "cs-conset1-f1", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset1-f2", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset1-f3", operator: "any", values: ["a", "b"] },
	//   ],
	//   [
	//     { field: "cs-conset2-f1", operator: "any", values: ["a", "b"] },
	//     { field: "new-field", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset2-f2", operator: "any", values: ["a", "b"] },
	//     { field: "cs-conset2-f3", operator: "any", values: ["a", "b"] },
	//   ],
	// ]

	const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(deletedConditionIndex, 1)
				return newConditionSet
			})

			return newCriteria
		})
		// if (criteria.length === 1) return

		// const filteredCriteria = criteria.filter((c, i) => i !== index)
		// setCriteria(filteredCriteria)
	}

	const handleUpdateConditionValues = ({ conditionSetIndex, conditionIndex, condition }) => {
		if (!condition) return
		if (condition.operator !== 'not' && !condition.values) return

		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				return newConditionSet.map((c, index) => {
					if (index !== conditionIndex) return c

					return condition
				})
			})

			return newCriteria
		})
	}

	const addNewApproverGroup = () => {
		setApprovers([...approvers, ...initialApprover])
	}

	const handleUpdate = async () => {
		if (!inputValues['name']) {
			message.error("Name can't be blank")
			return
		}

		if (isContainsBlankCriteria(criteria)) {
			message.error('Criteria cannot be blank')
			return
		}

		const pageState = {}

		const postData = {
			approval_chain: {
				criteria: criteria,
				...inputValues,
				approvers: approvers.filter((a) => a.length > 0),
				approval_type: approvalType,
			},
		}

		setUpdateButtonLoading(true)
		await ApprovalChainsService.update({
			pageState,
			values: postData,
			approvalChainId,
		})
		setUpdateButtonLoading(false)

		const { serverStatus, incentiveVariableErrors } = pageState

		if (incentiveVariableErrors) {
			message.error(incentiveVariableErrors[0] || "Approval chain couldn't be updated.")
			return
		}

		if (serverStatus.status === 200) {
			message.success('Approval chain updated.')
			setCriteria([[blankCondition]])
			setApprovers(initialApprover)
			setInputValues({})
			history.push(`/configuration/approval-chains/${approvalChainId}`)
			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<Row gutter={24} justify='space-between' align='middle'>
					<Col>
						<h1>Approval Chain</h1>
					</Col>
					<Col>
						<Row gutter={10} justify='end' align='middle'>
							<Col>
								<strong>Approval Type</strong>
							</Col>
							<Col>
								<Select
									style={{ display: 'block' }}
									placeholder='Choose Approval Type'
									value={approvalType}
									onChange={(value) => handleInputChange(value, 'approvalType')}>
									<Select.Option value={approvalTypes[0]}>Hirereview</Select.Option>
									<Select.Option value={approvalTypes[1]}>SmartBudget</Select.Option>
									<Select.Option value={approvalTypes[2]}>Salary</Select.Option>
								</Select>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={24}>
						<div className='input-box'>
							<strong>Name</strong>
							<Input value={inputValues['name']} onChange={(e) => handleInputChange(e, 'name')} />
						</div>
					</Col>
				</Row>
				<div className='input-box'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<strong>Criteria</strong>
						{criteria.filter((c) => c.length > 0).length === 0 && (
							<Button shape='circle' onClick={() => setCriteria([[blankCondition]])}>
								<PlusOutlined />
							</Button>
						)}
					</div>
					{criteria.map((conditionSet, conditionSetIndex) => {
						return (
							<div key={conditionSetIndex}>
								{conditionSet.map((condition, conditionIndex) => {
									return ['fitment', 'salary'].includes(approvalType) ? (
										<FitmentConditionRow
											condition={condition}
											key={conditionIndex}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											conditionSetLength={conditionSet.length}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleUpdateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											organizationFields={globalStore.currentOrganization.all_fields_with_datatype}
										/>
									) : (
										<BudgetConditionRow
											criteria={criteria}
											clearChildren={setCriteriaChildren}
											condition={condition}
											key={conditionIndex}
											conditionIndex={conditionSetIndex}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											conditionSetLength={conditionSet.length}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleUpdateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											budgetCriteriaHeads={budgetCriteriaHeads}
										/>
									)
								})}
							</div>
						)
					})}
				</div>
				<div style={{ marginTop: 20, marginBottom: 10 }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
							marginBottom: 10,
						}}>
						<h1 style={{ margin: 0 }}>Approvers</h1>
						<Button type='link' style={{ padding: 0, marginTop: 3 }} onClick={addNewApproverGroup}>
							Add Level
						</Button>
					</div>
					<ApproversForm
						approvers={approvers}
						setApprovers={setApprovers}
						initialApprover={initialApprover}
					/>
				</div>
				<div className='action-buttons submit'>
					<Button type='primary' loading={updateButtonLoading} onClick={handleUpdate}>
						Update Approval Chain
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('approval-chain-edit'))
		return <Redirect to={`/configuration/approval-chains/${approvalChainId}`} />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(ApprovalChainsEditPage)

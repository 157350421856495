import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import SsoService from 'services/payreview/sso.service'
import * as AuthHelper from 'helpers/auth.helper'
import ContentLoading from 'components/common/ContentLoading'
import { Result, message } from 'antd'
import styled from 'styled-components'
import queryString from 'query-string'
const HirereviewSsoLogin = () => {
	const StyledBox = styled.div`
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	`

	const history = useHistory()
	const location = useLocation()
	const {path} = queryString.parse(location.search)
	const [error, setError] = useState()

	useEffect(() => {
		const fetchData = async () => {
			const tempState = {}
			const token = AuthHelper.getToken('token')
			await SsoService.hirereviewLogin({ pageState: tempState, token })
			const { user, hirereviewLoginErrors } = tempState
			if (hirereviewLoginErrors) {
				message.error(hirereviewLoginErrors)
				setError(hirereviewLoginErrors)
				return
			}
			AuthHelper.setToken(user?.jwt)
			history.replace(path ? path.replace('%2F', '/') : '/')
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<StyledBox>
			{!error ? (
				<ContentLoading />
			) : (
				<Result status='warning' title='There are some problems with Hirereview Login.' />
			)}
		</StyledBox>
	)
}

export default HirereviewSsoLogin

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useParams, Redirect, useHistory } from "react-router-dom"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import StyledPageContent from "components/common/styled-components/StyledPageContent"
import CriteriaSet from "components/CriteriaSet"
import SSConfiguration from "components/ss/Configuration"

import { staleWhileRevalidate } from "utils/render-strategies"
import globalStore from "store/index"
import SSConfigurationsService from "services/users/ss/configurations.service"

const SSConfigurationsShowPage = () => {
  const pageState = globalStore.ui.configuration.ssConfigurations.show
  const history = useHistory()
  const { ssConfigurationId } = useParams()
  // const [deleteLoading, setDeleteLoading] = useState(false)
  // const deleteLoading = false

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.configuration.ssConfigurations.show

      await SSConfigurationsService.show({ pageState, ssConfigurationId })

      const { serverStatus, ssConfigurationErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (ssConfigurationErrors) {
        message.error(
          ssConfigurationErrors[0] ||
            "Coundn't fetch Salary Structure Configuration"
        )
        return
      }
    }

    fetchData()
  }, [ssConfigurationId])

  const handleEdit = () => {
    history.push(`/configuration/ss-configurations/${ssConfigurationId}/edit`)
  }

  // const handleDelete = async () => {
  //   const pageState = {}

  //   setDeleteLoading(true)
  //   await SSConfigurationsService.delete({ pageState, ssConfigurationId })
  //   setDeleteLoading(false)

  //   const { serverStatus } = pageState

  //   if (serverStatus.status !== 0) {
  //     message.error("Failed to delete this Salary Structure Configuration")
  //     return
  //   }

  //   message.success("Salary Structure Configuration deleted.")

  //   history.push("/configuration/ss-configurations")
  // }

  const {
    ss_configuration,
    API_USERS_SS_CONFIGURATIONS_SHOW_STATUS,
  } = pageState

  const renderContent = () => {
    if (!ss_configuration) return null

    const { criteria } = ss_configuration
    return (
      <div>
        <CriteriaSet criteria={criteria} />
        <SSConfiguration
          ss_configuration={ss_configuration}
          hideMonthly={true}
        />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="page-header">
          <h1>{ss_configuration.name}</h1>
          <div className="action-buttons top">
            <Button type="primary" ghost onClick={handleEdit}>
              Edit
            </Button>
            {/* <Button danger ghost loading={deleteLoading} onClick={handleDelete}>
              Delete
            </Button> */}
          </div>
        </div>

        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content ss-configurations-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_SS_CONFIGURATIONS_SHOW_STATUS,
          data: ss_configuration,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(SSConfigurationsShowPage)

import { API, generic } from "api"

export default class FitmentApprovalsService {
  static show = async ({ pageState, token }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_FITMENT_APPROVAL_SHOW_STATUS",
      stateDataKey: [
        "fitment_approval",
        "fitment_configuration",
        "organization",
      ],
      stateErrorKey: [
        "fitmentApprovalErrors",
        "fitmentConfigurationErrors",
        "organizationErrors",
      ],
      apiEndpoint: API.visitors.fitmentApprovals.show,
      apiUrlReplacements: { token },
      apiData: {},
      errorMessage: "Fitment approval could not be fetched.",
    })
  }

  static approve = async ({ pageState, token }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_FITMENT_APPROVAL_APPROVE_STATUS",
      stateDataKey: ["fitment_approval"],
      stateErrorKey: ["fitmentApprovalErrors"],
      apiEndpoint: API.visitors.fitmentApprovals.approve,
      apiUrlReplacements: { token },
      apiData: {},
      errorMessage: "Fitment approval could not be approved.",
    })
  }

  static reject = async ({ pageState, token, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_FITMENT_APPROVAL_REJECT_STATUS",
      stateDataKey: ["fitment_approval"],
      stateErrorKey: ["fitmentApprovalErrors"],
      apiEndpoint: API.visitors.fitmentApprovals.reject,
      apiUrlReplacements: { token },
      apiData: { ...values },
      errorMessage: "Fitment approval could not be rejected.",
    })
  }

  static comment = async ({ pageState, token, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_VISITORS_FITMENT_APPROVAL_COMMENT_STATUS",
      stateDataKey: ["fitment_approval"],
      stateErrorKey: ["fitmentApprovalErrors"],
      apiEndpoint: API.visitors.fitmentApprovals.comment,
      apiUrlReplacements: { token },
      apiData: { ...values },
      errorMessage: "Comment could not be submitted.",
    })
  }
}

import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import { message, Button, Empty, Pagination, Table, Tooltip } from 'antd'
import moment from 'moment'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledList from 'components/common/styled-components/StyledList'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import WorkForcePeriodService from 'services/users/workforce-period.service'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const WorkForcePeriodIndex = () => {
	const history = useHistory()
	const pageState = globalStore.ui.configuration.workforcePeriod.index

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	useEffect(() => {
		const fetchBudgets = async () => {
			const pageState = globalStore.ui.configuration.workforcePeriod.index

			await WorkForcePeriodService.index({ pageState })

			const { serverStatus, workforcePeriodsErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (workforcePeriodsErrors) {
				message.error(workforcePeriodsErrors[0] || "WorkForce Period couldn't be fetched.")
				return
			}
		}

		fetchBudgets()
	}, [])

	const repaginate = async (page, pageSize) => {
		await WorkForcePeriodService.index({ pageState, page, pageSize })
	}

	const handleCreate = () => {
		history.push('/configuration/workforce-period/new')
	}

	const handleCardItemClick = (workforcePeriodID) => {
		history.push(`/configuration/workforce-period/${workforcePeriodID}`)
	}

	const handleEdit = (workforcePeriodID) => {
		history.push(`/configuration/workforce-period/${workforcePeriodID}/edit`)
	}

	const {
		workforce_periods,
		workforce_periodsMeta,
		API_USERS_WORKFORCE_PERIOD_INDEX_STATUS,
		displayType = 'table',
	} = pageState

	const { total, page: currentPage, page_size: pageSize } = workforce_periodsMeta || {}

	const renderList = () => {
		return (
			<StyledList>
				{workforce_periods.map((ic) => (
					<div
						className='item-card'
						key={`item-card-${ic.id}`}
						onClick={() => handleCardItemClick(ic.id)}>
						<div className='details-box'>
							<div className='name-box'>
								<div className='name'>{ic.name}</div>
								<div>{}</div>
							</div>
						</div>
						<div className='icon-box'>
							<Button type='link'>
								<EyeOutlined />
							</Button>
						</div>
					</div>
				))}
			</StyledList>
		)
	}

	const renderContents = () => {
		if (workforce_periods.length === 0) {
			return (
				<Empty>
					<Button type='primary' ghost onClick={handleCreate}>
						Create WorkForce Period
					</Button>
				</Empty>
			)
		}

		const pagination = (
			<Pagination
				style={{ margin: 10}}
				current={currentPage}
				total={total}
				onChange={repaginate}
				onShowSizeChange={repaginate}
				pageSize={pageSize || 25}
				pageSizeOptions={[10, 25, 50, 100]}
			/>
		)

		const tableColumns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Start Date',
				dataIndex: 'start_date',
				key: 'start_date',
				render: (value) => <span>{moment.utc(value).format('DD-MMM-YYYY')}</span>,
			},
			{
				title: 'End Date',
				dataIndex: 'end_date',
				key: 'end_date',
				render: (value) => <span>{moment.utc(value).format('DD-MMM-YYYY')}</span>,
			},
			{
				title: 'Current Status',
				dataIndex: 'current',
				key: 'current',
				render: (value) => <span>{value ? 'true' : 'false'}</span>,
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				align: 'center',
				render: (value, data) => {
					return (
						<div>
							<Tooltip title='View'>
								<Button type='link' onClick={() => handleCardItemClick(data.id)}>
									<EyeOutlined />
								</Button>
							</Tooltip>
							<Tooltip title='Edit'>
								<Button type='link' onClick={() => handleEdit(data.id)}>
									<EditOutlined />
								</Button>
							</Tooltip>
						</div>
					)
				},
			},
		]

		const dataSource = workforce_periods.map((data) => {
			const rowData = { ...data, key: data.id }
			return rowData
		})

		const renderTable = () => {
			return (
				<Table
					columns={tableColumns}
					dataSource={dataSource}
					pagination={false}
					scroll={{
						x: 'max-content',
					}}></Table>
			)
		}

		const renderInfo = () => {
			if (displayType === 'table') return renderTable()
			return renderList()
		}

		return (
			<div>
				{pagination}
				{networkOnly({
					status: API_USERS_WORKFORCE_PERIOD_INDEX_STATUS,
					data: workforce_periods,
					render: renderInfo,
				})}

				{pagination}
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div></div>
					<div className='action-buttons top'>
						<Tooltip title='Create a new record'>
							<Button type='primary' ghost onClick={handleCreate}>
								Create WorkForce Period
							</Button>
						</Tooltip>
					</div>
				</div>

				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-period-view'))
		return <Redirect to='/' />

	return (
		<div className='page-content budgets-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_WORKFORCE_PERIOD_INDEX_STATUS,
					data: workforce_periods,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(WorkForcePeriodIndex)

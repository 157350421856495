import { API, generic } from "api"

export default class IncentivesService {
  static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_INDEX_STATUS",
      stateDataKey: [
        "incentive_configurations",
        "total",
        "page",
        "page_size",
        "criteria_keys",
      ],
      stateErrorKey: [
        "incentivesErrors",
        "totalErrors",
        "pageErrors",
        "pageSizeErrors",
        "criteriaKeysErrors",
      ],
      apiEndpoint: API.users.incentives.index,
      apiUrlReplacements: {},
      apiData: { params: { page, page_size: pageSize, bcsla } },
      errorMessage: "Incentives could not be fetched.",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_CREATE_STATUS",
      stateDataKey: ["incentive_configuration"],
      stateErrorKey: ["incentiveErrors"],
      apiEndpoint: API.users.incentives.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Incentive could not be created.",
    })
  }

  static show = async ({ pageState, incentiveId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_SHOW_STATUS",
      stateDataKey: ["incentive_configuration"],
      stateErrorKey: ["incentiveErrors"],
      apiEndpoint: API.users.incentives.show,
      apiUrlReplacements: { incentiveId },
      apiData: {},
      errorMessage: "Incentive could not be fetched.",
    })
  }

  static update = async ({ pageState, values, incentiveId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_UPDATE_STATUS",
      stateDataKey: ["incentive_configuration"],
      stateErrorKey: ["incentiveErrors"],
      apiEndpoint: API.users.incentives.update,
      apiUrlReplacements: { incentiveId },
      apiData: { ...values },
      errorMessage: "Incentive could not be updated.",
    })
  }

  static import = async ({ pageState, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_IMPORT_STATUS",
      stateDataKey: ["incentive_configuration"],
      stateErrorKey: ["incentiveErrors"],
      apiEndpoint: API.users.incentives.import,
      apiUrlReplacements: {},
      apiData: formData,
      errorMessage: "Incentives could not be imported.",
    })
  }

  static export = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_EXPORT_STATUS",
      stateDataKey: ["incentive_configurations"],
      stateErrorKey: ["incentivesErrors"],
      apiEndpoint: API.users.incentives.export,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Incentives could not be exported.",
    })
  }

  static refresh = async ({ pageState, fitmentId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_REFRESH_STATUS",
      stateDataKey: ["fitment"],
      stateErrorKey: ["fitmentErrors"],
      apiEndpoint: API.users.incentives.refresh,
      apiUrlReplacements: { fitmentId },
      apiData: {},
      errorMessage: "Incentive could not be refreshed.",
    })
  }

  static delete = async ({ pageState, incentiveId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_DELETE_STATUS",
      stateDataKey: ["incentive_configuration"],
      stateErrorKey: ["incentiveErrors"],
      apiEndpoint: API.users.incentives.delete,
      apiUrlReplacements: { incentiveId },
      apiData: {},
      errorMessage: "Incentive could not be deleted.",
    })
  }

  static auditReport = async ({ pageState, incentiveId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_AUDIT_REPORT_STATUS",
      stateDataKey: ["incentive_configuration"],
      stateErrorKey: ["incentiveErrors"],
      apiEndpoint: API.users.incentives.auditReport,
      apiUrlReplacements: { incentiveId },
      apiData: {},
      errorMessage: "Audit report could not be exported.",
    })
  }

  static auditReports = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_INCENTIVES_AUDIT_REPORTS_STATUS",
      stateDataKey: ["incentive_configurations"],
      stateErrorKey: ["incentivesErrors"],
      apiEndpoint: API.users.incentives.auditReports,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Audit reports could not be exported.",
    })
  }
}

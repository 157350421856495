import { store } from '@risingstack/react-easy-state'
// import { getFitmentList, getFitment } from "mock-data/fitments.mockdata"

const globalStore = store({
	siderCollapsed: false,
	currentUser: null,
	currentOrganization: {},
	organizationFields: {},
	ui: {
		login: {},
		me: {},
		adminUsers: {
			organizations: {
				index: {},
				show: {},
				new: {},
				users: {
					index: {},
					show: {},
					new: {},
				},
			},
		},
		users: {
			organizations: {
				show: {},
				edit: {},
			},
			index: {},
			show: {},
			edit: {},
			new: {},
			notifications: {
				index: {},
				show: {},
			},
			positionReportings: {
				orgChart: {},
			},
		},
		fitments: {
			index: {},
			show: {},
			edit: {},
			additionDocumentsIndex: {},
		},
		candidates: {
			show: {},
			edit: {},
		},
		fitmentFieldsConfigurations: {
			index: {
				defaultPage: {},
			},
			show: {},
			edit: {},
			new: {},
		},
		documents: {
			index: {},
			show: {},
			edit: {},
		},
		vendors: {
			index: {},
			show: {},
			edit: {},
		},
		salaryApprovals: {
			show: {},
			pendingApprovals: {},
		},
		fitmentApprovals: {
			show: {},
			pendingApprovals: {},
		},
		budgetApproval: {
			pendingBudgetApprovals: {
				index: {},
				show: {},
			},
		},
		configuration: {
			fitments: {},
			ssConfigurations: {
				index: {},
				show: {},
				edit: {},
			},
			budgetTentativeValues: {
				index: {},
				show: {},
			},
			offerLetterTemplates: {
				index: {},
				show: {},
				edit: {},
			},
			letterTemplates: {
				index: {},
				show: {},
				edit: {},
			},
			emailTemplates: {
				index: {},
				show: {},
				edit: {},
			},
			salaryRanges: {
				index: {},
				show: {},
				edit: {},
			},
			budgets: {
				index: {},
				show: {},
				edit: {},
			},
			currentWorkforcePeriod: {},
			workforcePeriod: {
				index: {},
				show: {},
				edit: {},
			},
			minimumWages: {
				index: {},
				show: {},
				edit: {},
			},
			budgetHeads: {
				index: {},
				show: {},
				edit: {},
				new: {},
				budget_analysis: {},
				resourcePlan: {},
				create_children: {},
				budget_data_entry: {},
				budgetUtilizationsData: {
					budget_utilizations: [],
					budget_utilizationsMeta: {},
					API_USERS_BUDGET_UTILIZATIONS_DATA_STATUS: 'fulfilled',
				},
				workforce_data: {},
				workForceTimeline: {},
				workForceMonitoring: {},
				transitionAnalysis: {},
				recruitmentReport: {},
				yearOnYearSummary: {},
			},
			budgetValues: {
				index: {},
				show: {},
				edit: {},
				new: {},
				workforce_sync: {},
				search: {},
			},
			budgetUtilizations: {
				index: {},
				show: {},
				edit: {},
				new: {},
				workforce_sync: {},
				search: {},
			},
			budgetCriteriaHeads: {
				index: {},
				show: {},
				new: {},
				edit: {},
				export: {},
				criteria_values: {},
				criteria_head_list: {},
				budgetCriteriaValues: {
					index: {},
					show: {},
					new: {},
					edit: {},
					parentBudgetCriteriaValue: {},
				},
			},
			organizationMinimumSalaries: {
				index: {},
				show: {},
				edit: {},
			},
			approvalChains: {
				index: {},
				show: {},
				edit: {},
			},
			incentives: {
				index: {},
				show: {},
				edit: {},
			},
			controlFeatures: {
				index: {},
				show: {},
				edit: {},
			},
			countryDetails: {
				index: {},
				show: {},
				new: {},
				edit: {},
			},
		},
		export: {},
		import: {},
		accessGroups: {
			index: {},
			show: {},
			edit: {},
			new: {},
		},
		designations: {
			index: {},
		},
	},
})

export default globalStore

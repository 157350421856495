import React from "react"
import { view } from "@risingstack/react-easy-state"

import ApprovalChainStatusesBox from "./ApprovalChainStatusesBox"
import StyledFitmentItemCard from "components/common/styled-components/StyledFitmentItemCard"

const ApplicableApprovalChains = ({ approvalChains,salaryApprovalChains, fitmentId }) => {
  if (!approvalChains && !salaryApprovalChains) return null

  const approvalChainsName = Object.keys(approvalChains || salaryApprovalChains)

  if (approvalChainsName.length === 0) return null

  const approvalChainsJsx = approvalChainsName.map((acn, i) => {
    const approvalChainStatuses = approvalChains ? approvalChains[acn]:salaryApprovalChains[acn]

    return (
			<div className='approval-chain' key={i}>
				<ApprovalChainStatusesBox
					approvalsId={fitmentId}
					approvalChainStatuses={approvalChainStatuses}
					title={acn}
					showStatus={false}
					ifEmptyMessage='Approval chain not found'
				/>
			</div>
		)
  })

  return (
		<StyledFitmentItemCard>
			<div className='header'>
				<h1>{`Applicable ${salaryApprovalChains ? 'Salary ':''}Approval Chains`}</h1>
			</div>
			<div className='approval-chains'>{approvalChainsJsx}</div>
		</StyledFitmentItemCard>
	)
}

export default view(ApplicableApprovalChains)

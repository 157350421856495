import React, { useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import { EyeOutlined, DownOutlined, RedoOutlined } from '@ant-design/icons'
import { message, Button, Tooltip, Pagination, Input, Table, Menu, Dropdown } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import RefreshButton from 'components/RefreshButton'
import FitmentService from 'services/users/fitments.service'
import { staleWhileRevalidate } from 'utils/render-strategies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import globalStore from 'store/index'
import {
	faCheck,
	faCheckDouble,
} from '../../../node_modules/@fortawesome/free-solid-svg-icons/index'

const AdditionalFitmentsDocumentIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.fitments.additionDocumentsIndex
	const [fitments, setFitments] = useState(null)
	const [tableDataLoading, setTableDataLoading] = useState(false)
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []
	const [selectedIds, setSelectedIds] = useState()
	const [selectedRowKeys, setSelectedRowKeys] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.fitments.additionDocumentsIndex
			setTableDataLoading(true)
			await FitmentService.additionDocumentsIndex({ pageState })
			setTableDataLoading(false)

			const { fitments } = pageState

			if (fitments) {
				setFitments(fitments)
				return
			}

			message.error('Failed to load fitments')
		}

		fetchData()
	}, [])

	const {
		fitmentsMeta,
		// displayType = "table",
	} = pageState

	const repaginate = async (page, pageSize, email) => {
		const tempState = {}

		setTableDataLoading(true)

		if (email) {
			await FitmentService.additionDocumentsIndex({
				pageState: tempState,
				page,
				pageSize,
				email,
			})
		} else {
			await FitmentService.additionDocumentsIndex({ pageState: tempState, page, pageSize })
		}

		setTableDataLoading(false)

		const { fitments, fitmentsMeta } = tempState

		if (fitments) {
			setFitments(fitments)
			pageState.fitmentsMeta = fitmentsMeta
			return
		}
	}

	const { total, page: currentPage, page_size: pageSize } = fitmentsMeta || {}

	const pagination = (
		<Pagination
			current={currentPage}
			total={total}
			onChange={repaginate}
			onShowSizeChange={repaginate}
			pageSize={pageSize || 25}
			pageSizeOptions={[10, 25, 50, 100]}
		/>
	)

	const handleFitmentClick = (fitmentId) => {
		if (!fitmentId) return null
		history.push(`/additional-fitment-document/${fitmentId}`)
	}

	const formatFitments = () => {
		if (!fitments) return []
		if (!Array.isArray(fitments)) return []
		return fitments.map((fitment) => {
			return {
				key: fitment?.id,
				fitment_id: fitment.id,
				status: fitment?.status,
				letter_status: fitment?.letter_status,
				ref_number: fitment?.job?.ref_number,
				...fitment['candidate'],
			}
		})
	}

	const handleRefreshBulkLetters = async () => {
		const tempState = {}
		const values = { fitment_ids: selectedIds }
		await FitmentService.refreshBulkDocusignLetterStatus({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
		setSelectedRowKeys([])
	}
	const handleRefreshDocusignLetterStatus = async (fitmentId) => {
		const tempState = {}
		await FitmentService.refreshDocusignLetterStatus({ pageState: tempState, fitmentId })
		const { fitmentErrors, fitments } = tempState
		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		// message.success('')
		setSelectedRowKeys([])
		setFitments(fitments)
	}
	const handleGenerateBulkLetters = async () => {
		const tempState = {}
		const values = { fitment_ids: selectedIds }
		await FitmentService.generateBulkLetters({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
		setSelectedRowKeys([])
	}
	const handleSendBulkLetters = async () => {
		const tempState = {}
		const values = { fitment_ids: selectedIds }
		await FitmentService.sendBulkLetters({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
		setSelectedRowKeys([])
	}
	const handleSendBulkLettersViaDocusign = async () => {
		const tempState = {}
		const values = { fitment_ids: selectedIds }
		await FitmentService.sendBulkLettersViaDocusign({ pageState: tempState, values })
		const { fitmentErrors, message: messages } = tempState

		message.destroy()
		if (fitmentErrors) {
			message.error(fitmentErrors)
			return
		}
		message.success(messages)
		setSelectedRowKeys([])
	}

	const sendDocumentButtonMenu = (
		<Menu>
			<Menu.Item key='0'>
				<Button type='link' ghost onClick={handleSendBulkLetters}>
					Send Via Email
				</Button>
			</Menu.Item>
			<Menu.Item key='1'>
				<Button type='link' ghost onClick={handleSendBulkLettersViaDocusign}>
					Send Via Docusign
				</Button>
			</Menu.Item>
		</Menu>
	)

	const letterStatusColor = (status) => {
		if (['created'].includes(status)) return <FontAwesomeIcon icon={faCheck} className='fa-sm' />
		if (['sent', 'sent_via_docusign'].includes(status))
			return <FontAwesomeIcon icon={faCheckDouble} className='fa-sm' />
		if (['received_via_docusign'].includes(status))
			return <FontAwesomeIcon icon={faCheckDouble} className='fa-sm' style={{ color: 'green' }} />
		return <FontAwesomeIcon icon={faCheck} className='fa-sm' style={{ color: 'transparent' }} />
	}

	const statusColor = (status) => {
		if (['details_updated', 'validated', 'in_progress'].includes(status)) return 'orange'
		if (['approved'].includes(status)) return 'green'
		if (['rejected', 'cancelled'].includes(status)) return 'red'
		return 'gray'
	}

	const handleTableChange = async (pagination, filters, sorter) => {
		const tempState = {}
		const searchData = {
			letter_statuses: filters.letter_status,
		}

		setTableDataLoading(true)
		await FitmentService.search({ pageState: tempState, values: searchData })
		setTableDataLoading(false)

		const { fitments } = tempState

		if (fitments) {
			setFitments(fitments)
			return
		}
	}

	const renderFitments = () => {
		const formattedFitments = formatFitments()

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: 'REF NUMBER',
				dataIndex: 'ref_number',
				key: 'ref_number',
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				filters: [
					{
						text: 'Pending',
						value: 'pending',
					},
					{
						text: 'Validated',
						value: 'validated',
					},
					{
						text: 'Approved',
						value: 'approved',
					},
					{
						text: 'In progress',
						value: 'in_progress',
					},
					{
						text: 'Cancelled',
						value: 'cancelled',
					},
				],
				render: (status, row) => {
					return (
						<div className='fitment__status-box'>
							<Tooltip title={status && status.split('_').join(' ')}>
								<div className={`fitment__status ${statusColor(status)}`}></div>
							</Tooltip>
							<span>{status}</span>
						</div>
					)
				},
			},
			{
				title: 'Letter Status',
				dataIndex: 'letter_status',
				key: 'letter_status',
				filters: [
					{
						text: 'Draft',
						value: 'draft',
					},
					{
						text: 'Created',
						value: 'created',
					},
					{
						text: 'Sent',
						value: 'sent',
					},
					{
						text: 'Sent via Docusign',
						value: 'sent_via_docusign',
					},
					{
						text: 'Received via Docusign',
						value: 'received_via_docusign',
					},
				],
				render: (status, row) => {
					return (
						<div className='fitment__status-box'>
							<Tooltip title={status && status.split('_').join(' ')}>
								{/* <div className={`fitment__status ${statusColor(status)}`}></div> */}
								{letterStatusColor(status)}
							</Tooltip>
							<span>{status.split('_').join(' ')}</span>
						</div>
					)
				},
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				align: 'center',
				render: (action, row) => {
					return (
						<div className='icon-box'>
							<Button type='link' onClick={() => handleFitmentClick(row.fitment_id)}>
								<EyeOutlined />
							</Button>
							<Button type='link' onClick={() => handleRefreshDocusignLetterStatus(row.fitment_id)}>
								<RedoOutlined rotate={270} />
							</Button>
						</div>
					)
				},
			},
		]

		const rowSelection = {
			selectedRowKeys,
			onChange: (selectedRowKeys, selectedRows) => {
				setSelectedRowKeys(selectedRowKeys)
				setSelectedIds(selectedRows.map((data) => data.fitment_id))
			},
			getCheckboxProps: (record) => ({
				disabled: !['approved', 'docusign_completed', 'docusign_inprogress'].includes(
					record?.status
				),
			}),
		}

		return (
			<div style={{ margin: '10px 0px' }}>
				<Table
					rowSelection={{
						type: 'checkbox',
						...rowSelection,
					}}
					columns={columns}
					dataSource={formattedFitments}
					loading={tableDataLoading}
					onChange={handleTableChange}
					pagination={false}
				/>
			</div>
		)
	}

	const renderPageContent = () => {
		const emailSearch = (
			<Input.Search
				placeholder='Email/Name'
				onSearch={(email) => repaginate(1, pageSize, email)}
				style={{ width: 200, marginBottom: '20px' }}
			/>
		)

		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div>{emailSearch}</div>
					<div
						className='action-buttons top'
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: '20px',
						}}>
						<RefreshButton
							key='refresh-button'
							type='primary'
							style={{ marginRight: 10 }}
							ghost
							refresh={handleRefreshBulkLetters}
						/>
						<Button
							type='primary'
							ghost
							style={{ marginRight: 10 }}
							onClick={handleGenerateBulkLetters}>
							Generate Additional Document
						</Button>
						<Dropdown overlay={sendDocumentButtonMenu}>
							<Button>
								Send Document <DownOutlined />
							</Button>
						</Dropdown>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
					}}>
					{pagination}
					<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}></div>
				</div>
				{renderFitments()}
				{pagination}
			</div>
		)
	}

	const { API_FITMENTS_INDEX_STATUS } = pageState

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('additional-document-send'))
		return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_FITMENTS_INDEX_STATUS,
					data: fitments,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(AdditionalFitmentsDocumentIndexPage)

import { API, generic } from 'api'

export default class EmailTemplatesService {
	static index = async ({ pageState, page = 1, pageSize = 25 }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_EMAIL_TEMPLATES_INDEX_STATUS',
			stateDataKey: ['email_templates', 'total', 'page', 'page_size', 'criteria_keys'],
			stateErrorKey: [
				'emailTemplatesErrors',
				'totalErrors',
				'pageErrors',
				'pageSizeErrors',
				'criteriaKeysErrors',
			],
			apiEndpoint: API.users.emailTemplates.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize } },
			errorMessage: 'Email Templates could not be fetched.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_EMAIL_TEMPLATES_CREATE_STATUS',
			stateDataKey: ['email_template'],
			stateErrorKey: ['emailTemplateErrors'],
			apiEndpoint: API.users.emailTemplates.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Email Template could not be created.',
		})
	}

	static show = async ({ pageState, emailTemplateId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_EMAIL_TEMPLATES_SHOW_STATUS',
			stateDataKey: ['email_template'],
			stateErrorKey: ['emailTemplateErrors'],
			apiEndpoint: API.users.emailTemplates.show,
			apiUrlReplacements: { emailTemplateId },
			apiData: {},
			errorMessage: 'Email Template could not be fetched.',
		})
	}

	static update = async ({ pageState, values, emailTemplateId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_EMAIL_TEMPLATES_UPDATE_STATUS',
			stateDataKey: ['email_template'],
			stateErrorKey: ['emailTemplateErrors'],
			apiEndpoint: API.users.emailTemplates.update,
			apiUrlReplacements: { emailTemplateId },
			apiData: { ...values },
			errorMessage: 'Email Template could not be updated.',
		})
	}

	static refresh = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_EMAIL_TEMPLATES_REFRESH_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.emailTemplates.refresh,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Email Template could not be refreshed.',
		})
	}
	static createDefaultBudgetApprovalTemplate = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_EMAIL_TEMPLATES_CREATE_DEFAULT_BUDGET_APPROVAL_TEMPLATE_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['errors'],
			apiEndpoint: API.users.emailTemplates.createDefaultBudgetApprovalTemplate,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Budget Approval Template could not be created.',
			serializedResponse: false,
		})
	}
}

import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Input, message, Row, Col, DatePicker, Select, Space } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import globalStore from 'store/index'

import WorkForcePeriodService from 'services/users/workforce-period.service'

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`


const WorkForcePeriodNew = () => {
	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})

  const handleInputChange = (e, inputType) => {
    
		if (inputType === 'date') {
			setInputValues({ ...inputValues, start_date: e[0], end_date: e[1] })
			return
		}

		if (inputType === 'current') {
			setInputValues({ ...inputValues, [inputType]: e })
			return
		}

		setInputValues({ ...inputValues, [inputType]: e.target.value })
	}

	const handleUpdate = async () => {
		if (!inputValues['name']) {
			message.error("Name can't be blank")
			return
		}

		if (!inputValues['start_date'] && !inputValues['end_date']) {
			message.error("Date can't be blank")
			return
		}

		const pageState = {}
		const postData = {
			workforce_period: {
				...inputValues,
			},
		}

		setCreateButtonLoading(true)
		await WorkForcePeriodService.create({
			pageState,
			values: postData,
		})
		setCreateButtonLoading(false)

		const { serverStatus, budgetErrors } = pageState

		if (budgetErrors) {
			message.error(budgetErrors[0] || "Workforce Period couldn't be created.")
			return
		}

		if (serverStatus.status === 200) {
			message.success('Workforce Period Created.')
			setInputValues({})
			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<h1>Workforce Period</h1>
				<Row gutter={20}>
					<Col span={24}>
						<div className='input-box'>
							<strong>Name</strong>
							<Input value={inputValues['name']} onChange={(e) => handleInputChange(e, 'name')} />
						</div>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={10}>
						<div className='input-box'>
							<Space align='center'>
								<strong>Date :</strong>
								<DatePicker.RangePicker
									format='DD-MMM-YYYY'
									onChange={(e) => handleInputChange(e, 'date')}
								/>
							</Space>
						</div>
					</Col>
					<Col span={10}>
						<Space align='center' style={{ marginTop: 10 }}>
							<strong>Current :</strong>
							<Select placeholder='Select option' defaultValue={false} onChange={(e) => handleInputChange(e, 'current')}>
								<Select.Option value={true}>True</Select.Option>
								<Select.Option value={false}>False</Select.Option>
							</Select>
						</Space>
					</Col>
				</Row>

				<div className='action-buttons submit'>
					<Button type='primary' loading={createButtonLoading} onClick={handleUpdate}>
						Create WorkForce Budget
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-period-edit'))
		return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(WorkForcePeriodNew)

import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, Redirect } from "react-router-dom"
import styled from "styled-components"
import {
  EyeOutlined,
  InboxOutlined,
  DeleteOutlined,
  FileSearchOutlined,
  EditOutlined,
  DownOutlined,
} from "@ant-design/icons"
import {
  message,
  Button,
  Empty,
  Pagination,
  Table,
  List,
  Input,
  Modal,
  Upload,
  Tooltip,
  Popconfirm,
  Dropdown,
  Menu,
} from "antd"

import { hideExportButtons } from "utils/export"
import StyledBox from "components/common/styled-components/StyledBox"
import StyledList from "components/common/styled-components/StyledList"
import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"
import globalStore from "store/index"
import IncentivesService from "services/users/incentives.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`

const IncentivesIndexPage = (props) => {
  const history = useHistory()
  const pageState = globalStore.ui.configuration.incentives.index
  const [exporting, setExporting] = useState(false)
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [fileList, setFileList] = useState([])
  const [importing, setImporting] = useState(false)
  const [exportingAuditReports, setExportingAuditReports] = useState(false)

  const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  useEffect(() => {
    const fetchIncentives = async () => {
      const pageState = globalStore.ui.configuration.incentives.index

      await IncentivesService.index({ pageState })

      const { serverStatus, incentivesErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (incentivesErrors) {
        message.error(incentivesErrors[0] || "Coundn't fetch salary ranges")
        return
      }
    }

    fetchIncentives()
  }, [])

  const repaginate = async (page, pageSize, bcsla = null) => {
    if (bcsla) {
      await IncentivesService.index({ pageState, page, pageSize, bcsla })
      return
    }
    await IncentivesService.index({ pageState, page, pageSize })
  }

  const handleCreate = () => {
    history.push("/configuration/incentives/new")
  }

  const openImportModal = () => {
    setImportModalVisible(true)
  }

  const handleImport = async () => {
    if (fileList.length === 0) return

    const tempState = {}

    const formData = new FormData()

    formData.append("file", fileList[0])

    setImporting(true)
    await IncentivesService.import({ pageState: tempState, formData })
    setImporting(false)

    setImportModalVisible(false)

    message.success(
      "Import started. Kindly check import page in Report Center to see imported incentives"
    )
  }

  const handleExport = async () => {
    const tempState = {}

    setExporting(true)
    await IncentivesService.export({ pageState: tempState })
    setExporting(false)

    message.success(
      "Export started. Kindly check export page in Report Center to see exported incentives"
    )
  }

  const handleExportAuditReports = async () => {
    const tempState = {}

    setExportingAuditReports(true)
    await IncentivesService.auditReports({ pageState: tempState })
    setExportingAuditReports(false)

    message.success(
      "Export started. Kindly check export page in Report Center to see exported audit reports"
    )
  }

  const handleExportAuditReport = async (incentiveId) => {
    const tempState = {}

    await IncentivesService.auditReport({ pageState: tempState, incentiveId })

    message.success(
      "Export started. Kindly check export page in Report Center to see exported audit reports"
    )
  }

  const handleCardItemClick = (incentiveId) => {
    history.push(`/configuration/incentives/${incentiveId}`)
  }

  const handleEdit = (incentiveId) => {
    history.push(`/configuration/incentives/${incentiveId}/edit`)
  }

  const handleDelete = async (incentiveId) => {
    const tempState = {}

    await IncentivesService.delete({ pageState: tempState, incentiveId })

    const { serverStatus } = tempState

    if (serverStatus.status !== 200) return

    globalStore.ui.configuration.incentives.index = {
      ...globalStore.ui.configuration.incentives.index,
      incentive_configurations: incentive_configurations.filter(
        (incentive_configuration) => incentive_configuration.id !== incentiveId
      ),
    }
  }

  const exportButtonMenu = (
    <Menu>
      <Menu.Item key="0">
        <Button type="link" ghost loading={exporting} onClick={handleExport}>
          Export
        </Button>
      </Menu.Item>
      <Menu.Item key="1">
        <Button
          type="link"
          ghost
          loading={exportingAuditReports}
          onClick={handleExportAuditReports}
        >
          {exportingAuditReports
            ? "Exporting Audit Reports..."
            : "Export Audit Reports"}
        </Button>
      </Menu.Item>
    </Menu>
  )

  const {
    incentive_configurations,
    incentive_configurationsMeta,
    API_USERS_INCENTIVES_INDEX_STATUS,
    displayType = "table",
  } = pageState

  const {
    total,
    page: currentPage,
    page_size: pageSize,
    criteria_keys,
  } = incentive_configurationsMeta || {}

  const renderList = () => {
    return (
      <StyledList>
        {incentive_configurations.map((ic) => (
          <div
            className="item-card"
            key={`item-card-${ic.id}`}
            onClick={() => handleCardItemClick(ic.id)}
          >
            <div className="details-box">
              <div className="name-box">
                <div className="name">{ic.name}</div>
                <div>{}</div>
              </div>
            </div>
            <div className="icon-box">
              <Button type="link">
                <EyeOutlined />
              </Button>
            </div>
          </div>
        ))}
      </StyledList>
    )
  }

  const renderContents = () => {
    if (incentive_configurations.length === 0) {
      return (
        <Empty>
          <Button type="primary" ghost onClick={handleCreate}>
            Create Incentive
          </Button>
        </Empty>
      )
    }

    const pagination = (
      <Pagination
        current={currentPage}
        total={total}
        onChange={repaginate}
        onShowSizeChange={repaginate}
        pageSize={pageSize || 25}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    )

    const tableColumns = [
      ...criteria_keys.map((ck) => ({
        title: ck,
        dataIndex: ck,
        key: ck,
        render: (filteredCriteria, row) => {
          if (!filteredCriteria) return "--" // if null
          if (!filteredCriteria[0]) return "--" // If [null]
          if (filteredCriteria.length === 0) return "---" // if []

          const dataSource = filteredCriteria.filter((fc) => fc) // FIXME: need to fix

          return (
            <List
              dataSource={dataSource}
              renderItem={(values) => {
                return values.join(", ")
              }}
            ></List>
          )
        },
      })),
      ...[
        {
          title: "Frequency",
          dataIndex: "frequency",
          key: "frequency",
        },
        {
          title: "Currency",
          dataIndex: "currency",
          key: "currency",
        },
        {
          title: "Payout Type",
          dataIndex: "payout",
          key: "payout",
        },
        {
          title: "Payout Value",
          dataIndex: "payout_value",
          key: "payout_value",
        },

        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          align: "center",
          render: (value, ic) => {
            return (
              <div>
                <Tooltip title="View">
                  <Button
                    type="link"
                    onClick={() => handleCardItemClick(ic.id)}
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Edit">
                  <Button type="link" onClick={() => handleEdit(ic.id)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => handleDelete(ic.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip title="Export audit report">
                  <Button
                    type="link"
                    onClick={() => handleExportAuditReport(ic.id)}
                  >
                    <FileSearchOutlined />
                  </Button>
                </Tooltip>
              </div>
            )
          },
        },
      ],
    ]

    const dataSource = incentive_configurations.map((ic) => {
      const retval = { ...ic, key: ic.id }
      criteria_keys.forEach((ck) => {
        retval[ck] = ic.criteria.map((conditions) => {
          const relevantCondition = conditions.find((c) => c.field === ck)
          if (!relevantCondition) return null
          return relevantCondition.values
        })
      })
      return retval
    })

    const renderTable = () => {
      return (
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            x: "max-content",
          }}
        ></Table>
      )
    }

    const renderInfo = () => {
      if (displayType === "table") return renderTable()
      return renderList()
    }

    return (
      <div>
        {pagination}
        <br />
        {networkOnly({
          status: API_USERS_INCENTIVES_INDEX_STATUS,
          data: incentive_configurations,
          render: renderInfo,
        })}

        {pagination}
      </div>
    )
  }

  const renderPageContent = () => {
    const bcslaSearch = (
      <Input.Search
        placeholder="BCSLA"
        onSearch={(bcsla) => repaginate(1, pageSize, bcsla)}
        style={{ width: 200, marginBottom: "10px" }}
      />
    )

    return (
      <StyledPageContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{bcslaSearch}</div>
          <div className="action-buttons top">
            <Tooltip title="Create or update multiple records by upload">
              <Button onClick={openImportModal} style={{ marginRight: 10 }}>
                Import
              </Button>
            </Tooltip>
            {hideExportButtons(accessibleFeatures) && (
              <React.Fragment>
                <Dropdown overlay={exportButtonMenu}>
                  <Button style={{ marginRight: 10 }}>
                    Export <DownOutlined />
                  </Button>
                </Dropdown>

                {/* <Tooltip title="Prepares report for download, available under export section">
                  <Button
                    loading={exporting}
                    onClick={handleExport}
                    style={{ marginRight: 10 }}
                  >
                    Export
                  </Button>
                </Tooltip>
                <Tooltip title="Export audit reports of incentives">
                  <Button
                    loading={exportingAuditReports}
                    onClick={handleExportAuditReports}
                    style={{ marginRight: 10 }}
                  >
                    {exportingAuditReports
                      ? "Exporting Audit Reports..."
                      : "Export Audit Reports"}
                  </Button>
                </Tooltip> */}
              </React.Fragment>
            )}
            <Tooltip title="Create a new record">
              <Button type="primary" ghost onClick={handleCreate}>
                Create Incentive
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="content">{renderContents()}</div>
      </StyledPageContent>
    )
  }

  const handleSampleFileDownload = () => {
    window.open(
      "https://storage.googleapis.com/ps-in-images-compensation/images/pdf/1096/incentive_configuration_importer_sample.xlsx?1630656317"
    )
  }

  const uploadProps = {
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([file])
      return false
    },
    fileList,
  }

  if (
    accessibleFeatures?.length > 0 &&
    !accessibleFeatures.includes("fitment-data-view")
  )
    return <Redirect to="/" />

  return (
    <div className="page-content incentives-page">
      <StyledBox>
        {staleWhileRevalidate({
          status: API_USERS_INCENTIVES_INDEX_STATUS,
          data: incentive_configurations,
          render: renderPageContent,
        })}
      </StyledBox>
      <Modal
        title="Import"
        visible={importModalVisible}
        confirmLoading={importing}
        closable={false}
        maskClosable={false}
        destroyOnClose
        onOk={handleImport}
        okText="Import"
        onCancel={() => setImportModalVisible(false)}
      >
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Please ensure data accuracy in your file for upload. After upload,
            each associated records will be updated directly.
          </p>
        </Upload.Dragger>
        <Button type="link" onClick={handleSampleFileDownload}>
          Sample Import File
        </Button>
      </Modal>
    </div>
  )
}

export default view(IncentivesIndexPage)

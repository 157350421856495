import { view } from '@risingstack/react-easy-state'
import { Redirect } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import BudgetHeadsService from 'services/users/budget-heads.service'
import globalStore from 'store/index'
import ContentLoading from 'components/common/ContentLoading'
import LABELS from 'constants/labels'

import styled from 'styled-components'
import { PlusOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, Input, message, Row, Col, Select, Tooltip, Modal, Upload, Popconfirm } from 'antd'
import dayjs from 'dayjs'

import StyledBox from 'components/common/styled-components/StyledBox'
import ConditionRow from 'components/budget-analysis/ConditionRow'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import { DatePicker } from '../../../../node_modules/antd/lib/index'
import moment from '../../../../node_modules/moment/moment'
import BudgetUtilizationsService from 'services/users/budget-utilizations.service'
import BudgetValuesService from 'services/users/budget-values.service'
import Insight from 'components/budget-analysis/Insight'
import WorkForcePeriodService from 'services/users/workforce-period.service'
import PositionReportingService from 'services/users/position-reportings.service'

import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import { ExportToExcel } from 'components/common/layouts/ExportToExcel'
import PositionService from 'services/users/positions.service'
import BudgetTentativeValuesService from 'services/users/budget-tentative-values.service'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const blankCondition = {
	field: null,
	operator: null,
	values: null,
}

const UpdateEmployee = () => {
	const [loading, setLoading] = useState(false)
	const [workforceBudgetHeadId, setWorkforceBudgetHeadId] = useState()
	const [criteria, setCriteria] = useState([[blankCondition]])
	const [inputValues, setInputValues] = useState({})
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [rate, setRate] = useState(1)
	const [count, setCount] = useState(1)
	const [total, setTotal] = useState(0)
	const [frequency, setFrequency] = useState('one_time')
	const [annualizedRate, setAnnualizedRate] = useState(0)
	const [proportioned, setProportioned] = useState(1)
	const dateFormat = 'DD-MMM-YYYY'
	const currentYear = new Date().getFullYear()
	const [dateRange, setDateRange] = useState([
		moment.utc(currentYear + '-04-01'),
		moment.utc(currentYear + 1 + '-03-31'),
	])
	const [insight, setInsight] = useState('')
	const [insightStatus, setInsightStatus] = useState('')
	const [importSampleFileModalVisible, setImportSampleFileModalVisible] = useState(false)
	const [fileList, setFileList] = useState([])
	const [importing, setImporting] = useState(false)
	const [importVacancyModalVisible, setImportVacancyModalVisible] = useState(false)
	const [importingVacancy, setImportingVacancy] = useState(false)
	const [workforcePeriod, setWorkForcePeriod] = useState(null)
	const [workforcePeriodId, setWorkforcePeriodId] = useState(null)
	const [sampleFileData, setSampleFileData] = useState(null)
	const [currency, setCurrency] = useState()
	const [accessGroups, setAccessGroups] = useState(null)
	const [importTransitionModalVisible, setImportTransitionModalVisible] = useState(false)
	const [criteriaChildren, setCriteriaChildren] = useState([])
	const [createVia, setCreateVia] = useState('withoutApproval')
	const enableAllocationFeature = globalStore?.currentOrganization?.enable_allocation_feature
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []
	const roles = globalStore.currentUser?.roles || []
	const wfmCurrentPeriod = globalStore.ui.configuration.currentWorkforcePeriod

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		setCurrency(globalStore.currentOrganization.currency || 'INR')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalStore.currentOrganization.currency])

	useEffect(() => {
		if (wfmCurrentPeriod.workforce_period) {
			const startDate = wfmCurrentPeriod?.workforce_period?.start_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.start_date)
				: moment.utc().set('month', 3)
			const endDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.end_date)
				: moment.utc().set('month', 4).add(1, 'y')
			setDateRange([startDate, endDate])
		}
	}, [wfmCurrentPeriod, wfmCurrentPeriod.workforce_period])

	useEffect(() => {
		const fetchBudgetHeads = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.index
			setLoading(true)
			await BudgetHeadsService.index({ pageState })
			const { budget_heads } = pageState
			if (!budget_heads) {
				message.error('Budget Heads not found')
				return
			}
			const workforceBudgetHead = budget_heads.find((bh) => bh.name === 'Workforce Plan')
			setWorkforceBudgetHeadId(workforceBudgetHead.id)
			setLoading(false)
		}

		const fetchAllFieldsData = async () => {
			const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		const fetchWorkforcePeriod = async () => {
			const pageState = globalStore.ui.configuration.workforcePeriod.index

			await WorkForcePeriodService.index({ pageState })

			const { serverStatus, workforcePeriodsErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (workforcePeriodsErrors) {
				message.error(workforcePeriodsErrors[0] || "WorkForce Period couldn't be fetched.")
				return
			}
			const { workforce_periods } = pageState
			setWorkForcePeriod(workforce_periods)
		}
		const fetchPosition = async () => {
			const tempState = {}
			await PositionService.workforcePositions({ pageState: tempState })
			const { access_groups } = tempState
			setAccessGroups(access_groups)
		}

		fetchPosition()
		fetchAllFieldsData()
		fetchWorkforcePeriod()

		fetchBudgetHeads()
	}, [])

	useEffect(() => {
		if (!roles.includes('super-admin') && accessibleFeatures.includes('initiate-budget-approval')) {
			setCreateVia('withApproval')
		}
	}, [accessibleFeatures, roles])

	useEffect(() => {
		if (criteriaChildren.length) {
			const updateCriteriaData = criteria[0].map((data) => {
				if (criteriaChildren.some((items) => items.name === data.field)) {
					return { ...data, values: null, operator: null }
				}
				return data
			})
			setCriteria([updateCriteriaData])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteriaChildren])

	useEffect(() => {
		const fetchSampleFile = async () => {
			const tempState = {}
			await BudgetUtilizationsService.sampleFileData({
				pageState: tempState,
				budgetHeadId: workforceBudgetHeadId,
			})

			const { data, dataErrors } = tempState
			if (dataErrors) {
				message.error(dataErrors || 'Sample File could not be fetched.')
				return
			}
			setSampleFileData(data)
		}

		const fetchEssentialFieldsList = async () => {
			const tempState = {}
			await BudgetUtilizationsService.essentialFieldsList({
				pageState: tempState,
				budgetHeadId: workforceBudgetHeadId,
			})

			const { budget_criteria_heads, dataErrors } = tempState
			if (dataErrors) {
				message.error(dataErrors || 'Sample File could not be fetched.')
				return
			}
			const formatCriteriaList = budget_criteria_heads?.map((data) => {
				const returnData = { field: data?.name, values: null, operator: null }
				return returnData
			})
			setCriteria(formatCriteriaList?.length ? [formatCriteriaList] : [[blankCondition]])
		}

		const fetchBudgetValues = async () => {
			const pageState = globalStore.ui.configuration.budgetValues.index

			await BudgetValuesService.index({ pageState, budgetHeadId: workforceBudgetHeadId })

			const { serverStatus, budgetValuesErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetValuesErrors) {
				message.error(budgetValuesErrors[0] || "Couldn't fetch Budget Values")
				return
			}
		}

		if (workforceBudgetHeadId) {
			fetchSampleFile()
			fetchEssentialFieldsList()
			fetchBudgetValues()
		}
	}, [workforceBudgetHeadId])

	const openImportSampleFileModal = () => {
		setImportSampleFileModalVisible(true)
	}

	const handleImportSampleFile = async () => {
		if (fileList.length === 0) return

		const tempState = {}

		const formData = new FormData()

		formData.append('file', fileList[0])

		setImporting(true)
		await PositionReportingService.import({ pageState: tempState, formData })
		setImporting(false)

		message.success('Import started. Kindly check import page in Report Center to see imported')
	}

	const handleImportTransition = async () => {
		if (fileList.length === 0) return

		const tempState = {}

		const formData = new FormData()

		formData.append('file', fileList[0])

		setImporting(true)
		await PositionReportingService.importTransition({ pageState: tempState, formData })
		setImporting(false)

		message.success('Import started. Kindly check import page in Report Center to see imported')
	}

	const handleInputChange = (value, inputType) => {
		let newInputValues = null

		if (inputType === 'frequency') {
			newInputValues = { ...inputValues, [inputType]: value }
			setFrequency(value)
			setAnnualizedRate(getAnnualizedRate(rate, value))
			// getInsights(rate, count, total)
			setTotal(getTotal(count, rate * getFrequencyDays(value), proportioned, value))
		} else {
			newInputValues = { ...inputValues, [inputType]: value }
		}

		if (inputType === 'rate') {
			setRate(value)
			setTotal(getTotal(count, getAnnualizedRate(value, frequency), proportioned, frequency))
			setAnnualizedRate(getAnnualizedRate(value, frequency))
			// getInsights(value, count, value * count)
		}

		if (inputType === 'unit') {
			setCount(value)
			setTotal(getTotal(value, annualizedRate, proportioned, frequency))
		}

		if (inputType === 'total_value') {
			setTotal(value)
			setRate(value / count)
			setAnnualizedRate(getAnnualizedRate(value / count, frequency))
			// getInsights(value/count, count, value)
		}

		if (inputType === 'currency') {
			setCurrency(value)
		}

		setInputValues(newInputValues)
	}

	const handleDateChange = (range) => {
		if (range) {
			setDateRange([range[0], range[1]])

			setProportioned(getProportionedFactor(range[0], range[1]))

			setInputValues({
				...inputValues,
				start_date: dateRange[0],
				end_date: dateRange[1],
			})
			setTotal(
				getTotal(count, annualizedRate, getProportionedFactor(range[0], range[1]), frequency)
			)
		}
	}

	const getTotal = (count, annualizedRate, proportioned, frequency) => {
		if (frequency === 'one_time') {
			return count * annualizedRate
		} else {
			return count * annualizedRate * proportioned
		}
	}

	const getProportionedFactor = (startDate, endDate) => {
		return Math.round((getPeriodDays(startDate, endDate) / 365) * 100) / 100
	}

	const getAnnualizedRate = (rate, frequency) => {
		return rate * getFrequencyDays(frequency)
	}

	const getFrequencyDays = (frequency) => {
		if (frequency === 'daily') {
			return 365.0
		} else if (frequency === 'half_yearly') {
			return 2 // 2.027
		} else if (frequency === 'quarterly') {
			return 4 // 4.05
		} else if (frequency === 'monthly') {
			return 12 // 12.16
		} else if (frequency === 'weekly') {
			return 52 // 52.14
		} else if (frequency === 'annually') {
			return 1
		} else if (frequency === 'one_time') {
			return 1
		}
	}

	const getPeriodDays = (startDate, endDate) => {
		const oneDay = 24 * 60 * 60 * 1000
		const days = Math.round(Math.abs((endDate - startDate) / oneDay))
		return days + 1
	}

	const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(deletedConditionIndex, 1)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleCreateConditionValues = ({ conditionSetIndex, conditionIndex, condition }) => {
		if (!condition) return
		if (!condition.values) return

		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				return newConditionSet.map((c, index) => {
					if (index !== conditionIndex) return c

					return condition
				})
			})

			return newCriteria
		})
	}

	const handleValidate = async () => {
		inputValues['currency'] = 'INR'
		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues['total_value'] = total
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()
		inputValues['frequency'] = frequency
		inputValues['annualized_rate'] = annualizedRate

		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}
		if (!inputValues['frequency']) {
			message.error("Frequency can't be blank")
			return
		}

		const pageState = {}
		const postData = {
			budget_utilization: {
				...inputValues,
			},
		}

		await BudgetUtilizationsService.validateActualBudget({
			pageState,
			values: postData,
			budgetHeadId: workforceBudgetHeadId,
		})

		const { serverStatus, insight, insightErrors } = pageState

		if (insightErrors) {
			setInsight(insightErrors)
			setInsightStatus('error')
			// message.error(budgetUtilizationErrors || "Actual Budget couldn't be created.")
			return
		}
		const insightMessage = insight?.message
		setInsight(insightMessage ? insightMessage : 'Go Ahead')
		setInsightStatus(insight?.status)

		if (serverStatus.status === 200) {
			return
		}

		message.error('Oops!! something went wrong')
	}

	const handleSampleFileDownload = () => {
		window.open(
			'https://storage.googleapis.com/ps-in-images-compensation/images/pdf/2216/sample_file_for_Organization_chart.xlsx?1677480600'
		)
	}
	const handleTransitionDownload = () => {
		window.open(
			'https://storage.googleapis.com/ps-in-images-compensationsationsationsationsationsationsation/images/pdf/2217/Sample_file_for_Transition_Report.xlsx?1677674420'
		)
	}

	const handleCreate = async () => {
		inputValues['currency'] = currency
		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues['total_value'] = total
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()
		inputValues['frequency'] = frequency

		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}
		if (!inputValues['frequency']) {
			message.error("Frequency can't be blank")
			return
		}

		if (!inputValues['position_code']) {
			message.error("Position Code can't be blank")
			return
		}

		if (!inputValues['employee_code']) {
			message.error("Employee Code can't be blank")
			return
		}

		const pageState = {}
		const tentativeData = {
			budget_utilization: {
				criteria: criteria.filter((c) => c.length > 0),
				...inputValues,
			},
		}

		const postData = {
			budget_tentative_value: {
				budget_head_id: workforceBudgetHeadId,
				tentative_data: tentativeData,
				budget_type: 'actual',
			},
		}
		if (createVia === 'withApproval')
			await BudgetTentativeValuesService.create({
				pageState,
				values: postData,
			})
		else
			await BudgetUtilizationsService.addEmployee({
				pageState,
				values: tentativeData,
				budgetHeadId: workforceBudgetHeadId,
			})

		const { serverStatus, budgetUtilizationErrors } = pageState
		// setInsight(budget_utilization.insight_message)
		// setInsightStatus(budget_utilization.insight_status)

		if (budgetUtilizationErrors) {
			message.error(budgetUtilizationErrors)
			return
		}

		if (serverStatus.status === 200) {
			message.success('Employee created successfully.')
			setCriteria([[blankCondition]])
			setInputValues({})
			return
		}

		message.error('Oops!! something went wrong')
	}

	const openImportVacancyModal = () => {
		setImportVacancyModalVisible(true)
	}

	const handleImportVacancy = async () => {
		if (fileList.length === 0) return

		const tempState = {}

		const formData = new FormData()

		formData.append('file', fileList[0])
		formData.append('workforce_period_id', workforcePeriodId)

		setImportingVacancy(true)
		await BudgetUtilizationsService.importVacancy({
			pageState: tempState,
			formData,
			budgetHeadId: workforceBudgetHeadId,
		})
		setImportingVacancy(false)

		setImportVacancyModalVisible(false)

		const { budgetValueErrors } = tempState

		if (budgetValueErrors) {
			message.error(budgetValueErrors || 'Import Failed')
			return
		}

		message.success('Import started. Kindly check import page in Report Center to see imported')
	}

	const handleSync = async (values) => {
		const pageState = globalStore.ui.configuration.budgetUtilizations.workforce_sync
		await BudgetUtilizationsService.sync({
			pageState,
			values,
		})

		const { serverStatus, budgetUtilizationErrors } = pageState
		if (budgetUtilizationErrors) {
			message.error(budgetUtilizationErrors || "Actual Budget couldn't be created.")
			return
		}

		if (serverStatus.status === 200) {
			message.success('Process Initiated')
			return
		}

		message.error('Oops!! something went wrong')
	}
	const uploadProps = {
		accept:
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
		onRemove: (file) => {
			const index = fileList.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)

			setFileList(newFileList)
		},
		beforeUpload: (file) => {
			setFileList([file])
			return false
		},
		fileList,
	}

	const openImportTransitionModal = () => {
		setImportTransitionModalVisible(true)
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h1>Actual Value for Workforce Plan </h1>
					<div className='action-buttons top'>
						{accessibleFeatures?.length > 0 &&
							accessibleFeatures.includes('organization-chart-edit') && (
								<Tooltip title='Create or update multiple records by upload'>
									<Button onClick={openImportSampleFileModal} style={{ marginRight: 10 }}>
										Org Chart Import
									</Button>
								</Tooltip>
							)}
						<Tooltip title='Create or update multiple records by upload'>
							<Button onClick={openImportTransitionModal} style={{ marginRight: 10 }}>
								Import Transition
							</Button>
						</Tooltip>
						<Tooltip title='Create or update multiple records by upload'>
							<Button onClick={openImportVacancyModal} style={{ marginRight: 10 }}>
								Import Actual Data
							</Button>
						</Tooltip>
						<Tooltip title='Sync hirereview with payreview'>
							<Popconfirm
								title='Sync for'
								okText='Active'
								cancelText='Inactive'
								placement='bottomLeft'
								onCancel={() => handleSync('inactive')}
								onConfirm={() => handleSync('active')}>
								<Button style={{ marginRight: 10 }}>Sync</Button>
							</Popconfirm>
						</Tooltip>
					</div>
				</div>
				<Row gutter={20}>
					<Col span={16}>
						<div className='input-box'>
							<div>
								<strong>
									Period <span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<DatePicker.RangePicker
								format={dateFormat}
								value={dateRange}
								onChange={handleDateChange}
							/>
						</div>
					</Col>
					<Col span={8} pull={1}>
						<div className='input-box'>
							<strong>Proportioned Factor</strong>
							<Input placeholder='Proportioned Factor' value={proportioned} disabled={true} />
						</div>
					</Col>
					<Col span={2}>
						<div className='input-box'>
							<strong>Currency</strong>
							<Input
								placeholder='Currency'
								value={currency}
								onChange={(e) => handleInputChange(e.target.value, 'currency')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>
								Headcount <span style={{ color: 'red' }}>*</span>
							</strong>
							<Input
								placeholder='Enter Count'
								disabled={true}
								value={count || inputValues['unit']}
								onChange={(e) => handleInputChange(e.target.value, 'unit')}
							/>
						</div>
					</Col>
					<Col span={5}>
						<div className='input-box'>
							<strong>
								Salary Rate per person
								<span style={{ color: 'red' }}>*</span>
							</strong>
							<Input
								placeholder='Enter Rate'
								value={rate || inputValues['rate']}
								onChange={(e) => handleInputChange(e.target.value, 'rate')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>
								Frequency <span style={{ color: 'red' }}>*</span>
							</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose frequency'
								defaultValue={frequency}
								onChange={(value) => handleInputChange(value, 'frequency')}>
								<Select.Option value='daily'>Daily</Select.Option>
								<Select.Option value='weekly'>Weekly</Select.Option>
								<Select.Option value='monthly'>Monthly</Select.Option>
								<Select.Option value='quarterly'>Quarterly</Select.Option>
								<Select.Option value='half_yearly'>Half Yearly</Select.Option>
								<Select.Option value='annually'>Annually</Select.Option>
								<Select.Option value='one_time'>One Time</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Annualised Rate</strong>
							<Input placeholder='Annualised Rate' value={annualizedRate} disabled={true} />
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Total Cost</strong>
							<Input
								placeholder='Total Cost'
								value={total || inputValues['total_value']}
								onChange={(e) => handleInputChange(e.target.value, 'total_value')}
							/>
						</div>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={6}>
						<div className='input-box'>
							<strong>Position Type</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose Position Type'
								onChange={(value) => handleInputChange(value, 'position_type')}>
								<Select.Option value='replacement'>Replacement</Select.Option>
								<Select.Option value='new'>New</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={6}>
						<div className='input-box'>
							<div>
								<strong>
									Position Code <span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<Select
								style={{ width: '100%' }}
								placeholder='Choose Position Code'
								value={inputValues['position_code']}
								onChange={(value) => handleInputChange(value, 'position_code')}>
								{accessGroups &&
									accessGroups.map((data, index) => (
										<Select.Option key={index} value={data?.id}>
											{data?.position_code}
										</Select.Option>
									))}
							</Select>
						</div>
					</Col>
					<Col span={6}>
						<div className='input-box'>
							<strong>
								Employee Code <span style={{ color: 'red' }}>*</span>
							</strong>
							<Input
								placeholder='Enter Employee Code'
								value={inputValues['employee_code']}
								onChange={(e) => handleInputChange(e.target.value, 'employee_code')}
							/>
						</div>
					</Col>
					<Col span={6}>
						<div className='input-box'>
							<strong>Employee Name</strong>
							<Input
								placeholder='Enter Employee Name'
								value={inputValues['employee_name']}
								onChange={(e) => handleInputChange(e.target.value, 'employee_name')}
							/>
						</div>
					</Col>
				</Row>
				{enableAllocationFeature && (
					<Row gutter={20}>
						<Col span={6}>
							<div className='input-box'>
								<strong>Allocation Percentage</strong>
								<Input
									placeholder='Enter Allocation Percentage'
									value={inputValues['allocation_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'allocation_percentage')}
								/>
							</div>
						</Col>
						<Col span={6}>
							<div className='input-box'>
								<strong>Increment Percentage</strong>
								<Input
									placeholder='Enter Increment Percentage'
									value={inputValues['increment_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'increment_percentage')}
								/>
							</div>
						</Col>
					</Row>
				)}
				<div className='input-box'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<strong>Criteria</strong>
						{criteria.filter((c) => c.length > 0).length === 0 && (
							<Button shape='circle' onClick={() => setCriteria([[blankCondition]])}>
								<PlusOutlined />
							</Button>
						)}
					</div>
					{criteria.map((conditionSet, conditionSetIndex) => {
						return (
							<div key={conditionSetIndex}>
								{conditionSet.map((condition, conditionIndex) => {
									return (
										<ConditionRow
											criteria={criteria}
											clearChildren={setCriteriaChildren}
											condition={condition}
											key={conditionIndex}
											conditionIndex={conditionSetIndex}
											conditionSetLength={conditionSet.length}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleCreateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											budgetCriteriaHeads={budgetCriteriaHeads}
										/>
									)
								})}
							</div>
						)
					})}
				</div>
				<div className='action-buttons submit'>
					{roles.includes('super-admin') && (
						<>
							<strong>Create Employee via :</strong>
							<Select
								style={{ margin: '0 20px' }}
								placeholder='Choose '
								value={createVia}
								onChange={(value) => setCreateVia(value)}>
								<Select.Option value='withoutApproval'>Without Approval</Select.Option>
								<Select.Option value='withApproval'>With Approval</Select.Option>
							</Select>
						</>
					)}
					<Button type='primary' onClick={handleCreate}>
						{createVia === 'withApproval' ? 'Send for Approval' : 'Save'}
					</Button>
					<Button type='link' onClick={handleValidate}>
						Validate
					</Button>
				</div>
				<Insight insight={insight} insightStatus={insightStatus} />
			</StyledConfigurationFormBox>
		)
	}

	if (
		accessibleFeatures?.length > 0 &&
		!['criteria-edit', 'wfm-employee-edit', 'budget-data-edit'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/' />

	if (loading) {
		return <ContentLoading />
	}

	if (workforceBudgetHeadId)
		return (
			<div className='page-content dashboard-page'>
				<StyledBox>{renderPageContent()}</StyledBox>
				<Modal
					title='Import Actual Data'
					visible={importVacancyModalVisible}
					confirmLoading={importingVacancy}
					closable={false}
					maskClosable={false}
					destroyOnClose
					onOk={handleImportVacancy}
					okText='Import'
					onCancel={() => setImportVacancyModalVisible(false)}>
					<Upload.Dragger {...uploadProps}>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p className='ant-upload-text'>Click or drag file to this area to upload</p>
						<p className='ant-upload-hint'>
							Please ensure data accuracy in your file for upload. After upload, each associated
							records will be updated directly.
						</p>
					</Upload.Dragger>
					{workforcePeriod && (
						<Select
							placeholder='Select Workforce Period'
							onChange={(value) => setWorkforcePeriodId(value)}
							style={{ width: '98%', padding: '20px 0 10px 10px' }}>
							{workforcePeriod.map((data, index) => (
								<Select.Option key={index} value={data.id}>
									{data.name}
								</Select.Option>
							))}
						</Select>
					)}
					<br />
					<ExportToExcel data={sampleFileData} fileName='sample_file_update_employee' />
				</Modal>
				<Modal
					title='Import'
					visible={importSampleFileModalVisible}
					confirmLoading={importing}
					closable={false}
					maskClosable={false}
					destroyOnClose
					onOk={handleImportSampleFile}
					okText='Import'
					onCancel={() => setImportSampleFileModalVisible(false)}>
					<Upload.Dragger {...uploadProps}>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p className='ant-upload-text'>Click or drag file to this area to upload</p>
						<p className='ant-upload-hint'>
							Please ensure data accuracy in your file for upload. After upload, each associated
							records will be updated directly.
						</p>
					</Upload.Dragger>
					<Button type='link' onClick={handleSampleFileDownload}>
						Sample Import File
					</Button>
				</Modal>
				<Modal
					title='Import Position Transition Analysis'
					visible={importTransitionModalVisible}
					confirmLoading={importing}
					closable={false}
					maskClosable={false}
					destroyOnClose
					onOk={handleImportTransition}
					okText='Import'
					onCancel={() => setImportTransitionModalVisible(false)}>
					<Upload.Dragger {...uploadProps}>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p className='ant-upload-text'>Click or drag file to this area to upload</p>
						<p className='ant-upload-hint'>
							Please ensure data accuracy in your file for upload. After upload, each associated
							records will be updated directly.
						</p>
					</Upload.Dragger>
					<Button type='link' onClick={handleTransitionDownload}>
						Sample Import File
					</Button>
				</Modal>
			</div>
		)
}

export default view(UpdateEmployee)

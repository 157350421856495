import React, { useState, useEffect } from "react"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Select, Input } from "antd"

const FitmentInputGroup = ({
  index,
  fitmentField,
  removeInputGroup,
  addInputGroup,
  updateFitmentFields,
}) => {
  const [dataType, setDataType] = useState(null)
  const [name, setName] = useState(null)

  useEffect(() => {
    if (fitmentField) {
      const { name, data_type } = fitmentField
      setName(name)
      setDataType(data_type)
    }
  }, [fitmentField])

  const handleDataTypeChange = (dataType) => {
    setDataType(dataType)
  }

  const handleNameChange = (e) => {
    const name = e.target.value
    setName(name)

    updateFitmentFields({
      data_type: dataType,
      name,
    })
  }

  return (
    <div className="input-group">
      <Select
        placeholder="Select data type"
        value={dataType}
        style={{ width: 300 }}
        onChange={handleDataTypeChange}
      >
        <Select.Option value="string">Text</Select.Option>
        <Select.Option value="decimal">Number</Select.Option>
      </Select>
      <Input
        placeholder="Enter field name"
        value={name}
        onChange={handleNameChange}
      />
      <div className="action-buttons">
        <Button danger shape="circle" onClick={() => removeInputGroup(index)}>
          <DeleteOutlined />
        </Button>
        <Button type="primary" ghost shape="circle" onClick={addInputGroup}>
          <PlusOutlined />
        </Button>
      </div>
    </div>
  )
}

export default FitmentInputGroup

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useParams } from "react-router-dom"
import ContentLoading from "components/common/ContentLoading"

import OffersService from "services/users/offers.service"

const DocusignOfferDownloadRedirector = () => {
  const { offerUuid } = useParams()

  useEffect(() => {
    const fetchDataAndRedirect = async () => {
      const tempState = {}

      await OffersService.docusignDownload({ pageState: tempState, offerUuid })

      const { redirect_url } = tempState

      // TODO: Handle errors. Show error messages on the same page
      if (redirect_url) window.location = redirect_url
    }
    fetchDataAndRedirect()
  }, [offerUuid])

  return <ContentLoading />
}

export default view(DocusignOfferDownloadRedirector)
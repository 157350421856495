import React from "react"
import { view } from "@risingstack/react-easy-state"

import StyledFitmentDetails from "components/common/styled-components/StyledFitmentDetails"

import JobDetails from "components/JobDetails"
import FieldsWithCTC from "components/FieldsWithCTC"
import ApprovalChainStatusesBox from "components/ApprovalChainStatusesBox"
import CandidateCard from "components/CandidateCard"
import CompensationTable from "components/CompensationTable"
import SSConfiguration from "components/ss/Configuration"
import FitmentDocumentsList from "components/FitmentDocumentsList"
import CriteriaSet from "components/CriteriaSet"

const FitmentView = ({ fitment, actionButtons }) => {
  const offeredBonus = () => {
    const { ss_configuration, incentive_configuration } = fitment
    if (!ss_configuration) return "N/A"
    if (!incentive_configuration) return "N/A"
    const { section_configurations } = ss_configuration

    const head_configurations = section_configurations
      .map((sc) => sc.head_configurations)
      //.flat() // flat method doesn't support in IE - https://stackoverflow.com/a/53022680/4650675
      .reduce((acc, val) => acc.concat(val), []) // Alternative of flat method that support IE
    const bonus_hc = head_configurations.find((hc) => hc.key === "bonus")
    if (!bonus_hc) return "N/A"
    return bonus_hc.computed_value
  }
  // Work form here for budgeted Value
  const budgetMessage = (fitment) => {
    if (!fitment) return null
    if (!fitment.budget) return null
    return `${fitment.budget?.currency} ${fitment.budget?.amount_display}`
  }

  const budget_hash = {
    key: "budget",
    title: "Budget",
    length: fitment.assignable_ids?.budgets_ids?.length || 0,
    resource: fitment.budget,
    details: budgetMessage(fitment),
  }

  const renderBudgetDetails = (item) => {
    if (item.length > 1) {
      return <div>Multiple found with matching criteria</div>
    }

    if (item.length === 0) {
      if (fitment?.fitment_validation?.budget_validation?.amount) {
        return (
          <div>
            Average Amount:{" "}
            {fitment.fitment_validation.budget_validation.amount}
          </div>
        )
      }
      return (
        <div>
          None found with matching criteria. Average value will be considered
        </div>
      )
    }

    return <div>{item.details}</div>
  }

  const renderSSConfiguration = () => {
    const {
      ss_configuration,
      computed_salary_structure_for_display,
      incentive_configuration,
      disable_features,
    } = fitment

    if (!ss_configuration) return null

    if (
      disable_features.includes("minimum_wage") &&
      disable_features.includes("salary_range") &&
      disable_features.includes("organization_minimum_salary") &&
      disable_features.includes("budget")
    ) {
      return null
    }

    if (!incentive_configuration) {
      return (
        <div>
          <h1>Salary Structure</h1>
          Cannot be calculated as incentive configuration has not been assigned
          <br />
          <br />
        </div>
      )
    }

    return (
      <div>
        <h1>Salary Structure</h1>
        <SSConfiguration
          computedSalary={computed_salary_structure_for_display}
          ss_configuration={ss_configuration}
          mode="values"
        />
        <br />
        <br />
      </div>
    )
  }

  const ExtraDetails = () => {
    const { disable_features } = fitment
    if (
      disable_features.includes("budget") &&
      disable_features.includes("salary_range")
    )
      return null

    return (
      <div className="card fitment-fields">
        <div className="header">
          <h1>Extra Details</h1>
        </div>
        {!disable_features.includes("budget") && (
          <div className="item-list">
            <div className="item-row fitment-field">
              <div className="label">Budgeted Salary</div>
              <div>{renderBudgetDetails(budget_hash)}</div>
            </div>
          </div>
        )}
        {!disable_features.includes("salary_range") && (
          <div className="item-list">
            <div className="item-row fitment-field">
              <div className="label">Salary Range</div>
              <div>{`Min: ${fitment.salary_range?.minimum_display} | Mid: ${fitment.salary_range?.median_display} | Max: ${fitment.salary_range?.maximum_display}`}</div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <StyledFitmentDetails>
      {actionButtons && (
        <div className="action-buttons top">{actionButtons}</div>
      )}
      <CandidateCard {...fitment.candidate} />
      <FieldsWithCTC fitment={fitment} externalView />
      <ExtraDetails />
      <CompensationTable fitment={fitment} offeredBonus={offeredBonus()} />
      {renderSSConfiguration()}
      <div className="card" style={{ borderWidth: 0, margin: "30px 0px" }}>
        <div className="header">
          <h1>Criteria</h1>
        </div>
        <div className="item-list">
          {fitment.approval_chain ? (
            <CriteriaSet criteria={fitment.approval_chain.criteria} />
          ) : null}
        </div>
      </div>

      <ApprovalChainStatusesBox
        approvalsId={fitment.id}
        approvalChainStatuses={fitment.approval_statuses}
      />
      <JobDetails fitment={fitment} />
      <div className="card">
        <h1>Documents</h1>
        <FitmentDocumentsList documents={fitment.fitment_documents} />
      </div>
    </StyledFitmentDetails>
  )
}

export default view(FitmentView)

const CONTROL_FEATURE_TYPES = [
	{
		value: 'minimum_wage',
		label: 'Minimum Wage',
	},
	{
		value: 'salary_range',
		label: 'Salary Range',
	},
	{
		value: 'organization_minimum_salary',
		label: 'Organization Minimum Salary',
	},
	{
		value: 'incentive_configuration',
		label: 'Incentive Configuration',
	},
	{
		value: 'budget',
		label: 'Budget',
	},
	{
		value: 'salary_structure',
		label: 'Salary Structure',
	},
	{
		value: 'system_validation',
		label: 'System Validation',
	},
	{
		value: 'ss_configuration',
		label: 'SS Configuration',
	},
	{
		value: 'salary_comparison',
		label: 'Salary Comparison',
	},
	{
		value: 'docusign',
		label: 'Docusign',
	},
	{
		value: 'offer_letter_with_password',
		label: 'Offer Letter With Password',
	},
]

const CONTROL_FEATURE_STATUES = [
  { value: "disable", label: "Disable" },
  { value: "enable", label: "Enable" },
]

export { CONTROL_FEATURE_TYPES, CONTROL_FEATURE_STATUES }

import React, { useState } from "react"
import styled from "styled-components"
import { Input, Button, Empty } from "antd"

const StyledCommentEditor = styled.div`
  & .btn {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0px 5px 0px;
  }
`

const StyledCommentsBox = styled.div`
  & > h1 {
    font-size: 1.5em;
    font-weight: bold;
  }
  & .comment {
    padding: 20px;
    margin: 10px 0px;
    background: #f3f6f8;
    border-radius: 4px;
  }
`

const Comments = ({
  comments,
  onComment,
  commentButtonLoading,
  commentAble = false,
}) => {
  const [comment, setComment] = useState(null)

  const sendComment = async () => {
    if (!comment) return
    setComment(await onComment(comment))
  }

  const renderComments = () => {
    if (!comments) return <Empty description="No comment found" />
    if (!Array.isArray(comments))
      return <Empty description="No comment found" />
    if (comments.length === 0) return <Empty description="No comment found" />

    return comments.map((c) => (
      <div className="comment" key={c.id}>
        <strong>{c.commenter_details.email}</strong>
        <p>{c.text}</p>
      </div>
    ))
  }

  return (
		<StyledCommentsBox id='comments'>
			<h1>Comments</h1>
			{commentAble && (
				<StyledCommentEditor>
					<Input.TextArea
						autoSize={{ minRows: 4 }}
						value={comment}
						onChange={(e) => setComment(e.target.value)}
					/>
					<div className='btn'>
						<Button
							type='primary'
							disabled={!comment}
							loading={commentButtonLoading}
							onClick={sendComment}>
							Comment
						</Button>
					</div>
				</StyledCommentEditor>
			)}
			{renderComments()}
		</StyledCommentsBox>
	)
}

export default Comments

import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import { message, Button } from "antd"

import StyledBox from "components/common/styled-components/StyledBox"
import CriteriaSet from "components/CriteriaSet"

import { staleWhileRevalidate, networkOnly } from "utils/render-strategies"

import globalStore from "store/index"

import AccessGroupsService from "services/users/access-groups.service"

const StyledPageContent = styled.div`
  & > .action-buttons.top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > button {
      margin-right: 10px;
    }
  }
`

const AccessGroupsShowPage = (props) => {
  const pageState = globalStore.ui.accessGroups.show
  const history = useHistory()
  const { accessGroupId } = useParams()

  useEffect(() => {
    const fetchAccessGroup = async () => {
      const pageState = globalStore.ui.accessGroups.show

      await AccessGroupsService.show({ pageState, accessGroupId })

      const { serverStatus, accessGroupErrors } = pageState

      if (serverStatus.status !== 200) {
        message.error("Oops!! something went wrong")
        return
      }

      if (accessGroupErrors) {
        message.error(
          accessGroupErrors[0] || "Access group couldn't be fetched."
        )
        return
      }
    }

    fetchAccessGroup()
  }, [accessGroupId])

  const handleEdit = () => {
    history.push(`/access-groups/${accessGroupId}/edit`)
  }

  const { access_group, API_USERS_ACCESS_GROUPS_SHOW_STATUS } = pageState

  const renderContent = () => {
    if (!access_group) return null

    const { name, tags } = access_group

    return (
      <div>
        <h1>{name}</h1>
        <CriteriaSet criteria={tags} tag />
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <StyledPageContent>
        <div className="action-buttons top">
          <Button type="primary" ghost onClick={handleEdit}>
            Edit Access Group
          </Button>
        </div>
        <div className="content">{renderContent()}</div>
      </StyledPageContent>
    )
  }

  const strategy =
    access_group && access_group.id === accessGroupId
      ? staleWhileRevalidate
      : networkOnly

  // const accessibleFeatures = globalStore.currentUser?.accessible_features || []

  // if (
  //   accessibleFeatures?.length > 0 &&
  //   !accessibleFeatures.includes("fitment-data-view")
  // )
  //   return <Redirect to="/configuration/budgets" />

  return (
    <div className="page-content">
      <StyledBox>
        {strategy({
          status: API_USERS_ACCESS_GROUPS_SHOW_STATUS,
          data: access_group,
          render: renderPageContent,
        })}
      </StyledBox>
    </div>
  )
}

export default view(AccessGroupsShowPage)

import { API, generic } from "api"

export default class CountriesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_USERS_COUNTRIES_INDEX_STATUS",
      stateDataKey: ["countries"],
      stateErrorKey: ["countriesErrors"],
      apiEndpoint: API.adminUsers.countries.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Countries data could not be fetched.",
    })
  }
}

import { API, generic } from "api"

export default class OrganizationsService {
	static index = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_ADMIN_USERS_ORGANIZATIONS_INDEX_STATUS',
			stateDataKey: ['organizations'],
			stateErrorKey: ['organizationsErrors'],
			apiEndpoint: API.adminUsers.organizations.index,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Organizations data could not be fetched.',
		})
	}

	static show = async ({ pageState, organizationId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_ADMIN_USERS_ORGANIZATIONS_SHOW_STATUS',
			stateDataKey: ['organization'],
			stateErrorKey: ['organizationErrors'],
			apiEndpoint: API.adminUsers.organizations.show,
			apiUrlReplacements: { organizationId },
			apiData: {},
			errorMessage: 'Organization data could not be fetched.',
		})
	}

	static update = async ({ pageState, organizationId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_ADMIN_USERS_ORGANIZATIONS_UPDATE_STATUS',
			stateDataKey: ['organization'],
			stateErrorKey: ['organizationErrors'],
			apiEndpoint: API.adminUsers.organizations.update,
			apiUrlReplacements: { organizationId },
			apiData: { ...values },
			errorMessage: 'Organization data could not be updated.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_ADMIN_USERS_ORGANIZATIONS_CREATE_STATUS',
			stateDataKey: ['organization'],
			stateErrorKey: ['organizationErrors'],
			apiEndpoint: API.adminUsers.organizations.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Organization data could not be updated.',
		})
	}

	static defaultWorkforceReason = async ({ pageState, organizationId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_ADMIN_USERS_ORGANIZATIONS_DEFAULT_WORKFORCE_REASON_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['organizationsErrors'],
			apiEndpoint: API.adminUsers.organizations.defaultWorkforceReason,
			apiUrlReplacements: { organizationId },
			apiData: {},
			errorMessage: 'Organizations data could not be fetched.',
			serializedResponse: false,
		})
	}
}

import styled from "styled-components"
import { Color } from "constants/theme"

const StyledList = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  & .item-card {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${Color.secondaryColor};
    margin-bottom: 10px;
    cursor: pointer;
    & > .status-box {
      width: 40px;
      & .status {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin: auto;
      }
      & .status.red {
        background: #d82626;
      }
      & .status.green {
        background: green;
      }
      & .status.orange {
        background: orange;
      }
      & .status.gray {
        background: gray;
      }
    }
    & > div.details-box {
      flex: auto;
      & > .name-box {
        display: flex;
        align-items: baseline;
        & > .name {
          font-size: 1.5em;
          font-weight: bold;
          margin-right: 15px;
        }
      }
    }
    & > div.icon-box {
      max-width: 100px;
      & > button {
        font-size: 1.5em;
        display: flex;
      }
    }
  }
  & .item-card:last-child {
    margin: 0px;
  }
`

export default StyledList

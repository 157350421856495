import { API, generic } from 'api'

export default class BudgetHeadsService {
	static index = async ({ pageState, page = 1, pageSize = 25, bcsla }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_INDEX_STATUS',
			stateDataKey: ['budget_heads', 'total', 'page', 'page_size'],
			stateErrorKey: ['budgetHeadsErrors', 'totalErrors', 'pageErrors', 'pageSizeErrors'],
			apiEndpoint: API.users.budgetHeads.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, bcsla } },
			errorMessage: 'Budget Heads could not be fetched.',
		})
	}

	static budget_analysis = async ({ pageState, criteria, start_date, end_date }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_ANALYSIS_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.budget_analysis,
			apiUrlReplacements: {},
			apiData: { criteria, start_date, end_date },
			errorMessage: 'Budget Heads Report could not be fetched.',
		})
	}

	static budget_entry_data = async ({ pageState, id, startDate, endDate, budgetCriteria }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_ENTRY_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.budget_entry_data,
			apiUrlReplacements: { id },
			apiData: {
				start_date: startDate,
				end_date: endDate,
				criteria: budgetCriteria,
			},
			errorMessage: 'Budget Criteria could not be fetched.',
		})
	}

	static create = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_CREATE_STATUS',
			stateDataKey: ['budget_head'],
			stateErrorKey: ['budgetHeadErrors'],
			apiEndpoint: API.users.budgetHeads.create,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Budget Head could not be created.',
		})
	}

	static createChildren = async ({ pageState, id, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_CREATE_CHILDREN_STATUS',
			stateDataKey: ['budget_head'],
			stateErrorKey: ['budgetHeadErrors'],
			apiEndpoint: API.users.budgetHeads.create_children,
			apiUrlReplacements: { id },
			apiData: { ...values },
			errorMessage: 'Budget Head could not be created.',
		})
	}

	static show = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_SHOW_STATUS',
			stateDataKey: ['budget_head'],
			stateErrorKey: ['budgetHeadsErrors'],
			apiEndpoint: API.users.budgetHeads.show,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Budget Head could not be fetched.',
		})
	}

	static update = async ({ pageState, values, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_UPDATE_STATUS',
			stateDataKey: ['budget_head'],
			stateErrorKey: ['budgetHeadErrors'],
			apiEndpoint: API.users.budgetHeads.update,
			apiUrlReplacements: { budgetHeadId },
			apiData: { ...values },
			errorMessage: 'Budget Head could not be updated.',
		})
	}

	static delete = async ({ pageState, budgetHeadId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEADS_DELETE_STATUS',
			stateDataKey: ['budget_head'],
			stateErrorKey: ['budgetHeadErrors'],
			apiEndpoint: API.users.budgetHeads.delete,
			apiUrlReplacements: { budgetHeadId },
			apiData: {},
			errorMessage: 'Budget head could not be deleted.',
		})
	}

	// static import = async ({ pageState, formData }) => {
	//   await generic({
	//     pageState,
	//     stateApiStatusKey: "API_USERS_BUDGET_CRITERIA_HEADS_IMPORT_STATUS",
	//     stateDataKey: ["budget_criteria_head"],
	//     stateErrorKey: ["budgetCriteriaHeadErrors"],
	//     apiEndpoint: API.users.budget_criteria_heads.import,
	//     apiUrlReplacements: {},
	//     apiData: formData,
	//     errorMessage: "Budget Criteria could not be imported.",
	//   })
	// }

	static budgetUtilizationData = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_DATA_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.budgetUtilizationData,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'Budget utilization data could not be fetched.',
		})
	}

	static export = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_UTILIZATIONS_DATA_EXPORT_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.export,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'Budget head could not be exported.',
		})
	}
	static workforceReport = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WORKFORCE_DATA_STATUS',
			stateDataKey: ['new_data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.workforceData,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'Workforce data could not be fetched.',
			serializedResponse: false,
		})
	}
	static workforceTimeline = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WORKFORCE_TIMELINE_STATUS',
			stateDataKey: ['workforce_timeline_data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.workforceTimeline,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'Workforce Timeline data could not be fetched.',
			serializedResponse: false,
		})
	}
	static workforceMonitor = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WORKFORCE_MONITOR_STATUS',
			stateDataKey: ['workforce_moniter_data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.workforceMonitor,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'Workforce Monitoring data could not be fetched.',
			serializedResponse: false,
		})
	}

	static calculateResourcePlan = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEAD_CALCULATE_RESOURCE_PLAN_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.calculateResourcePlan,
			apiData: postData,
			errorMessage: 'Resource plan data could not be fetched.',
			serializedResponse: false,
		})
	}

	static calculateAllResourcePlan = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_BUDGET_HEAD_CALCULATE_All_RESOURCE_PLAN_STATUS',
			stateDataKey: ['data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.calculateAllResourcePlan,
			apiData: postData,
			errorMessage: 'Resource plan data could not be fetched.',
			serializedResponse: false,
		})
	}

	static recruitmentReport = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_RECRUITMENT_REPORT_STATUS',
			stateDataKey: ['workforce_recruitment_data'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.recruitmentReport,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'Recruitment Report data could not be fetched.',
			serializedResponse: false,
		})
	}

	static yearOnYearSummary = async ({ pageState, postData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_YEAR_ON_YEAR_SUMMARY_STATUS',
			stateDataKey: ['workforce_summary'],
			stateErrorKey: ['dataErrors'],
			apiEndpoint: API.users.budgetHeads.yearOnYearSummary,
			apiUrlReplacements: {},
			apiData: postData,
			errorMessage: 'YOY Summary data could not be fetched.',
			serializedResponse: false,
		})
	}
  static exportPositionWiseReport = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: 'API_USERS_BUDGET_VALUES_SEARCH_STATUS',
      stateDataKey: ['budget_heads'],
      stateErrorKey: ['budgetHeadErrors'],
      apiEndpoint: API.users.budgetHeads.exportPositionWiseReport,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: 'Export position Report.',
    })
  }
}

import React from "react"
import { view } from "@risingstack/react-easy-state"
import { Collapse, Tag, Divider, Table } from "antd"
import { CaretLeftOutlined } from "@ant-design/icons"

const tableColumns = [
  {
    title: "Field",
    dataIndex: "field",
    key: "field",
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
  },
  {
    title: "Values",
    dataIndex: "values",
    key: "values",
    render: (values, row) => {
      return values.join(", ")
    },
  },
]

const CriteriaSet = ({ criteria, table = false, tag = false }) => {
  const render = () => {
    if (table) {
      return criteria.map((conditions, index) => {
        const conditionsWithKeys = conditions.map((c, c_index) => ({
          ...c,
          key: c_index,
        }))
        return (
          <div key={index}>
            {criteria.length > 1 && <h5>Criteria {index + 1}</h5>}
            <Table
              columns={tableColumns}
              dataSource={conditionsWithKeys}
              pagination={false}
            />
          </div>
        )
      })
    }

    if (tag) {
      return (
        <div>
          {criteria.map((c, c_index) => (
            <Tag color="purple" key={c_index} style={{ marginBottom: 5 }}>
              {c.field}: {c.values?.join(", ")}
            </Tag>
          ))}
        </div>
      )
    }

    return criteria.map((conditions, index) => (
      <div key={index} style={{ marginBottom: 10 }}>
        {criteria.length > 1 && <h5>Criteria {index + 1}</h5>}
        {conditions.map((c, c_index) => (
          <Tag color="purple" key={c_index} style={{ marginBottom: 5 }}>
            {c.field}: {c.values?.join(", ")}
          </Tag>
        ))}
      </div>
    ))
  }

  return [
		<Collapse
			expandIcon={({ isActive }) => <CaretLeftOutlined rotate={isActive ? -90 : 0} />}
			expandIconPosition='right'
			key='criteria-collapse'>
			<Collapse.Panel header={criteria && criteria.length <= 1 ? 'Criteria' : 'Criteria Set'}>
				{render()}
			</Collapse.Panel>
		</Collapse>,
		<Divider key='criteria-divider' />,
	]
}

export default view(CriteriaSet)

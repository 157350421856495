import React, { useState } from "react"
import { view } from "@risingstack/react-easy-state"
import { RedoOutlined, SyncOutlined } from "@ant-design/icons"
import { Badge, Collapse, message, Button } from "antd"

import RefreshButton from "components/RefreshButton"
import StyledFitmentItemCard from "components/common/styled-components/StyledFitmentItemCard"

import globalStore from "store"

import MinimumWagesService from "services/users/minimum-wages.service"
// import OfferLetterTemplatesService from "services/users/offer-letter-templates.service"
import FitmentsService from "services/users/fitments.service"
import SalaryRangesService from "services/users/salary-ranges.service"
import BudgetsService from "services/users/budgets.service"
import OrganizationMinimumSalariesService from "services/users/organization-minimum-salaries.service"
// import IncentiveConfigurationsService from "services/users/incentives.service"
// import SSConfigurationsService from "services/users/ss/configurations.service"

// import { pp } from "utils/pp"

const SystemValidationCollapse = ({ fitment, onSetFitment }) => {
  const [revalidateRefreshing, setRevalidateRefreshing] = useState(false)

  const handleRevalidate = async ({ service, errorMessage = "" }) => {
    const tempState = {}
    await service.revalidate({
      pageState: tempState,
      fitmentId: fitment.id,
    })
    const { fitment: newFitment, fitmentErrors } = tempState
    if (fitmentErrors) {
      message.error(fitmentErrors[0] || errorMessage)
    }
    globalStore.ui.fitments.show.fitment = newFitment
    onSetFitment(newFitment)
  }

  const handleRevalidateAll = async () => {
    const tempState = {}

    setRevalidateRefreshing(true)
    await FitmentsService.validateSalary({
      pageState: tempState,
      fitmentId: fitment.id,
    })
    setRevalidateRefreshing(false)

    const { fitment: newFitment, fitmentErrors } = tempState

    if (fitmentErrors) {
      message.error(fitmentErrors[0] || "Salary could not be validated.")
    }

    globalStore.ui.fitments.show.fitment = newFitment
    onSetFitment(newFitment)
  }

  const handleRevalidateMinimumWage = async () => {
    await handleRevalidate({
      service: MinimumWagesService,
      errorMessage: "Minimum wage couldn't be refreshed.",
    })
  }

  const handleRevalidateBudget = async () => {
    await handleRevalidate({
      service: BudgetsService,
      errorMessage: "Budget couldn't be refreshed.",
    })
  }

  const handleRevalidateSalaryRange = async () => {
    await handleRevalidate({
      service: SalaryRangesService,
      errorMessage: "Salary Range couldn't be refreshed.",
    })
  }

  const handleRevalidateOrganizationMinimumSalary = async () => {
    await handleRevalidate({
      service: OrganizationMinimumSalariesService,
      errorMessage: "Organization minimum salary couldn't be refreshed.",
    })
  }

  const data = [
    {
      key: "minimum-wage",
      title: "Minimum Wage",
      resource: fitment.fitment_validation?.minimum_wage_validation,
      disabled: fitment.disable_features.includes("minimum_wage"),
      details:
        fitment.fitment_validation?.minimum_wage_validation?.messages?.join(
          " | "
        ),
      revalidate: handleRevalidateMinimumWage,
    },
    {
      key: "salary-range",
      title: "Salary Range",
      resource: fitment.fitment_validation?.salary_range_validation,
      disabled: fitment.disable_features.includes("salary_range"),
      details:
        fitment.fitment_validation?.salary_range_validation?.messages?.join(
          " | "
        ),
      revalidate: handleRevalidateSalaryRange,
    },
    {
      key: "organization-minimum-salary",
      title: "Organization Minimum Salary",
      resource:
        fitment.fitment_validation?.organization_minimum_salary_validation,
      disabled: fitment.disable_features.includes(
        "organization_minimum_salary"
      ),
      details:
        fitment.fitment_validation?.organization_minimum_salary_validation?.messages?.join(
          " | "
        ),
      revalidate: handleRevalidateOrganizationMinimumSalary,
    },
    {
      key: "budget",
      title: "Budget",
      resource: fitment.fitment_validation?.budget_validation,
      disabled: fitment.disable_features.includes("budget"),
      details:
        fitment.fitment_validation?.budget_validation?.messages?.join(" | "),
      revalidate: handleRevalidateBudget,
    },
  ]

  const renderDetails = (item) => {
    if (!item.resource) {
      return (
        <div>
          Not yet validated. {renderRefreshButton(item.refresh)}{" "}
          {/* <RefreshButton refresh={item.refresh} /> */}
        </div>
      )
    }
    return (
      <div style={{ color: "black" }}>
        {item.details}
        {/* <RefreshButton refresh={item.revalidate} /> */}
        {renderRefreshButton(item.revalidate)}
      </div>
    )
  }

  // const renderRefreshButton = (item) => {
  //   var status = fitment.status
  //   if (status !== "in_progress" && status !== "approved") {
  //     return <RefreshButton refresh={item} />
  //   }
  // }

  const statusFor = (item) => {
    // if (item.key === "budget" && item.status === 0) return "warning"
    if (item.resource?.status !== "valid") return "error"
    return "success"
  }

  const renderRefreshButton = (refresh) => {
    var status = fitment.status
    if (status !== "in_progress" && status !== "approved") {
      return <RefreshButton refresh={refresh} />
    }
  }

  const renderHeader = (item) => {
    return <Badge status={statusFor(item)} text={item.title} />
  }

  const renderRevalidateButtonIcon = () => {
    if (revalidateRefreshing) return <SyncOutlined spin />
    return <RedoOutlined rotate={270} />
  }

  const filteredConfigurations = data.filter((d) => !d.disabled)

  if (filteredConfigurations.length === 0) return null

  return (
    <StyledFitmentItemCard>
      <div className="header">
        <h1>System Validation</h1>
        {false && (
          <Button onClick={handleRevalidateAll} disabled={revalidateRefreshing}>
            {renderRevalidateButtonIcon()}
            Revalidate
          </Button>
        )}
      </div>
      <Collapse>
        {filteredConfigurations.map((item, i) => (
          <Collapse.Panel
            key={i}
            header={renderHeader(item)}
            showArrow={false}
            disabled={true}
            extra={renderDetails(item)}
          ></Collapse.Panel>
        ))}
      </Collapse>
    </StyledFitmentItemCard>
  )
}

export default view(SystemValidationCollapse)
